import { collection, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from 'react';
import { LicenseeSettings } from "./licenseeSettings";

//
// Loads all licenseeSettings mapped by licenseeId.
// This can only be used by superAdmin.
//

export const useLicenseeSettingsById = () => {
    const [licenseeSettingsById, setLicenseeSettingsById] = useState<{
        [ licenseeId: string ]: LicenseeSettings
    }>();

    useEffect(() => {
        return onSnapshot(
            query(
                collection(firestore, 'licenseeSettings')
            ),
            (snapshot) => {
                const byId = {} as {
                    [ licenseeId: string ]: LicenseeSettings
                };
                snapshot.docs.forEach((doc) => {
                    byId[doc.id] = doc.data() as LicenseeSettings;
                });
                setLicenseeSettingsById(byId);
            }, (error) => {
                // This should be very rare
                console.log('Failed to access licensee data', error.message, error);
            }
        );
    }, []);

    return licenseeSettingsById;
}
