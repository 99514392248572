// const completedSafetyEquipmentItems = useCompletedSafetyEquipmentItems(showModal ? selectedItem : undefined);

import { useMemo, useState } from "react";
import { renderCategoryName } from "../../lib/categories";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvStatus, formatCsvString } from "../../lib/csv";
import { sharedState } from "../../shared-state/shared-state";
import { SafetyEquipmentItem } from "../../shared-state/VesselSafety/safetyEquipmentItems";
import { formatValue, renderCamelCase } from '../../lib/util';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { SafetyEquipmentTaskCompleted } from '../../shared-state/VesselSafety/useCompletedSafetyEquipmentItems';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { useVesselCompletedSafetyEquipmentItems } from '../../shared-state/VesselSafety/useVesselCompletedSafetyEquipmentItems';
import { useSafetyEquipmentItems } from '../../shared-state/VesselSafety/useSafetyEquipmentExpiries';
import { formatEmailReminder, formatInterval, warnDays } from "../../lib/datesAndTime";
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';
import SeaExporterDateRange from '../../components/SeaExporter/SeaExporterDateRange';

type SafetyEquipmentExpiriesCsvProps = {
    onCompleted: () => void
}

const SafetyEquipmentExpiriesCsv = ({onCompleted}: SafetyEquipmentExpiriesCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const safetyEquipmentExpiries = useSafetyEquipmentItems(dateRange ? true : false);
    const completedSafetyEquipmentsById = useVesselCompletedSafetyEquipmentItems(dateRange);

    const csvConfig: CsvConfig<SafetyEquipmentItem> | undefined = useMemo(() => {
        if (!completedSafetyEquipmentsById) {
            return undefined
        }
        return [
            {
                name: 'Safety Item',
                value: (item) => renderCategoryName(item.itemId, sharedState.vesselSafetyItems.current)
            },
            {
                name: 'Type',
                value: (item) => renderCamelCase(item.type)
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.locationId, sharedState.vesselLocations.current)
            },
            {
                name: 'Critical',
                value: (item) => formatCsvBoolean(item.isCritical)
            },
            {
                name: 'Quantity',
                value: (item) => item.quantity || ''
            },
            {
                name: 'Interval',
                value: (item) => item.type === 'servicable' ? formatInterval(item.interval) : ''
            },
            {
                name: 'Service Task',
                value: (item) => item.type === 'servicable' && item.description ? item.description : ''
            },
            {
                name: 'Service/Expiry date',
                value: (item) => item.type !== 'nonExpiring' ? formatCsvDate(item.whenDue) : ''
            },
            {
                name: 'Expiry Task',
                value: (item) => item.type === 'expiring' && item.description ? item.description : ''
            },
            {
                name: 'Last Service',
                value: (item) => item.type === 'servicable' ? formatCsvDate(item.whenLastChecked) : ''
            },
            {
                name: 'Status',
                value: (item) => item.type !== 'nonExpiring' ? formatCsvStatus(item.whenDue, warnDays.safetyEquipmentExpiries[0], item.hasFault) : ''
            },
            {
                name: 'Notification',
                value: (item) => formatValue(formatEmailReminder(item.emailReminder))
            },
            {
                name: 'Completed',
                items: (item) => completedSafetyEquipmentsById[item.id],
                columns: () => [
                    {
                        name: 'Date Completed',
                        value: (item: SafetyEquipmentTaskCompleted) => formatCsvDate(item.whenCompleted)
                    },
                    {
                        name: 'Checked By',
                        value: (item: SafetyEquipmentTaskCompleted) => renderFullNameForUserId(item.completedBy)
                    },
                    {
                        name: 'Notes',
                        value: (item: SafetyEquipmentTaskCompleted) => formatCsvString(item.notes)
                    }
                ]
            },
        ]
    }, [completedSafetyEquipmentsById]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Safety-Equipment-Expiries'} onCompleted={onCompleted} config={csvConfig} data={safetyEquipmentExpiries} />
};

export default SafetyEquipmentExpiriesCsv