import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../lib/firebase';
import { collection, query, where, doc, getDocs, arrayRemove, increment, serverTimestamp } from "firebase/firestore";
import { regions, formatValue } from '../../../lib/util';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { Vessel } from '../../../shared-state/Core/vessel';
import { confirmAction } from '../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { makeBatchTrace } from '../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaGrid from '../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../components/SeaGridCell/SeaGridCell';
import SeaLinkButton from '../../../components/SeaLinkButton/SeaLinkButton';
import SeaButton from '../../../components/SeaButton/SeaButton';
import ReplicateVesselModal from '../ReplicateVesselModal/ReplicateVesselModal';

interface ViewVesselDetailsModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

const ViewVesselDetailsModal: React.FC<ViewVesselDetailsModalProps> = ({showModal, setShowModal}) => {
    const superAdmin = sharedState.superAdmin.use(showModal);
    const user = sharedState.user.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const vessel = sharedState.vessel.use(showModal) as Vessel;
    const [showReplicateVesselModal, setShowReplicateVesselModal] = useState(false);

    const onArchiveVessel = (vessel: Vessel) => {
        if (!licenseeId) {
            throw new Error('licenseeId is not defined');
        }
        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'vessels', 'archive', vessel.id);

        batch.set(
            doc(firestore, 'vessels', vessel.id),
            {
                state: 'archived',
                whenArchived: batchTrace.whenAction,
                archivedBy: user?.id
            },
            {merge: true}
        );
        batch.set(
            doc(firestore, 'userDetails', licenseeId),
            {
                numVessels: increment(-1),
                touched: serverTimestamp()
            },
            { merge: true }
        );
        onCollectionUpdated(batch, 'userDetails');

        getDocs(
            query(
                collection(firestore, 'users'),
                where('licenseeId', '==', licenseeId)
            )
        ).then((snap) => {
            snap.docs.forEach((_doc) => {
                batch.set(
                    doc(firestore, 'users', _doc.id),
                    { vesselIds: arrayRemove(vessel.id) },
                    { merge: true }
                );
                batch.set(
                    doc(firestore, 'userDetails', _doc.id),
                    {
                        emailMeVesselIds: arrayRemove(vessel.id),
                        weeklyReportVesselIds: arrayRemove(vessel.id),
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            });
            return getDocs(
                query(
                    collection(firestore, 'customForms'),
                    where('forVesselIds', 'array-contains', vessel.id)
                )
            );
        }).then((snap) => {
            snap.docs.forEach((_doc) => {
                batch.set(
                    doc(firestore, 'customForms', _doc.id),
                    {
                        forVesselIds: arrayRemove(vessel.id),
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            });

            onCollectionUpdated(batch, 'customForms');

            return getDocs(query(
                collection(firestore, 'risks'),
                where('vesselIds', 'array-contains', vessel.id)
            ));
        }).then((snap) => {
            snap.docs.forEach((_doc) => {
                batch.set(
                    doc(firestore, 'risks', _doc.id),
                    {
                        vesselIds: arrayRemove(vessel.id),
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            });

            onCollectionUpdated(batch, 'risks');

            return getDocs(query(
                collection(firestore, 'risksReviewed'),
                where('vesselIds', 'array-contains', vessel.id)
            ))
        }).then((snap) => {
            snap.docs.forEach((_doc) => {
                batch.set(
                    doc(firestore, 'risksReviewed', _doc.id),
                    {
                        vesselIds: arrayRemove(vessel.id),
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            });

            onCollectionUpdated(batch, 'risksReviewed');

            batchTrace.data = {
                vessel
            };
            batchTrace.save(`Archive vessel ${vessel?.name}`);
            return batch.commit();
        }).then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            batchTrace.reportError(error.message, error);
        });
    };

    return (
        <SeaModal
            title="Vessel Details"
            showModal={showModal}
            setShowModal={setShowModal}
            size="thin"
        >
            <SeaGrid>
                <SeaGridCell label="Callsign" w="50">
                    {formatValue(vessel.callsign)}
                </SeaGridCell>
                <SeaGridCell label={licenseeSettings && regions[licenseeSettings?.region]?.vesselReg} w="50">
                    {formatValue(vessel.mnz)}
                </SeaGridCell>
                <SeaGridCell label="MMSI" w="50">
                    {formatValue(vessel.mmsi)}
                </SeaGridCell>
                <SeaGridCell label="Length" w="50">
                    {formatValue(vessel.length)}
                </SeaGridCell>
                <SeaGridCell label="Area of operation" w="50">
                    {formatValue(vessel.areaOfOperation)}
                </SeaGridCell>
                <SeaGridCell label="Drive Type" w="50">
                    {formatValue(vessel.driveType)}
                </SeaGridCell>
                <SeaGridCell label="Gross Tonnage" w="50">
                    {formatValue(vessel.grossTonnage)}
                </SeaGridCell>
                <SeaGridCell label="Engines" w="50">
                    {formatValue(vessel.enginesDescription)}
                </SeaGridCell>
                <SeaGridCell label="Construction" w="50">
                    {formatValue(vessel.construction)}
                </SeaGridCell>
                <SeaGridCell label="Fuel Range" w="50">
                    {formatValue(vessel.fuelRange)}
                </SeaGridCell>
                <SeaGridCell label="Master's Name" w="50">
                    {formatValue(vessel.master)}
                </SeaGridCell>
                {superAdmin &&
                    <SeaGridCell w="100">
                        <div style={{ height: '20px' }}></div>
                        <SeaButton onClick={(e) => setShowReplicateVesselModal(true)}>
                            Replicate Another Vessel
                        </SeaButton>
                        <div>
                            <SeaLinkButton
                                style={{
                                    marginTop: '16px',
                                    color: 'red',
                                    textDecoration: 'underline'
                                }}
                                onClick={(e) => {
                                    confirmAction(`Are you sure you want to archive this ${(vessel?.isShoreFacility) ? 'shore based facility' : 'vessel'}?`, `Yes, archive ${(vessel?.isShoreFacility) ? 'facility' : 'vessel'}`)
                                        .then(() => {
                                            onArchiveVessel(vessel);
                                        }).catch(() => {});
                                }}
                            >
                                Archive {vessel?.isShoreFacility ? 'Facility' : 'Vessel'}
                            </SeaLinkButton>
                        </div>
                    </SeaGridCell>
                }
            </SeaGrid>
            {showModal &&
                <ReplicateVesselModal
                    showModal={showReplicateVesselModal}
                    setShowModal={setShowReplicateVesselModal}
                />
            }
        </SeaModal>
    );
};

export default ViewVesselDetailsModal;
