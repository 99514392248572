import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { isPasswordOk, passwordRequirements } from '../../../lib/util';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { functions, logPageView } from '../../../lib/firebase';
import { httpsCallable } from "firebase/functions";
import { useFormik } from 'formik';
import Yup from '../../../lib/yup';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaFormError from '../../../components/SeaFormError/SeaFormError';
import CenteredBoxPageLayout from '../../../layouts/CenteredBoxPageLayout/CenteredBoxPageLayout';

const ResetPassword: React.FC = () => {
    const [code, setCode] = useState('');
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const {handleSubmit, handleChange, handleBlur, values, errors, setFieldError, touched, setFieldValue, resetForm} = useFormik({
        initialValues: {
            email: '',
            password: '',
            passwordConfirm: ''
        }, validationSchema: Yup.object({
            password: Yup.string().max(32).required(),
            passwordConfirm: Yup.string().max(32).required()
        }), onSubmit: (data) => {
            //console.log('formik onsubmit data:', data);
            if (!isPasswordOk(data.password)) {
                return;
            }
            if (data.password !== data.passwordConfirm) {
                setFieldError('passwordConfirm', 'Passwords do not match');
                return;
            }
            setLoading(true);
            setFormError('');

            //console.log('calling activateAccount...');
            httpsCallable(functions, 'resetPassword')({
                code: code,
                email: values.email.trim().toLowerCase(),
                password: values.password
            }).then((result: any) => {
                //console.log('httpsCallable result', result);
                if (result && result.data && result.data.error) {
                    return Promise.reject(result.data.error);
                }
                showToast('Your password has been reset!');
                navigate(`/login?email=${ encodeURIComponent(values.email.trim().toLowerCase()) }`);
            }).catch((error) => {
                //console.log('httpsCallable error', error);
                setFormError(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    });

    useEffect(() => {
        resetForm();
        if (searchParams.get('email')) {
            setFieldValue('email', searchParams.get('email') ? searchParams.get('email')!.trim().toLowerCase() : '');
        }
        if (searchParams.get('code')) {
            setCode(searchParams.get('code') ?? '');
        }
        logPageView('ResetPassword');
    }, [searchParams, resetForm, setFieldValue]);

    return (
        <CenteredBoxPageLayout>
            <form onSubmit={handleSubmit}>
                <IonGrid className="auth-form-grid" style={{paddingTop: '8px'}}>
                    <IonRow>
                        <IonCol size="12">
                            <SeaInput
                                label="Email"
                                name="email"
                                value={values.email}
                                zone="white"
                                type="email"
                                inputmode="email"
                                disabled={true}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="New Password"
                                name="password"
                                value={values.password}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="password"
                                disabled={loading}
                                error={
                                    touched.password ? (
                                        (values.password && !isPasswordOk(values.password))
                                        ?
                                        passwordRequirements
                                        :
                                        errors.password
                                    ) : ''
                                }
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="Confirm New Password"
                                name="passwordConfirm"
                                value={values.passwordConfirm}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="password"
                                disabled={loading}
                                error={touched.passwordConfirm ? errors.passwordConfirm : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="form-spacer"></div>
                <SeaFormError message={formError}/>
                <SeaButton zone="white" disabled={loading} type="submit" size="wide">Reset Password</SeaButton>
                <input type="submit" className="submit-enter"/>
            </form>
        </CenteredBoxPageLayout>
    );
};

export default ResetPassword;
