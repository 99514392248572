import React from 'react';
import { pdfStyles } from '../../lib/pdf';
import { View, Text } from '@react-pdf/renderer';
import { formatDatetimeForPdf } from '../../lib/datesAndTime';

interface PdfItemsHeaderProps {
    heading: string,
    subHeading?: string,
    userFullName: string,
    marginBottom?: string
}

const PdfItemsHeader: React.FC<PdfItemsHeaderProps> = ({ heading, subHeading, userFullName, marginBottom = '0.7cm' }) => {
    return (
        <View wrap={false} style={{
            ...pdfStyles.itemsHeader,
            marginBottom: marginBottom
        }}>
            <View style={{flex: 1}}>
                <Text style={pdfStyles.heading}>{heading}</Text>
                {subHeading ?
                    <Text style={pdfStyles.subHeading}>
                        {subHeading}
                    </Text>
                : null}
            </View>
            <View style={pdfStyles.itemsHeaderRight}>
                <Text>{userFullName}, {formatDatetimeForPdf()}</Text>
            </View>
        </View>
    );
};

export default PdfItemsHeader;
