import React from 'react';
import { isPlatform, IonSpinner, IonProgressBar } from '@ionic/react';
import { firestore, splittableBatch, storage } from '../../lib/firebase';
import { collection, doc, setDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { reportError, makeBatchTrace } from '../ErrorsManager/ErrorsManager';
import { toByteArray } from 'base64-js';
import { sharedState, SharedStateConfig } from '../../shared-state/shared-state';
import { convertBase64toBlob, getContentType, isImage, SeaFile } from '../../lib/files';
import { saveFileToLocalStorage } from '../../shared-state/FileSyncSystem/cachedFiles';
import { deleteFile } from '../../shared-state/FileSyncSystem/filesToDelete';
import { uploadFileInBackground } from '../../shared-state/FileSyncSystem/filesToUpload';
import { onFileUploaded } from '../../shared-state/General/appActivity';
import { alertMessage } from '../AlertManager/AlertManager';
import SeaButton from '../../components/SeaButton/SeaButton';

export type FileUpload = {
    progress?: number,
    message?: string,
    fadeIn?: boolean // when set to true, triggers UI to visually fade in
};

export const fileUploadConfig: SharedStateConfig<FileUpload> = {
    isAlwaysActive: true,
    notes: 'Describes the current uploading state that should be displayed to the user (desktop only).'
};

let uploadTask: any = undefined; // Reference to the current upload task as returned by uploadBytesResumable(...)

// Handles common error codes generated when using uploadFiles
// Returns true if the upload error was handled
export const handleUploadError = (error: any) => {
    if (error?.code === 'cancelled') {
        alertMessage('File upload was cancelled');
    } else if (error?.code === 'offline') {
        alertMessage(error.message);
    } else {
        return false; // Did not handle error
    }
    return true;
};

// If on desktop, the user will need to wait for the uploads to occur
// If hybrid, resolve straight away and let uploading happen in the background
export const uploadFiles = (files: SeaFile[], _licenseeId?: string) => {
    const licenseeId = _licenseeId ? _licenseeId : sharedState.licenseeId.current;
    let needToUpload = false;
    for (let i = files.length - 1; i >= 0; i--) {
        //if (files[i] && files[i].id && files[i].state && (files[i].state > 0)) { // already uploaded
        if (files[i] && files[i].state && files[i].state as number > 0) {
            continue;
        }
        if (files[i] === undefined) {
            // Remove undefined array element
            files.splice(i, 1);
            continue;
        }
        needToUpload = true;
        break;
    }
    if (!needToUpload) {
        return Promise.resolve('No files to upload');
    }
    return new Promise((resolve, reject) => {
        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'files', 'create');
        const filesToUpload = [] as SeaFile[];

        files.forEach((file: SeaFile) => {
            if (file === undefined) {
                return;
            }
            if (
                file.id && ( // Having an id means it has been loaded from the database
                    (file.state && file.state > 0) || // <--- we might end up not doing nothing if trapped on another device
                    isPlatform('hybrid')
                )
            ) {
                // Already uploaded (or being uploaded on a device), so don't need to do anything
            } else {
                const fileRef = doc(collection(firestore, 'files'));
                file.id = fileRef.id;
                batchTrace.exampleDocId = fileRef.id;
                file.state = 0;
                batch.set(fileRef, {
                    whenAdded: Date.now(),
                    addedBy: sharedState.userId.current,
                    state: 0,
                    licenseeIds: [licenseeId],
                    uploadFor: {
                        collection: file.collection,
                        field: file.field
                    },
                    ext: file.ext,
                    name: file.name,
                    contentType: file.contentType,
                    lastModified: file.lastModified,
                    isSignature: file.isSignature,
                    emailToken: uuidv4()
                }, {
                    merge: true
                });
                filesToUpload.push(file);
            }
        });

        if (filesToUpload.length > 0) {
            batchTrace.data = {
                files: files.map((file) => {
                    // We need to make sure we don't include base64 data!
                    return {
                        id: file.id,
                        ext: file.ext,
                        collection: file.collection,
                        contentType: file.contentType,
                        field: file.field,
                        lastModified: file.lastModified,
                        name: file.name,
                        state: file.state,
                        unique: file.unique,
                        isSignature: file.isSignature
                    };
                })
            };
            batchTrace.save(`Record files to upload (${filesToUpload.length})`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });
        }

        const saveAllFilesLocally = () => {
            const promises = [] as Promise<any>[];
            filesToUpload.forEach((file: SeaFile) => {
                promises.push(
                    saveFileToLocalStorage(
                        file.id as string,
                        file.ext as string,
                        'O',
                        //file.base64 as string
                        //new Blob([jsonString], { type: 'text/plain' })
                        convertBase64toBlob(file.base64 as string),
                        true // special state = 0 case
                    )
                );
            });
            return Promise.all(promises);
        };

        if (isPlatform('hybrid') && sharedState.licenseeSettings.current?.hasOffline) { // Hybrid mode with SmartSync
            //debugApp('File Uploading', `About to saveAllFilesLocally`);
            saveAllFilesLocally().then(() => {
                console.log(`[File Upload] Succeeded saving all files (${filesToUpload.length})`);
            }).catch((error) => {
                console.log(`[File Upload] Failed to save files (${filesToUpload.length}). error=${JSON.stringify(error)}`);
                reject({message:'Failed to save file(s) to local storage!'});
                reportError(`Failed to upload file(s)`, error.message, error, {
                    filesToUpload
                });
                // If there is an error, we'll want to delete the files that did save
                filesToUpload.forEach((file) => {
                    deleteFile(`O${file.id}.${file.ext}`);
                });
                reject({message: 'Failed to submit file(s)'});
            }).then(() => {
                filesToUpload.forEach((file) => {
                    uploadFileInBackground(`O${file.id}.${file.ext}`)
                });
                resolve('done');
            });
        } else { // Desktop mode OR SmartSync is off
            // Upload while you wait
            if (!sharedState.onlineStatus.current?.isOnline) {
                reject({
                    message: `Failed to upload file${filesToUpload.length === 1 ? '' : 's'} due to being offline.`,
                    code: 'offline'
                });
            } else {
                //setUploading(true);
                sharedState.fileUpload.set({
                    message: 'Uploading...',
                    progress: 0,
                    fadeIn: false
                });

                setTimeout(() => {
                    //setFadeIn(true);
                    sharedState.fileUpload.set((current) => {
                        return {
                            ...current,
                            fadeIn: true
                        };
                    });
                }, 10);

                uploadFilesSequentially(filesToUpload, 0, licenseeId).then(() => {
                    if (sharedState.licenseeSettings.current?.hasOffline) {
                        // Also, let's cache the files locally so they're immediately available
                        saveAllFilesLocally().catch((error) => {
                            //reject(error);
                            // We don't care if this fails because it's an optional nicety
                            console.log('[File Upload] saveAllFilesLocally failed (ignoring)');
                            resolve('done');
                        }).then(() => {
                            console.log('[File Upload] saveAllFilesLocally completed');
                            resolve('done');
                        });
                    } else {
                        resolve('done');
                    }
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    uploadTask = undefined;;
                    // setUploading(false);
                    // setUploadProgress(0.0);
                    // setFadeIn(false);
                    sharedState.fileUpload.clear();
                });
            }
        }
    });
};

const setUploadingMessage = (message: string) => {
    sharedState.fileUpload.set((current) => {
        return {
            ...current,
            message: message
        };
    });
};

const uploadFilesSequentially = (filesToUpload: SeaFile[], index: number, _licenseeId?: string): Promise<string> => {
    if (index >= filesToUpload.length) {
        sharedState.fileUpload.clear();
        uploadTask = undefined;
        return Promise.resolve('done');
    }

    const file = filesToUpload[index] as SeaFile;

    const ref = storageRef(storage, `files/${file.id}.${file.ext}`);
    //const ref = storage.ref().child(`files/${file.id}.${file.ext}`);
    const now = Date.now();
    const metadata = {
        cacheControl: 'public,max-age=30000000',
        customMetadata: {
            licenseeIds: _licenseeId ? _licenseeId : sharedState.licenseeId.current,
            whenUploaded: now,
            whenMetadata: now,
            //passcode: generateCode(20),
            // Desktop upload only values (extra info we know here)
            uploadedBy: sharedState.userId.current,
            collection: file.collection,
            field: file.field
        }
    } as any;

    if (file.name) {
        metadata.contentDisposition = `attachment; filename="${file.name}"`;
    }

    if (file.contentType) {
        metadata.contentType = file.contentType;
    } else if (file.ext && getContentType(file.ext)) {
        metadata.contentType = getContentType(file.ext);
    }
    if (file.base64) {
        //uploadTask = ref.putString(file.base64, 'base64', metadata);
        //uploadTask = uploadString(ref, file.base64, 'base64', metadata);
        uploadTask = uploadBytesResumable(
            ref,
            toByteArray(file.base64),
            metadata
        );
    }
    if (uploadTask) {
        if (filesToUpload.length === 1) {
            if (filesToUpload[0].ext === 'sfdoc') {
                setUploadingMessage('Saving changes. Please wait...');
            } else {
                setUploadingMessage(`Uploading ${file.ext && isImage(file.ext) ? 'image' : 'file'}. Please wait...`);
            }
        } else {
            setUploadingMessage(`Uploading file ${index+1} of ${filesToUpload.length}. Please wait...`);
        }
        const whenStarted = Date.now();
        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snap: any) => {
                    let progress = (snap.bytesTransferred / snap.totalBytes);
                    progress *= (1.0 / filesToUpload.length);
                    progress += (1.0 / filesToUpload.length) * index;
                    sharedState.fileUpload.set((current) => {
                        return {
                            ...current,
                            progress: progress
                        }
                    });
                },
                (error: any) => {
                    console.log('[File Upload] upload error!', error);

                    // Remove file.id and file.state so we don't count it as uploaded
                    delete file.id;
                    delete file.state;
                    switch (error.code) {
                        case 'storage/unauthorized':
                            return reject({message: 'Access to upload was denied'});
                        case 'storage/canceled':
                            return reject({code: 'cancelled', message: 'Upload was cancelled'});
                        // case 'storage/unknown':
                        //     // Unknown error occurred, inspect error.serverResponse
                        //     break;
                    }
                    return reject(error);
                },
                () => {
                    console.log('[File Upload] completed a file');

                    // update state to 1 (uploaded)
                    filesToUpload[index].state = 1;

                    setDoc(
                        doc(firestore, 'files', ''+filesToUpload[index].id),
                        {
                            state: 1
                        },
                        { merge: true }
                    ).then(() => {
                        // successfully updated state in file document
                    }).catch((error) => {
                        reportError('Failed to update file state', error.message, error, {
                            filesToUpload,
                            index
                        });
                    });

                    uploadFilesSequentially(filesToUpload, index + 1).then(() => {
                        onFileUploaded(whenStarted, file.base64!.length);
                        resolve('done');
                    }).catch((error) => {
                        reject(error);
                    });
                }
            );
        });
    }
    return uploadFilesSequentially(filesToUpload, index + 1);
};

const FileUploadManager: React.FC = () => {
    const fileUpload = sharedState.fileUpload.use();

    return (
        <>
            {fileUpload && 
                <div className={`sea-uploading ${ fileUpload.fadeIn ? 'reveal' : 'conceal'}`}>
                    <div className="text">
                        {fileUpload.message}
                        <div className="progress">
                            <IonProgressBar value={fileUpload.progress} />
                        </div>
                        <div className="progress" onClick={(e) => {
                            if (uploadTask) {
                                uploadTask.cancel();
                            }
                        }}>
                            <SeaButton>Cancel</SeaButton>
                        </div>
                    </div>
                    <IonSpinner name="crescent" className="sea-spinner"/>
                </div>
            }
        </>
    );
};

export default FileUploadManager;
