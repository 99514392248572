import React, { useCallback } from 'react';
import { Document, Image, Page, Text, View  } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn, limitPdfText } from '../../../lib/pdf';
import { formatValue, renderCamelCase } from '../../../lib/util';
import { formatDate } from '../../../lib/datesAndTime';
import { incidentTypes } from '../../../pages/HealthSafety/Incidents/incidents-util';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { Incident } from '../../../shared-state/HealthSafety/incidents';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface IncidentEventReportsPdfProps {
    onCompleted: () => void,
    incidents: Incident[],
    vesselId?: string
}

const IncidentEventReportsPdf: React.FC<IncidentEventReportsPdfProps> = ({
    onCompleted,
    incidents,
    vesselId
}) => {
    const vessels = sharedState.vessels.use();
    const vesselName = vesselId && vessels?.byId[vesselId]?.name;
    const title = vesselName ? `Incident / Event Reports - ${vesselName}` : 'Incident / Event Reports';

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        //subHeading={(listType === 'prioritised') ? `Overdue & Due Within ${warnDays.safetyEquipmentChecks[0]} Days` : undefined}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(2)}>
                            <Text>Name</Text>
                        </View>
                        {!vesselName && <View style={styleItemColumn(1)}>
                            <Text>Vessel</Text>
                        </View>}
                        <View style={pdfStyles.numColumn}>
                            <Text>Report #</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Type</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {incidents.map((incident, index) =>  {
                        const file = (incident.files && incident.files.length > 0) ? incident.files[0] : undefined;
                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                        return (
                            <React.Fragment key={incident.id}>
                                {index > 0 &&
                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                }
                                <View wrap={false} style={pdfStyles.itemsRow}>
                                    <View style={pdfStyles.thumbColumn}>
                                        {imgSrc && 
                                            <Image
                                                src={imgSrc}
                                                style={pdfStyles.thumbImg}
                                            />
                                        }
                                    </View>
                                    <View style={styleItemColumn(2)}>
                                        <Text wrap={true}>{formatValue(limitPdfText(incident.name, 80))}</Text>
                                    </View>
                                    {!vesselName && <View style={styleItemColumn(1)}>
                                        <Text>{vessels?.byId[incident.vesselId]?.name}</Text>
                                    </View>}
                                    <View style={pdfStyles.numColumn}>
                                        <Text>{formatValue(incident.reportNum)}</Text>
                                    </View>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>{formatDate(incident.whenAccident)}</Text>
                                    </View>
                                    <View style={styleItemColumn(1)}>
                                        <Text>{formatValue(incidentTypes[incident.type])}</Text>
                                    </View>
                                    <View style={[pdfStyles.statusColumn, pdfStyles.incidentStatus, pdfStyles[incident.state + 'Status' as keyof typeof pdfStyles]]}>
                                        <Text>{renderCamelCase(incident.state)}</Text>
                                    </View>
                                </View>
                            </React.Fragment>
                        )
                        }  
                    )}
                </Page>
            </Document>
        );
    }, [incidents, title, vesselName, vessels?.byId]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="incidentEventReports"
        />
    );
};

export default IncidentEventReportsPdf;
