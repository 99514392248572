import React, { useCallback } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { renderFullName } from '../../../shared-state/Core/users';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import { UserType } from '../../../shared-state/Core/user';
import { TrainingTasksData } from '../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReportsData } from '../../../shared-state/Crew/useTrainingTaskReports';
import { TrainingTask } from '../../../shared-state/Crew/trainingTasks';
import { warnDays } from '../../../lib/datesAndTime';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface CrewTrainningListPdfProps {
    onCompleted: () => void,
    vesselId?: string,
    trainingTasks: TrainingTasksData | undefined,
    trainingTaskReports: TrainingTaskReportsData | undefined
}

const CrewTrainningListPdf: React.FC<CrewTrainningListPdfProps> = ({
    onCompleted,
    vesselId,
    trainingTasks,
    trainingTaskReports
}) => {

    const title = `Crew Training - ${vesselId ? renderVesselName(vesselId) : ''}`;

    const MakePDF = useCallback(() => {
        if (!trainingTasks || !trainingTaskReports) return (<></>);

        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="landscape"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={styleItemColumn(2)}>
                            <Text>Task</Text>
                        </View>
                        {trainingTaskReports?.users?.map((user: UserType) => {
                            if (vesselId && user.vesselIds?.includes(vesselId)) {
                                return (
                                    <View style={styleItemColumn(1)} key={user.id}>
                                        <Text>{renderFullName(user)}</Text>
                                    </View>
                                )
                            }
                            return undefined;
                        })}
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {vesselId && trainingTasks?.allByVesselId[vesselId]?.map((task: TrainingTask, index: number) => {
                        return (
                            <React.Fragment key={task.id}>
                                {index > 0 &&
                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                }
                                <View wrap={false} style={pdfStyles.itemsRow}>
                                    <View style={styleItemColumn(2)}>
                                        <Text>{task.task}</Text>
                                    </View>
                                    {trainingTaskReports?.users?.map((user: UserType) => {
                                        if (vesselId && user.vesselIds?.includes(vesselId)) {
                                            const key = `${task.id}${user.id}`;
                                            const report = trainingTaskReports?.byTaskAndUser[key];
                                            return (
                                                report ?
                                                    <View key={key}
                                                        style={[styleItemColumn(1), {
                                                            margin: '0.1cm',
                                                            padding: '0.1cm',
                                                            textAlign: 'center',
                                                            backgroundColor: (report.whenDueDiff && report.whenDueDiff < 0) ? '#FBD6D6' : ((report.whenDueDiff && report.whenDueDiff <= warnDays.crewTraining[0]) ? '#FFF3CD' : '#D4EDDA'),
                                                            borderRadius: '0.1cm',
                                                        }]}
                                                    >
                                                        <Text style={{
                                                            fontWeight: 500
                                                        }}>
                                                            {report.whenDueDiff && Math.abs(report.whenDueDiff)}
                                                        </Text>
                                                        <Text style={{
                                                            fontWeight: 400,
                                                            fontSize: 6
                                                        }}>
                                                            {report.whenDueDiff === 1 || report.whenDueDiff === -1 ? 'DAY' : 'DAYS'}{report.whenDueDiff && report.whenDueDiff < 0 && ' OD'}
                                                        </Text>
                                                    </View>
                                                    : <View style={styleItemColumn(1)} key={key}><Text></Text></View>
                                            )
                                        }
                                        return undefined;
                                    })}
                                </View>
                            </React.Fragment>
                        )
                    })}


                </Page>
            </Document>
        );
    }, [trainingTasks, trainingTaskReports, vesselId, title]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="CrewTrainningList"
        />
    );
};

export default CrewTrainningListPdf;
