import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../lib/pdf';
import { formatValue, formatTextAreaText, formatVessels } from '../../lib/util';
import { formatDate, formatInterval } from '../../lib/datesAndTime';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../lib/files';
import { renderCategoryName } from '../../lib/categories';
import { renderFullName } from '../../shared-state/Core/users';
import { Risk } from '../../shared-state/HealthSafety/risks';
import { sharedState } from '../../shared-state/shared-state';
import { useRisksReviewed } from '../../shared-state/HealthSafety/useRisksReviewed';
import {renderFullNameForUserId} from '../../shared-state/Core/users';

export interface ControlType {
    name: string,
    description: string
}

interface RiskAssessmentModalPdfProps {
    onCompleted: () => void,
    selectedRisk: Risk,
}

const RiskAssessmentModalPdf: React.FC<RiskAssessmentModalPdfProps> = ({
    onCompleted,
    selectedRisk,
}) => {
    const licenseeSettings = sharedState.licenseeSettings.use(true);
    const vessels = sharedState.vessels.use(true);
    const todayMillis = sharedState.todayMillis.use(true) as number;
    const riskCategories = sharedState.riskCategories.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);
    const risksReviewed = useRisksReviewed(selectedRisk);

    const reportTitle = useMemo(() => {
        if (selectedRisk) {
            return selectedRisk?.name;
        }
        return '';
    }, [selectedRisk]);

    const renderRiskRating = useCallback((preOrPost: 'pre' | 'post', likelihoodId: string, consequenceId: string) => {
        if (!licenseeSettings?.riskRegister) return <></>
        const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId}`];
        const color = raw.substring(0, 6);
        const name = raw.substring(7);
        return (
            <View style={{
                ...pdfStyles.status,
                backgroundColor: `#${color}`,
                position: 'relative',
                width: '100px',
                textAlign: 'center',
            }}>
                <Text>{name}</Text>
            </View>
        );
    }, [licenseeSettings]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedRisk.files || [], setImagesLoaded);
    }, [getImageSrcs, selectedRisk.files]);

    const renderWhenDueClass = useCallback((whenDue) => {
        if (whenDue < todayMillis) {
            return 'fail';
        } else if (whenDue < todayMillis + (30 * 24 * 60 * 60 * 1000)) {
            return 'warn';
        }
        return '';
    }, [todayMillis]);

    const formatTextAreaTextForPdf = (s: string) => {
        if (s) {
            return s.split('\n').map((text, index) => {
                return <Text key={`${text}_${index}`}>
                    {text}
                </Text>;
            });
        }
        else{
            return <Text>-</Text>;
        }
    };

    const MakePDF = useCallback(() => {
        if (!selectedRisk) return (<></>);
        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    {selectedRisk && licenseeSettings?.riskRegister && <>
                        <PdfItemsHeader
                            heading={reportTitle}
                            subHeading=''
                            userFullName={renderFullName()}
                        />

                        {/* Vessels / Facilities and Category */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Vessels / Facilities</Text>
                                <Text>{formatVessels(selectedRisk?.vesselIds, vessels)}</Text>
                            </View>
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Category</Text>
                                <Text>
                                    {formatValue(renderCategoryName(selectedRisk?.categoryId, riskCategories))}
                                </Text>
                            </View>
                        </View>

                        {/* Risks Associated With This Hazard */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.labelHeading}>Risks Associated With This Hazard</Text>
                                {formatTextAreaTextForPdf(selectedRisk?.risks)}
                            </View>
                        </View>

                        <View style={pdfStyles.greyLine}></View>

                        {/* Pre Controls Risk */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.labelHeading}>Pre Controls Risk</Text>
                            </View>
                        </View>

                        {/* Likelihood, Consequence and Risk Rating */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Likelihood</Text>
                                <Text>
                                    {(licenseeSettings.riskRegister.likelihoods[`L${selectedRisk.preControls.likelihood}`] as ControlType).name}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Consequence</Text>
                                <Text>
                                    {(licenseeSettings.riskRegister.consequences[`C${selectedRisk.preControls.consequence}`] as ControlType).name}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Risk Rating</Text>
                                {renderRiskRating('pre', selectedRisk.preControls.likelihood, selectedRisk.preControls.consequence)}
                            </View>
                        </View>

                        <View style={pdfStyles.greyLine}></View>

                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.labelHeading}>Risk Controls</Text>
                                {formatTextAreaTextForPdf(selectedRisk?.controls)}
                            </View>
                        </View>

                        <View style={pdfStyles.greyLine}></View>

                        {/* Post Controls Risk */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.labelHeading}>Post Controls Risk</Text>
                            </View>
                        </View>

                        {/* Likelihood, Consequence and Risk Rating */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Likelihood</Text>
                                <Text>
                                    {(licenseeSettings.riskRegister.likelihoods[`L${selectedRisk.postControls.likelihood}`] as ControlType).name}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Consequence</Text>
                                <Text>
                                    {(licenseeSettings.riskRegister.consequences[`C${selectedRisk.postControls.consequence}`] as ControlType).name}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Risk Rating</Text>
                                    {renderRiskRating('post', selectedRisk.postControls.likelihood, selectedRisk.postControls.consequence)}
                            </View>
                        </View>

                        <View style={pdfStyles.greyLine}></View>

                        {/* Person/s Responsible, Review Interval and Review Date */}
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Person/s Responsible</Text>
                                <Text>
                                    {formatValue(formatTextAreaText(selectedRisk.whoResponsible))}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Review Interval</Text>
                                <Text>
                                    {formatValue(formatInterval(selectedRisk.interval))}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Review Date</Text>
                                <Text>
                                    <div className={`review-date ${renderWhenDueClass(selectedRisk.whenDue)}`}>
                                        {formatDate(selectedRisk.whenDue)}
                                    </div>
                                </Text>
                            </View>
                        </View>

                        <View wrap={false} style={pdfStyles.header}>
                            <Text style={pdfStyles.heading}>Review History</Text>
                        </View>
                        
                        {/* Review History */}
                        {
                            risksReviewed && risksReviewed.length > 0 &&
                            <>
                                <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>Date</Text>
                                    </View>
                                    <View style={styleItemColumn(1)}>
                                        <Text>By</Text>
                                    </View>
                                    <View style={styleItemColumn(2)}>
                                        <Text>Notes</Text>
                                    </View>
                                </View>
                                <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                                {
                                    risksReviewed.map((riskReviewed, index) => {
                                        return (
                                            <React.Fragment key={riskReviewed.id}>
                                                {index > 0 &&
                                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                                }
                                                <View wrap={false} style={pdfStyles.itemsRow}>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text>{formatDate(riskReviewed.whenReviewed)}</Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text>{renderFullNameForUserId(riskReviewed.reviewedBy)}</Text>
                                                    </View>
                                                    <View style={styleItemColumn(2)}>
                                                        <Text>{formatValue(formatTextAreaText(riskReviewed.notes))}</Text>
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </>
                        }
                        {
                            risksReviewed && risksReviewed.length === 0 &&
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column1}>
                                    <Text>This risk assessment has no history</Text>
                                </View>
                            </View>
                        }
                    </>
                    }

                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [risksReviewed, licenseeSettings?.riskRegister, renderRiskRating, renderWhenDueClass, reportTitle, riskCategories, selectedRisk, vessels]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="RiskAssessment"
            />
        );
    } else return <></>
};
export default RiskAssessmentModalPdf;