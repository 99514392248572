import React, { useCallback, useEffect, useState, useRef } from 'react';
import { $getRoot, LexicalNode, EditorState, LexicalEditor, $copyNode, ParagraphNode, TextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { extractHeadingText, lexicalTheme, lexNodes, scrollToSection } from '../../../components/lexical/SeaRichText/SeaRichText';
import { TableContext } from '../../../components/lexical/plugins/LexTable/LexTable';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { SeaSectionNode } from '../../../components/lexical/nodes/SeaSectionNode/SeaSectionNode';
import { debugApp } from '../../../shared-state/Core/debugging';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaScrollableArea from '../../../components/SeaScrollableArea/SeaScrollableArea';
import SeaButton from '../../../components/SeaButton/SeaButton';
import ToolBarPlugin from '../../../components/lexical/plugins/LexToolBar/LexToolBar';
import LexDragDropPaste from '../../../components/lexical/plugins/LexDragDropPaste/LexDragDropPaste';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import LexTableCellResizer from '../../../components/lexical/plugins/LexTableCellResizer/LexTableCellResizer';
import LexImages from '../../../components/lexical/plugins/LexImages/LexImages';
import LexDraggableBlock from '../../../components/lexical/plugins/LexDraggableBlock/LexDraggableBlock';
import LexTableActionMenu from '../../../components/lexical/plugins/LexTableActionMenu/LexTableActionMenu';
import TreeViewPlugin from '../../../components/lexical/plugins/LexTreeView/LexTreeView';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaSelectRichTextHeading from '../../../components/lexical/SeaSelectRichTextHeading/SeaSelectRichTextHeading';
import './EditRichTextModal.css';

interface Section {
    title: string;
    key: string;
    tag: 'h1' | 'h2';
}

interface EditRichTextModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    title: string,
    initialDocumentJson: any, // initial document json
    onSaveChanges: (json: any) => Promise<boolean> // Will be called if new changes are saved. Should return true if successful and modal should be closed.
    confirmText?: string,
}

const EditRichTextModal: React.FC<EditRichTextModalProps> = ({
    showModal,
    setShowModal,
    level = 1,
    title,
    initialDocumentJson,
    onSaveChanges,
    confirmText = 'Apply Changes'
}) => {
    const [isMounted, setIsMounted] = useState(false);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
    const [sections, setSections] = useState<Section[]>([]);
    const [activeSection, setActiveSection] = useState<string>();
    const [saving, setSaving] = useState(false);
    const editorRef = useRef<LexicalEditor>();
    const editorStateRef = useRef<EditorState>();
    const lexContainerRef = useRef<HTMLDivElement>(null);
    const onChangeIdRef = useRef(0); // Give an onChange process an id so it can be skipped if a newer job starts
    const scrollOnChange = useRef(false); // If currently true, a change in activeSection will scroll to that section

    useEffect(() => {
        setIsMounted(true);
        return () => { setIsMounted(false); };
    }, []);

    function EditorRefPlugin() { // Simply for getting a reference to our LexicalEditor
        const [editor] = useLexicalComposerContext();
        editorRef.current = editor;
        return null;
    }

    useEffect(() => {
        debugApp('sfdoc', `EditRichTextModal showModal=${showModal}`);
        if (showModal) {
            scrollOnChange.current = false;
        } else {
            setActiveSection(undefined);
        }
    }, [showModal]);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    // Refreshes list of sections based on content
    // Eliminates undesirable nodes like links
    const processLexicalTree = useCallback(() => {
        if (editorStateRef.current) {
            editorStateRef.current.read(() => {
                // Read the contents of the EditorState here.
                const root = $getRoot();
                // const selection = $getSelection();

                const _sections = [] as Section[];
                let needsFixing = false;
                const scanNode = (node: LexicalNode | null | undefined) => {
                    while (node) {
                        if (node.__type === 'link') {
                            needsFixing = true; // Don't want links (at least for now)
                        } else if (node.__type === 'image' && !node.__src.startsWith('data')) {
                            needsFixing = true; // Don't want images that don't contain data
                        } else if (node.__tag === 'h1') {
                            _sections.push({
                                title: extractHeadingText(node.getFirstChild()),
                                key: node.__key,
                                tag: 'h1'
                            });
                        } else if (node.__tag === 'h2') {
                            _sections.push({
                                title: extractHeadingText(node.getFirstChild()),
                                key: node.__key,
                                tag: 'h2'
                            });
                        }
                        if (node.getFirstChild && node.getFirstChild()) {
                            scanNode(node.getFirstChild());
                        }
                        node = node.getNextSibling();
                    }
                };

                //debugNodeTree(root.getFirstChild());
                scanNode(root.getFirstChild());
                setSections(_sections);
                if (_sections.length > 0) {
                    if (activeSection === undefined) {
                        setActiveSection(_sections[0].key);
                    }
                } else {
                    setActiveSection(undefined);
                }

                if (needsFixing && editorRef.current) {
                    editorRef.current.update(() => {
                        const fixNode = (node: LexicalNode | null | undefined, level = 0) => {
                            while (node) {
                                const nextSibling = node.getNextSibling();
                                if (node.__type === 'link') {
                                    console.log('Auto removing link node', node);
                                    const clones = [] as LexicalNode[];
                                    if (node.getChildren && node.getChildren()) {
                                        node.getChildren().forEach((child: LexicalNode) => {
                                            clones.push(
                                                $copyNode(child)
                                            );
                                        })
                                    }
                                    clones.forEach((clone: LexicalNode) => {
                                        (node as LexicalNode).insertBefore(clone);
                                    });
                                    node.remove();
                                } else if (node.__type === 'image' && !node.__src.startsWith('data')) {
                                    console.log('Auto removing image with no data', node);
                                    node.remove();
                                } else if (node.getFirstChild && node.getFirstChild()) {
                                    fixNode(node.getFirstChild(), level + 1);
                                }
                                node = nextSibling;
                            }
                        };
                        fixNode(root.getFirstChild());
                    });
                }
            });
        }
    }, [activeSection]);

    // Refresh editor state if initialDocumentJson changes (if need be)
    useEffect(() => {
        //setTimeout(() => {
        if (showModal && initialDocumentJson) {
            let whenStarted = Date.now();
            const interval = setInterval(() => {
                debugApp('sfdoc', `initialDocumentJson changed. editorRef.current=${editorRef.current} (${Date.now() - whenStarted}ms)`);
                if (editorRef.current) {
                    editorRef.current?.setEditorState(
                        editorRef.current.parseEditorState(initialDocumentJson)
                    );
                    processLexicalTree();
                    clearInterval(interval);
                }
            }, 1);
        }
        //}, 10);
    }, [showModal, initialDocumentJson]);  // Note: Removing processLexicalTree from dependencies causes copy/paste of large amounts to fail

    const onLexicalChange = (editorState: EditorState, editor: LexicalEditor) => {
        //console.log(`onLexicalChange!`);
        editorRef.current = editor;
        editorStateRef.current = editorState;

        const onChangeId = ++onChangeIdRef.current;
        setTimeout(() => {
            if (!isMounted) return;
            if (onChangeId === onChangeIdRef.current) {
                processLexicalTree();
            }
        }, 500);
    }

    const findDifferences = useCallback((obj1: any, obj2: any, path: string = ''): any => {
        const differences: any = {};
        
        const shouldIgnoreDifference = (path: string, value: any) => {
            // Ignore direction changes from 'ltr' to null
            if (path.endsWith('.direction') && 
                value.from === 'ltr' && 
                value.to === null) {
                return true;
            }
            
            // Ignore added 'checked' properties that are undefined
            if (path.endsWith('.checked') && 
                'added' in value && 
                value.added === undefined) {
                return true;
            }
            
            // Ignore added 'width' properties that are undefined
            if (path.endsWith('.width') && 
                'added' in value && 
                value.added === undefined) {
                return true;
            }
            
            return false;
        };

        for (const key in obj1) {
            const currentPath = path ? `${path}.${key}` : key;
            
            if (!(key in obj2)) {
                const diff = { removed: obj1[key] };
                if (!shouldIgnoreDifference(currentPath, diff)) {
                    differences[currentPath] = diff;
                }
            } else if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                const nestedDiff = findDifferences(obj1[key], obj2[key], currentPath);
                if (Object.keys(nestedDiff).length > 0) {
                    Object.assign(differences, nestedDiff);
                }
            } else if (obj1[key] !== obj2[key]) {
                const diff = { from: obj1[key], to: obj2[key] };
                if (!shouldIgnoreDifference(currentPath, diff)) {
                    differences[currentPath] = diff;
                }
            }
        }
        
        for (const key in obj2) {
            const currentPath = path ? `${path}.${key}` : key;
            if (!(key in obj1)) {
                const diff = { added: obj2[key] };
                if (!shouldIgnoreDifference(currentPath, diff)) {
                    differences[currentPath] = diff;
                }
            }
        }
        
        return differences;
    }, []);

    const isModalDirty = useCallback(() => {
        if (editorStateRef.current) {

            // Get differences between documents
            const differences = findDifferences(initialDocumentJson, editorStateRef.current.toJSON());
        
            return Object.keys(differences).length > 0;
        }
        return false;
    }, [findDifferences, initialDocumentJson]);

    const onLexicalError = (error: Error, editor: LexicalEditor) => {
        console.error(error);
        console.log('lexical editor', editor);
    };

    const onAddNewSection = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editorRef.current) {
            editorRef.current.update(() => {
                const root = $getRoot();
                const lastNode = root.getLastChild();
                const newSectionNode = new SeaSectionNode('h1');
                lastNode?.insertAfter(
                    newSectionNode
                );
                newSectionNode.append(
                    new TextNode('New Section')
                );
                newSectionNode.select();
                const newParagraphNode = new ParagraphNode();
                newSectionNode.insertAfter(newParagraphNode);
                // newParagraphNode.append(
                //     new TextNode('')
                // );
                setTimeout(() => {
                    if (!isMounted) return;
                    scrollToSection('h1', newSectionNode.__key);
                }, 100);
            });
        }
    }, []);

    const changeSection = (increment: number) => {
        if (activeSection && sections && sections.length > 0) {
            let index = 0;
            for (let i = 0; i < sections.length; i++) {
                if (activeSection === sections[i].key) {
                    index = i;
                    break;
                }
            }
            index = index + increment;
            index = Math.min(index, sections.length - 1);
            index = Math.max(index, 0);
            scrollOnChange.current = true;
            setActiveSection(sections[index].key);
        }
    };

    const onScrollEnd = (event: Event) => {
        if (activeSection && sections) {
            let index = 0;
            while (index < sections.length - 1) {
                
                const target = document.getElementById(`${sections[index + 1].tag}_${sections[index + 1].key}`);

                let h = 80; // Fixed modal top space
                const toolbarContainers = document.getElementsByClassName('lex-toolbar-container');
                if (toolbarContainers && toolbarContainers.length > 0 && (toolbarContainers[0] as any).offsetHeight) {
                    h += (toolbarContainers[0] as any).offsetHeight;
                } else {
                    h += 92;
                }
                h += 100; // 25; // Leeway

                if (target && target.getBoundingClientRect().top > h) {
                    break;
                }
                index++;
            }
            if (sections[index].key !== activeSection) {
                scrollOnChange.current = false;
                setActiveSection(sections[index].key);
            }
        }
    };

    const onCloseModal = useCallback(() => {
        if (editorStateRef.current) {
            if (isModalDirty()) {
                setSaving(true);
                setTimeout(() => {
                    if (!isMounted) return;
                    if (editorStateRef.current) {
                        onSaveChanges(editorStateRef.current.toJSON()).then((closeModal: boolean) => {
                            if (!isMounted) return;
                            if (closeModal) {
                                setShowModal(false);
                            }
                        }).finally(() => {
                            if (!isMounted) return;
                            setSaving(false);
                        });
                    }
                }, 100);
            } else {
                // No changes to save, therefore just close modal
                setShowModal(false);
            }
        }
    }, [isModalDirty, isMounted, onSaveChanges, setShowModal]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            onOpened={() => {
                debugApp('sfdoc', 'EditRichTextModal opened');
            }}
            size="edit-rich-text"
            isDirty={isModalDirty}
            noTitleOverflow={true}
            confirmDismissDirty={{
                title: 'Are you sure you want to close without saving your changes?',
                yes: 'Yes, lose my changes',
                no: 'Cancel'
            }}
            actionPanel={
                <div className="rich-text columns">
                    <div className="lex-editor-sections">
                        
                    </div>
                    <div className="lex-editor-column">
                        <SeaButton
                            onClick={onCloseModal}
                            disabled={saving}
                        >
                            {confirmText}
                        </SeaButton>
                    </div>
                </div>
            }
            alternativeContent={
                <LexicalComposer initialConfig={{
                    editable: true,
                    namespace: 'LexEditor',
                    theme: lexicalTheme,
                    nodes: lexNodes,
                    onError: onLexicalError,
                    //editorState: initialContent
                }}>
                    <TableContext>
                        <>
                            <div className={`rich-text columns${saving ? ' sfdoc-saving' : ''}`}>
                                <div
                                    className="lex-editor-sections"
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '8px 20px 8px calc(var(--modal-padding-horizontal) + 4px)',
                                        alignSelf: 'end'
                                    }}
                                >
                                    <h3>Sections</h3>
                                    {/* Section / version navigator here */}
                                </div>
                                <div className="lex-editor-column lex-toolbar-container">
                                    {sections &&
                                        <div className="lex-editor-doc-bar columns">
                                            <div className="button pushy no-select lex-next-prev" onClick={(e) => changeSection(-1)}>
                                                <SeaIcon icon="moveLeft" forceFontSize="50px"/>
                                            </div>
                                            <div>
                                                <SeaSelectRichTextHeading
                                                    value={activeSection}
                                                    setValue={(key: string | undefined) => {
                                                        setTimeout(() => {
                                                            setActiveSection(key);
                                                            if (scrollOnChange.current !== undefined) {
                                                                for (let i = 0; i < sections.length; i++) {
                                                                    if (sections[i].key === key) {
                                                                        scrollToSection(sections[i].tag, sections[i].key, false);
                                                                    }
                                                                }
                                                            }
                                                            scrollOnChange.current = true;
                                                        }, 0);
                                                    }}
                                                    sections={sections}
                                                />
                                                {/*
                                                <SeaSelect
                                                    name="completedByIdFilter"
                                                    value={activeSection}
                                                    width="200px"
                                                    //zone="grey"
                                                    onchange={(e) => {
                                                        setActiveSection(e.detail.value);
                                                        if (scrollOnChange.current) {
                                                            for (let i = 0; i < sections.length; i++) {
                                                                if (sections[i].key === e.detail.value) {
                                                                    scrollToSection(sections[i].tag, sections[i].key, false);
                                                                }
                                                            }
                                                        }
                                                        scrollOnChange.current = true;
                                                    }}
                                                >
                                                    {sections.map((section) => {
                                                        return (
                                                            <IonSelectOption key={section.key} value={section.key}>
                                                                {section.title}
                                                            </IonSelectOption>
                                                        );
                                                    })}
                                                </SeaSelect>
                                                */}
                                            </div>
                                            <div className="button pushy no-select lex-next-prev" onClick={(e) => changeSection(1)}>
                                                <SeaIcon icon="moveRight" forceFontSize="50px"/>
                                            </div>
                                        </div>
                                    }
                                    <ToolBarPlugin level={level} />
                                </div>
                            </div>
                            <div
                                className={`rich-text columns${saving ? ' sfdoc-saving' : ''}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    overflowY: 'auto'
                                }}
                            >
                                <div
                                    className="lex-editor-sections"
                                    style={{
                                        backgroundColor: 'white',
                                        width: '20%',
                                        height: '100%',
                                        padding: '0px 0px 0px var(--modal-padding-horizontal)',
                                        alignSelf: 'flex-end'
                                    }}
                                >
                                    <SeaScrollableArea>
                                        <div style={{ padding: '0px 16px 16px 0px' }}>
                                            {sections?.map((section) => {
                                                return (
                                                    <div
                                                        key={section.key}
                                                        className={`lex-section-button ${section.tag} pushy no-select${(section.key === activeSection) ? ' active' : ''}`}
                                                        onClick={(e) => {
                                                            scrollOnChange.current = false;
                                                            setActiveSection(section.key);
                                                            scrollToSection(section.tag, section.key);
                                                        }}
                                                    >
                                                        {section.title}
                                                    </div>
                                                );
                                            })}
                                            <div className="sea-add-new-button no-select" style={{marginTop: '16px'}}>
                                                <SeaButton zone="white" shape="circle" onClick={(e) => null}>
                                                    <SeaIcon slot="icon-only" icon="add" />
                                                </SeaButton>
                                                <div
                                                    className="text"
                                                    onClick={onAddNewSection}
                                                >
                                                    Add New Section
                                                </div>
                                            </div>
                                        </div>
                                    </SeaScrollableArea>
                                </div>
                                <div className="lex-editor-column">
                                    <SeaScrollableArea
                                        scrollEvents={true}
                                        onScrollEnd={onScrollEnd}
                                    >
                                        <div className="lex-content-container">
                                            <div className="lex-container" ref={lexContainerRef}>
                                                <LexDragDropPaste />
                                                <AutoFocusPlugin />
                                                <RichTextPlugin
                                                    contentEditable={
                                                        <div className="lex-scroller">
                                                            <div className="lex-editor" ref={onRef}>
                                                                <ContentEditable className="lex-content-editable-root"/>
                                                            </div>
                                                        </div>
                                                    }
                                                    placeholder={null}
                                                    ErrorBoundary={LexicalErrorBoundary}
                                                />
                                                <ListPlugin />
                                                <TablePlugin />
                                                <LexTableCellResizer />
                                                <LexImages />
                                                <HorizontalRulePlugin />
                                                <TabIndentationPlugin />
                                                {floatingAnchorElem && (
                                                    <>
                                                        <LexDraggableBlock anchorElem={floatingAnchorElem} />
                                                        <LexTableActionMenu anchorElem={floatingAnchorElem} />
                                                    </>
                                                )}
                                                <EditorRefPlugin />
                                                <OnChangePlugin
                                                    onChange={onLexicalChange}
                                                    ignoreSelectionChange={true}
                                                />
                                                <HistoryPlugin />
                                                {false && <TreeViewPlugin />}
                                            </div>
                                        </div>
                                    </SeaScrollableArea>
                                </div>
                            </div>
                        </>
                    </TableContext>
                </LexicalComposer>
            }
        >
        </SeaModal>
    );
};

export default EditRichTextModal;
