import React, { useState, useEffect, useMemo } from 'react';
import { logPageView } from '../../lib/firebase';
import { formatTextSingleLine, formatValue, whenDueToClassName } from '../../lib/util';
import { formatDate, formatDateSimplify, getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { permissionLevels, canView } from '../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../lib/categories';
import { sharedState } from '../../shared-state/shared-state';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { useNavigate } from 'react-router';
import { SafetyCheckItem } from '../../shared-state/VesselSafety/safetyCheckItems';
import { SafetyEquipmentItem } from '../../shared-state/VesselSafety/safetyEquipmentItems';
import { Drill } from '../../shared-state/VesselSafety/drills';
import { ScheduledMaintenanceTask } from '../../shared-state/VesselMaintenance/maintenanceSchedule';
import { Job } from '../../shared-state/VesselMaintenance/jobs';
import { VesselCertificate } from '../../shared-state/VesselDocuments/vesselCertificates';
import { VesselDocument } from '../../shared-state/VesselDocuments/vesselDocuments';
import { CrewCertificate } from '../../shared-state/Crew/crewCertificates';
import { TrainingTask } from '../../shared-state/Crew/trainingTasks';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaButton from '../../components/SeaButton/SeaButton';
import SeaStatusDueDate from '../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaIcon from '../../components/SeaIcon/SeaIcon';
import EditVesselModal from '../../modals/VesselDashboard/EditVesselModal/EditVesselModal';
import ActionLogModal from '../../modals/Utilities/ActionLogModal/ActionLogModal';
import EditEnginesModal from '../../modals/VesselDashboard/EditEnginesModal/EditEnginesModal';
import ViewSafetyCheck from '../../modals/VesselSafety/SafetyEquipmentChecks/ViewSafetyCheck/ViewSafetyCheck';
import ViewSafetyEquipment from '../../modals/VesselSafety/SafetyEquipmentExpiries/ViewSafetyEquipmentExpiry/ViewSafetyEquipmentExpiry';
import EditTrainingTask from '../../modals/Crew/CrewTraining/EditTrainingTask/EditTrainingTask';
import ViewMaintenanceSchedule from '../../modals/VesselMaintenance/MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule';
import ViewJobList from '../../modals/VesselMaintenance/JobList/ViewJobList/ViewJobList';
import ViewVesselCertificate from '../../modals/VesselDocuments/VesselCertificates/ViewVesselCertificate/ViewVesselCertificate';
import ViewVesselDocument from '../../modals/VesselDocuments/VesselDocuments/ViewVesselDocument/ViewVesselDocument';
import SeaStatusDueHours from '../../components/SeaStatusDueHours/SeaStatusDueHours';
import EditHealthSafetyMeeting from '../../modals/HealthSafety/HealthSafetyMeetings/EditHealthSafetyMeeting/EditHealthSafetyMeeting';
import ViewDrill from '../../modals/VesselSafety/Drills/ViewDrill/ViewDrill';
import RequirePermissions from '../../components/RequirePermissions/RequirePermissions';
import ViewCrewCertificate from '../../modals/Crew/CrewCertificates/ViewCrewCertificate/ViewCrewCertificate';
import ViewVesselDetailsModal from '../../modals/VesselDashboard/ViewVesselDetailsModal/ViewVesselDetailsModal';
import SeaStatusIcon from '../../components/SeaStatusIcon/SeaStatusIcon';
import './VesselDashboard.css';

const VesselDashboard: React.FC = () => {
    const navigate = useNavigate();
    const vessel = sharedState.vessel.use();
    const vesselId = sharedState.vesselId.use();
    const todayMillis = sharedState.todayMillis.use();

    const engines = sharedState.engines.use();
    const vesselSafetyItems = sharedState.vesselSafetyItems.use();
    const equipment = sharedState.equipment.use();
    const crewCertificates = sharedState.crewCertificates.use();
    const crewCertificateTitles = sharedState.crewCertificateTitles.use();
    // const trainingTasks = sharedState.trainingTasks.use();
    const safetyCheckItems = sharedState.dashboardSafetyCheckItems.use();
    const safetyEquipmentItems = sharedState.dashboardSafetyEquipmentItems.use();
    const vesselDrills = sharedState.dashboardVesselDrills.use();
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(); // We use the full version (rather than dashboard specific), because of how engineHouseDue is calculated
    const jobs = sharedState.dashboardJobs.use();
    const vesselCertificates = sharedState.dashboardVesselCertificates.use();
    const vesselDocuments = sharedState.dashboardVesselDocuments.use();
    const crewCertificatesPrioritisedVessel = sharedState.dashboardVesselCrewCertificates.use();
    const trainingTasks = sharedState.dashboardVesselCrewTraining.use();
    const actionLog = sharedState.actionLog.use(100);
    const [selectedItem, setSelectedItem] = useState<SafetyCheckItem | SafetyEquipmentItem | Drill | ScheduledMaintenanceTask | Job | VesselCertificate | VesselDocument | TrainingTask | CrewCertificate>();
    const [modalToShow, setModalToShow] = useState<string>();
    const [recentActionsCount, setRecentActionsCount] = useState<number>();
    // const [crewCertificatesPrioritisedVessel, setCrewCertificatesPrioritisedVessel] = useState<any>();

    useEffect(() => {
        logPageView('VesselDashboard');
    }, []);

    // Keep selectedItem fresh
    useEffect(() => {
        if (modalToShow === 'safetyCheck' && selectedItem?.id && safetyCheckItems?.byId[selectedItem.id]) {
            setSelectedItem(safetyCheckItems.byId[selectedItem.id])
        };
    }, [safetyCheckItems, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'safetyEquipment' && selectedItem?.id && safetyEquipmentItems?.byId[selectedItem.id]) { 
            setSelectedItem(safetyEquipmentItems.byId[selectedItem.id]);
        };
    }, [safetyEquipmentItems, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'drill' && selectedItem?.id && vesselDrills?.byId[selectedItem.id]) { 
            setSelectedItem(vesselDrills.byId[selectedItem.id]);
        };
    }, [vesselDrills, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'maintenanceSchedule' && selectedItem?.id && scheduledMaintenanceTasks?.byId[selectedItem?.id]) { 
            setSelectedItem(scheduledMaintenanceTasks.byId[selectedItem.id]);
        };
    }, [scheduledMaintenanceTasks, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'vesselCertificate' && selectedItem?.id && vesselCertificates?.byId[selectedItem.id]) { 
            setSelectedItem(vesselCertificates.byId[selectedItem.id])
        };
    }, [vesselCertificates, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'vesselDocument' && selectedItem?.id && vesselDocuments?.byId[selectedItem.id]) { 
            setSelectedItem(vesselDocuments.byId[selectedItem.id])
        };
    }, [vesselDocuments, modalToShow, selectedItem?.id]);
    useEffect(() => {
        if (modalToShow === 'crewCertificate' && selectedItem?.id && crewCertificates?.byId[selectedItem.id]) { 
            setSelectedItem(crewCertificates.byId[selectedItem.id])
        };
    }, [crewCertificates, modalToShow, selectedItem?.id]);

    const onViewSafetyCheckItem = (item: SafetyCheckItem) => {
        setSelectedItem(item);
        setModalToShow('safetyCheck');
    };
    const onViewSafetyEquipmentItem = (item: SafetyEquipmentItem) => {
        setSelectedItem(item);
        setModalToShow('safetyEquipment');
    };
    const onViewDrill = (drill: Drill) => {
        setSelectedItem(drill);
        setModalToShow('drill');
    };
    const onViewMaintenanceScheduleItem = (task: ScheduledMaintenanceTask) => {
        setSelectedItem(task);
        setModalToShow('maintenanceSchedule');
    };
    const onViewJob = (job: Job) => {
        setSelectedItem(job);
        setModalToShow('jobList');
    };
    const onViewVesselCertificate = (certificate: VesselCertificate) => {
        setSelectedItem(certificate);
        setModalToShow('vesselCertificate');
    };
    const onViewVesselDocument = (document: VesselDocument) => {
        setSelectedItem(document);
        setModalToShow('vesselDocument');
    };
    const onEditHealthSafetyMeeting = () => {
        setModalToShow('editHealthSafetyMeeting');
    };
    const onViewCrewCertificate = (certificate: CrewCertificate) => {
        setSelectedItem(certificate);
        setModalToShow('crewCertificate');
    };
    const onViewCrewTraining = (item: TrainingTask) => {
        setSelectedItem(item);
        setModalToShow('editTrainingTask');
    };

    // Handy booleans
    const hasSafetyCheckItems = useMemo(() => {
        return (safetyCheckItems?.top5?.length || 0) > 0 && canView('safetyEquipmentChecks');
    }, [safetyCheckItems]);
    const hasSafetyEquipmentItems = useMemo(() => {
        return safetyEquipmentItems?.top5 && safetyEquipmentItems.top5.length > 0 && canView('safetyEquipmentList');
    }, [safetyEquipmentItems]);
    const hasDrills = useMemo(() => {
        return (vesselDrills?.top5?.length || 0) > 0 && canView('drills');
    }, [vesselDrills]);
    const hasSafety = hasSafetyCheckItems || hasSafetyEquipmentItems || hasDrills;

    const hasScheduledMaintenanceTasks = useMemo(() => {
        return (scheduledMaintenanceTasks?.top5?.length || 0) > 0 && canView('maintenanceSchedule');
    }, [scheduledMaintenanceTasks]);
    const hasJobs = useMemo(() => {
        return (jobs?.top5?.length || 0) > 0 && canView('jobList');
    }, [jobs]);
    const hasMaintenance = hasScheduledMaintenanceTasks || hasJobs;

    const hasVesselCertificates = useMemo(() => {
        return (vesselCertificates?.top5?.length || 0) > 0 && canView('vesselCertificates');
    }, [vesselCertificates]);
    const hasVesselDocuments = useMemo(() => {
        return (vesselDocuments?.top5?.length || 0) > 0 && canView('vesselDocuments');
    }, [vesselDocuments]);
    const hasSafetyMeetings = useMemo(() => {
        return (
            vessel?.safetyMeetingSettings?.whenDue &&
            whenDueToClassName(vessel.safetyMeetingSettings.whenDue, warnDays.healthSafetyMeetings[0]) !== '' &&
            canView('healthSafetyMeetings')
        );
    }, [vessel]);
    const hasDocuments = hasVesselCertificates || hasVesselDocuments || hasSafetyMeetings;

    const hasCrewCertificates = useMemo(() => {
        return (crewCertificatesPrioritisedVessel?.top5?.length || 0) > 0 && canView('crewCertificates');
    }, [crewCertificatesPrioritisedVessel]);
    const hasCrewTraining = useMemo(() => {
        return (trainingTasks?.top5.length || 0) > 0 && canView('crewTraining');
    }, [trainingTasks]);

    const goto = (to: string) => {
        navigate(to);
    };

    useEffect(() => {
        setRecentActionsCount(undefined);
        if (vesselId && actionLog) {
            const whenFrom = getDayOffsetMillis(-7);
            let count = 0;
            for (let i = 0; i < actionLog.length; i++) {
                const action = actionLog[i];
                if (action.when < whenFrom) {
                    break; // This is older than 7 days ago
                }
                if (action.vesselIds === 'any' || action.vesselIds.includes(vesselId)) {
                    count++;
                }
            }
            setRecentActionsCount(count);
        }
    }, [vesselId, actionLog, todayMillis]);

    // Crew Certificates for Dashboard - Deprecated. Found in sharedState/VesselDashboard/dashboardVesselCrewCertificatesConfig.ts
    // useEffect(() => {
    //     setCrewCertificatesPrioritisedVessel(undefined);
    //     if (crewCertificates) {
    //         let data = [] as any[];
    //         crewCertificates.prioritised.forEach((certificate: any) => {
    //             if (users?.byId[certificate.heldBy] && users?.byId[certificate.heldBy].vesselIds.includes(vesselId)) {
    //                 data.push(certificate);
    //             }
    //         });
    //         const minWhenExpiries = getDayOffsetMillis(warnDays.crewCertificates[0])
    //         const numHighestPriority = data.filter((p) => p.whenExpires < minWhenExpiries).length;
    //         const top5 = data.slice(0, 5).filter((p) => p.whenExpires < minWhenExpiries);
    //         setCrewCertificatesPrioritisedVessel({
    //             prioritised: data,
    //             top5,
    //             numHighestPriority
    //         });
    //     }
    // }, [vesselId, crewCertificates, users?.byId]);

    return (
        <StandardPageLayout section="vessel">
            {vessel && <>
                <SeaBreadcrumb>
                    <SeaLink to="/fleet">Fleet DashBoard</SeaLink>
                </SeaBreadcrumb>
                <div className="vessel-dashboard">
                    <div className="columns wrap">
                        <div><h1>{`${vessel.name}`}</h1></div>
                        <div className="right actions">
                            <div className="link" onClick={(e) => setModalToShow('vesselDetails')}>
                                Vessel Details
                            </div>
                            {vessel?.marineTrafficLink &&
                                <div className="link" onClick={(e) => {
                                    window.open(vessel.marineTrafficLink, '_system', 'location=yes');
                                }}>
                                    Open Marine Traffic
                                </div>
                            }
                            <RequirePermissions
                                role="vesselSettings"
                                level={permissionLevels.EDIT}
                            >
                                <div className="vessel-settings-fat">
                                    <SeaButton zone='grey' type='button' size='wide' onClick={(e) => setModalToShow('editVessel')}>
                                        <SeaIcon slot='start' icon='settings' /> {vessel?.isShoreFacility ? 'Facility Settings' : 'Vessel Settings'}
                                    </SeaButton>
                                </div>
                                <div className="vessel-settings-thin">
                                    <SeaButton zone="grey" type="button" shape="circle" onClick={(e) => setModalToShow('editVessel')}>
                                        <SeaIcon slot="icon-only" icon="settings" />
                                    </SeaButton>
                                </div>
                            </RequirePermissions>
                        </div>
                    </div>

                    <div className={`vessel-dashboard-top columns ${engines ? 'reveal' : 'conceal'}`}>
                        <div className="tasks-completed sea-container">
                            <div>
                                {recentActionsCount}
                            </div>
                            <div style={{paddingBottom: 20}}>
                                Actions <br />completed in <br />the last 7 DAYS<br />
                            </div>
                            {/* <ClipboardIcon /> */}
                            <div style={{marginLeft: -14, marginBottom: 9}}>
                                <SeaButton zone='gold' type='button' onClick={(e) => setModalToShow('actionLog')}>
                                    <SeaIcon slot='start' icon='log' /> View Log
                                </SeaButton>
                            </div>
                        </div>
                        <RequirePermissions
                            role="engineHours"
                            level={permissionLevels.VIEW}
                        >
                            {!vessel?.isShoreFacility && 
                                <div className="engine-hours sea-container">
                                    <div className="columns">
                                        <div><h2>Engine Hours</h2></div>
                                        {engines?.whenLastUpdated ?
                                            <div className="right last-updated">
                                                Updated <span>{formatDateSimplify(engines.whenLastUpdated)}</span>
                                            </div>
                                        : null}
                                    </div>
                                    <div className="columns engine-items">
                                        {engines?.all?.map((engine) => {
                                            return (
                                                <div key={engine.id}>
                                                    <div>{engine.hours}</div>
                                                    <div>{engine.name}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <RequirePermissions
                                        role="engineHours"
                                        level={permissionLevels.EDIT}
                                    >
                                        <div className="edit-engines-btn">
                                            <SeaButton zone='white' shape='circle' onClick={(e) => setModalToShow('editEngines')}>
                                                <SeaIcon slot='icon-only' icon='edit' />
                                            </SeaButton>
                                        </div>
                                    </RequirePermissions>
                                </div>
                            }
                        </RequirePermissions>
                    </div>

                    {(hasSafety || hasMaintenance || hasDocuments) && <div className="title"><h2>Overdue &amp; Upcoming Tasks</h2></div>}

                    {/* safetyCheckItems */}
                    <div className={`${hasSafetyCheckItems ? 'reveal' : 'conceal'}`}>
                        {hasSafetyCheckItems &&
                            <div className="dashboard-table safety">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/safety?tab=SafetyEquipmentChecks`)}>
                                    <div>
                                        <h2>Safety Checks</h2>
                                    </div>
                                </div>
                                {safetyCheckItems?.top5?.map((item) => {
                                    return (
                                        <div key={item.id} className={`row${item.hasFault ? ' fault' : ''}`} onClick={(e) => onViewSafetyCheckItem(item)}>
                                            <div>{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                            <div>{formatValue(formatTextSingleLine(item.description))}</div>
                                            <div className='critical'>
                                                {vesselSafetyItems?.byId[item.itemId]?.isCritical && (
                                                    <SeaStatusIcon
                                                        icon="flag"
                                                        className="critical bottom"
                                                    />
                                                )}
                                            </div>
                                            <div className='status'>{item.whenDue && <SeaStatusDueDate whenDue={item.whenDue} hasFault={item.hasFault} warnDays={warnDays.safetyEquipmentChecks[0]}/>}</div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total upcoming</div>
                                    <div>{safetyCheckItems?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* safetyEquipmentExpiries */}
                    <div className={`${hasSafetyEquipmentItems ? 'reveal' : 'conceal'}`}>
                        {hasSafetyEquipmentItems &&
                            <div className="dashboard-table safety">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/safety?tab=SafetyEquipmentList`)}>
                                    <div>
                                        <h2>Safety Equipment Expiries</h2>
                                    </div>
                                </div>
                                {safetyEquipmentItems?.top5?.map((item) => {
                                    return (
                                        <div key={item.id} className="row" onClick={(e) => onViewSafetyEquipmentItem(item)}>
                                            <div>{renderCategoryName(item.itemId, vesselSafetyItems)}</div>
                                            <div>{formatValue(formatTextSingleLine(item.description))}</div>
                                            <div className='critical'>
                                                {vesselSafetyItems?.byId[item.itemId]?.isCritical && (
                                                    <SeaStatusIcon
                                                        icon="flag"
                                                        className="critical bottom"
                                                    />
                                                )}
                                            </div>
                                            <div className='status'>
                                                {item.useHours ?
                                                    <SeaStatusDueHours engineHoursLeft={item.engineHoursLeft}/>
                                                    :
                                                    item.whenDue && <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.safetyEquipmentExpiries[0]}/>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total upcoming</div>
                                    <div>{safetyEquipmentItems?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* drills */}
                    <div className={`${hasDrills ? 'reveal' : 'conceal'}`}>
                        {hasDrills &&
                            <div className="dashboard-table safety">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/safety?tab=Drills`)}>
                                    <div>
                                        <h2>Drills</h2>
                                    </div>
                                </div>
                                {vesselDrills?.top5?.map((drill) => {
                                    return (
                                        <div
                                            key={drill.id}
                                            className="row"
                                            onClick={(e) => onViewDrill(drill)}
                                        >
                                            <div>{drill?.name}</div>
                                            <div></div>
                                            <div className='status'>{drill.whenDue && <SeaStatusDueDate whenDue={drill.whenDue} warnDays={warnDays.drills[0]} />}</div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total upcoming</div>
                                    <div>{vesselDrills?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* scheduledMaintenanceTasks */}
                    <div className={`${hasScheduledMaintenanceTasks ? 'reveal' : 'conceal'}`}>
                        {hasScheduledMaintenanceTasks &&
                            <div className="dashboard-table maintenance schedule">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/maintenance?tab=MaintenanceSchedule`)}>
                                    <div>
                                        <h2>Maintenance Schedule</h2>
                                    </div>
                                </div>
                                {scheduledMaintenanceTasks?.top5?.map((task) => {
                                    return (
                                        <div key={task.id} className="row" onClick={(e) => onViewMaintenanceScheduleItem(task)}>
                                            <div>{equipment?.byId[task.equipmentId]?.equipment}</div>
                                            <div>{formatValue(formatTextSingleLine(task.task))}</div>
                                            <div>
                                                {(task.intervalType === 'weekMonth' || task.intervalType === 'weekMonthAndHours') && formatDate(task.whenDue)}
                                                {task.intervalType === 'weekMonthAndHours' && ' / '}
                                                {(task.intervalType === 'engineHours' || task.intervalType === 'weekMonthAndHours') && `${task.engineHoursDue} Hours`}
                                            </div>
                                            <div className='critical'>
                                                {task.equipment?.isCritical && (
                                                    <SeaStatusIcon
                                                        icon="flag"
                                                        className="critical bottom"
                                                    />
                                                )}
                                            </div>
                                            <div className="status">
                                                {task.useHours ?
                                                    <SeaStatusDueHours engineHoursLeft={task.engineHoursLeft}/>
                                                    :
                                                    <SeaStatusDueDate whenDue={task.whenDue} warnDays={warnDays.maintenanceSchedule[0]}/>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total upcoming</div>
                                    <div>{scheduledMaintenanceTasks?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* jobs */}
                    <div className={`${hasJobs ? 'reveal' : 'conceal'}`}>
                        {hasJobs &&
                            <div className="dashboard-table maintenance jobs-list">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/maintenance?tab=JobList`)}>
                                    <div>
                                        <h2>Job List</h2>
                                    </div>
                                </div>
                                {jobs?.top5?.map((job) => {
                                    return (
                                        <div key={job.id} className="row" onClick={(e) => onViewJob(job)}>
                                            <div>{formatValue(formatTextSingleLine(job.task))}</div>
                                            <div style={{flex:0}}></div>
                                            <div>{job.whenDue ? formatDate(job.whenDue) : '-'}</div>
                                            <div className="status" style={{fontWeight: 700}}>{job.priority.substring(1)}</div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total Urgent &amp; High Priority</div>
                                    <div>{jobs?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* vesselCertificates */}
                    <div className={`${hasVesselCertificates ? 'reveal' : 'conceal'}`}>
                        {hasVesselCertificates &&
                            <div className="dashboard-table documents vessel-certificates">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/documents?tab=Certifications`)}>
                                    <div>
                                        <h2>{vessel?.isShoreFacility ? 'Vehicle Expiries' : 'Vessel Certificates'}</h2>
                                    </div>
                                </div>
                                {vesselCertificates?.top5?.map((certificate) => {
                                    return (
                                        <div key={certificate.id} className="row" onClick={(e) => onViewVesselCertificate(certificate)}>
                                            <div className="truncate bold">{certificate.title}</div>
                                            <div className="status">{certificate.whenExpires && <SeaStatusDueDate whenDue={certificate.whenExpires} warnDays={warnDays.vesselCertificates[0]} />}</div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total Expired/Expiring</div>
                                    <div>{vesselCertificates?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* vesselDocuments */}
                    <div className={`${hasVesselDocuments ? 'reveal' : 'conceal'}`}>
                        {hasVesselDocuments &&
                            <div className="dashboard-table documents vessel-documents">
                                <div className="section-head columns" onClick={(e) => goto(`/vessel/${ vesselId }/documents?tab=Documents`)}>
                                    <div>
                                        <h2>{vessel?.isShoreFacility ? 'Facility Documents' : 'Vessel Documents'}</h2>
                                    </div>
                                </div>
                                {vesselDocuments?.top5?.map((document) => {
                                    return (
                                        <div key={document.id} className="row" onClick={(e) => onViewVesselDocument(document)}>
                                            <div className="truncate bold">{document.title}</div>
                                            <div className="status">{document.whenExpires && <SeaStatusDueDate whenDue={document.whenExpires} warnDays={warnDays.vesselDocuments[0]} />}</div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total Expired/Expiring</div>
                                    <div>{vesselDocuments?.numHighestPriority}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* Health and Safety Meetings */}
                    <div className={`${hasSafetyMeetings ? 'reveal' : 'conceal'}`}>
                        {hasSafetyMeetings &&
                            <div className="dashboard-table documents safety-meetings">
                                <div className="section-head columns" onClick={(e) => goto(`/healthsafety?tab=HealthSafetyMeetings`)}>
                                    <div>
                                        <h2>Health &amp; Safety Meetings</h2>
                                    </div>
                                </div>
                                <div className="row" onClick={(e) => onEditHealthSafetyMeeting()}>
                                    <div className="truncate bold">Meeting Report</div>
                                    <div className="status"><SeaStatusDueDate whenDue={vessel.safetyMeetingSettings?.whenDue} warnDays={warnDays.healthSafetyMeetings[0]} /></div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* crewCertificates */}
                    <div className={`${hasCrewCertificates ? 'reveal' : 'conceal'}`}>
                        {hasCrewCertificates &&
                            <div className="dashboard-table crew certificates">
                                <div className="section-head columns" onClick={(e) => goto(`/crew?tab=CrewCertificates`)}>
                                    <div>
                                        <h2>Crew Certificates</h2>
                                    </div>
                                </div>
                                {crewCertificatesPrioritisedVessel?.top5?.map((certificate) => {
                                    return (
                                        <div key={certificate.id} className="row" onClick={(e) => onViewCrewCertificate(certificate)}>
                                            <div className="truncate">
                                                <span>{renderFullNameForUserId(certificate.heldBy)}</span>, {renderCategoryName(certificate?.titleId, crewCertificateTitles)}
                                            </div>
                                            <div className="truncate bold">{renderFullNameForUserId(certificate.heldBy)}</div>
                                            <div className="truncate">{renderCategoryName(certificate?.titleId, crewCertificateTitles)}</div>
                                            <div className="status">
                                                {certificate.whenExpires && !Number.isNaN(certificate.whenExpires) &&
                                                    <SeaStatusDueDate
                                                        whenDue={certificate.whenExpires}
                                                        warnDays={warnDays.crewCertificates[0]}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="summary row">
                                    <div>Total Expired/Expiring</div>
                                    <div>{crewCertificatesPrioritisedVessel?.numHighestPriority}</div>
                                </div> 
                            </div>
                        }
                    </div>
                    {/* crewTraining */}
                    <div className={`${hasCrewTraining ? 'reveal' : 'conceal'}`}>
                        {hasCrewTraining &&
                            <div className="dashboard-table crew training">
                                <div className="section-head columns" onClick={(e) => goto(`/crew?tab=CrewTraining`)}>
                                    <div>
                                        <h2>Crew Training</h2>
                                    </div>
                                </div>
                                {(trainingTasks?.top5.length) && trainingTasks.top5.map((task, index) => {
                                    if (index < 5) { // Only show top 5
                                        return (
                                            <div
                                                key={task.id} 
                                                className="row"
                                                onClick={(e) => onViewCrewTraining(task)}
                                            >
                                                <div>
                                                    {task?.task}
                                                </div>
                                                <div className="truncate bold">
                                                    {task?.task}
                                                </div>
                                                <div></div>
                                                <div className="status">{task.whenDue && <SeaStatusDueDate whenDue={task.whenDue} warnDays={warnDays.crewTraining[0]} />}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                                <div className="summary row">
                                    <div>Total Expired/Expiring</div>
                                    <div>{trainingTasks?.totalExpiredOrDueSoon}</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ViewVesselDetailsModal
                    showModal={modalToShow === 'vesselDetails'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'vesselDetails' : undefined)}
                />
                <EditVesselModal
                    showModal={modalToShow === 'editVessel'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'editVessel' : undefined)}
                />
                <EditEnginesModal
                    showModal={modalToShow === 'editEngines'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'editEngines' : undefined)}
                />
                <ViewSafetyCheck
                    showModal={modalToShow === 'safetyCheck'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'safetyCheck' : undefined)}
                    selectedItem={modalToShow === 'safetyCheck' ? selectedItem as SafetyCheckItem : undefined}
                />
                <ViewSafetyEquipment
                    showModal={modalToShow === 'safetyEquipment'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'safetyEquipment' : undefined)}
                    selectedItem={modalToShow === 'safetyEquipment' ? selectedItem as SafetyEquipmentItem : undefined}
                />
                <ViewDrill
                    showModal={modalToShow === 'drill'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'drill' : undefined)}
                    selectedItem={selectedItem as Drill}
                />
                <ViewMaintenanceSchedule
                    showModal={modalToShow === 'maintenanceSchedule'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'maintenanceSchedule' : undefined)}
                    selectedItem={modalToShow === 'maintenanceSchedule' ? selectedItem as ScheduledMaintenanceTask : undefined}
                    level={1}
                />
                <ViewJobList
                    showModal={modalToShow === 'jobList'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'jobList' : undefined)}
                    selectedItem={modalToShow === 'jobList' ? selectedItem as Job : undefined}
                />
                <ViewVesselCertificate
                    showModal={modalToShow === 'vesselCertificate'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'vesselCertificate' : undefined)}
                    selectedItem={modalToShow === 'vesselCertificate' ? selectedItem as VesselCertificate : undefined}
                />
                <ViewVesselDocument
                    showModal={modalToShow === 'vesselDocument'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'vesselDocument' : undefined)}
                    selectedItem={modalToShow === 'vesselDocument' ? selectedItem as VesselDocument : undefined}
                />
                <EditHealthSafetyMeeting
                    showModal={modalToShow === 'editHealthSafetyMeeting'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'editHealthSafetyMeeting' : undefined)}
                />
                <ViewCrewCertificate
                    showModal={modalToShow === 'crewCertificate'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'crewCertificate' : undefined)}
                    selectedItem={modalToShow === 'crewCertificate' ? selectedItem as CrewCertificate : undefined}
                />
                <ActionLogModal 
                    showModal={modalToShow === 'actionLog'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'actionLog' : undefined)}
                    vesselId={vesselId}
                />
                <EditTrainingTask
                    vesselId={(selectedItem as TrainingTask)?.vesselId}
                    showModal={modalToShow === 'editTrainingTask'}
                    setShowModal={(showModal) => setModalToShow(showModal ? 'editTrainingTask' : undefined)}
                />
            </>}
        </StandardPageLayout>
    );
};

export default VesselDashboard;
