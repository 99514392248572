import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { getDefaultCategoryId, makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { Contact } from '../../../../shared-state/Crew/contacts';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditContactProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: Contact,
}

const EditContact: React.FC<EditContactProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const contactCategories = sharedState.contactCategories.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                company: itemToUpdate.company ? ''+itemToUpdate.company : '',
                address: itemToUpdate.address ? ''+itemToUpdate.address : '',
                number: itemToUpdate.number ? ''+itemToUpdate.number : '',
                email: itemToUpdate.email ? ''+itemToUpdate.email : '',
                vendorNumber: itemToUpdate.vendorNumber ? ''+itemToUpdate.vendorNumber : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
                notes: itemToUpdate.notes ? ''+itemToUpdate.notes : ''
            };
        } else {
            return {
                name: '',
                company: '',
                address: '',
                number: '',
                email: '',
                vendorNumber: '',
                categoryId: getDefaultCategoryId('General', contactCategories),
                notes: ''
            };
        }
    }, [itemToUpdate, contactCategories]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    };

    const onClosed = () => {
        setFieldValue('categoryId', '');
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            company: Yup.string().max(500),
            address: Yup.string().max(5000),
            number: Yup.string().max(500),
            email: Yup.string().max(500),
            vendorNumber: Yup.string().max(500),
            categoryId: Yup.string().max(500).required(),
            notes: Yup.string().max(5000)
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('contact')) { return; }
            if (!licenseeId) {
                console.error('No licenseeId');
                return;
            }
            // Process form
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'contacts');
            if (itemToUpdate) {
                batchTrace.exampleDocId = itemToUpdate.id;
                batchTrace.exampleOperation = 'update';
                batch.set(
                    doc(firestore, 'contacts', itemToUpdate.id),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        name: data.name,
                        company: data.company ? data.company : '',
                        address: data.address ? data.address : deleteValue,
                        number: data.number ? data.number : deleteValue,
                        email: data.email ? data.email.trim().toLowerCase() : deleteValue,
                        vendorNumber: data.vendorNumber ? data.vendorNumber : deleteValue,
                        categoryId: makeCategoryId(
                            data.categoryId,
                            contactCategories,
                            deleteValue,
                            batch,
                            'contactCategories',
                            'licenseeId',
                            licenseeId as string
                        ),
                        notes: data.notes ? data.notes : deleteValue,
                        touched: serverTimestamp(),
                    },
                    { merge: true }
                );

                logAction(
                    batch,
                    'Update',
                    'contacts',
                    itemToUpdate.id,
                    data.name
                );
            } else {
                const newRef = doc(collection(firestore, 'contacts'));
                batchTrace.exampleDocId = newRef.id;
                batchTrace.exampleOperation = 'create';
                batch.set(newRef, {
                    licenseeId: licenseeId,
                    addedBy: userId,
                    whenAdded: batchTrace.whenAction,
                    name: data.name,
                    company: data.company ? data.company : '',
                    address: data.address ? data.address : undefined,
                    number: data.number ? data.number : undefined,
                    email: data.email ? data.email.trim().toLowerCase() : undefined,
                    vendorNumber: data.vendorNumber ? data.vendorNumber : undefined,
                    categoryId: makeCategoryId(
                        data.categoryId,
                        contactCategories,
                        undefined,
                        batch,
                        'contactCategories',
                        'licenseeId',
                        licenseeId
                    ),
                    notes: data.notes ? data.notes : undefined,
                    state: 'active',
                    touched: serverTimestamp(),
                });

                logAction(
                    batch,
                    'Add',
                    'contacts',
                    newRef.id,
                    data.name
                );
            }

            onCollectionUpdated(batch,'contacts');

            batchTrace.data = {
                data
            };
            batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} crew contact ${data?.name}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Contact/Supplier' : 'Add New Contact/Supplier'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label="Contact Name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
								label="Company"
								name="company"
								value={values.company}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.company ? errors.company : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Address"
								name="address"
								value={values.address}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.address ? errors.address : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
								label="Contact Number"
								name="number"
								value={values.number}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="tel"
								inputmode="tel"
								error={touched.number ? errors.number : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
								label="Email"
								name="email"
								value={values.email}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="email"
								inputmode="email"
								error={touched.email ? errors.email : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
								label="Vendor Number"
								name="vendorNumber"
								value={values.vendorNumber}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="tel"
								inputmode="tel"
								error={touched.vendorNumber ? errors.vendorNumber : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={contactCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Contact' : 'Save Contact'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditContact;
