import React, { useState, useMemo, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, addInterval, subtractInterval } from '../../../../lib/datesAndTime';
import { makeCategoryId, renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { SafetyEquipmentItem } from '../../../../shared-state/VesselSafety/safetyEquipmentItems';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';

interface EditSafetyEquipmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: SafetyEquipmentItem,
    level?: number
}

const EditSafetyEquipment: React.FC<EditSafetyEquipmentProps> = ({showModal, setShowModal, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                itemId: itemToUpdate.itemId ? ''+itemToUpdate.itemId : '',
                locationId: itemToUpdate.locationId ? ''+itemToUpdate.locationId : '',
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                quantity: itemToUpdate.quantity ? ''+itemToUpdate.quantity : '',
                description: itemToUpdate.description ? ''+itemToUpdate.description : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
                whenLastChecked: (itemToUpdate.type === 'servicable') ? formatSeaDate(itemToUpdate.whenLastChecked) : '',
                whenExpires: (itemToUpdate.type === 'expiring') ? formatSeaDate(itemToUpdate.whenDue) : '',
                emailReminder: itemToUpdate.emailReminder ? ''+itemToUpdate.emailReminder : '',
                isCritical: (vesselSafetyItems?.byId[itemToUpdate.itemId]?.isCritical) ? true : false,
            };
        } else {
            return {
                itemId: '',
                locationId: '',
                type: '',
                quantity: '',
                interval: '',
                whenLastChecked: formatSeaDate(),
                whenExpires: formatSeaDate(),
                emailReminder: '',
                isCritical: false,
            }
        }
    }, [itemToUpdate, vesselSafetyItems]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            itemId: Yup.string().max(500).required(),
            locationId: Yup.string().max(500),
            type: Yup.string().max(500).required(),
            quantity: Yup.string().max(500),
            description: Yup.string().max(5000),
            interval: Yup.string().max(200).when('type', {is: 'servicable', then: (schema) => schema.required()}),
            whenLastChecked: Yup.date().when('type', {is: 'servicable', then: (schema) => schema.max(formatSeaDate()).required().min(...notTooOld)}),
            whenExpires: Yup.date().when('type', {is: 'expiring', then: (schema) => schema.required().min(...notTooOld)}),
            emailReminder: Yup.string().max(200),
        }), onSubmit: (data) => {
            if (preventMultiTap('safetyEquipment')) { return; }
            if (!vesselId) {
                throw new Error("No vesselId");
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'safetyEquipmentItems');

                let whenDue = undefined;
                switch (data.type) {
                    case 'servicable':
                        whenDue = addInterval(data.whenLastChecked, data.interval);
                        break;
                    case 'expiring':
                        whenDue = toMillis(data.whenExpires);
                        break;
                }
                let whenToRemind = undefined;
                if (whenDue && data.emailReminder) {
                    whenToRemind = subtractInterval(whenDue, data.emailReminder);
                }
                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'safetyEquipmentItems', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            itemId: makeCategoryId(
                                data.itemId,
                                vesselSafetyItems,
                                deleteValue,
                                batch,
                                'vesselSafetyItems',
                                'vesselId',
                                vesselId,
                                {
                                    isCritical: data.isCritical ? true : false,
                                }
                            ),
                            locationId: makeCategoryId(
                                data.locationId,
                                vesselLocations,
                                deleteValue,
                                batch,
                                'vesselLocations',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            type: data.type,
                            quantity: data.quantity ? data.quantity : deleteValue,
                            description: data.description ? data.description : deleteValue,
                            interval: (data.type === 'servicable') ? data.interval : deleteValue,
                            whenDue: whenDue,
                            emailReminder: data.emailReminder ? data.emailReminder : deleteValue,
                            whenToRemind: whenToRemind,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),                            
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'safetyEquipmentItems', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'safetyEquipmentItems',
                        itemToUpdate.id,
                        renderCategoryName(data.itemId, vesselSafetyItems),
                        [itemToUpdate.vesselId]
                    );
                } else {
                    const newRef = doc(collection(firestore, 'safetyEquipmentItems'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        itemId: makeCategoryId(
                            data.itemId,
                            vesselSafetyItems,
                            undefined,
                            batch,
                            'vesselSafetyItems',
                            'vesselId',
                            vesselId,
                            {
                                isCritical: data.isCritical ? true : false,
                            }
                        ),
                        type: data.type,
                        locationId: makeCategoryId(
                            data.locationId,
                            vesselLocations,
                            undefined,
                            batch,
                            'vesselLocations',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        quantity: data.quantity ? data.quantity : undefined,
                        description: ((data.type === 'expiring' || data.type === 'servicable') && data.description) ? data.description : undefined,
                        interval: ((data.type === 'expiring' || data.type === 'servicable') && data.interval) ? data.interval : undefined, 
                        whenLastChecked: (data.type === 'servicable' && data.whenLastChecked) ? toMillis(data.whenLastChecked) : undefined,
                        whenDue: whenDue,
                        whenAdded: batchTrace.whenAction,
                        state: 'active',
                        emailReminder: ((data.type === 'expiring' || data.type === 'servicable') && data.emailReminder) ? data.emailReminder : undefined,
                        whenToRemind: whenToRemind,
                        files: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'safetyEquipmentItems', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'safetyEquipmentItems',
                        newRef.id,
                        renderCategoryName(data.itemId, vesselSafetyItems),
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'safetyEquipmentItems');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Create'} safety equipment expiry ${renderCategoryName(data.itemId, vesselSafetyItems)}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload safety equipment expiry files`, error.message, error, {
                        itemToUpdate,
                        data,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });
    
    const onOpened = () => {
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const onClosed = () => {
        setFieldValue('itemId', '');
        setFieldValue('locationId', '');
    }

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [values, files, initialValues, itemToUpdate?.files]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Safety Check Expiry' : 'Add Safety Check Expiry'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6" sizeMd='4' >
                            <SeaSelectCategory
                                categories={vesselSafetyItems}
                                label="Safety Item"
                                name="itemId"
                                initialCategoryId={initialValues.itemId}
                                categoryId={values.itemId}
                                otherPlaceholder="Add New Item"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.itemId ? errors.itemId : ''}
                            />
                        </IonCol>
                        <IonCol size="6" sizeMd='4' >
                            <SeaSelectCategory
                                categories={vesselLocations}
                                label="Location"
                                name="locationId"
                                initialCategoryId={initialValues.locationId}
                                categoryId={values.locationId}
                                otherPlaceholder="Add New Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.locationId ? errors.locationId : ''}
                            />
                        </IonCol>
                        <IonCol size="6" sizeMd='4'  style={{paddingRight: '4px'}}>
                            <SeaCheckbox
                                mode="beside-input"
                                label="Critical Equipment"
                                name="isCritical"
                                checked={values.isCritical ? true : false}
                                setFieldValue={setFieldValue}
                                help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}
                            >
                               This equipment is critical
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                disabled={itemToUpdate ? true : false}
                                label="Type"
                                name="type"
                                zone="white"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                                placeholder="Select Type"
                            >
                                <IonSelectOption value="servicable">Servicable equipment</IonSelectOption>
                                <IonSelectOption value="expiring">Expiring equipment</IonSelectOption>
                                <IonSelectOption value="nonExpiring">Non expiring equipment</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Quantity"
                                name="quantity"
                                value={values.quantity}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.quantity ? errors.quantity : ''}
                            />
                        </IonCol>
                        {values.type === 'servicable' && <>
                            <IonCol size="6">
                                <SeaSelectInterval
                                    label="Interval"
                                    name="interval"
                                    value={values.interval}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.interval ? errors.interval : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaDate
                                    disabled={itemToUpdate ? true : false}
                                    label="Last Check"
                                    name="whenLastChecked"
                                    value={values.whenLastChecked}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.whenLastChecked ? errors.whenLastChecked : ''}
                                />
                            </IonCol>
                        </>}
                        {values.type === 'expiring' && <>
                            <IonCol size="6">
                                <SeaDate
                                    disabled={itemToUpdate ? true : false}
                                    label="Expiry Date"
                                    name="whenExpires"
                                    value={values.whenExpires}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.whenExpires ? errors.whenExpires : ''}
                                />
                            </IonCol>
                        </>}
                        {(values.type === 'servicable' || values.type === 'expiring') && <>
                            <IonCol size="12">
                                <SeaTextarea
                                    label={values.type === 'servicable' ? 'Service Task' : 'Expiry Task'}
                                    name="description"
                                    value={values.description}
                                    placeholder="Add task..."
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    inputmode="text"
                                    error={touched.description ? errors.description : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaSelectEmailReminder
                                    label="Set Email Reminder"
                                    name="emailReminder"
                                    value={values.emailReminder}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.emailReminder ? errors.emailReminder : ''}
                                />
                            </IonCol>
                        </>}
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="safetyEquipmentItems"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Safety Check Expiry' : 'Save New Safety Check Expiry'}</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditSafetyEquipment;
