import React, { useState } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { formatValue } from '../../../../lib/util';
import { formatDate, formatEmailReminder, warnDays } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CrewCertificate } from '../../../../shared-state/Crew/crewCertificates';
import { isPdf } from '../../../../lib/files';
import { renderCategoryName } from '../../../../lib/categories';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditCrewCertificate from '../EditCrewCertificate/EditCrewCertificate';
import RenewCrewCertificate from '../RenewCrewCertificate/RenewCrewCertificate';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaPDFViewer from '../../../../components/SeaPDFViewer/SeaPDFViewer';
import CrewPCertificatesDetailPdf from '../../../../exports/Crew/CrewCertificates/CrewCertificatesDetailPdf';

interface ViewCrewCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem?: CrewCertificate,
    level?: number
}

const ViewCrewCertificate: React.FC<ViewCrewCertificateProps> = ({showModal, setShowModal, selectedItem, level = 1}) => {
    const userId = sharedState.userId.use(showModal);
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showRenewItemModal, setShowRenewItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    const onEditItem = () => {
        setShowEditItemModal(true);
    };
    const onRenewItem = () => {
        setShowRenewItemModal(true);
    };
    const onArchiveItem = () => {
        if (!selectedItem) {
            return;
        }
        confirmAction('Are you sure you want to archive this certificate?', 'Yes, archive').then(() => {
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'crewCertificates', 'archive', selectedItem.id);

            batch.set(
                doc(firestore, 'crewCertificates', selectedItem.id),
                {
                    archivedBy: userId,
                    whenArchived: batchTrace.whenAction,
                    state: 'archived',
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            logAction(
                batch,
                'Archive',
                'crewCertificates',
                selectedItem.id,
                renderCategoryName(selectedItem?.titleId, crewCertificateTitles),
                undefined,
                [selectedItem.heldBy]
            );

            batchTrace.data = {selectedItem};
            batchTrace.save(`Archive certificate ${renderCategoryName(selectedItem?.titleId, crewCertificateTitles)}`);
            onCollectionUpdated(batch, 'crewCertificates');
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            showToast('Certificate has been archived');
            setShowModal(false);
        }).catch(() => {});
    };


    if (!selectedItem) {
        return <></>;
    }

    return (
        <SeaModal
            title={
                <>
                    <span style={{marginRight: '12px'}}>
                        {renderFullNameForUserId(selectedItem?.heldBy)} - {renderCategoryName(selectedItem?.titleId, crewCertificateTitles)}
                    </span>
                    {selectedItem?.whenExpires &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenExpires}
                            warnDays={warnDays.crewCertificates[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size='standard'
            level={level}
            onOpened={() => console.log(`Viewing crewCertificate ${selectedItem?.id}`)}
            onPdf={() => setExportType('pdf')}
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.files}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Issued By" w="50">
                    {formatValue(selectedItem?.issuedBy)}
                </SeaGridCell>
                <SeaGridCell label="Issued Date" w="50">
                    {formatDate(selectedItem?.whenIssued)}
                </SeaGridCell>
                {selectedItem?.type === 'renewable' && 
                    <>
                        <SeaGridCell label="Expiry" w="50">
                            {formatDate(selectedItem?.whenExpires)}
                        </SeaGridCell>
                        <SeaGridCell label="Notification" w="50">
                            {formatValue(formatEmailReminder(selectedItem?.emailReminder), 'No reminder set')}
                        </SeaGridCell>
                    </>
                }
            </SeaGrid>

            <RequirePermissions
                role="crewCertificates"
                level={permissionLevels.CREATE}
            >
                <div className="view-modal-buttons">
                    <RequirePermissions
                        role="crewCertificates"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" onClick={(e) => onEditItem()}>
                            <SeaIcon icon="edit" slot="start" />Edit Certificate
                        </SeaButton>
                        <div className="spacer-wide"></div>
                    </RequirePermissions>
                    {selectedItem?.type === 'renewable' &&
                        <SeaButton zone="white" onClick={(e) => onRenewItem()}>Renew Certificate</SeaButton>
                    }
                    <RequirePermissions
                        role="crewCertificates"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton mode="standard-link" onClick={(e) => onArchiveItem()}>Archive Certificate</SeaLinkButton>
                    </RequirePermissions>
                </div>
            </RequirePermissions>

            {isPdf(selectedItem?.files?.[0]) ? (
                <div className='sea-fill-container'>
                    <div className="end-info-line"></div>
                    <SeaLabel>PDF Preview</SeaLabel>
                    <SeaPDFViewer file={selectedItem.files?.[0]} allowScroll /> 
                </div>
            ) : null}
    
            {showModal &&
                <>
                    <EditCrewCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={level+1}
                    />
                    <RenewCrewCertificate
                        showModal={showRenewItemModal}
                        setShowModal={setShowRenewItemModal}
                        selectedItem={selectedItem}
                        setShowParentModal={setShowModal}
                        level={level+1}
                    />
                    {exportType === 'pdf' && <CrewPCertificatesDetailPdf
                        onCompleted={() => setExportType(undefined)}
                        selectedItem={selectedItem}
                    />}
                </>
            }
        </SeaModal>
    );
};

export default ViewCrewCertificate;
