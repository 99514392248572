import { IonSelectOption } from '@ionic/react';
import { formatInterval } from '../../lib/datesAndTime';
import React from 'react';
import SeaSelect from '../SeaSelect/SeaSelect';
import './SeaSelectInterval.css';

interface SeaSelectIntervalProps {
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    value?: string,
    selectedText?: string,
    error?: string,
    disabled?: boolean,

    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    oncancel?: (e: CustomEvent) => void
}

const SeaSelectInterval = React.forwardRef<HTMLInputElement, SeaSelectIntervalProps>(({ zone, name, label, value, selectedText, error, disabled, onchange, onblur, onfocus, oncancel }, forwardedRef:any) => {
    return (
        <SeaSelect
            ref={forwardedRef}
            zone={zone}
            label={label}
            name={name}
            disabled={disabled}
            value={value}
            selectedText={selectedText}
            error={error}
            onchange={onchange}
            onblur={onblur}
            onfocus={onfocus}
            oncancel={oncancel}
        >
            <IonSelectOption value="">Not Set</IonSelectOption>
            <IonSelectOption value="1d">{formatInterval('1d')}</IonSelectOption>
            <IonSelectOption value="7d">{formatInterval('7d')}</IonSelectOption>
            <IonSelectOption value="14d">{formatInterval('14d')}</IonSelectOption>
            <IonSelectOption value="1m">{formatInterval('1m')}</IonSelectOption>
            <IonSelectOption value="5w">{formatInterval('5w')}</IonSelectOption>
            <IonSelectOption value="2m">{formatInterval('2m')}</IonSelectOption>
            <IonSelectOption value="3m">{formatInterval('3m')}</IonSelectOption>
            <IonSelectOption value="4m">{formatInterval('4m')}</IonSelectOption>
            <IonSelectOption value="5m">{formatInterval('5m')}</IonSelectOption>
            <IonSelectOption value="6m">{formatInterval('6m')}</IonSelectOption>
            <IonSelectOption value="9m">{formatInterval('9m')}</IonSelectOption>
            <IonSelectOption value="12m">{formatInterval('12m')}</IonSelectOption>
            <IonSelectOption value="18m">{formatInterval('18m')}</IonSelectOption>
            <IonSelectOption value="24m">{formatInterval('24m')}</IonSelectOption>
            <IonSelectOption value="30m">{formatInterval('30m')}</IonSelectOption>
            <IonSelectOption value="36m">{formatInterval('36m')}</IonSelectOption>
            <IonSelectOption value="48m">{formatInterval('48m')}</IonSelectOption>
            <IonSelectOption value="60m">{formatInterval('60m')}</IonSelectOption>
            <IonSelectOption value="72m">{formatInterval('72m')}</IonSelectOption>
            <IonSelectOption value="96m">{formatInterval('96m')}</IonSelectOption>
            <IonSelectOption value="120m">{formatInterval('120m')}</IonSelectOption>
        </SeaSelect>
    );
});

export default SeaSelectInterval;
