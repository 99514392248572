import React, { useState } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import SeaDateRange, { DateRange } from '../SeaDateRange/SeaDateRange';
import SeaModal from '../SeaModal/SeaModal';
import SeaButton from '../SeaButton/SeaButton';

type SeaExporterDateRangeProps = {
    onExport: (dateRange: DateRange) => void;
    onClose: () => void; // Required as this is used to set exportType to undefined
}

const SeaExporterDateRange = ({onExport, onClose}: SeaExporterDateRangeProps) => {
    const [showDateRangeModal, setShowDateRangeModal] = useState(true);
    const todayMillis = sharedState.todayMillis.use()!;
    const [dateRange, setDateRange] = useState<DateRange>({
        from: todayMillis - (365 * 24 * 60 * 60 * 1000),
        to: todayMillis + (1 * 24 * 60 * 60 * 1000)
    });

    const onSubmit = () => {
        onExport(dateRange);
        setShowDateRangeModal(false);
    }

    return (
        <SeaModal
            title="Select Date Range to Export"
            showModal={showDateRangeModal}
            setShowModal={setShowDateRangeModal}
            size="thin"
            noContentPadding={false}
            onClosed={onClose}
        >
            <SeaDateRange
                label="Date Range"
                zone="white"
                dateRange={dateRange}
                setDateRange={setDateRange}
            />
            <div className="view-modal-buttons">
                <SeaButton onClick={onSubmit} size='wide'>
                    Export
                </SeaButton>
            </div>
        </SeaModal>
    )
}

export default SeaExporterDateRange