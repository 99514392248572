import React, { useEffect, useMemo, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { Job, jobPriorities } from '../../../../shared-state/VesselMaintenance/jobs';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import reporting from '../../../../lib/reporting';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import JobsReportPDF from '../../../../exports/Reporting/Maintenance/JobsReportPdf';
import './ViewJobsReport.css';
import { Equipment } from '../../../../shared-state/VesselMaintenance/equipment';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const jobsOptions = Object.keys(jobPriorities).map((statusId) => {
    return {
        id: statusId,
        name: jobPriorities[statusId as keyof typeof jobPriorities]
    };
});

interface ViewJobsReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: Job[],
    categories?: (string | undefined)[],
    selectedStatuses: string[],
    setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    itemCriticality: string,
    setItemCriticality: React.Dispatch<React.SetStateAction<string>>,
    itemsById?: {
        [key: string]: Equipment
    },
}

const ViewJobsReport: React.FC<ViewJobsReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
    selectedStatuses,
    setSelectedStatuses,
    itemCriticality,
    setItemCriticality,
    itemsById
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    const firstOfPriority = useMemo(() => {
        const firstOfPriority: { id: string; index: number; priority: string }[] = [];
        filteredItems?.forEach((item, index) => {
            if (!firstOfPriority.some((f) => item.priority === f.priority)) {
                firstOfPriority.push({ id: item.id, index, priority: item.priority })
            }
        });
        return firstOfPriority;
    }, [filteredItems])

    useEffect(() => {
        if (showModal) {
            logModalView('JobsStatusReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    
    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Maintenance Task Statuses"
                        values={selectedStatuses}
                        setValues={setSelectedStatuses}
                        options={jobsOptions}
                        useAllOption={true}
                    />,
                    <SeaSelect
                        label="Critical?"
                        value={itemCriticality}
                        onchange={(e) => {
                            setItemCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.jobPriorities}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="maintenance tasks"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`job-report has-thumbs has-status ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Task</div>
                        <div>Vessel</div>
                        <div>Due Date</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(filteredItems, (item, index) => {
                        let rowClass = '';
                        switch (item.priority) {
                            case "8urgent":
                                rowClass = "fail fault";
                                break;
                            case "6high":
                                rowClass = "fail";
                                break;
                            case "4medium":
                                rowClass = "warn-medium";
                                break;
                            case "2low":
                                rowClass = "warn-low";
                                break;
                            case "0shipyard":
                                rowClass = "warn-alt";
                                break;
                        }
                        let showPriority = false;
                        if (firstOfPriority.some((f) => f.index === index)) {
                            showPriority = true;
                        }
                        return (
                            <React.Fragment key={item.id}>
                                {showPriority ? 
                                    <div className={`category-heading ${item.priority === '8urgent' ? '' : 'top-spacer'}`}>
                                        {formatValue(item.priority && item.priority.charAt(1).toUpperCase() + item.priority.slice(2))}
                                    </div>
                                : null}
                                <div
                                    key={item.id}
                                    className={`sea-card sea-row-history ${rowClass}`}
                                >
                                    <div>
                                        <SeaFileImage files={item.files?.length ? item.files : itemsById?.[item.equipmentId]?.files} size="tiny"/>
                                    </div>
                                    <div className="bold truncate-2">
                                        {formatValue(item.task)}
                                    </div>
                                    <div className="truncate-2">
                                        {renderVesselName(item.vesselId)}
                                    </div>
                                    <div>{formatDate(item.whenDue)}</div>
                                    <div>
                                        {itemsById?.[item.equipmentId]?.isCritical && (
                                            <SeaStatusIcon
                                                icon="flag"
                                                className="critical"
                                            />
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>

            {showModal && filteredItems && exportType === 'pdf' &&
                <JobsReportPDF
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    itemsById={itemsById}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                    firstOfPriority={firstOfPriority}
                />
            }
        </SeaModal>
    );
};

export default ViewJobsReport;
