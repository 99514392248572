import { EditorConfig } from "lexical";
import { HeadingNode, HeadingTagType } from "@lexical/rich-text";

export class SeaSectionNode extends HeadingNode {
    static getType() {
        return "sea-section";
    }

    static clone(node: SeaSectionNode) {
        return new SeaSectionNode(node.__tag, node.__key);
    }

    // Add this new static method
    static importJSON(serializedNode: {
        tag: HeadingTagType;
        type: string;
        version: number;
    }): SeaSectionNode {
        const node = new SeaSectionNode(serializedNode.tag);
        return node;
    }

    createDOM(config: EditorConfig) {
        const dom = super.createDOM(config);
        dom.id = `${this.__tag}_${this.__key}`;

        if (this.__tag === 'h1' && this.__parent === 'root' && this.__prev !== null) {
            dom.classList.add('lex-page-breaker');
        }
        return dom;
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            tag: this.getTag(),
            type: 'sea-section'
        };
    }
}