import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { SFDoc } from './companyDocuments';
import { registerRichText } from "../FileSyncSystem/filesToCache";

//
// Loads the company plan
//

export interface CompanyPlan extends CreateableDocument, UpdateableDocument {
    emailReminder?: string;
    interval?: string;
    lastReviewDate?: number;
    sfdoc: SFDoc;
    whenDue?: number;
    whenToRemind?: number;
}

export const companyPlanConfig: SharedStateConfig<CompanyPlan> = {
    isAlwaysActive: false,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.companyPlan.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId && canView('companyPlan')) {
            return onSnapshot(
                doc(firestore, 'companyPlans', licenseeId),
                (doc) => {
                    done();
                    const companyPlan = doc.data() as CompanyPlan;
                    console.log(`<><><> Got companyPlan`, companyPlan, licenseeId, doc);
                    registerRichText(companyPlan?.sfdoc, 'companyPlans');
                    set(companyPlan);
                }, (error) => {
                    done();
                    console.log(`Error getting companyPlan`, error);
                }
            );
        }
    }
};
