import { doc, onSnapshot } from "firebase/firestore";
import { firestore, analytics, auth } from "../../lib/firebase";
import { logEvent as logAnalyticsEvent, setUserProperties as setAnalyticsUserProperties } from "firebase/analytics";
import { SharedStateConfig, sharedState } from "../shared-state";
import { hasArrayChanged } from "../../lib/util";
import { isPlatform } from "@ionic/react";
import { signInWithCustomToken, signOut } from "firebase/auth";

// 
// Loads user data based on userId.
// Is also responsible to set user properties for Analytics.
// Note: The user data may not match the authenticated user if a superadmin has logged in as someone.
//

export type UserType = {
    fromCache?: boolean,
    id?: string,
    firstName?: string,
    lastName?: string,
    position?: string,
    roleIds?: string[],
    isSuperAdmin?: boolean,
    isLicensee?: boolean,
    licenseeId?: string,
    vesselIds?: string[],
    isStaff?: boolean,
    whenRoleChanged?: number,
    isLoginDisabled?: boolean,
    contactNumber?: string,
    //dateOfBirth?: string,
    whenAdded?: number,
    whenWelcomed?: number,
    whenActivated?: number,
    whenInducted?: number,
    //passportNumber?: string,
    //address?: string,
    //nextOfKin?: {
    //    name?: string,
    //    relationship?: string,
    //    phone?: string,
    //},
    //bankAccountNumber?: string,
    //irdNumber?: string,
    //contractFiles?: string[],
    //inductionFiles?: string[],
    //medicalDoctor?: {
    //    name?: string,
    //    phone?: string,
    //},
    //currentMedicalIssues?: string,
    //currentMedication?: string,
    //previousInjuryOrSurgery?: string,
    //allergies?: string,
    //bloodType?: string,
    //emailMe?: string[],
    //weeklyReport?: string[],
    state?: string,
    timezone?: string,
    whenUpdated?: string,
    whenArchived?: string,
    whenDeleted?: string,
    deletedBy?: string,
    addedBy?: string,
    updatedBy?: string,
    isDeactivated?: boolean,
    // licensee
    companyName?: string,
    //companyAddress?: string,
    //skipperInductionFiles?: string[],
    //crewInductionFiles?: string[],
    //stripeCreditCardDetails?: any,
    //numVessels?: number,
};

export type LicenseeUserType = UserType & {
    id: string,
    uid?: string,
    dateOfBirth?: string,
    passportNumber?: string,
    address?: string,
    nextOfKin?: {
       name?: string,
       relationship?: string,
       phone?: string,
    },
    bankAccountNumber?: string,
    irdNumber?: string,
    contractFiles?: string[],
    inductionFiles?: string[],
    medicalDoctor?: {
       name?: string,
       phone?: string,
    },
    currentMedicalIssues?: string,
    currentMedication?: string,
    previousInjuryOrSurgery?: string,
    allergies?: string,
    bloodType?: string,
    emailMe?: string[],
    weeklyReport?: string[],
    companyAddress?: string,
    skipperInductionFiles?: string[],
    crewInductionFiles?: string[],
    stripeCreditCardDetails?: any,
    numVessels?: number,
    licenseeNumber?: number,
};

export const userConfig: SharedStateConfig<UserType> = {
    isAlwaysActive: true,
    dependencies: ['userId'],
    countLiveDocs: () => sharedState.user.current ? 1 : 0,
    run: (done, set, clear) => {
        const userId = sharedState.userId.current;
        if (userId) {
            return onSnapshot(
                doc(firestore, 'users', userId),
                { includeMetadataChanges: true },
                (snap) => {
                    done();
                    if (!snap.exists()) {
                        console.log('No user matching userId! '+userId);
                        clear();
                        sharedState.vesselIds.clear();

                        // Fix the "quick restart" problem which takes you to the login screen
                        if (
                            isPlatform('hybrid') &&
                            !sharedState.onlineStatus.current?.isOnline &&
                            window.location.href.indexOf('/restart?reason=offlineFail') === -1
                        ) {
                            window.location.href = '/restart?reason=offlineFail';
                        }
                    } else {
                        const data = snap.data();
                        set({
                            id: snap.id,
                            fromCache: snap.metadata.fromCache,
                            ...data
                        });

                        // Update licenseeId if changed and notify fileSync system
                        if (sharedState.licenseeId.current !== data?.licenseeId) {
                            sharedState.licenseeId.set(data?.licenseeId);
                        }

                        // Update vesselIds if they changed
                        if (hasArrayChanged(data.vesselIds, sharedState.vesselIds.current)) {
                            sharedState.vesselIds.set(data.vesselIds);
                        }

                        // User properties for analytics
                        if (sharedState.superAdmin.current === undefined) {
                            let type = 'nonStaff';
                            if (data?.isLicensee) {
                                type = 'licensee';
                            } else if (data?.isStaff) {
                                type = 'staff';
                            } else {
                                type = 'nonStaff';
                            }
                            setAnalyticsUserProperties(
                                analytics,
                                {
                                    isSuperAdmin: false,
                                    isLicensee: data?.isLicensee,
                                    isStaff: data?.isStaff,
                                    state: data?.state,
                                    type
                                }
                            );
                        }
                    }
                    // sharedState.userPending.set(false); <-- Actually, let's keep userPending=true until userPermissions have loaded
                }, (error) => {
                    done();
                    clear();
                    sharedState.userPending.set(false);
                    console.log(`error getting user ${userId}`, error);
                }

            );
        }
    },
};

// export const signupUser = (email: string, password: string) => {
//     return createUserWithEmailAndPassword(auth, email || '', password || ''); // returns Promise
// };

export const loginUser = (token: string) => {
    return signInWithCustomToken(auth, token); // returns Promise
};

export const logoutUser = () => { // returns Promise
    return signOut(auth).then(() => {
        logAnalyticsEvent(analytics, 'logout');
        sharedState.superAdmin.clear();
        sharedState.user.clear();
        return Promise.resolve();
    });
};
