import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../shared-state";
import { makeDateTime } from "../../lib/datesAndTime";
import { canView } from "../Core/userPermissions";
import { useEffect, useState } from 'react';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';

//
// Load training task reports
//

export interface TrainingTaskReport extends CreateableDocument, UpdateableDocument {
    completedBy: string[];
    deletedBy?: string;
    licenseeId: string;
    notes?: string;
    state: string;
    taskId: string;
    trainer: string;
    vesselId: string;
    whenCompleted: number;
    whenDeleted?: number;
    whenDue: number;
    whenDueDiff?: number;
}

export type TrainingTaskReportsData = {
    all: TrainingTaskReport[], // ordered by whenDue (desc)
    byTaskId: {
        [taskId: string]: TrainingTaskReport[] // ordered by whenDue (desc)
    },
}


export const useTrainingTaskReportsDateRange = (dateRange?: DateRange) => {
    const trainingTasks = sharedState.trainingTasks.use();
    const todayMillis = sharedState.todayMillis.use();
    const licenseeId = sharedState.licenseeId.use();
    const users = sharedState.users.use();
    const [trainingTaskReports, setTrainingTaskReports] = useState<TrainingTaskReportsData>();

    useEffect(() => {
        setTrainingTaskReports(undefined);
        if (
            licenseeId &&
            dateRange &&
            trainingTasks &&
            users &&
            canView('crewTraining')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'trainingTaskReports'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    where('whenAdded', '>=', dateRange.from),
                    where('whenAdded', '<=', dateRange.to),
                    orderBy('whenDue', 'desc')
                ),
                (snap) => {
                    const reports = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as TrainingTaskReport;
                    });
                    const byTaskId = {} as {
                        [taskId: string]: TrainingTaskReport[]
                    };

                    const today = makeDateTime(todayMillis);
                    reports.forEach((report: TrainingTaskReport) => {
                        if (trainingTasks.byId[report.taskId]?.state === 'active') {
                            report.whenDueDiff = Math.round(
                                makeDateTime(report.whenDue).diff(today, ['days']).days
                            );
                            if (byTaskId[report.taskId] === undefined) {
                                byTaskId[report.taskId] = [];
                            }
                            byTaskId[report.taskId].push(report);
                        }
                    });
    

                    setTrainingTaskReports({ byTaskId, all: reports});

                }, (error) => {
                    // This should be very rare
                    console.log('Failed to access training task reports ', error)
                }
            );
        }
    }, [trainingTasks, users, todayMillis, licenseeId, dateRange]);

    return trainingTaskReports;
};
