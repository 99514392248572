import React, { useCallback, useMemo } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { formatValue, formatVessels, getBasedOnBackgroundColour } from '../../../lib/util';
import { formatDate, formatInterval } from '../../../lib/datesAndTime';
import { renderCategoryName } from '../../../lib/categories';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { Risk } from '../../../shared-state/HealthSafety/risks';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';


interface RiskRegisterPdfProps {
    onCompleted: () => void,
    filterVesselId?: string,
    isUsingFilter: boolean,
    filteredRisks?: {
        [key: string]: Risk[]
    },
}

const RiskRegisterPdf: React.FC<RiskRegisterPdfProps> = ({
    onCompleted,
    filterVesselId,
    isUsingFilter,
    filteredRisks
}) => {
    const vessels = sharedState.vessels.use();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const todayMillis = sharedState.todayMillis.use()!;
    const riskCategories = sharedState.riskCategories.use();

    const pdfTitle = useMemo(() => {
        return `Risk Assessments - ${isUsingFilter && filterVesselId ? vessels?.byId[filterVesselId]?.name : 'All Vessels' }`;
    }, [isUsingFilter, filterVesselId, vessels]);

    const renderRiskRating = useCallback((likelihoodId: string, consequenceId: string) => {
        if (!licenseeSettings) {
            return undefined;
        }
        const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId}`];
        const color = raw.substring(0, 6);
        const name = raw.substring(7);
        return (
            <View style={{
                ...pdfStyles.statusColumn,
                ...pdfStyles.tableCell,
                width: '3.5cm',
                alignSelf: 'center',
                backgroundColor: `#${color}`,
                ...pdfStyles.bold,
                color: getBasedOnBackgroundColour(color, '#333', '#fff'),
                paddingBottom: '0.25cm'
            }}>
                <Text>{name}</Text>
                {/* <Text style={{ ...pdfStyles.semiBold, fontSize: '8pt' }}>
                    LIKELIHOOD
                </Text> */}
                <View style={{ height: '0.2cm' }}></View>
                <Text style={pdfStyles.semiBold}>Likelihood:</Text>
                <Text style={pdfStyles.semiBold}>{(licenseeSettings.riskRegister.likelihoods[`L${likelihoodId}`] as { name: string, description: string }).name}</Text>
                <View style={{ height: '0.2cm' }}></View>
                <Text style={pdfStyles.semiBold}>Consequence:</Text>
                <Text style={pdfStyles.semiBold}>{(licenseeSettings.riskRegister.consequences[`C${consequenceId}`] as { name: string, description: string }).name}</Text>
            </View>
        );
    }, [licenseeSettings]);

    const renderWhenDueColor = useCallback((whenDue) => {
        if (whenDue < todayMillis) {
            return '#eb445a'; // fail
        } else if (whenDue < todayMillis + (30 * 24 * 60 * 60 * 1000)) {
            return '#e0ac08'; // warn
        }
        return '';
    }, [todayMillis]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={pdfTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="landscape"
                    size="A4" // 29.7cm x 21cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={pdfTitle}
                        //subHeading={(listType === 'prioritised') ? `Overdue & Due Within ${warnDays.safetyEquipmentChecks[0]} Days` : undefined}
                        userFullName={renderFullName()}
                        marginBottom="0"
                    />
                    {filteredRisks && riskCategories?.ids?.map((categoryId: string) => {
                        if (filteredRisks[categoryId] === undefined) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={categoryId}>
                                <View wrap={false} style={{
                                    ...pdfStyles.itemsHeader,
                                    marginBottom: '0.4cm',
                                    marginTop: '0.2cm'
                                }}>
                                    <View>
                                        <Text style={{
                                            ...pdfStyles.subHeading,
                                            fontSize: '12pt'
                                        }}>
                                            {renderCategoryName(categoryId, riskCategories)}
                                        </Text>
                                    </View>
                                </View>
                                <View wrap={false} style={{
                                    ...pdfStyles.tableHeadingsRow,
                                    marginBottom: '0.1cm'
                                }}>
                                    {!isUsingFilter &&
                                        <View style={pdfStyles.dateColumn}>
                                            <Text>Vessel</Text>
                                        </View>
                                    }
                                    <View style={styleItemColumn(2)}>
                                        <Text>Hazards / Risks</Text>
                                    </View>
                                    <View style={{
                                        ...pdfStyles.statusColumn,
                                        width: '3.5cm'
                                    }}>
                                        <Text>Pre Controls</Text>
                                    </View>
                                    <View style={styleItemColumn(2.25)}>
                                        <Text>Controls, Person Responsible</Text>
                                    </View>
                                    <View style={{
                                        ...pdfStyles.statusColumn,
                                        width: '3.5cm'
                                    }}>
                                        <Text>Post Controls</Text>
                                    </View>
                                    <View style={{
                                        ...pdfStyles.dateColumn,
                                        width: '2.5cm'
                                    }}>
                                        <Text>Review</Text>
                                    </View>
                                </View>
                                {filteredRisks[categoryId]?.map((item: Risk, index: number) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            {/* {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            } */}
                                            <View wrap={false} style={pdfStyles.tableRow}>
                                                {!isUsingFilter &&
                                                    <View style={{
                                                        ...pdfStyles.dateColumn,
                                                        ...pdfStyles.tableCell
                                                    }}>
                                                        <Text>{formatVessels(item.vesselIds, vessels)}</Text>
                                                    </View>
                                                }
                                                <View style={{
                                                    ...styleItemColumn(2),
                                                    ...pdfStyles.tableCell
                                                }}>
                                                    {item.name &&
                                                        <Text style={pdfStyles.bold}>{item.name}</Text>
                                                    }
                                                    <Text>{formatValue(item.risks, '')}</Text>
                                                </View>
                                                {renderRiskRating(item.preControls.likelihood, item.preControls.consequence)}
                                                <View style={{
                                                    ...styleItemColumn(2.25),
                                                    ...pdfStyles.tableCell
                                                }}>
                                                    <Text>{formatValue(item.controls, '')}</Text>
                                                    <Text> </Text>
                                                    <Text>Responsible: {formatValue(item.whoResponsible, 'None')}</Text>
                                                </View>
                                                {renderRiskRating(item.postControls.likelihood, item.postControls.consequence)}
                                                <View style={{
                                                    ...pdfStyles.dateColumn,
                                                    width: '2.5cm',
                                                    ...pdfStyles.tableCell,
                                                    borderRightWidth: '1pt',
                                                    borderRightStyle: 'solid',
                                                    borderRightColor: '#999'
                                                }}>
                                                    <Text
                                                        style={{
                                                            color: renderWhenDueColor(item.whenDue),
                                                            fontWeight: renderWhenDueColor(item.whenDue) ? 600 : 400
                                                        }}
                                                    >
                                                        {formatDate(item.whenDue)}
                                                    </Text>
                                                    <Text>({formatInterval(item.interval)})</Text>
                                                </View>
                                            </View>
                                            {(index === filteredRisks[categoryId].length - 1) &&
                                                <View wrap={false} style={{
                                                    ...pdfStyles.itemHeadingsLine,
                                                    marginTop: '0',
                                                    marginBottom: '0.4cm'
                                                }}></View>
                                            }
                                        </React.Fragment>
                                    );
                                })}

                            </React.Fragment>
                        );
                    })}
                </Page>
            </Document>
        );
    }, [filteredRisks, isUsingFilter, pdfTitle, renderRiskRating, renderWhenDueColor, riskCategories, vessels]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={pdfTitle}
            MakePdf={MakePDF}
            requestedFrom="RiskRegister"
        />
    );
};

export default RiskRegisterPdf;
