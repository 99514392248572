import React, { useCallback } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDate } from '../../../lib/datesAndTime';
import { renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

export interface FilteredUsersProps {
    staff: UserType[];
    nonStaff: UserType[];
}

interface CrewParticularsListPdfProps {
    onCompleted: () => void,
    filteredUsers: FilteredUsersProps,
}

const CrewParticularsListPdf: React.FC<CrewParticularsListPdfProps> = ({
    onCompleted,
    filteredUsers
}) => {
    const title = 'Crew';
    const userDetails = sharedState.userDetails.use(1);


    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={styleItemColumn(2)}>
                            <Text>Name</Text>
                        </View>
                        <View style={styleItemColumn(2)}>
                            <Text>Email</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Job Title / Position</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Inducted Date</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Login Access</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {
                        filteredUsers?.staff && filteredUsers.staff.length > 0 &&
                        <React.Fragment key='crew-staff'>
                        <View wrap={false} style={pdfStyles.itemsCategory}>
                            <Text>CREW/STAFF</Text>
                        </View>
                        {filteredUsers?.staff.map((user: UserType, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {index > 0 &&
                                        <View style={pdfStyles.itemsRowSpacer}></View>
                                    }
                                    <View wrap={false} style={pdfStyles.itemsRow}>
                                        <View style={styleItemColumn(2)}>
                                            <Text wrap={true}>
                                                {renderFullName(user)}
                                            </Text>
                                            {
                                                user.isLicensee &&
                                                <Text>Licensee</Text>
                                            }
                                        </View>

                                        <View style={styleItemColumn(2)}>
                                            <Text>{user.id ? userDetails?.byId[user.id]?.email : ''}</Text>
                                        </View>
                                        <View style={styleItemColumn(1)}>
                                            <Text>{formatValue(user.position)}</Text>
                                        </View>
                                        <View style={pdfStyles.dateColumn}>
                                            <Text>{formatValue(user.whenInducted ? formatDate(user.whenInducted) : '')}</Text>
                                        </View>
                                        <View style={pdfStyles.statusColumn}>
                                            <Text>{!user.isLoginDisabled ? 'Y' : 'N'}</Text>
                                        </View>
                                    </View>
                                </React.Fragment>
                            )
                        }
                        )}
                        <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                        </React.Fragment>
                    }

                    {
                        filteredUsers?.nonStaff && filteredUsers.nonStaff.length > 0 &&
                        <React.Fragment key='non-staff'>
                            <View wrap={false} style={pdfStyles.itemsCategory}>
                                <Text>NON-STAFF</Text>
                            </View>
                            {filteredUsers?.nonStaff.map((user: UserType, index) => {
                                return (
                                    <React.Fragment key={'nostaff' + index}>
                                        {index > 0 &&
                                            <View style={pdfStyles.itemsRowSpacer}></View>
                                        }
                                        <View wrap={false} style={pdfStyles.itemsRow}>
                                            <View style={styleItemColumn(2)}>
                                                <Text wrap={true}>
                                                    {renderFullName(user)}
                                                </Text>
                                                {
                                                    user.isLicensee &&
                                                    <Text>Licensee</Text>
                                                }
                                            </View>

                                            <View style={styleItemColumn(2)}>
                                                <Text>{user.id ? userDetails?.byId[user.id]?.email : ''}</Text>
                                            </View>
                                            <View style={styleItemColumn(1)}>
                                                <Text>{formatValue(user.position)}</Text>
                                            </View>
                                            <View style={pdfStyles.dateColumn}>
                                                <Text>{formatValue(user.whenInducted ? formatDate(user.whenInducted) : '')}</Text>
                                            </View>
                                            <View style={pdfStyles.statusColumn}>
                                                <Text>{!user.isLoginDisabled ? 'Y' : 'N'}</Text>
                                            </View>
                                        </View>
                                    </React.Fragment>
                                )
                            })}
                            <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                        </React.Fragment>
                    }
                </Page>
            </Document>
        );
    }, [filteredUsers, userDetails?.byId]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="CrewParticularsListReports"
        />
    );
};

export default CrewParticularsListPdf;
