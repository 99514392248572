import { DocumentData, QueryDocumentSnapshot, collection, orderBy, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { CreateableDocument, UpdateableDocument, sharedState } from "../../shared-state";
import { canView } from "../../Core/userPermissions";
import { useEffect, useState } from 'react';
import { CustomForm } from './customForms';
import { registerFiles, registerSignature } from "../../FileSyncSystem/filesToCache";

//
// Load completed custom forms
//


export interface CustomFormCompleted extends UpdateableDocument, CreateableDocument {
    attachTo: any;
    attachToId?: string;
    attachToVesselId?: string;
    customFormId: string;
    data: any;
    deletedBy?: string;
    isDraft: boolean;
    licenseeId: string;
    personnelIds?: string[];
    state: string;
    version: number;
    vesselIds: string[];
    whenDeleted?: number;
    completedBy: string;
    whenCompleted: number;
    notes?: string;
    files?: string[];
}

export type CustomFormsCompletedData = {
    all: CustomFormCompleted[], // Active & alphabetical
    byId: {
        [id: string]: CustomFormCompleted
    },
};

export const useCustomFormsCompleted = (selectedCustomForm?: CustomForm) => {
    const vesselIds = sharedState.vesselIds.current;
    const licenseeId = sharedState.licenseeId.current;
    const customFormVersions = sharedState.customFormVersions.use();
    const [customFormsCompleted , setCustomFormsCompleted] = useState<CustomFormsCompletedData>();

    useEffect(() => {
        setCustomFormsCompleted(undefined);
        if (
            selectedCustomForm && 
            vesselIds &&
            licenseeId &&
            canView('customForms')
        ) {
            return setupArrayQueryListener(
                'customFormsCompleted', // what
                collection(firestore, 'customFormsCompleted'),
                [
                    where('customFormId', '==', selectedCustomForm.id),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active')
                ],
                'vesselIds',
                'array-contains-any',
                ['none', ...vesselIds],
                [orderBy('whenAdded', 'desc')], // orderBy
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean // (since there is no orderBy, this is irrelevant)
                ) => { // processDocs
                    const all = docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as CustomFormCompleted;
                    });
                    const byId = {} as {
                        [id: string]: CustomFormCompleted
                    };
                    all.forEach((form) => {
                        byId[form.id] = form;
                        const formVersion = customFormVersions?.byFormIdAndVersion[selectedCustomForm.id][form.version];
                        if (formVersion) {
                            for (let key in formVersion.form) {
                                if (formVersion.form.hasOwnProperty(key)) {
                                    if (formVersion.form[key].id === 'files') {
                                        if (form?.data[formVersion.form[key].n]) {
                                            registerFiles(form.data[formVersion.form[key].n], 'customFormsCompleted', form);
                                        }
                                    } else if (formVersion.form[key].id === 'signature') {
                                        if (form?.data[formVersion.form[key].n]) {
                                            registerSignature(form.data[formVersion.form[key].n], 'customFormsCompleted', form);
                                        }
                                    }
                                }
                            }
                        }
                    });

                    if (isCombined) { // Need to sort by whenAdded
                        all.sort((a, b) => {
                            return b.whenAdded - a.whenAdded;
                        });
                    }

                    setCustomFormsCompleted({
                        all,
                        byId
                    });
                }
            );
        }
    }, [customFormVersions?.byFormIdAndVersion, licenseeId, selectedCustomForm, vesselIds]);

    return customFormsCompleted;
};
