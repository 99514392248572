import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { formatSeaDate, makeDateTime } from "../../lib/datesAndTime";
import { SharedStateConfig, sharedState } from "../shared-state";

// Handles timezone changes.
// If the user's device's timezone has changed, then the new timezone is recorded within the deviceInfo collection

export const handleTimezoneConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    dependencies: ['deviceInfo', 'todayMillis', 'deviceId', 'userId'],
    default: makeDateTime().zoneName,
    notes: 'Will update device\'s timezone to Firestore if it has changed',
    run: (done, set, clear) => {
        // Techinically, this doesn't load anything... It's just used to trigger timezone updates
        done();
        const deviceInfo = sharedState.deviceInfo.current;
        const todayMillis = sharedState.todayMillis.current;
        const deviceId = sharedState.deviceId.current;
        const userId = sharedState.userId.current;
        if (deviceInfo && todayMillis) {
            const lastDate = formatSeaDate();
            const timezone = makeDateTime().zoneName;
            set(timezone);
            if (
                deviceInfo.timezone === undefined ||
                deviceInfo.timezone !== timezone ||
                deviceInfo.lastDate === undefined ||
                deviceInfo.lastDate !== lastDate
            ) {
                // Update current timezone for this device
                setDoc(
                    doc(firestore, 'deviceInfo', `${userId}${deviceId}`),
                    {
                        timezone: timezone,
                        lastDate: lastDate
                    },
                    { merge: true }
                ).catch((error) => {
                    console.error('Failed to update timezone', error);
                    set('Failed to update timezone');
                }).then(() => {
                    console.log('Timezone updated.', timezone);
                    set(`Updated timezone to ${timezone}`);
                });
            }
        }
    }
};
