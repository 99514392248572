import React, { useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { Incident } from "../../../shared-state/HealthSafety/incidents";
import { renderCategoryName } from '../../../lib/categories';
import { renderCamelCase } from '../../../lib/util';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type IncidentEventReportsCsvProps = {
    onCompleted: () => void
}

const IncidentEventReportsCsv = ({onCompleted }: IncidentEventReportsCsvProps) => {
    const incidents = sharedState.incidents.use();
    const vessels = sharedState.vessels.use();
    const incidentCategories = sharedState.incidentCategories.use();
    const incidentCauses = sharedState.incidentCauses.use();
    const injuryLocations = sharedState.injuryLocations.use();

    const csvConfig: CsvConfig<Incident> | undefined = useMemo(() => {
        if (!vessels || !incidentCategories || !incidentCauses || !injuryLocations) {
            return undefined;
        }
        return [
            {
                name: 'Name',
                value: (item) => item.name || ''
            },
            {
                name: 'Report #',
                value: (item) => item.reportNum || ''
            },
            {
                name: 'Date',
                value: (item) => item.whenEvent ? new Date(item.whenEvent).toLocaleDateString() : ''
            },
            {
                name: 'Type',
                value: (item) => renderCamelCase(item.type)
            },
            {
                name: 'Status',
                value: (item) => renderCamelCase(item.state)
            },
            {
                name: 'Vessel/Facility',
                value: (item) => vessels.byId[item.vesselId]?.name || ''
            },
            {
                name: 'Type of event',
                value: (item) => renderCamelCase(item.type)
            },
            {
                name: 'Title of event',
                value: (item) => item.name || ''
            },
            {
                name: 'Event category',
                value: (item) => renderCategoryName(item.categoryId, incidentCategories)
            },
            {
                name: 'Types of Personnel involved',
                value: (item) => item.whoInvolvedTypes?.join(', ') || ''
            },
            {
                name: 'First reported by',
                value: (item) => item.reportedBy || ''
            },
            {
                name: 'What was their role in the event?',
                value: (item) => item.role || ''
            },
            {
                name: 'Names of those involved',
                value: (item) => item.whoInvolved || ''
            },
            {
                name: 'Witnesses',
                value: (item) => item.witnesses || ''
            },
            {
                name: 'Time of event',
                value: (item) => formatCsvDate(item.whenEvent)
            },
            {
                name: 'Event location',
                value: (item) => item.location || ''
            },
            {
                name: 'Environmental conditions (if applicable)',
                value: (item) => item.conditions || ''
            },
            {
                name: 'Have authorities been notified',
                value: (item) => formatCsvBoolean(item.notifiedAuthorities)
            },
            {
                name: 'Contributing factors of this incident/event',
                value: (item) => formatCsvNamesFromIds(item.causeIds, incidentCauses?.byId)
            },
            {
                name: 'Is there any damage to property?',
                value: (item) => formatCsvBoolean(item.propertyDamage)
            },
            {
                name: 'What happened?',
                value: (item) => item.description || ''
            },
            {
                name: 'What initial actions have you taken?',
                value: (item) => item.initialActions || ''
            },
            {
                name: 'Do you suggest any corrective actions?',
                value: (item) => item.prevention || ''
            },
        ];
    }, [incidentCategories, incidentCauses, injuryLocations, vessels]);


    return <SeaCSVGenerator name={'Incident-Event-Reports'} onCompleted={onCompleted} config={csvConfig} data={incidents?.all} />
};


export default IncidentEventReportsCsv