import React, { useMemo, useState } from 'react';
import { formatTextAreaText, formatValue, formatSparePartsList, renderCamelCase } from '../../../../lib/util';
import { formatDatetime } from '../../../../lib/datesAndTime';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { renderCategoryName } from '../../../../lib/categories';
import { SplittableBatch } from '../../../../lib/firebase';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { getEngineName } from '../../../../shared-state/Core/vessel';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import { SparePart } from '../../../../shared-state/VesselMaintenance/spareParts';
import { Job } from '../../../../shared-state/VesselMaintenance/jobs';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditMaintenanceHistory from '../EditMaintenanceHistory/EditMaintenanceHistory';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import './ViewMaintenanceHistory.css';

interface ViewMaintenanceHistoryProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: MaintenanceTaskCompleted,
}

const ViewMaintenanceHistory: React.FC<ViewMaintenanceHistoryProps> = ({showModal, setShowModal, selectedItem}) => {
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    const jobs = sharedState.jobs.use(showModal);
    const spareParts = sharedState.spareParts.use(showModal);
    const [showEditItemModal, setShowEditItemModal] = useState(false);

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    const equipmentSpareParts = useMemo(() => {
        if (showModal && selectedItem && spareParts) {
            const _spareParts: SparePart[] = [];
            spareParts.all.forEach((sparePart) => {
                if (sparePart.equipmentIds) {
                    for (let i = 0; i < sparePart.equipmentIds.length; i++) {
                        if (sparePart.equipmentIds[i] === selectedItem.equipmentId) {
                            _spareParts.push(sparePart);
                            break;
                        }
                    }
                }
            });
            return _spareParts;
        }
        return undefined;
    }, [showModal, selectedItem, spareParts]);

    const scheduledTask = useMemo(() => {
        if (selectedItem.maintenanceTaskId && scheduledMaintenanceTasks) {
            return scheduledMaintenanceTasks.byId[selectedItem.maintenanceTaskId];
        } else if (selectedItem.jobId && jobs) {
            return jobs?.byId.all[selectedItem.jobId];
        }
    }, [jobs, scheduledMaintenanceTasks, selectedItem.jobId, selectedItem.maintenanceTaskId])

    if (selectedItem) {
        return (
            <SeaModal
                title="Completed Maintenance Task"
                showModal={showModal}
                setShowModal={setShowModal}
                size='wide'
            >
                <div className="info-image">
                    <SeaFileImage
                        files={selectedItem?.files}
                        size="medium"
                        showOthers
                    />
                </div>
                <SeaGrid>
                    <SeaGridCell label="Task" w="100">
                        {formatValue(formatTextAreaText(selectedItem?.task))}
                    </SeaGridCell>
                </SeaGrid>
                {selectedItem?.description &&
                    <SeaGrid>
                        <SeaGridCell label="Description" w="100">
                            {formatValue(formatTextAreaText(selectedItem?.description))}
                        </SeaGridCell>
                    </SeaGrid>
                }
                <SeaGrid>
                    <SeaGridCell label="When Completed" w="50">
                        {formatDatetime(selectedItem?.whenCompleted)}
                    </SeaGridCell>
                    <SeaGridCell label="Completed By" w="50">
                        {renderFullNameForUserId(selectedItem?.completedBy)}
                    </SeaGridCell>

                    <SeaGridCell label="Type" w="50">
                        {formatValue(selectedItem.type === 'unscheduled' ? 'Job' : renderCamelCase(selectedItem.type))}
                    </SeaGridCell>
                    {selectedItem?.engineHours ?
                        <SeaGridCell label={`${selectedItem?.engineId && getEngineName(selectedItem.engineId)} Hours`} w="50">
                            {selectedItem.engineHours}
                        </SeaGridCell>
                    : null}
                    <SeaGridCell label="Equipment" w="50">
                        {formatValue(selectedItem?.equipment?.equipment)}
                        {selectedItem?.equipment?.state === 'deleted' && ' (deleted)'}
                    </SeaGridCell>
                    <SeaGridCell label="System" w="50">
                        {formatValue(renderCategoryName(selectedItem?.equipment?.systemId, vesselSystems))}
                    </SeaGridCell>
                    <SeaGridCell label="Location" w="50">
                        {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                    </SeaGridCell>
                    <SeaGridCell label="Critical Equipment" w="50" help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew. You can change whether an equipment item is critical or not through the Equipment List page.'}}>
                        {formatValue((selectedItem?.equipment) ? (selectedItem.equipment.isCritical ? 'Yes' : 'No') : '')}
                    </SeaGridCell>
                    <SeaGridCell label="Parts Used" w="50">
                        {selectedItem.spareParts ? formatValue(formatTextAreaText(formatSparePartsList(selectedItem.spareParts, equipmentSpareParts))) : '-'}
                    </SeaGridCell>
                    {selectedItem.type === 'job' ?
                        <SeaGridCell label="Job Tags" w="50">
                            {formatValue((scheduledTask as Job)?.tags?.join(', '))}
                        </SeaGridCell>
                    : null}
                    <SeaGridCell label="Maintenance Tags" w={selectedItem.type === 'job' ? '50' : '100'}>
                        {formatValue(selectedItem?.maintenanceTags?.join(', '))}
                    </SeaGridCell>
                </SeaGrid>
                <SeaGrid>
                    <SeaGridCell label="Notes" w={selectedItem.type === 'job' ? '50' : '100'}>
                        {formatValue(formatTextAreaText(selectedItem?.notes))}
                    </SeaGridCell>
                    {selectedItem.type === 'job' ?
                        <SeaGridCell label="Job#" w="50">
                            {formatValue((scheduledTask as Job)?.jobNum)}
                        </SeaGridCell>
                    : null}
                </SeaGrid>

                

                <div className="view-modal-buttons">
                    <RequirePermissions
                        role="maintenanceHistory"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Completed Task</SeaButton>
                        <RequirePermissions
                            role="maintenanceHistory"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    //onDeleteItem(selectedItem, 'maintenanceTasksCompleted', true);
                                    deleteIfConfirmed(
                                        'maintenanceTasksCompleted',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'maintenanceTasksCompleted');
                                            setShowModal(false);
                                        },
                                        'completed task',
                                        selectedItem.task,
                                        [selectedItem.vesselId]
                                    );
                                }}
                            >
                                Delete Completed Task
                            </SeaLinkButton>
                        </RequirePermissions>
                    </RequirePermissions>
                </div>

                {showModal &&
                    <EditMaintenanceHistory
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                }
            </SeaModal>
        );
    };
    return <></>;
};

export default ViewMaintenanceHistory;
