import React, { useEffect, useState, useMemo } from 'react';
import { formatValue, whenDueToClassName } from '../../../../lib/util';
import {  formatDate, makeDateTime, formatSeaDate, formatInterval, engineHoursLeftToClassName, warnDays } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { useMaintenanceTasksCompletedDuringVoyage } from '../../../../shared-state/VesselLogbook/useMaintenanceTasksCompletedDuringVoyage';
import { sharedState } from '../../../../shared-state/shared-state';
import { Voyage } from '../../../../shared-state/VesselLogbook/voyages';
import { ScheduledMaintenanceTask } from '../../../../shared-state/VesselMaintenance/maintenanceSchedule';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaStatusDueHours from '../../../../components/SeaStatusDueHours/SeaStatusDueHours';
import ViewMaintenanceSchedule from '../../../VesselMaintenance/MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule';
import './VoyageMaintenanceTab.css';

interface VoyageMaintenanceTabProps {
    showModal: boolean,
    selectedVoyage: Voyage,
    setMaintenanceNumber: React.Dispatch<React.SetStateAction<number>>
}

const VoyageMaintenanceTab: React.FC<VoyageMaintenanceTabProps> = ({
    showModal,
    selectedVoyage,
    setMaintenanceNumber
}) => {
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit } = usePageLimiter();
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ScheduledMaintenanceTask>();
    const maintenanceTasksCompletedDuringVoyage = useMaintenanceTasksCompletedDuringVoyage(showModal ? selectedVoyage : undefined);

    const voyageMaintenanceTasks = useMemo(() => {
        if (scheduledMaintenanceTasks?.prioritised && maintenanceTasksCompletedDuringVoyage !== undefined) {
            const tasks: ScheduledMaintenanceTask[] = [];
            const hasTask: {
                [scheduledMaintenanceTaskId: string]: boolean
            } = {};
            for (let i = 0; i < scheduledMaintenanceTasks.prioritised.length; i++) {
                const task = scheduledMaintenanceTasks.prioritised[i];
                if (task.priority !== undefined && task.priority > warnDays.maintenanceSchedule[0]) {
                    break;
                }
                hasTask[task.id] = true;
                tasks.push(task);
            }
            maintenanceTasksCompletedDuringVoyage.forEach((task) => {
                if (hasTask[task.id] === undefined) {
                    tasks.push(task);
                }
            });
            tasks.sort((a, b) => {
                return ((a.whenDue || 0) - (a.hasFault ? 1000000000000 : 0)) - ((b.whenDue || 0) - (b.hasFault ? 1000000000000 : 0));
            });
            return tasks;
        }
        return undefined;
    }, [scheduledMaintenanceTasks, maintenanceTasksCompletedDuringVoyage]);

    // Determine safetyChecksNumber for parent tab button
    useEffect(() => {
        setMaintenanceNumber(0);
        if (showModal && voyageMaintenanceTasks && voyageMaintenanceTasks.length > 0) {
            const today = makeDateTime(formatSeaDate()); //DateTime.fromISO(formatSeaDate());
            let failCount = 0;
            voyageMaintenanceTasks.forEach((item) => {
                if (item.useHours) {
                    if (item.engineHoursLeft !== undefined && item.engineHoursLeft < 0) {
                        failCount++
                    }
                } else {
                    const due = makeDateTime(item.whenDue);
                    const days = due.diff(today, ['days']).days;
                    if (days < 0) {
                        failCount++;
                    }
                }
            });
            setMaintenanceNumber(failCount);
        }
    }, [voyageMaintenanceTasks, showModal, setMaintenanceNumber]);

    const onViewMaintenance = (item: ScheduledMaintenanceTask) => {
        console.log(`Viewing maintenance task ${item?.id}`);
        setShowViewItemModal(true);
        setSelectedItem(item)
    };

    return (<>
        <SeaNoData
            dataName="forms or documents"
            isLoading={!voyageMaintenanceTasks}
            hasNoData={voyageMaintenanceTasks && voyageMaintenanceTasks.length === 0}
            isUsingFilter={false}
        />
        <div className={`voyage-maintenance ${voyageMaintenanceTasks && voyageMaintenanceTasks.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div>Equipment</div>
                <div>Maintenance Task</div>
                <div>Interval</div>
                <div>Next Due</div>
                <div>Status</div>
            </div>
            {voyageMaintenanceTasks && mapArrayWithLimit(voyageMaintenanceTasks, (item : ScheduledMaintenanceTask) => {
                return (
                    <div key={item.id} className={`sea-card sea-row-history clickable ${item.useHours && item.engineHoursLeft !== undefined ? engineHoursLeftToClassName(item.engineHoursLeft) : whenDueToClassName(item.whenDue, warnDays.maintenanceSchedule[0])}`} onClick={(e) => onViewMaintenance(item)}>
                        <div className="truncate bold">{item.equipment?.equipment}{item.equipment?.state === 'deleted' && ' (deleted)'}</div>
                        <div className="truncate">{formatValue(item.task)}</div>
                        <div className="truncate">
                            {(item.intervalType === 'weekMonth' || item.intervalType === 'weekMonthAndHours') && formatInterval(item.intervalWeekMonth)}
                            {item.intervalType === 'weekMonthAndHours' && <br/>}
                            {(item.intervalType === 'engineHours' || item.intervalType === 'weekMonthAndHours') && `${item.intervalEngineHours} Hours`}
                        </div>
                        <div className="truncate">
                            {(item.intervalType === 'weekMonth' || item.intervalType === 'weekMonthAndHours') && formatDate(item.whenDue)}
                            {item.intervalType === 'weekMonthAndHours' && <br/>}
                            {(item.intervalType === 'engineHours' || item.intervalType === 'weekMonthAndHours') && `${item.engineHoursDue} Hours`}
                        </div>
                        <div className="truncate">
                            {item.useHours ?
                                <SeaStatusDueHours engineHoursLeft={item.engineHoursLeft}/>
                                :
                                <SeaStatusDueDate whenDue={item.whenDue} warnDays={warnDays.maintenanceSchedule[0]}/>
                            }
                        </div>
                    </div>
                );
            })}
            {limitTriggerElement}
        </div>

        {showModal &&
            <ViewMaintenanceSchedule
                showModal={showViewItemModal}
                setShowModal={setShowViewItemModal}
                selectedItem={selectedItem}
                level={2}
            />
        }
    </>);
};

export default VoyageMaintenanceTab;
