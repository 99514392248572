import { useMemo } from "react";
import { CsvConfig } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { Contact } from '../../../shared-state/Crew/contacts';
import { renderCategoryName } from '../../../lib/categories';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type ContactsCsvProps = {
    onCompleted: () => void
}

const ContactsCsv = ({ onCompleted }: ContactsCsvProps) => {
    const contacts = sharedState.contacts.use();
    const contactCategories = sharedState.contactCategories.use();

    const csvConfig: CsvConfig<Contact> | undefined = useMemo(() => {
        if (!contactCategories) {
            return undefined;
        }
        return [
            {
                name: 'Company',
                value: (item) => item.company || ''
            },
            {
                name: 'Name',
                value: (item) => item.name || ''
            },
            {
                name: 'Contact #',
                value: (item) => item.number || ''
            },
            {
                name: 'Email',
                value: (item) => item.email || ''
            },
            {
                name: 'Address',
                value: (item) => item.address || ''
            },
            {
                name: 'Vendor #',
                value: (item) => item.vendorNumber || ''
            },
            {
                name: 'Category',
                value: (item) => renderCategoryName(item.categoryId, contactCategories)
            },
            {
                name: 'Notes',
                value: (item) => item.notes || ''
            }
        ];
    }, [contactCategories]);

    return <SeaCSVGenerator name={'Contacts'} onCompleted={onCompleted} config={csvConfig} data={contacts?.all} />
};

export default ContactsCsv;