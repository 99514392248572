import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, warnDays } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { UserType } from '../../../../shared-state/Core/user';
import { CrewCertificate } from '../../../../shared-state/Crew/crewCertificates';
import { renderCategoryName } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../../lib/reporting';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import CrewCertificatesReportPdf from '../../../../exports/Reporting/Crew/CrewCertificatesReportPdf';
import './ViewCrewCertificatesReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const statusOptions = [
    {
        id: 'upcoming',
        name: 'Upcoming'
    },
    {
        id: 'overdue',
        name: 'Overdue'
    },
];

interface ViewCrewCertificatesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredItems?: CrewCertificate[],
    itemsById?: {
        [key: string]: UserType
    },
    categories?: (string | undefined)[],
    selectedStatuses: string[],
    setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    yLabelWidth?: number,
    vesselName?: string,
}

const ViewCrewCertificatesReport: React.FC<ViewCrewCertificatesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    itemsById,
    categories,
    selectedStatuses,
    setSelectedStatuses,
    yLabelWidth,
    vesselName
}) => {
    const crewCertificateTitles = sharedState.crewCertificateTitles.use(showModal);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('Crew Certificates Report', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
                extraComponents={[
                    <SeaMultiSelect
                        label="Statuses"
                        values={selectedStatuses}
                        setValues={setSelectedStatuses}
                        options={statusOptions}
                        useAllOption={true}
                    />
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.taskStatuses}
                yLabelWidth={yLabelWidth}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="crew certificates by crew"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`crew-certificate-report has-thumbs has-status ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Title</div>
                        <div>Expiry</div>
                        <div>Held By</div>
                        <div>Status</div>
                    </div>
                    {mapArrayWithLimit(filteredItems, (item) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(renderCategoryName(item?.titleId, crewCertificateTitles))}
                                </div>
                                <div>{formatDate(item.whenExpires)}</div>
                                <div className="truncate-2">
                                    {renderFullNameForUserId(item.heldBy)}
                                </div>
                                <div>
                                    <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]}/>
                                </div>
                                
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>

            {showModal && exportType === 'pdf' &&
                <CrewCertificatesReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                    yLabelWidth={yLabelWidth}
                    vesselName={vesselName}
                />
            }
        </SeaModal>
    );
};

export default ViewCrewCertificatesReport;
