import { useMemo, useState } from "react";
import { formatCsvDate, formatCsvRiskRating, formatCsvNamesFromIds, formatCsvRiskDescription, formatCsvRisk, CsvConfig } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { Risk } from "../../../shared-state/HealthSafety/risks";
import { renderCategoryName } from '../../../lib/categories';
import { useRisksReviewedDateRange } from '../../../shared-state/HealthSafety/useRisksReviewedDateRange';
import { RiskReviewed } from '../../../shared-state/HealthSafety/useRisksReviewed';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import { formatInterval } from "../../../lib/datesAndTime";
import SeaExporterDateRange from '../../../components/SeaExporter/SeaExporterDateRange';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type RiskAssessmentsCsvProps = {
    onCompleted: () => void
}

const RiskRegisterCsv = ({ onCompleted }: RiskAssessmentsCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const risks = sharedState.risks.use(dateRange ? 1 : 0);
    const risksReviewed = useRisksReviewedDateRange(dateRange);
    const riskCategories = sharedState.riskCategories.use(dateRange ? 1 : 0);
    const vessels = sharedState.vessels.use(dateRange ? 1 : 0);
    const licenseeSettings = sharedState.licenseeSettings.use(dateRange ? 1 : 0);


    const csvConfig: CsvConfig<Risk> | undefined = useMemo(() => {
        if (!riskCategories || !vessels || !risksReviewed || !licenseeSettings) {
            return undefined;
        }
        return [
            {
                name: 'Hazard',
                value: (item) => item.name
            },
            {
                name: 'Risks',
                value: (item) => item.risks
            },
            {
                name: 'Pre risk rating',
                value: (item) => formatCsvRiskRating(item.preControls.likelihood, item.preControls.consequence)
            },
            {
                name: 'Controls',
                value: (item) => item.controls
            },
            {
                name: 'Post Risk Rating',
                value: (item) => formatCsvRiskRating(item.postControls.likelihood, item.postControls.consequence)
            },
            {
                name: 'Review Date',
                value: (item) => formatCsvDate(item.whenLastReviewed)
            },
            {
                name: 'Vessels/Facilities',
                value: (item) => formatCsvNamesFromIds(item.vesselIds, vessels?.byId)
            },
            {
                name: 'Category',
                value: (item) => renderCategoryName(item.categoryId, riskCategories)
            },
            {
                name: 'Risks Associated with this Hazard',
                value: (item) => item.risks
            },
            {
                name: 'Pre Control Risks',
                value: (item) => formatCsvRiskDescription(item.preControls)
            },
            {
                name: 'Pre Control Likelihood',
                value: (item) => formatCsvRisk(item.preControls.likelihood)
            },
            {
                name: 'Pre Control Consequence',
                value: (item) => formatCsvRisk(item.preControls.consequence)
            },
            {
                name: 'Pre Control Risk Rating',
                value: (item) => formatCsvRiskRating(item.preControls.likelihood, item.preControls.consequence)
            },
            {
                name: 'Risk Controls',
                value: (item) => item.controls
            },
            {
                name: 'Post Controls Risk',
                value: (item) => formatCsvRiskDescription(item.postControls)
            },
            {
                name: 'Post Controls Likelihood',
                value: (item) => formatCsvRisk(item.postControls.likelihood)
            },
            {
                name: 'Post Controls Consequence',
                value: (item) => formatCsvRisk(item.postControls.consequence)
            },
            {
                name: 'Post Controls Risk Rating',
                value: (item) => formatCsvRiskRating(item.postControls.likelihood, item.postControls.consequence)
            },
            {
                name: 'Person/s Responsible',
                value: (item) => item.whoResponsible || ''
            },
            {
                name: 'Review Interval',
                value: (item) => formatInterval(item.interval)
            },
            {
                name: 'Review History',
                items: (item) => risksReviewed.byRiskId[item.id],
                columns: () => [
                    {
                        name: 'Review Date',
                        value: (item: RiskReviewed) => formatCsvDate(item.whenReviewed)
                    },
                    {
                        name: 'By',
                        value: (item: RiskReviewed) => renderFullNameForUserId(item.reviewedBy)
                    },
                    {
                        name: 'Notes',
                        value: (item: RiskReviewed) => item.notes || ''
                    }
                ]
            },
        ];
    }, [licenseeSettings, riskCategories, risksReviewed, vessels]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Risk-Register'} onCompleted={onCompleted} config={csvConfig} data={risks?.all} />
};

export default RiskRegisterCsv