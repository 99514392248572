import React, { useState, useRef, useMemo, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { hasArrayChanged, haveValuesChanged, cleanupStringArray } from '../../../lib/util';
import { firestore, deleteValue, splittableBatch } from '../../../lib/firebase';
import { doc } from "firebase/firestore";
import { logAction } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../managers/ErrorsManager/ErrorsManager';
import SeaModal from '../../../components/SeaModal/SeaModal';
import Yup from '../../../lib/yup'
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaTabsGroup from '../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../components/SeaTabContent/SeaTabContent';
import SeaInputList from '../../../components/SeaInputList/SeaInputList';
import SeaCheckbox from '../../../components/SeaCheckbox/SeaCheckbox';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';

interface LogbookSettingsProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
}

const LogbookSettings: React.FC<LogbookSettingsProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const formRef = useRef<HTMLFormElement>(null);
    const [tab, setTab] = useState("safetyBriefing");
    const [destinations, setDestinations] = useState<string[]>();
    const [stops, setStops] = useState<string[]>();
    const [startupChecks, setStartupChecks] = useState<string[]>();
    const [shutdownChecks, setShutdownChecks] = useState<string[]>();
    const [actions, setActions] = useState<string[]>();
    const [customTextFields, setCustomTextFields] = useState<string[]>();
    const [customHourFields, setCustomHourFields] = useState<string[]>();

    const initialValues = useMemo(() => {
        return {
            safetyBriefing: (vessel?.logbookSettings?.safetyBriefing) ? ''+vessel.logbookSettings.safetyBriefing : '',
            hideTides: (vessel?.logbookSettings?.hideTides) ? true : false,
            crewHours: (vessel?.logbookSettings?.crewHours) ? ''+vessel.logbookSettings.crewHours : 'optional'
        }
    }, [vessel?.logbookSettings]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);
        setDestinations(vessel?.logbookSettings?.destinations ? vessel.logbookSettings.destinations : []);
        setStops(vessel?.logbookSettings?.stops ? vessel.logbookSettings.stops : []);
        setStartupChecks(vessel?.logbookSettings?.startupChecks ? vessel.logbookSettings.startupChecks : []);
        setShutdownChecks(vessel?.logbookSettings?.shutdownChecks ? vessel.logbookSettings.shutdownChecks : []);
        setActions(vessel?.logbookSettings?.actions ? vessel.logbookSettings.actions : []);
        setCustomTextFields(vessel?.logbookSettings?.customTextFields ? vessel.logbookSettings.customTextFields : []);
        setCustomHourFields(vessel?.logbookSettings?.customHourFields ? vessel.logbookSettings.customHourFields : []);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            safetyBriefing: Yup.string().max(2000),
            crewHours: Yup.string().max(50)
        }), onSubmit: (data) => {
            if (!vesselId) {
                throw new Error('No vesselId');
            }
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'vessels', 'update', vesselId);

            batch.set(
                doc(firestore, 'vessels', vesselId),
                {
                    logbookSettings: {
                        //safetyBriefing: data.safetyBriefing ? data.safetyBriefing : deleteValue,
                        hideTides: data.hideTides ? data.hideTides : deleteValue,
                        destinations: cleanupStringArray(destinations)?.sort(),
                        stops: cleanupStringArray(stops)?.sort(),
                        safetyBriefing: data.safetyBriefing ? data.safetyBriefing : deleteValue,
                        startupChecks: cleanupStringArray(startupChecks),
                        shutdownChecks: cleanupStringArray(shutdownChecks),
                        actions: cleanupStringArray(actions),
                        customTextFields: cleanupStringArray(customTextFields),
                        customHourFields: cleanupStringArray(customHourFields),
                        crewHours: values.crewHours ? values.crewHours : 'optional'
                    },
                    whenUpdated: batchTrace.whenAction,
                    updatedBy: userId
                },
                { merge: true }
            );

            onCollectionUpdated(batch, 'vessels');
            logAction(
                batch,
                'Update',
                'vessels',
                vesselId,
                vessel?.name ? vessel.name : '',
                [vesselId],
                undefined,
                'logbookSettings'
            );

            batchTrace.data = {
                data,
                destinations,
                stops,
                startupChecks,
                shutdownChecks,
                actions,
                customTextFields,
                customHourFields,
                crewHours: values.crewHours
            };
            batchTrace.save(`Save logbook settings for ${vessel?.name}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
            showToast('Logbook settings have been saved');
        }
    });

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(destinations, vessel?.logbookSettings?.destinations) ||
            hasArrayChanged(stops, vessel?.logbookSettings?.stops) ||
            hasArrayChanged(startupChecks, vessel?.logbookSettings?.startupChecks) ||
            hasArrayChanged(shutdownChecks, vessel?.logbookSettings?.shutdownChecks) ||
            hasArrayChanged(actions, vessel?.logbookSettings?.actions) ||
            hasArrayChanged(customTextFields, vessel?.logbookSettings?.customTextFields) ||
            hasArrayChanged(customHourFields, vessel?.logbookSettings?.customHourFields)
        );
    }, [
        initialValues,
        vessel?.logbookSettings,
        values,
        destinations,
        stops,
        startupChecks,
        shutdownChecks,
        actions,
        customTextFields,
        customHourFields
    ]);

    return (
        <SeaModal
            title={'Logbook Settings'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            tabsPanel={
                <SeaTabsGroup selectedTab={tab} setTab={setTab} mode="forms" mini>
                    <SeaTab tab="safetyBriefing" mode="forms" hasErrors={
                        (touched.safetyBriefing && errors.safetyBriefing) ? true : false
                    }>
                        Safety Briefing
                    </SeaTab>
                    <SeaTab tab="destinations" mode="forms">Common Destinations</SeaTab>
                    <SeaTab tab="stops" mode="forms">Common Stops / Anchorages</SeaTab>
                    <SeaTab tab="startupChecks" mode="forms">Startup Checks</SeaTab>
                    <SeaTab tab="shutdownChecks" mode="forms">Shutdown Checks</SeaTab>
                    <SeaTab tab="actions" mode="forms">Common Actions</SeaTab>
                    <SeaTab tab="customTextFields" mode="forms">Custom Text Fields</SeaTab>
                    <SeaTab tab="customHourFields" mode="forms">Custom Hour Fields</SeaTab>
                    <SeaTab tab="other" mode="forms">Other Settings</SeaTab>
                    <SeaTab tab="admin" mode="forms" hide={!superAdmin}>Admin</SeaTab>
                </SeaTabsGroup>
            }
            actionPanel={
                <SeaButton zone="white" onClick={(e) => {
                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                }}>Save Settings</SeaButton>
            }
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <SeaTabContent tab="safetyBriefing" selectedTab={tab}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="12">
                                <SeaTextarea
                                    label="Safety Briefing"
                                    name="safetyBriefing"
                                    value={values.safetyBriefing}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.safetyBriefing ? errors.safetyBriefing : ''}
                                    height={200}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>
                <SeaTabContent tab="destinations" selectedTab={tab}>
                    <SeaInputList
                        name="destinations"
                        label="Common Destinations"
                        maxWidth="350px"
                        values={destinations}
                        setValues={setDestinations}
                        addNewText="Add common destination"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="stops" selectedTab={tab}>
                    <SeaInputList
                        name="stops"
                        label="Common Stops / Anchorages"
                        maxWidth="350px"
                        values={stops}
                        setValues={setStops}
                        addNewText="Add common stop / anchorage"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="startupChecks" selectedTab={tab}>
                    <SeaInputList
                        name="startupChecks"
                        label="Startup Checks"
                        maxWidth="500px"
                        values={startupChecks}
                        setValues={setStartupChecks}
                        addNewText="Add startup check"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="shutdownChecks" selectedTab={tab}>
                    <SeaInputList
                        name="shutdownChecks"
                        label="Shutdown Checks"
                        maxWidth="500px"
                        values={shutdownChecks}
                        setValues={setShutdownChecks}
                        addNewText="Add shutdown check"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="actions" selectedTab={tab}>
                    <SeaInputList
                        name="actions"
                        label="Common Actions"
                        maxWidth="500px"
                        values={actions}
                        setValues={setActions}
                        addNewText="Add common action"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="customTextFields" selectedTab={tab}>
                    <SeaInputList
                        name="customTextFields"
                        label="Custom Text Fields"
                        maxWidth="350px"
                        values={customTextFields}
                        setValues={setCustomTextFields}
                        addNewText="Add custom text field"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="customHourFields" selectedTab={tab}>
                    <SeaInputList
                        name="customHourFields"
                        label="Custom Hour Fields"
                        maxWidth="350px"
                        values={customHourFields}
                        setValues={setCustomHourFields}
                        addNewText="Add custom hour field"
                        confirmDelete={true}
                    />
                </SeaTabContent>
                <SeaTabContent tab="other" selectedTab={tab}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="6">
                                <SeaSelect
                                    label="Enter Crew Hours"
                                    name="crewHours"
                                    value={values.crewHours}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.crewHours ? errors.crewHours : ''}
                                >
                                    <IonSelectOption value="optional">Optional</IonSelectOption>
                                    <IonSelectOption value="mandatory">Mandatory</IonSelectOption>
                                    <IonSelectOption value="hidden">Hidden</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>
                {(superAdmin) &&
                    <SeaTabContent tab="admin" selectedTab={tab}>
                        <IonGrid className="form-grid">
                            <IonRow>
                                <IonCol size="12">
                                    <SeaCheckbox
                                        name="hideTides"
                                        label="Tides"
                                        checked={values.hideTides}
                                        setFieldValue={setFieldValue}
                                        error={touched.hideTides ? errors.hideTides : ''}
                                    >
                                        Hide Tides
                                    </SeaCheckbox>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </SeaTabContent>
                }
            </form>
        </SeaModal>
    );
};

export default LogbookSettings;
