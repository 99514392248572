import React, { useEffect, useState } from 'react';
import { formatValue, renderCamelCase } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { IonSelectOption } from '@ionic/react';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { MaintenanceTaskCompleted } from '../../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import reporting from '../../../../lib/reporting';
import SeaStatusIcon from '../../../../components/SeaStatusIcon/SeaStatusIcon';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import MaintenanceCompletedTasksReportPDF from '../../../../exports/Reporting/Maintenance/MaintenanceCompletedTasksReportPdf';
import './ViewMaintenanceCompletedTasksReport.css';
import { Equipment } from '../../../../shared-state/VesselMaintenance/equipment';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const maintenanceCompletedTasksOptions = [
    {
        id: 'scheduled',
        name: 'Scheduled'
    },
    {
        id: 'unscheduled',
        name: 'Unscheduled / Jobs'
    }
]

interface ViewMaintenanceCompletedTasksReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: MaintenanceTaskCompleted[],
    categories?: string[],
    selectedStatuses: string[],
    setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>,
    itemCriticality: string,
    setItemCriticality: React.Dispatch<React.SetStateAction<string>>,
    itemsById?: {
        [key: string]: Equipment
    },
}

const ViewMaintenanceCompletedTasksReport: React.FC<ViewMaintenanceCompletedTasksReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
    selectedStatuses,
    setSelectedStatuses,
    itemCriticality,
    setItemCriticality,
    itemsById
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('MaintenanceCompletedTasksReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
                extraComponents={[
                    <SeaMultiSelect
                        label="Completed Task Types"
                        values={selectedStatuses}
                        setValues={setSelectedStatuses}
                        options={maintenanceCompletedTasksOptions}
                        useAllOption={true}
                    />,
                    <SeaSelect
                        label="Critical?"
                        value={itemCriticality}
                        onchange={(e) => {
                            setItemCriticality(e.detail.value);
                        }}
                    >
                        <IonSelectOption value="both">Critical & Non-Critical</IonSelectOption>
                        <IonSelectOption value="critical">Critical Only</IonSelectOption>
                        <IonSelectOption value="nonCritical">Non-Critical Only</IonSelectOption>
                    </SeaSelect>
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.completedMaintenanceTasks}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="maintenance tasks"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`maintenance-completed-tasks-report ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>When Completed</div>
                        <div>Type</div>
                        <div>Times Completed</div>
                        <div>Task</div>
                        <div>Vessel</div>
                        <div>Critical</div>
                    </div>
                    {mapArrayWithLimit(filteredItems, (item: MaintenanceTaskCompleted) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row-history"
                            >
                                <div>{formatDate(item.whenCompleted)}</div>
                                <div className="truncate-2">
                                    {formatValue(renderCamelCase(item.type))}
                                </div>
                                <div>{formatValue(item.timesCompleted)}</div>
                                <div className="truncate-2">{formatValue(item.task)}</div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    {item.equipmentId && itemsById?.[item.equipmentId]?.isCritical && (
                                        <SeaStatusIcon
                                            icon="flag"
                                            className="critical"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>

            {showModal && filteredItems && exportType === 'pdf' &&
                <MaintenanceCompletedTasksReportPDF
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    itemsById={itemsById}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
        </SeaModal>
    );
};

export default ViewMaintenanceCompletedTasksReport;
