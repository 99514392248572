import { ArchivableDocument, CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { registerFiles } from "../FileSyncSystem/filesToCache";

export interface VesselCertificate extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    certNum?: string;
    deletedBy?: string;
    emailReminder?: string;
    files?: string[];
    isShoreFacility?: boolean;
    issuedBy?: string;
    state: string;
    title: string;
    type: string;
    vesselId: string;
    categoryId: string;
    wasRenewed?: boolean;
    whenDeleted?: number;
    whenExpires?: number;
    whenIssued: number;
    whenToRemind?: number;
}

export type VesselCertificatesData = { 
    all: VesselCertificate[],
    prioritised: VesselCertificate[],
    byId: {
        [id: string]: VesselCertificate
    }
    byCategoryId: {
        [categoryId: string]: VesselCertificate[]
    }
};

export const vesselCertificatesConfig: SharedStateConfig<VesselCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'todayMillis'], // Depends on todayMillis because we're doing day offset calculations
    countLiveDocs: () => Object.keys(sharedState.vesselCertificates.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('vesselCertificates')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselCertificates'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const certificates = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as VesselCertificate;
                    });

                    // all is categorised by renewable and nonExpiring
                    const all = [] as VesselCertificate[];
                    const byId = {} as {
                        [id: string]: VesselCertificate
                    };
                    const byCategoryId: {
                        [categoryId: string]: VesselCertificate[]
                    } = {}
                    certificates.forEach((certificate) => {
                        registerFiles(certificate.files, 'vesselCertificates', certificate);
                        byId[certificate.id] = certificate;
                        if (!byCategoryId[certificate.categoryId]) {
                            byCategoryId[certificate.categoryId] = [];
                        }
                        byCategoryId[certificate.categoryId].push(certificate);
                        all.push(certificate);
                    });
                    let prioritised = [...certificates] as VesselCertificate[];
                    prioritised.sort((a: VesselCertificate, b: VesselCertificate) => {
                        return (
                            (a.type === 'renewable'
                                ? a.whenExpires ?? Number.MAX_SAFE_INTEGER
                                : Number.MAX_SAFE_INTEGER) -
                            (b.type === 'renewable'
                                ? b.whenExpires ?? Number.MAX_SAFE_INTEGER
                                : Number.MAX_SAFE_INTEGER)
                        );
                    });

                    // prioritised should only contain whenExpires up to a set amount of days in the future
                    // (and should not contain and nonExpiring either)
                    const maxWhenExpires = getDayOffsetMillis(
                        warnDays.vesselCertificates[warnDays.vesselCertificates.length - 1]
                    );

                    for (let i = 0; i < prioritised.length; i++) {
                        if (
                            prioritised[i].type === 'nonExpiring' ||
                            (prioritised[i].whenExpires || 0) >= maxWhenExpires
                        ) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        }
                    }
                    

                    set({
                        all,
                        prioritised,
                        byId,
                        byCategoryId
                    });
                },
                (error) => {
                    done();
                    // This should be very rare
                    console.log(
                        `Failed to access vesselCertificates for vessel ${vesselId}`,
                        error
                    );
                }
            );
        }
    }
};