import React, { useEffect } from 'react';
import { sharedState } from '../../shared-state/shared-state';
import { physicalDeviceInfo } from '../../shared-state/Core/deviceInfo';
import { generateCode, toInt } from '../../lib/util';
import { formatDatetime } from '../../lib/datesAndTime';
import { WriteBatch, collection, doc, getDocs, increment, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { SplittableBatch, firestore } from '../../lib/firebase';
import { appActivityToJson } from '../../shared-state/General/appActivity';
import { loadErrorsOnHold, saveErrorsOnHold } from '../../shared-state/General/errorsOnHold';
import SeaButton from '../../components/SeaButton/SeaButton';
import packageJson from '../../../package.json';
import './ErrorsManager.css';

//
// Handles errorReports and traceReports
//

export type SeaFluxError = {
    whatFailed: string,
    reason: string,
    error?: Error,
    data: any,
    errorId?: string,
    reference?: string
};

export interface BatchTrace {
    batch: WriteBatch | SplittableBatch,
    exampleCollection: string | undefined,
    exampleOperation: 'create' | 'update' | 'delete' | 'archive' | 'unarchive' | 'complete' | undefined,
    exampleDocId: string | undefined,
    whenAction: number, // This ssould be used whenever possible within associated firestore batches
    errorId: string | undefined,
    name: string,
    data: any,
    save: (name: string) => void,
    reportSuccess: () => void,
    reportError: (reason: string, error?: Error) => void
};

const showError = (errorToShow: SeaFluxError) => {
    sharedState.errorsToShow.set((previousErrors) => {
        // Note: If we did straight forward setErrors(newValue) we would always be seeing [] (initial value)
        return [...(previousErrors as SeaFluxError[]), errorToShow];
    });
    // saveError(errorToShow); // TODO! Should replace when fileSync is revamped
};

export const makeDefaultErrorReport = () => {
    return {
        userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
        licenseeId: sharedState.licenseeId.current,
        deviceId: sharedState.deviceId.current,
        vesselId: sharedState.vesselId.current,
        whenReported: Date.now(),
        version: packageJson.version,
        build: toInt(packageJson.build),
        device: physicalDeviceInfo,
        userClaims: sharedState.securityClaims.current,
        isOnline: sharedState.onlineStatus.current?.isOnline,
        onlineHistory: sharedState.onlineStatus.current?.history,
        isAppActive: sharedState.appState.current?.isActive,
        appActivity: appActivityToJson(JSON.stringify(sharedState.appActivity.current)),
        state: 'unknown'
    };
};

const addError = (
    whatFailed: string,
    reason?: string,
    error?: Error,
    data = undefined,
    errorId?: string
) => {
    const reference = generateCode(8);

    const newErrorReportRef = (
        errorId
        ?
        doc(firestore, 'errorReports', errorId)
        :
        doc(collection(firestore, 'errorReports'))
    );

    // Decide whether this errorReport should be shown to the user
    // or held if we suspect it could be a fake error.
    // We are suspicious the error could be fake if:
    // * It's a security error
    //     Yes, Firestore can lie when saying there are missing or insufficient permissions...
    //     It should be saying something went wrong with the connection while trying to test permissions
    // * We're offline or have only been online for less than 5 seconds
    // * Is an error generated by an action (i.e. batchTrace.reportError rather than ReportingContext.reportError directly)
    const holdError = (
        (
            !sharedState.onlineStatus.current?.isOnline ||
            (Date.now() - sharedState.onlineStatus.current.lastWentOnline) < (5 * 1000)
        ) &&
        reason === 'Missing or insufficient permissions.' && // Security violations are the only errors that we might want to hold on to
        errorId // A defined errorId means that the original error was generated via an action
    );

    const newError = {
        errorId: newErrorReportRef.id,
        whatFailed: whatFailed,
        reason: reason,
        reference: reference,
        errorStack: error?.stack ? error.stack : undefined,
        data: data,
        shownToUser: !holdError
    } as SeaFluxError;
    console.log('ReportingContext.addError!', newError);

    setDoc(
        newErrorReportRef,
        {
            ...makeDefaultErrorReport(),
            ...newError
        }
    ).then(() => {
        console.log(`Error Reported id=${newErrorReportRef.id} reference=${reference}`);
    }).catch((error) => {
        console.error('Failed to report error to firestore', error);
    });

    if (holdError) {
        sharedState.errorsOnHold.set((previousErrors) => {
            return [...(previousErrors as SeaFluxError[]), newError];
        });
        saveErrorsOnHold();
    } else {
        // Show this error to the user right now
        showError(newError);
    }
};

const saveBatchTrace = (
    batchTrace: BatchTrace,
    name: string
) => {
    batchTrace.name = name;
    batchTrace.errorId = doc(collection(firestore, 'errorReports')).id;
    if (batchTrace.exampleDocId === undefined) {
        console.log('----------------- No docId was set!!!!', batchTrace);
        return;
    }

    let batches = [];
    if (batchTrace.batch) {
        if ('getBatches' in batchTrace.batch) { // SplittableBatch
            batches = batchTrace.batch.getBatches();
        } else { // Regular WriteBatch
            batches = [batchTrace.batch];
        }
        batches.forEach((_batch: WriteBatch) => {
            _batch.set(
                doc(firestore, 'actionsConfirmed', batchTrace.errorId!),
                {
                    userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
                    licenseeId: sharedState.licenseeId.current,
                    errorReportId: batchTrace.errorId,
                    collection: batchTrace.exampleCollection,
                    docId: batchTrace.exampleDocId,
                    whenAction: batchTrace.whenAction,
                    whenSynced: serverTimestamp(),
                    batches: batches.length,
                    writeCount: increment(1)
                },
                { merge: true }
            );
        });
    }

    setDoc(
        doc(firestore, 'traceReports', batchTrace.errorId),
        {
            userId: (sharedState.user.current && (sharedState.user.current as any).id ? (sharedState.user.current as any).id : 'unknown'),
            licenseeId: sharedState.licenseeId.current,
            collection: batchTrace.exampleCollection,
            docId: batchTrace.exampleDocId,
            type: batchTrace.exampleOperation,
            whenAction: batchTrace.whenAction,
            state: 'unknown', // Server will later determine if success | error | lost | bug
            errorReportId: batchTrace.errorId,
            deviceId: sharedState.deviceId.current,
            whenTraced: Date.now(),
            whenSynced: serverTimestamp(),
            action: batchTrace.name,
            data: batchTrace.data,
            batches: batches.length,
            version: packageJson.version,
            build: toInt(packageJson.build),
            device: physicalDeviceInfo,
            appActivity: appActivityToJson(JSON.stringify(sharedState.appActivity.current)),
            userClaims: sharedState.securityClaims.current
        },
        { merge: true }
    ).then(() => {
        console.log(`Traced Action ${batchTrace.errorId}: ${batchTrace.name}`);
    }).catch((error) => {
        console.log(`Error saving action trace: ${batchTrace.name}`, error);
        console.log('Failed action', batchTrace);
    });
};

const reportBatchSuccess = (batchTrace: BatchTrace) => {
    console.log('Success: '+batchTrace.name);
};

export const reportError = (
    whatFailed: string,
    reason?: string,
    error?: Error,
    data?: any,
    errorId?: string
) => {
    if (error) {
        console.error(error);
    }
    addError(whatFailed, reason, error, data, errorId);
};

const reportBatchError = (batchTrace: BatchTrace, reason: string, error?: Error) => {
    reportError(
        `Failed: ${batchTrace.name}`,
        reason,
        error,
        {
            docId: batchTrace.exampleDocId,
            collection: batchTrace.exampleCollection,
            ...batchTrace.data
        },
        batchTrace.errorId
    );
};

//
// This is used to trace (aka track or log) batch operations.
// This exists to help diagnose Firestore operation failures, generate errorReports if necessary, and manage "fake errors".
// Will end up creating the following data alongside a batch operation
//      * traceReports
//          Is created in parallel to the provided batch.
//          This means, if the batch fails for whatever reason, a corresponding traceReports doc can still be created.
//          Thus, if we end up with a traceReports doc with no actionsConfirmed doc (or exampleDoc), we know something failed.
//      * actionsConfirmed
//          Is created using the provided batch.
//          This means, if the batch fails for whatever reason, the corresponding actionsConfirmed doc will not be created.
//          Thus, if we end up with an actionsConfirmed doc, we know that the batch ended up succeeding.
//          Note: In the special case of having a SplittableBatch with multiple batches inside,
//          actionsConfirmed will increment writeCount once per batch.
//          Therefore, we know that the SplittableBatch succeeded if writeCount matches the number of batches
//      * errorReports
//          When reportError is called, an errorReport is created.
//          Actually, it's more complicated than that due to the iOS fake errors problem...
//          See shared-state/General/errorsOnHold.ts for more information
//
export const makeBatchTrace = (
    batch: WriteBatch | SplittableBatch,
    exampleCollection?: string,
    exampleOperation?: 'create' | 'update' | 'delete' | 'archive' | 'unarchive' | 'complete' | undefined,
    exampleDocId?: string
): BatchTrace => {
    const batchTrace = {
        batch: batch,
        exampleCollection: exampleCollection,
        exampleOperation: exampleOperation,
        exampleDocId: exampleDocId,
        whenAction: Date.now(),
        name: '',
        errorId: undefined,
        data: {},
        save: (name: string) => saveBatchTrace(batchTrace, name),
        reportSuccess: () => reportBatchSuccess(batchTrace),
        reportError: (reason: string, error?: Error) => reportBatchError(batchTrace, reason, error)
    };
    return batchTrace;
};

const ErrorsManager: React.FC = () => {
    const licenseeId = sharedState.licenseeId.use();
    const errorsOnHold = sharedState.errorsOnHold.use();
    const errorsToShow = sharedState.errorsToShow.use();
    const onlineStatus = sharedState.onlineStatus.use();
    const triggerProcessErrorsOnHold = sharedState.triggerProcessErrorsOnHold.use();

    useEffect(() => {
        if (licenseeId) {
            loadErrorsOnHold();
        }
    }, [licenseeId]);

    useEffect(() => {
        if (
            triggerProcessErrorsOnHold &&
            errorsOnHold &&
            errorsOnHold.length > 0
        ) {
            const testErrorReports = [...errorsOnHold];
            // Remove errorsOnHold right away so they wont be processed again
            sharedState.errorsOnHold.clear();
            saveErrorsOnHold();

            testErrorReports.forEach((errorOnHold) => {
                getDocs(
                    query(
                        collection(firestore, 'actionsConfirmed'),
                        where('errorReportId', '==', errorOnHold.errorId),
                        where('licenseeId', '==', sharedState.licenseeId.current)
                    )
                ).then((snap) => {
                    if (snap.docs.length > 0) {
                        const actionConfirmed = snap.docs[0].data();
                        if (
                            actionConfirmed.batches &&
                            actionConfirmed.writeCount &&
                            actionConfirmed.writeCount >= actionConfirmed.batches
                        ) {
                            // errorOnHold is FAKE
                            console.log('Fake error dropped from errorsOnHold', errorOnHold);
                            return;
                        }
                    }
                    // errorOnHold is probably REAL
                    // Show errorOnHold to user
                    console.log('Promoting errorOnHold to be shown to the user', errorOnHold);
                    showError(errorOnHold);
                    // Update shownToUser to true in errorReports document
                    return setDoc(
                        doc(firestore, 'errorReports', errorOnHold.errorId as string),
                        {
                            shownToUser: true
                        },
                        { merge: true }
                    ).catch((error) => {
                        console.error(`Failed to update errorReports to have shownToUser errorId=${errorOnHold.errorId}`, error);
                    });
                }).catch((error: any) => {
                    console.error(`Error getting actionsConfirmed for errorId=${errorOnHold.errorId}`, error);
                });
            });
        }
    }, [errorsOnHold, triggerProcessErrorsOnHold]);

    return <>
        {errorsToShow && errorsToShow.length > 0 && (
            <div className="sea-error-report-backdrop">
                <div className="sea-error-report">
                    <h3>{(errorsToShow.length === 1) ? 'An error has occurred' : 'Some errors have occurred'}</h3>
                    {errorsToShow.map((error, index) => {
                        return (
                            <p key={index}>
                                <b>{error.whatFailed}</b>
                                <br/>
                                {error.reason}
                                <br/>
                                Error reference: {error.reference}
                            </p>
                        );
                    })}
                    <div style={{ margin: '20px 0px 15px 0px' }}>
                        Sending an error report has been sent to the admin team.
                        <p>
                            If you quote an error reference to the Sea Flux team it will help them diagnose the problem faster.'
                        </p>
                    </div>
                    <div>
                        {`\n\nVersion ${packageJson.version}, build ${packageJson.build}`}{
                            physicalDeviceInfo && `, ${physicalDeviceInfo.osVersion} ${(onlineStatus?.isOnline) ? 'online' : 'offline'} @ ${formatDatetime()}`
                        }
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <SeaButton onClick={(e) => {
                            sharedState.errorsToShow.clear();
                        }}>
                            OK
                        </SeaButton>
                    </div>
                </div>
            </div>
        )}
    </>;
};

export default ErrorsManager;
