import { BatchTrace } from '../managers/ErrorsManager/ErrorsManager';
import { onCollectionUpdated } from '../shared-state/DataSyncSystem/dataSync';
import { SplittableBatch, firestore } from './firebase';
import { collection, query, where, orderBy, onSnapshot, WriteBatch, doc, serverTimestamp } from "firebase/firestore";


// Extract Category Heading from something like "[Category Heading] Category Name"
export const extractHeadingFromCategoryName = (name: string) => {
    if (
        name.length > 0 &&
        name[0] === '[' &&
        name.indexOf(']') > 1
    ) {
        return name.substring(1, name.indexOf(']'));
    }
    return '';
};

// export const extractCategoryHeadingFromCategory = (category: Category, returnHeading?: boolean) => {
//     let name = category.name;
//      // Handle the case where input is a single string potentially with a category in brackets
//      const match = name.match(/^\[(.*?)\](.*)$/);
//      if (match) {
//          if (returnHeading) {
//             return match[1].trim();
//         } else {
//             const restOfString = match[2].trim();
//             if (category.state === 'deleted') {
//                 return `${restOfString} (deleted)`;
//             }
//             return restOfString;
//         }
//      } else if (returnHeading) {
//         return undefined;
//      }
//      return name;
// }

// export const returnHeadingsFromCategories = (categories: CategoriesData) => {
//     const headings: { [categoryId: string]: string } = {};
//     const uniqueHeadings: Set<string> = new Set();

//     for (const categoryId of categories.ids) {
//         const category = categories.byId[categoryId];
//         const heading = extractCategoryHeadingFromCategory(category, true);

//         if (heading && !uniqueHeadings.has(heading)) {
//             uniqueHeadings.add(heading);
//             headings[categoryId] = heading;
//         }
//     }
//     return headings;
// }

// export const extractHeadingFromOption = (name: string, returnHeading?: boolean) => {
//      // Handle the case where input is a single string potentially with a category in brackets
//      const match = name.match(/^\[(.*?)\](.*)$/);
//      if (match) {
//          if (returnHeading) {
//             return match[1].trim();
//         } else {
//             const restOfString = match[2].trim();
//             return restOfString;
//         }
//      } else if (returnHeading) {
//         return undefined;
//      }
//      return name;
// }

// export const returnHeadingsFromOptions = (options: {id: string, name: string}[]) => {
//     const headings: { [categoryId: string]: string } = {};
//     const uniqueHeadings: Set<string> = new Set();

//     for (const option of options) {
//         const heading = extractHeadingFromOption(option.name, true);
//         if (heading && !uniqueHeadings.has(heading)) {
//             uniqueHeadings.add(heading);
//             headings[option.id] = heading;
//         }
//     }
//     return headings;
// }

export const stripCategoryHeadingFromCategoryName = (categoryName: string) => {
    if (
        categoryName.length > 0 &&
        categoryName[0] === '[' &&
        categoryName.indexOf(']') > 1
    ) {
        return categoryName.substring(categoryName.indexOf(']') + 1).trim();
    }
    return categoryName;
};

export const renderCategoryName = (categoryId: string | undefined, categories: CategoriesData | undefined, stripCategoryHeading = true) => {
    if (categoryId && categories?.byId[categoryId]) {
        let name = categories.byId[categoryId].name;
        if (stripCategoryHeading) {
            name = stripCategoryHeadingFromCategoryName(name);
        }
        return `${name}${categories.byId[categoryId].state === 'deleted' ? ' (deleted)' : ''}`;
    }
    return '';
};

export const renderCategoryNames = (categoryIds: string[] | undefined, categories: CategoriesData | undefined, separator = ', ', stripCategoryHeadings = true) => {
    if (categoryIds && categories?.byId) {
        let s = '';
        for (let i = 0; i < categoryIds.length; i++) {
            if (categories.byId[categoryIds[i]]) {
                if (s.length > 0) {
                    s += separator;
                }
                let name = categories.byId[categoryIds[i]].name;
                if (stripCategoryHeadings) {
                    name = stripCategoryHeadingFromCategoryName(name);
                }
                s += `${name}${categories.byId[categoryIds[i]].state === 'deleted' ? ' (deleted)' : ''}`;
            }
        }
        return s;
    }
    return '';
}


export const makeCategoryIdsForEditing = (categories: CategoriesData) => {
    const ids = [] as string[];
    categories?.ids?.forEach((id: string) => {
        if (categories.byId[id].state === 'active') {
            ids.push(
                id
            );
        }
    });
    return ids;
};

export const makeCategoryNamesForEditing = (categories: CategoriesData) => {
    const names = [] as string[];
    categories?.ids?.forEach((id: string) => {
        if (categories.byId[id].state === 'active') {
            names.push(
                categories.byId[id].name
            );
        }
    });
    return names;
};

export const makeCategoryCustomDataForEditing = (categories: CategoriesData) => {
    const customData = [] as any[];
    categories?.ids?.forEach((id: string) => {
        if (categories.byId[id].state === 'active') {
            const _category: Partial<Category>  = {...categories.byId[id]};
            delete _category.state;
            delete _category.name;
            delete _category.replacedBy;
            customData.push(_category);
        }
    });
    return customData;
};

// Choose a default categoryId
// Return categoryId for a category that matches defaultCategoryName
// Else if there are no active categories, return ''
// Else return defaultCategoryName
export const getDefaultCategoryId = (
    defaultCategoryName = '',    
    categories?: CategoriesData,
): string => {
    if (!categories) {
        return defaultCategoryName;
    }
    if (categories?.ids.length > 0) {
        let hasActive = false;
        for (let i = 0; i < categories.ids.length; i++) {
            const cat = categories.byId[categories.ids[i]];
            if (cat.state === 'active') {
                hasActive = true;
                if (cat.name == defaultCategoryName) { // eslint-disable-line eqeqeq
                    return categories.ids[i];
                }
            }
        }
        if (hasActive) {
            return '';
        }
        return defaultCategoryName;
    }
    return '';
};

// Detect if any categories have changed
export const haveCategoriesChanged = (ids: string[] | undefined, names: string[] | undefined, categories: CategoriesData | undefined, customData?: any[]) => {
    if (ids === undefined || names === undefined || categories === undefined || categories.ids === undefined) {
        return (ids || names || (categories?.ids?.length || 0) > 0) ? true : false;
    }
    let index = 0;
    for (let i = 0; i < ids.length; i++) {
        const category = categories.byId[ids[i]];
        if (category === undefined) {
            return true;
        }
        if (category.state !== 'active') {
            continue;
        }
        if (categories.byId[ids[i]].name !== names[index]) {
            return true;
        }
        if (customData?.[index]) {
             for (const [key, value] of Object.entries(customData[index])) {
                if (categories.byId[ids[i]][key as keyof Category] !== value) {
                    return true;
                }
            };
        }
        index++;
    }
    for (let i = 0; i < categories.ids.length; i++) {
        if (ids.indexOf(categories.ids[i]) === -1 && categories.byId[categories.ids[i]].state === 'active') {
            return true; // A category was deleted
        }
    }
    return false;
};

export const countCategoriesDeleted = (categoryIds: string[] | undefined, categories: CategoriesData) => {
    // Find deleted categories
    let count = 0;
    categories?.ids?.forEach((id: string) => {
        if (
            (categoryIds === undefined || categoryIds.indexOf(id) === -1) &&
            categories.byId[id].state === 'active'
        ) {
            count++;
        }
    });
    return count;
}

// Analyse categories and save changes if there are any (using batch).
// Records changes to batchTrace.data object.
export const saveCategoryChanges = (
    batch: WriteBatch | SplittableBatch,
    batchTrace: BatchTrace,
    categoryIds: string[] | undefined,
    categoryNames: string[] | undefined,
    customData: any[] | undefined,
    categories: CategoriesData | undefined,
    categoriesCollection: string | undefined,
    keyField: string,
    keyValue: string | undefined,
    onVesselUpdated?: (
        batch: WriteBatch | SplittableBatch,
        collectionName: string,
        vesselId: string |
        undefined
    ) => void
) => {
    if (categoryIds && categoryNames && categories?.byId) {
        const newCategories = [] as Category[];
        const changedCategories = [] as Category[];
        const deletedCategoryIds = [] as string[];
        // Find new/ changed / deleted-by-blanking categories
        categoryIds.forEach((id: string, index: number) => {
            if (id === '') {
                if (categoryNames[index].trim() !== '') {
                    // New category added
                    newCategories.push({
                        name: categoryNames[index].trim(),
                        ...(customData?.[index])
                    });
                }
            } else if (categories?.byId[id]?.name !== categoryNames[index]) {
                // Changed category
                if (categoryNames[index].trim() === '') {
                    deletedCategoryIds.push(id); // Deleted
                } else {
                    changedCategories.push({ // Changed
                        id: id,
                        name: categoryNames[index].trim(),
                        ...(customData?.[index])
                    });
                }
            } else  if (customData?.[index]) {
                for (const [key, value] of Object.entries(customData[index])) {
                    if (categories.byId[id][key as keyof Category] !== value) {
                        changedCategories.push({ // Changed
                            id: id,
                            ...(customData?.[index])
                        });
                        break; // Exit the loop when the condition is met
                    }
                }
            }
        });
        // Find deleted categories
        categories?.ids?.forEach((id: string) => {
            if (categoryIds.indexOf(id) === -1 && categories.byId[id]?.state === 'active') {
                deletedCategoryIds.push(id);
            }
        });
        if (categoriesCollection && (newCategories.length > 0 || changedCategories.length > 0 || deletedCategoryIds.length > 0)) {
            batchTrace.data[categoriesCollection] = {};
            if (newCategories.length > 0) {
                batchTrace.data[categoriesCollection].newCategories = newCategories;
                newCategories.forEach((newCategory: Category) => {
                    const obj = {
                        ...newCategory,
                        state: 'active',
                        touched: serverTimestamp()
                    } as Category;
                    if (typeof keyValue !== 'undefined') {
                        obj[keyField as keyof Category] = keyValue;
                    }
                    batch.set(
                        doc(collection(firestore, categoriesCollection)),
                        obj
                    );
                });
            }
            if (changedCategories.length > 0) {
                batchTrace.data[categoriesCollection].changedCategories = changedCategories;
                changedCategories.forEach((changedCategory: Category) => {
                    batch.set(
                        doc(firestore, categoriesCollection, changedCategory.id),
                        {
                            ...changedCategory,
                            state: 'active',
                            id: undefined,
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                });
            }
            if (deletedCategoryIds.length > 0) {
                batchTrace.data[categoriesCollection].deletedCategoryIds = deletedCategoryIds;
                deletedCategoryIds.forEach((deletedCategoryId: string) => {
                    batch.set(
                        doc(firestore, categoriesCollection, deletedCategoryId),
                        {
                            state: 'deleted',
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                });
            }

            onCollectionUpdated(
                batch,
                categoriesCollection,
                (keyField === 'vesselId') ? keyValue : undefined
            );
        }
    }
};

// Make a categoryId given a value from SeaSelectCategory.
// Will create a new category if necessary
export const makeCategoryId = (
    categoryId: string | undefined,
    categories: CategoriesData | undefined,
    valueForNoCategory: any, // Should be undefined or lib/firebase.ts/deleteValue
    batch: WriteBatch | SplittableBatch,
    categoriesCollection: string,
    keyField: keyof Category,
    keyValue: string,
    customData?: any,
) => {
    if (categoryId && categoryId.trim().length > 0) {
        if (categories?.byId[categoryId]) {
            // Is an existing category
            let customDataChanged = false;
            if (customData) {
                Object.keys(customData).forEach((field: string) => {
                    if (customData[field] !== categories.byId[categoryId][field]) {
                        customDataChanged = true;
                    }
                })
            }
            if (customDataChanged) {
                // Update the customData
                batch.set(
                    doc(firestore, categoriesCollection, categoryId),
                    {
                        ...customData,
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );
            }
            return categoryId;
        } else {
            // Is a new category - we will need to create it.
            const newCategoryRef = doc(collection(firestore, categoriesCollection));
            const newCategoryData = {
                name: categoryId.trim(),
                state: 'active',
                ...customData,
                touched: serverTimestamp()
            } as Category;
            newCategoryData[keyField] = keyValue;
            batch.set(newCategoryRef, newCategoryData);

            onCollectionUpdated(
                batch,
                categoriesCollection,
                (keyField === 'vesselId') ? keyValue : undefined
            );

            return newCategoryRef.id;
        }
    }
    return valueForNoCategory;
};

export type Category = {
    id: string,
    name: string,
    state: 'active' | 'deleted' | 'replaced',
    replacedBy?: string,
    isCritical?: boolean,
    [key: string]: any
};

export type CategoriesData = {
    ids: string[],
    byId: {
        [id: string]: Category
    }
};

// Listen for changes to a categories collection
export const onCategoriesSnapshot = (
    _collection: string,
    keyField: string,
    keyValue: string,
    onLoaded: (data: CategoriesData) => void,
    onError?: (error: any) => void
) => {
    return onSnapshot(
        query(
            collection(firestore, _collection),
            where(keyField, '==', keyValue),
            orderBy('name', 'asc')
        ),
        (snap) => {
            const byId = {} as {
                [id: string]: Category
            };
            const ids = [] as string[]; // Alphabetical list (includes non-active! We'll need to filter them out later)
            snap.docs.forEach((doc) => {
                const categoryData = doc.data() as Omit<Category, 'id'>; // Assuming 'id' is not stored in the document itself but used as the document key
                const completeCategoryData: Category = {
                    id: doc.id,
                    name: categoryData.name || 'Default Name', // Provide a default name if necessary
                    state: categoryData.state || 'active', // Provide a default state if necessary
                    ...categoryData,
                };
                delete completeCategoryData[keyField as keyof Category];
                byId[doc.id] = completeCategoryData;
                ids.push(doc.id);
            });
            onLoaded({
                byId,
                ids
            } as CategoriesData);
        }, (error) => {
            // This should be very rare
            if (onError) {
                onError(error);
            } else {
                console.log(`Error getting ${collection} for ${keyField}=${keyValue}`, error.message, error);
            }
        }
    );
};

// Convert to options suitable for <SeaMultiSelect/>
export const categoriesToSeaMultiSelectOptions = (categories: CategoriesData | undefined): {id: string, name: string}[] => {
    const results: {id: string, name: string}[] = [];
    if (!categories) {
        return results;
    }
    if (categories?.ids?.length > 0) {
        categories.ids.forEach((id: string) => {
            const category = categories.byId[id];
            if (category && category.state === 'active') {
                results.push({
                    id: id,
                    name: category.name
                });
            }
        });
    }
    return results;
};
