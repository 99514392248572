import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap, hasArrayChanged, haveObjectsChanged } from '../../../../lib/util';
import { formatSeaDatetime } from '../../../../lib/datesAndTime';
import { categoriesToSeaMultiSelectOptions, makeCategoryId, renderCategoryName, renderCategoryNames } from '../../../../lib/categories';
import { auhoritiesNotifiedByRegion, authoritiesNotified, incidentTypes, incidentWhoInvolvedTypes, injuryOutcomes } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { Incident, Injury } from '../../../../shared-state/HealthSafety/incidents';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { hasSignatureChanged, haveFilesChanged, makeSeaFiles, makeSignature, saveFileRefs, SeaFile, seaFilesToValue, signatureToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaSignature from '../../../../components/SeaSignature/SeaSignature';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import './EditIncident.css';

const whoInvolvedTypeOptions = Object.keys(incidentWhoInvolvedTypes).map((key) => {
    return {
        id: key,
        name: incidentWhoInvolvedTypes[key]
    };
});

interface EditIncidentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    defaultVesselId?: string,
    itemToUpdate?: Incident
}

const compileInjuries = (_injuries: Injury[]) => {
    const injuries: Injury[] = [];
    _injuries?.forEach((_injury) => {
        const injury: Injury = {};
        if (_injury.whoInjured) {
            injury.whoInjured = _injury.whoInjured;
        }
        if (_injury.typeIds && _injury.typeIds?.length > 0) {
            injury.typeIds = _injury.typeIds;
        }
        if (_injury.locationIds && _injury.locationIds?.length > 0) {
            injury.locationIds = _injury.locationIds;
        }
        if (_injury.outcome) {
            injury.outcome = _injury.outcome;
        }
        if (Object.keys(injury).length > 0) {
            injuries.push(injury);
        }
    });
    return injuries;
};

const EditIncident: React.FC<EditIncidentProps> = ({
    showModal,
    setShowModal,
    level,
    defaultVesselId,
    itemToUpdate
}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const licenseeSettings = sharedState.licenseeSettings.use(showModal);
    const incidentCategories = sharedState.incidentCategories.use(showModal);
    const incidentCauses = sharedState.incidentCauses.use(showModal);
    const injuryTypes = sharedState.injuryTypes.use(showModal);
    const injuryLocations = sharedState.injuryLocations.use(showModal);
    const [whoInvolvedTypes, setWhoInvolvedTypes] = useState<string[]>();
    const [causeIds, setCauseIds] = useState<string[]>();
    const [injuries, setInjuries] = useState<Injury[]>([{}]);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [signature, setSignature] = useState<SeaFile>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const hasSubmittedRef = useRef(false);
    const formRef = useRef<HTMLFormElement>(null);
    const saveAsDraftRef = useRef(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                isDraft: (itemToUpdate.state === 'draft'),
                vesselId: itemToUpdate.vesselId ? ''+itemToUpdate.vesselId : '',
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
                isSensitive: (itemToUpdate.isSensitive === 'y') ? true : false,
                reportedBy: itemToUpdate.reportedBy ? ''+itemToUpdate.reportedBy : '',
                role: itemToUpdate.role ? ''+itemToUpdate.role : '',
                whoInvolved: itemToUpdate.whoInvolved ? ''+itemToUpdate.whoInvolved : '',
                witnesses: itemToUpdate.witnesses ? ''+itemToUpdate.witnesses : '',
                whenEvent: itemToUpdate.whenEvent ? formatSeaDatetime(itemToUpdate.whenEvent) : '',
                location: itemToUpdate.location ? ''+itemToUpdate.location : '',
                conditions: itemToUpdate.conditions ? ''+itemToUpdate.conditions : '',
                notifiedAuthorities: (itemToUpdate.notifiedAuthorities === 'y') ? 'y' : 'n',
                authority: itemToUpdate.authority ? ''+itemToUpdate.authority : '',
                propertyDamage: itemToUpdate.propertyDamage ? ''+itemToUpdate.propertyDamage : '',
                description: itemToUpdate.description ? ''+itemToUpdate.description : '',
                initialActions: itemToUpdate.initialActions ? ''+itemToUpdate.initialActions : '',
                prevention: itemToUpdate.prevention ? ''+itemToUpdate.prevention : '',
            };
        } else {
            return {
                isDraft: true,
                vesselId: defaultVesselId ? defaultVesselId : '',
                type: '',
                name: '',
                categoryId: '',
                // categoryId: defaultVesselId ? (
                //     (incidents?.byVesselId[defaultVesselId]?.length > 0 && incidents.byVesselId[defaultVesselId][0].categoryId) ? ''+incidents.byVesselId[defaultVesselId][0].categoryId : ''
                // ) : (
                //     (incidents?.all?.length > 0 && incidents.all[0].categoryId) ? ''+incidents.all[0].categoryId : ''
                // ),
                // reportNum auto generated
                isSensitive: false,
                reportedBy: '',
                role: '',
                whoInvolved: '',
                witnesses: '',
                whenEvent: formatSeaDatetime(),
                location: '',
                conditions: '',
                notifiedAuthorities: '',
                authority: '',
                propertyDamage: '',
                description: '',
                initialActions: '',
                prevention: '',
            };
        }
    }, [itemToUpdate, defaultVesselId]);

    const onOpened = () => {
        hasSubmittedRef.current = false;
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setWhoInvolvedTypes((itemToUpdate?.whoInvolvedTypes) ? itemToUpdate.whoInvolvedTypes : []);
        setCauseIds((itemToUpdate?.causeIds) ? itemToUpdate.causeIds : []);
        if (itemToUpdate?.injuries && itemToUpdate?.injuries?.length > 0) {
            setInjuries(itemToUpdate.injuries.map((injury) => {
                return {...injury};
            }));
        } else {
            setInjuries([{}]);
        }
        setFiles(makeSeaFiles(itemToUpdate?.files));
        setSignature(makeSignature(itemToUpdate?.signature));
    }

    const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().required(),
            type: Yup.string().max(200)
                .when('isDraft', {is: false, then: (schema) => schema.required()}),
            name: Yup.string().max(500).required(),
            categoryId: Yup.string().max(200),
            isSensitive: Yup.boolean(),
            reportedBy: Yup.string().max(500),
            role: Yup.string().max(500),
            whoInvolved: Yup.string().max(500),
            witnesses: Yup.string().max(5000),
            whenEvent: Yup.date().max(formatSeaDatetime()).required().min(...notTooOld),
            location: Yup.string().max(500),
            conditions: Yup.string().max(5000),
            notifiedAuthorities: Yup.string().max(200)
                .when('isDraft', {is: false, then: (schema) => schema.required()}),
            authority: Yup.string().max(200),
            propertyDamage: Yup.string().max(5000),
            description: Yup.string().max(5000),
            initialActions: Yup.string().max(5000),
            prevention: Yup.string().max(5000),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('incident') || (signature === undefined && !saveAsDraftRef.current)) {
                return;
            }
            // Attempt upload first....
            const filesToUpload = [...files];
            if (signature) {
                filesToUpload.push(signature);
            }
            uploadFiles(filesToUpload).then(() => {
                if (!licenseeId) {
                    throw new Error('Licensee ID not set');
                }
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'incidents');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'incidents', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            type: data.type,
                            name: data.name,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                incidentCategories,
                                deleteValue,
                                batch,
                                'incidentCategories',
                                'licenseeId',
                                licenseeId
                            ),
                            isSensitive: data.isSensitive ? 'y' : deleteValue,
                            whoInvolvedTypes: whoInvolvedTypes,
                            reportedBy: data.reportedBy ? data.reportedBy : deleteValue,
                            role: data.role ? data.role : deleteValue,
                            whoInvolved: data.whoInvolved ? data.whoInvolved : deleteValue,
                            witnesses: data.witnesses ? data.witnesses : deleteValue,
                            whenEvent: toMillis(data.whenEvent),
                            location: data.location ? data.location : deleteValue,
                            conditions: data.conditions ? data.conditions : deleteValue,
                            notifiedAuthorities: data.notifiedAuthorities ? data.notifiedAuthorities : deleteValue,
                            authority: data.authority ? data.authority : deleteValue,
                            causeIds: causeIds,
                            propertyDamage: data.propertyDamage ? data.propertyDamage : deleteValue,
                            description: data.description ? data.description : deleteValue,
                            initialActions: data.initialActions ? data.initialActions : deleteValue,
                            prevention: data.prevention ? data.prevention : deleteValue,
                            injuries: (data.type === 'injury') ? compileInjuries(injuries) : deleteValue,
                            files: seaFilesToValue(files),
                            signature: signatureToValue(signature),
                            state: (itemToUpdate.state === 'draft' && saveAsDraftRef.current === false) ? 'forReview' : undefined,
                            completedBy: (saveAsDraftRef.current || itemToUpdate.state !== 'draft') ? undefined : userId,
                            whenCompleted: (saveAsDraftRef.current || itemToUpdate.state !== 'draft') ? undefined : batchTrace.whenAction,
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'incidents', itemToUpdate.id);
                    if (signature) {
                        saveFileRefs(batch, [signature], 'incidents', itemToUpdate.id);
                    }

                    if (!saveAsDraftRef.current) {
                        logAction(
                            batch,
                            (itemToUpdate.state === 'draft') ? 'Add' : 'Update',
                            'incidents',
                            itemToUpdate.id,
                            data.name,
                            [itemToUpdate.vesselId]
                        );
                    }

                } else {
                    const newRef = doc(collection(firestore, 'incidents'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        licenseeId: licenseeId,
                        vesselId: data.vesselId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        type: data.type,
                        name: data.name,
                        categoryId: makeCategoryId(
                            data.categoryId,
                            incidentCategories,
                            undefined,
                            batch,
                            'incidentCategories',
                            'licenseeId',
                            licenseeId
                        ),
                        isSensitive: data.isSensitive ? 'y' : undefined,
                        whoInvolvedTypes: whoInvolvedTypes,
                        reportedBy: data.reportedBy ? data.reportedBy : undefined,
                        role: data.role ? data.role : undefined,
                        whoInvolved: data.whoInvolved ? data.whoInvolved : undefined,
                        witnesses: data.witnesses ? data.witnesses : undefined,
                        whenEvent: toMillis(data.whenEvent),
                        location: data.location ? data.location : undefined,
                        conditions: data.conditions ? data.conditions : undefined,
                        notifiedAuthorities: data.notifiedAuthorities ? data.notifiedAuthorities : undefined,
                        authority: data.authority ? data.authority : undefined,
                        causeIds: causeIds,
                        propertyDamage: data.propertyDamage ? data.propertyDamage : undefined,
                        description: data.description ? data.description : undefined,
                        initialActions: data.initialActions ? data.initialActions : undefined,
                        prevention: data.prevention ? data.prevention : undefined,
                        injuries: (data.type === 'injury') ? compileInjuries(injuries) : undefined,
                        files: seaFilesToValue(files),
                        signature: signatureToValue(signature),
                        state: (saveAsDraftRef.current) ? 'draft' : 'forReview',
                        completedBy: saveAsDraftRef.current ? undefined : userId,
                        whenCompleted: saveAsDraftRef.current ? undefined : batchTrace.whenAction,
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'incidents', newRef.id);
                    if (signature) {
                        saveFileRefs(batch, [signature], 'incidents', newRef.id);
                    }
                    if (!saveAsDraftRef.current) {
                        logAction(
                            batch,
                            'Add',
                            'incidents',
                            newRef.id,
                            data.name,
                            [data.vesselId]
                        );
                    }
                }

                if (!saveAsDraftRef.current) {
                    sendVesselNotification(batch, 'incidentReported', 'incidents', {
                        isUpdate: itemToUpdate ? (itemToUpdate.state !== 'draft') : false,
                        id: batchTrace.exampleDocId,
                        name: data.name,
                        type: data.type ? (data.type === 'event' ? 'Event' : incidentTypes[data.type]) : undefined,
                        category: renderCategoryName(data.categoryId, incidentCategories),
                        // reportNum: to be loaded in backend
                        // Personnel
                        whoInvolvedTypes: whoInvolvedTypes?.map((type: string) => {
                            return incidentWhoInvolvedTypes[type];
                        }).join(', '),
                        reportedBy: data.reportedBy ? data.reportedBy : undefined,
                        role: data.role ? data.role : undefined,
                        whoInvolved: data?.whoInvolved ? data.whoInvolved : undefined,
                        witnesses: data?.witnesses ? data.witnesses : undefined,
                        // Event
                        whenEvent: toMillis(data.whenEvent),
                        location: data.location ? data.location : undefined,
                        conditions: data.conditions ? data.conditions : undefined,
                        notifiedAuthorities: authoritiesNotified[data.notifiedAuthorities],
                        causes: renderCategoryNames(causeIds, incidentCauses),
                        propertyDamage: data.propertyDamage ? data.propertyDamage : undefined,
                        description: data.description ? data.description : undefined,
                        initialActions: data.initialActions ? data.initialActions : undefined,
                        prevention: data.prevention ? data.prevention : undefined,
                        injuries: (injuries?.length > 0) ? injuries.map((injury) => {
                            return {
                                ...injury,
                                type: renderCategoryNames(injury?.typeIds, injuryTypes),
                                location: renderCategoryNames(injury?.locationIds, injuryLocations)
                            }
                        }) : undefined
                    }, files, [data.vesselId]);
                }

                onCollectionUpdated(batch, 'incidents');
                batchTrace.data = {
                    data,
                    causeIds,
                    files: seaFilesToValue(files),
                    signature: signatureToValue(signature)
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add' } incident/event report ${data?.name}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload incident/event files`, error.message, error, {
                        data,
                        itemToUpdate,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });

    const setInjuryValue = (injuryIndex: number, field: keyof Injury, value: string | string[]) => {
        setInjuries((current) => {
            const newArray = [...current];
            newArray[injuryIndex][field] = value as any;
            return newArray;
        });
    };

    const addInjury = () => {
        setInjuries((current) => {
            return [...current, {}];
        });
    };

    const removePerson = (e: React.MouseEvent<Element, MouseEvent>, injuryIndex: number) => {
        e.preventDefault();
        if (injuries.length < 2) {
            return;
        }
        confirmAction(`Are you sure you want to remove this person?`, `Yes, remove`).then(() => {
            setInjuries((current) => {
                const newArray = [...current];
                newArray.splice(injuryIndex, 1);
                return newArray;
            });
        }).catch(() => {});
    };

    const causeOptions = useMemo(() => {
        return categoriesToSeaMultiSelectOptions(incidentCauses);
    }, [incidentCauses]);

    const injuryTypeOptions = useMemo(() => {
        return categoriesToSeaMultiSelectOptions(injuryTypes);
    }, [injuryTypes]);

    const injuryLocationOptions = useMemo(() => {
        return categoriesToSeaMultiSelectOptions(injuryLocations);
    }, [injuryLocations]);

    useEffect(() => {
        if (isSubmitting) {
            hasSubmittedRef.current = true;
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasArrayChanged(causeIds, itemToUpdate?.causeIds) ||
            hasArrayChanged(whoInvolvedTypes, itemToUpdate?.whoInvolvedTypes) ||
            haveFilesChanged(files, itemToUpdate?.files) ||
            hasSignatureChanged(signature, itemToUpdate?.signature) ||
            haveObjectsChanged(injuries, itemToUpdate?.injuries ? itemToUpdate.injuries : [{}])
        );
    }, [values, files, itemToUpdate, signature, causeIds, initialValues, injuries, whoInvolvedTypes]);

    return (
        <SeaModal
            title={itemToUpdate ? `Edit Report - ${itemToUpdate?.name}` : 'Report Incident / Event'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <h4 style={{ marginTop: '0px' }}>Type</h4>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Vessel / Facility"
                                name="vesselId"
                                zone="white"
                                value={values.vesselId}
                                disabled={itemToUpdate ? true : false}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Type of event"
                                name="type"
                                zone="white"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {Object.keys(incidentTypes).map((type) => {
                                    return <IonSelectOption key={type} value={type}>{incidentTypes[type]}</IonSelectOption>
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Title of event"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={incidentCategories}
                                label="Event Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        {itemToUpdate?.reportNum &&
                            <IonCol size="6">
                                <SeaInput
                                    label="Report#"
                                    name="reportNum"
                                    value={itemToUpdate.reportNum}
                                    disabled={true}
                                    zone="white"
                                />
                            </IonCol>
                        }
                    </IonRow>
                    {/* <IonRow>
                        <IonCol size="12">
                            <SeaCheckbox
                                name="isSensitive"
                                checked={values.isSensitive}
                                setFieldValue={setFieldValue}
                                error={touched.isSensitive ? errors.isSensitive : ''}
                            >
                                Sensitive event? (i.e. graphic content, workplace violence, bullying or stress in the workplace)
                            </SeaCheckbox>
                        </IonCol>
                    </IonRow> */}
                    <IonRow>
                        <IonCol size="12">
                            <div className="form-line" style={{ marginTop: '0px' }}></div>
                            <h4>Personnel</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            {/* <SeaSelect
                                label="Types of personnel involved"
                                name="whoInvolvedTypes"
                                zone="white"
                                value={values.whoInvolvedTypes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.whoInvolvedTypes ? errors.whoInvolvedTypes : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {Object.keys(incidentWhoInvolvedTypes).map((type) => {
                                    return <IonSelectOption key={type} value={type}>{incidentWhoInvolvedTypes[type]}</IonSelectOption>
                                })}
                            </SeaSelect> */}
                            <SeaMultiSelect
                                mode="popover"
                                label="Types of personnel involved"
                                values={whoInvolvedTypes}
                                setValues={setWhoInvolvedTypes}
                                options={whoInvolvedTypeOptions}
                                useAllOption={false}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="First reported by"
								name="reportedBy"
								value={values.reportedBy}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.reportedBy ? errors.reportedBy : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="What was their role in the event?"
								name="role"
								value={values.role}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.role ? errors.role : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Names of those involved"
								name="whoInvolved"
								value={values.whoInvolved}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.whoInvolved ? errors.whoInvolved : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Witnesses"
								name="witnesses"
								value={values.witnesses}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.witnesses ? errors.witnesses : ''}
                          	/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <div className="form-line" style={{ marginTop: '0px' }}></div>
                            <h4>Event</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <SeaDatetime
                                label="Time of event"
                                name="whenEvent"
                                value={values.whenEvent}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={errors.whenEvent ? errors.whenEvent : ''}
                                required={true}
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Event location"
								name="location"
								value={values.location}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.location ? errors.location : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Environmental conditions (if applicable)"
								name="conditions"
								value={values.conditions}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.conditions ? errors.conditions : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Have authorities been notified?"
                                name="notifiedAuthorities"
                                zone="white"
                                popoverSize="wide"
                                value={values.notifiedAuthorities}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.notifiedAuthorities ? errors.notifiedAuthorities : ''}
                                help={{
                                    text: `Events that are notifiable include: serious injury, death etc.`
                                }}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {licenseeSettings && auhoritiesNotifiedByRegion[licenseeSettings?.region]?.map((authority: string) => {
                                    return <IonSelectOption key={authority} value={authority}>{authoritiesNotified[authority]}</IonSelectOption>
                                })}
                                {(values.notifiedAuthorities && licenseeSettings && auhoritiesNotifiedByRegion[licenseeSettings?.region]?.indexOf(values.notifiedAuthorities) === -1) &&
                                    <IonSelectOption value={values.notifiedAuthorities}>{authoritiesNotified[values.notifiedAuthorities]}</IonSelectOption>
                                }
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaMultiSelect
                                mode="popover"
                                label="Contributing Factors of this Incident/Event"
                                values={causeIds}
                                setValues={setCauseIds}
                                options={causeOptions}
                                useAllOption={false}
                                isSubmitting={hasSubmitted}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Is there any damage to property?"
								name="propertyDamage"
								value={values.propertyDamage}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.propertyDamage ? errors.propertyDamage : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="What happened?"
                                name="description"
                                value={values.description}
                                height={100}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.description ? errors.description : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTextarea
                                label="What initial actions have you taken?"
                                name="initialActions"
                                value={values.initialActions}
                                height={100}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.initialActions ? errors.initialActions : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaTextarea
                                label="Do you suggest any corrective actions?"
                                name="prevention"
                                value={values.prevention}
                                height={100}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.prevention ? errors.prevention : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="incidents"
                                field="files"
                            />
                        </IonCol>
                    </IonRow>
                    {values.type === 'injury' &&
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <div className="form-line" style={{ marginTop: '0px' }}></div>
                                    <h4>Injuries</h4>
                                </IonCol>
                                <IonCol size="12" style={{ paddingBottom: '8px' }}>
                                    {injuries?.map((injury, injuryIndex) => {
                                        return (
                                            <div key={injuryIndex} className="columns" style={{ marginBottom: '12px', marginTop: '-4px' }}>
                                                {injuries.length > 1 &&
                                                    <div>
                                                        <div className="trip-stop-block"></div>
                                                    </div>
                                                }
                                                <div style={{ flex: '1 1 0' }}>
                                                    <IonGrid className="trip-stops form-grid">
                                                        <IonRow>
                                                            <IonCol size="6">
                                                                <SeaInput
                                                                    label={`Person injured${injuries.length > 1 ? ` (${injuryIndex + 1})` : ''}`}
                                                                    //label={`Person injured (${index + 1})`}
                                                                    //label={`Person injured ${index + 1}`}
                                                                    //label={`${index + 1}. Person injured`}
                                                                    value={injury.whoInjured}
                                                                    onchange={(e) => {
                                                                        setInjuryValue(injuryIndex, 'whoInjured', e.detail.value);
                                                                    }}
                                                                    // onblur={handleBlur}
                                                                    zone="white"
                                                                    type="text"
                                                                    inputmode="text"
                                                                    //error={touched.whoInjured ? errors.whoInjured : ''}
                                                                />
                                                            </IonCol>
                                                            <IonCol size="6">
                                                                <SeaMultiSelect
                                                                    label="Type of Injury"
                                                                    mode="popover"
                                                                    values={injury.typeIds}
                                                                    setValues={(values: string[]) => {
                                                                        setInjuryValue(injuryIndex, 'typeIds', values);
                                                                    }}
                                                                    options={injuryTypeOptions}
                                                                    useAllOption={false}
                                                                    isSubmitting={hasSubmitted}
                                                                    emptyText="Not Set"
                                                                />
                                                            </IonCol>
                                                            <IonCol size="6">
                                                                <SeaMultiSelect
                                                                    label="Injury Location"
                                                                    mode="popover"
                                                                    values={injury.locationIds}
                                                                    setValues={(values: string[]) => {
                                                                        setInjuryValue(injuryIndex, 'locationIds', values);
                                                                    }}
                                                                    options={injuryLocationOptions}
                                                                    useAllOption={false}
                                                                    isSubmitting={hasSubmitted}
                                                                    emptyText="Not Set"
                                                                />
                                                            </IonCol>
                                                            <IonCol size="6">
                                                                <SeaSelect
                                                                    label="Injury outcome"
                                                                    zone="white"
                                                                    value={injury.outcome}
                                                                    onchange={(e) => {
                                                                        setInjuryValue(injuryIndex, 'outcome', e.detail.value);
                                                                    }}
                                                                    //onblur={handleBlur}
                                                                >
                                                                    <IonSelectOption value="">Not Set</IonSelectOption>
                                                                    {Object.keys(injuryOutcomes).map((outcome) => {
                                                                        return <IonSelectOption key={outcome} value={outcome}>{injuryOutcomes[outcome]}</IonSelectOption>
                                                                    })}
                                                                </SeaSelect>
                                                            </IonCol>
                                                            {(injuries.length) > 1 &&
                                                                <IonCol size="12">
                                                                    <div style={{
                                                                        textAlign: 'right',
                                                                        color: 'var(--ion-color-primary)'
                                                                    }}>
                                                                        <div
                                                                            className="clickable pushy"
                                                                            onClick={(e: React.MouseEvent<Element, MouseEvent>) => removePerson(e, injuryIndex)}
                                                                            style={{
                                                                                zIndex: 1,
                                                                                position: 'relative',
                                                                                display: 'inline-block'
                                                                            }}
                                                                        >
                                                                            <SeaIcon icon='trash' slot="icon-only" />
                                                                            <span style={{
                                                                                fontWeight: '500',
                                                                                paddingLeft: '4px',
                                                                                position: 'relative',
                                                                                top: '-5px'
                                                                            }}>
                                                                                Remove Person
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </IonCol>
                                                            }
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol size="12">
                                    <div className="sea-add-new-button">
                                        <SeaButton zone="white" shape="circle" onClick={(e) => addInjury()}>
                                            <SeaIcon slot="icon-only" icon="add" />
                                        </SeaButton>
                                        <div className="text" onClick={(e) => addInjury()}>
                                            Add Person
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                    <IonRow>
                        <IonCol size="12">
                            <div className="form-line" style={{ marginTop: '0px' }}></div>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSignature
                                collection="incidents"
                                file={signature}
                                setFile={setSignature}
                                label="Sign or initial below"
                                isRequired={hasSubmittedRef.current && !values.isDraft}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="grid-row-spacer"></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && (signature !== undefined || saveAsDraftRef.current)}
                />
                <div className="view-modal-buttons">
                    <SeaButton
                        size="wide"
                        onClick={(e) => {
                            console.log('Normal submit!');
                            saveAsDraftRef.current = false;
                            setFieldValue('isDraft', false).then(() => {
                                formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                            });
                        }}
                    >
                        {(itemToUpdate && itemToUpdate.state !== 'draft') ? 'Update Report' : 'Submit Report'}
                    </SeaButton>
                    <div className="spacer"></div>
                    {(itemToUpdate === undefined || itemToUpdate.state === 'draft') &&
                        <SeaButton
                            size="wide"
                            onClick={(e) => {
                                console.log('Save as draft!');
                                saveAsDraftRef.current = true;
                                setFieldValue('isDraft', true).then(() => {
                                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                                });
                            }}
                        >
                            {(itemToUpdate && itemToUpdate.state === 'draft') ? 'Update Draft' : 'Save As Draft'}
                        </SeaButton>
                    }
                </div>
            </form>
        </SeaModal>
    );
};

export default EditIncident;
