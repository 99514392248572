import { onSnapshot, query, collection, where, orderBy } from 'firebase/firestore';
import { CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { registerFiles, registerRichText } from '../FileSyncSystem/filesToCache';
import { firestore } from '../../lib/firebase';
import { canView } from '../Core/userPermissions';
import { SFDoc } from '../CompanyDocuments/companyDocuments';

export interface SOP extends CreateableDocument, UpdateableDocument {
    categoryId: string;
    deletedBy?: string;
    files?: string[];
    sfdoc?: SFDoc;
    state: string;
    title: string;
    vesselId: string;
    whenDeleted?: number;
    whenIssued: number;
}

export type VesselSOPsData = { 
    byId: {
        [id: string]: SOP
    },
    byCategoryId: {
        [id: string]: SOP[]
    },
    categoryIds: string[]
}

export const vesselSOPsConfig: SharedStateConfig<VesselSOPsData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId', 'vesselSOPCategories'],
    countLiveDocs: () => Object.keys(sharedState.vesselSOPs.current?.byId ?? {}).length,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        const vesselSOPCategories = sharedState.vesselSOPCategories.current;

        if (
            vesselId &&
            vesselSOPCategories &&
            canView('standardOperatingProcedures')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'SOPs'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    orderBy('title', 'asc')
                ),
                (snap) => {
                    done();
                    const items = snap.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    } as SOP));

                    const byId = {} as {
                        [id: string]: SOP
                    };
                    const byCategoryId = {} as {
                        [id: string]: SOP[]
                    };

                    items.forEach((sop) => {
                        byId[sop.id] = sop;
                        if (!byCategoryId[sop.categoryId]) {
                            byCategoryId[sop.categoryId] = [];
                        }
                        byCategoryId[sop.categoryId].push(sop);

                        registerFiles(sop.files, 'SOPs', sop);
                        registerRichText(sop.sfdoc, 'SOPs');
                    });

                    const categoryIds = Object.keys(vesselSOPCategories.byId).filter(categoryId => byCategoryId[categoryId]);

                    set({
                        byId,
                        byCategoryId,
                        categoryIds
                    });

                }, (error) => {
                    console.error(`Failed to access SOPs data for vessel ${vesselId}`, error);
                    clear();
                }
            );
        } else {
            done();
        }
    }
};