import { useMemo, useState } from "react";
import { CsvConfig, formatCsvDate, formatCsvSpareParts } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { MaintenanceTaskCompleted } from "../../../shared-state/VesselMaintenance/maintenanceTasksCompleted";
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import { renderCategoryName } from '../../../lib/categories';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { useVesselMaintenanceTasksCompleted } from '../../../shared-state/VesselMaintenance/useVesselMaintenanceTasksCompleted';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';
import SeaExporterDateRange from '../../../components/SeaExporter/SeaExporterDateRange';

type MaintenanceHistoryCsvProps = {
    onCompleted: () => void
}

const MaintenanceHistoryCsv = ({onCompleted}: MaintenanceHistoryCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const maintenanceTasksCompleted = useVesselMaintenanceTasksCompleted(dateRange);
    const spareParts = sharedState.spareParts.use(!!dateRange);
    const vesselSystems = sharedState.vesselSystems.use(!!dateRange);
    const vesselLocations = sharedState.vesselLocations.use(!!dateRange);

    const csvConfig: CsvConfig<MaintenanceTaskCompleted> | undefined = useMemo(() => {
        if (!dateRange || !spareParts || !vesselSystems || !vesselLocations) {
            return undefined;
        }
        return [
            {
                name: 'Date Completed',
                value: (item) => formatCsvDate(item.whenCompleted)
            },
            {
                name: 'Task',
                value: (item) => item.task || ''
            },
            {
                name: 'Description',
                value: (item) => item.description || ''
            },
            {
                name: 'System',
                value: (item) => renderCategoryName(item.equipment?.systemId, vesselSystems) || ''
            },
            {
                name: 'Equipment',
                value: (item) => item.equipment?.equipment || ''
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.equipment?.locationId, vesselLocations) || ''
            },
            {
                name: 'Type',
                value: (item) => item.type === 'scheduled' ? 'Scheduled' : 'Job'
            },
            {
                name: 'Critical',
                value: (item) => item.isCritical ? 'Y' : 'N'
            },
            {
                name: 'Maintenance Tags',
                value: (item) => item.maintenanceTags?.join(', ') || ''
            },
            {
                name: 'Engine Hours',
                value: (item) => item.engineHours?.toString() || ''
            },
            {
                name: 'Completed By',
                value: (item) => renderFullNameForUserId(item.completedBy)
            },
            {
                name: 'Parts Used',
                value: (item) => formatCsvSpareParts(item.spareParts, spareParts.byId, 'used')
            },
            {
                name: 'Notes',
                value: (item) => item.notes || ''
            },
            {
                name: 'Job #',
                value: (item) => item.jobNum || ''
            }
        ];
    }, [dateRange, spareParts, vesselLocations, vesselSystems]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Maintenance-History'} onCompleted={onCompleted} config={csvConfig} data={maintenanceTasksCompleted?.all} />
};

export default MaintenanceHistoryCsv