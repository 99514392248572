import React, { useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, warnDays } from '../../../../lib/datesAndTime';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { WriteBatch } from "firebase/firestore";
import { SplittableBatch } from '../../../../lib/firebase';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { DangerousGood } from '../../../../shared-state/HealthSafety/dangerousGoods';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import EditDangerousGoods from '../EditDangerousGoods/EditDangerousGood'
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaToast from '../../../../components/SeaToast/SeaToast';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';

interface ViewDangerousGoodProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: DangerousGood,
}

const ViewDangerousGood: React.FC<ViewDangerousGoodProps> = ({showModal, setShowModal, selectedItem}) => {
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    return (
        <SeaModal
            title={
                <>
                    <span style={{ marginRight: '12px' }}>
                        {'Edit - ' + selectedItem?.name}
                    </span>
                    {selectedItem?.whenExpires &&
                        <SeaStatusDueDate
                            whenDue={selectedItem.whenExpires}
                            warnDays={warnDays.dangerousGoods[0]}
                            inline={true}
                        />
                    }
                </>
            }
            showModal={showModal}
            setShowModal={setShowModal}
            size='wide'
        >
            <div className="info-image">
                <SeaFileImage
                    files={selectedItem?.imageFiles}
                    size="medium"
                    showOthers
                />
            </div>
            <SeaGrid>
                <SeaGridCell label="Quantity" w="50">
                    {formatValue(selectedItem?.quantity)}
                </SeaGridCell>
                <SeaGridCell label="Hazardous" w="50">
                    {selectedItem?.isHazardous ? 'Yes' : 'No'}
                </SeaGridCell>
                <SeaGridCell label="Location" w="50">
                    {formatValue(selectedItem?.location)}
                </SeaGridCell>
                <SeaGridCell label="DG Class" w="50">
                    {formatValue(selectedItem?.class)}
                </SeaGridCell>
                <SeaGridCell label="Renewal Date" w="50">
                    {(selectedItem?.whenExpires) ? formatDate(selectedItem.whenExpires) : '-'}
                </SeaGridCell>
            </SeaGrid>
            <SeaGrid>
                <SeaGridCell label="SDS Document" w="100">
                    <SeaFileImage files={selectedItem?.msdsFiles} size="tiny" showOthers/>
                </SeaGridCell>
            </SeaGrid>

            <div className="view-modal-buttons">
                <RequirePermissions
                    role="dangerousGoodsRegister"
                    level={permissionLevels.EDIT}
                >
                    <SeaButton zone="white" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Dangerous Good</SeaButton>
                    <RequirePermissions
                        role="dangerousGoodsRegister"
                        level={permissionLevels.FULL}
                    >
                        <div className="spacer-wide"></div>
                        <SeaLinkButton
                            mode="standard-link"
                            onClick={(e) => {
                                deleteIfConfirmed(
                                    'dangerousGoods',
                                    selectedItem.id,
                                    (batch: WriteBatch | SplittableBatch) => {
                                        onCollectionUpdated(batch, 'dangerousGoods');
                                        setShowModal(false);
                                    },
                                    'dangerous good',
                                    selectedItem?.name,
                                    selectedItem.vesselIds
                                );
                            }}
                        >
                            Delete Dangerous Good
                        </SeaLinkButton>
                    </RequirePermissions>
                </RequirePermissions>
            </div>

            {showModal &&
                <>
                    <EditDangerousGoods
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={2}
                    />
                    <SeaToast message={toastMessage} setMessage={setToastMessage}/>
                </>
            }
        </SeaModal>
    );
};

export default ViewDangerousGood;
