import React, { useEffect, useMemo, useRef } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import { formatTime } from '../../../../lib/datesAndTime';
import { sharedState } from '../../../../shared-state/shared-state';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { VoyageTrip, VoyageTripStop } from '../../../../shared-state/VesselLogbook/voyages';
import { makeInitialCustomFields } from '../voyage-utils'
import Yup from '../../../../lib/yup';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaTabContent from '../../../../components/SeaTabContent/SeaTabContent';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaSelectOrOther from '../../../../components/SeaSelectOrOther/SeaSelectOrOther';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaLabel from '../../../../components/SeaLabel/SeaLabel';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaCustomFields from '../../../../components/SeaCustomFields/SeaCustomFields';
import './VoyageTrips.css';

  
export interface StopsErrors {
    any?: boolean;
    [key: number]: TripStopError;
}

export interface StopsTouched {
    all?: boolean;
    any?: boolean;
    [key: number]: boolean | TripStopTouched;
}

export interface TripsErrors {
    any?: boolean,
    stops?: StopsErrors | boolean,
    anyStops?: boolean,
    [tripIndex: number]: TripError
}
  
export interface TripError {
    any?: boolean;
    masterId?: string;
    departureFrom?: string;
    whenDeparted?: string;
    paxOn?: string;
    destinationTo?: string;
    whenArrived?: string;
    location?: string;
    stops?: StopsErrors | boolean;
}
  
export interface TripStopError {
    any?: boolean;
    location?: string;
    whenArrived?: string;
    paxOff?: string;
    paxOn?: string;
    whenDeparted?: string;
}
  
export interface TripsTouched {
    all?: boolean;
    any?: boolean;
    [key: number]: boolean | TripFieldTouched;
}
  
export interface TripFieldTouched {
    any?: boolean;
    crewInvolved?: boolean;
    masterId?: boolean;
    departureFrom?: boolean;
    whenDeparted?: boolean;
    paxOn?: boolean;
    destinationTo?: boolean;
    whenArrived?: boolean;
    stops?: StopsTouched;
    anyStops?: boolean;
    customText?: boolean;
}
  
export interface TripStopTouched {
    any?: boolean;
    location?: boolean;
    whenArrived?: boolean;
    paxOff?: boolean;
    paxOn?: boolean;
    whenDeparted?: boolean;
}

interface VoyageTripsProps {
    showModal: boolean,
    trips: VoyageTrip[],
    setTrips: (trips: VoyageTrip[]) => void,
    tripsErrors: TripsErrors,
    setTripsErrors: (tripsErrors: TripsErrors) => void,
    tripsTouched: TripsTouched,
    setTripsTouched: (tripsTouched: TripsTouched) => void,
    tripTab: string,
    setTripTab: (tripTab: string) => void,
    vesselUserOptions: { id: string, name: string }[]
}

const VoyageTrips: React.FC<VoyageTripsProps> = ({
    showModal,
    trips,
    setTrips,
    tripsErrors,
    setTripsErrors,
    tripsTouched,
    setTripsTouched,
    tripTab,
    setTripTab,
    vesselUserOptions
}) => {
    const vessel = sharedState.vessel.use(showModal);
    const voyages = sharedState.voyages.use(showModal);
    const tripsRef = useRef<any[]>();

    const setTripValue = (tripIndex: number, field: keyof Omit<TripFieldTouched, 'stops'>, value: any) => {
        if (tripsRef.current === undefined) return;
        const _trips = tripsRef.current.map((trip, index) => 
            index === tripIndex ? { ...trip, [field]: value } : trip
        );
        setTrips(_trips);
        touchTripField(tripIndex, field);
    };

    const setTripStopValue = (tripIndex: number, stopIndex: number, field: keyof TripStopTouched, value: any) => {
        console.log('<><><> setTripStopValue', tripIndex, stopIndex, field, value);
        if (tripsRef.current === undefined) return;
        const _trips = tripsRef.current.map((trip, index) => {
            if (index === tripIndex) {
                const stops = (trip as VoyageTrip).stops?.map((stop, sIndex) => 
                    sIndex === stopIndex ? { ...stop, [field]: value } : stop
                );
                return { ...trip, stops };
            }
            return trip;
        });
        setTrips(_trips);
        touchTripStopField(tripIndex, stopIndex, field);
    };

    const touchTripField = (tripIndex: number, field: keyof Omit<TripFieldTouched, 'stops'>) => {
        const touched = {...tripsTouched};
        touched.any = true;
        if (touched[tripIndex] === undefined) {
            touched[tripIndex] = {};
        }
        (touched[tripIndex] as TripFieldTouched).any = true;
        (touched[tripIndex] as TripFieldTouched)[field] = true;
        setTripsTouched(touched);
    };

    const touchTripStopField = (tripIndex: number, stopIndex: number, field: keyof TripStopTouched) => {
        const touched = {...tripsTouched};
        touched.any = true;
        if (touched[tripIndex] === undefined) {
            touched[tripIndex] = {};
        }
        (touched[tripIndex] as TripFieldTouched).any = true;
        if ((touched[tripIndex] as TripFieldTouched).stops === undefined) {
            (touched[tripIndex] as TripFieldTouched).stops = {
                any: true
            };
        }
        ((touched[tripIndex] as TripFieldTouched).stops as TripStopTouched).any = true;
        if ((touched[tripIndex] as TripFieldTouched).stops![stopIndex] === undefined) {
            (touched[tripIndex] as TripFieldTouched).stops![stopIndex] = {};
        }
        
        ((touched[tripIndex] as TripFieldTouched).stops![stopIndex] as TripStopTouched).any = true;
        ((touched[tripIndex] as TripFieldTouched).stops![stopIndex] as TripStopTouched)[field] = true;
        setTripsTouched(touched);
    };

    const touchTrips = (trips: VoyageTrip[]) => {
        const touched: TripsTouched = { all: true, any: true };
        for (let tripIndex = 0; tripIndex < trips.length; tripIndex++) {
            touched[tripIndex] = {
                any: true,
                masterId: true,
                // crewInvolved can't have a problem
                departureFrom: true,
                whenDeparted: true,
                destinationTo: true,
                whenArrived: true,
                paxOn: true
            };
        }
        setTripsTouched(touched);
    };

    const touchTripStops = (tripIndex: number) => {
        if (trips[tripIndex]?.stops && trips[tripIndex].stops!.length > 0) {
            const touched = {...tripsTouched};
            touched.any = true;
            if (touched[tripIndex] === undefined) {
                touched[tripIndex] = {};
            }
            if ((touched[tripIndex] as TripFieldTouched).stops === undefined) {
                (touched[tripIndex] as TripFieldTouched).stops = {};
            }
            (touched[tripIndex] as TripFieldTouched).any = true;
            for (let stopIndex = 0; stopIndex < trips[tripIndex].stops!.length; stopIndex++) {
                (touched[tripIndex] as TripFieldTouched).stops!.any = true;
                (touched[tripIndex] as TripFieldTouched).stops![stopIndex] = {
                    any: true,
                    location: true,
                    whenArrived: true,
                    paxOff: true,
                    paxOn: true,
                    whenDeparted: true
                };
            }
            setTripsTouched(touched);
        }
    };

    useEffect(() => {
        // Keep fresh reference to trips
        tripsRef.current = trips;
        const yupPax = Yup.number().integer().min(0);
        // Verify trips
        const errors: TripsErrors = {};
        trips.forEach((trip: VoyageTrip, tripIndex: number) => {
            const addError = (field: keyof Omit<TripError, 'any'>, error: string) => {
                errors.any = true;
                if (errors[tripIndex] === undefined) {
                    errors[tripIndex] = { any: false } as TripError;
                }
                errors[tripIndex].any = true;
                if (field !== 'stops') { 
                    errors[tripIndex][field] = error;
                }
            };
            if (!trip.masterId) addError('masterId', 'Field required');
            // crewInvolved can't have a problem
            if (!trip.departureFrom) addError('departureFrom', 'Field required');
            if (!trip.whenDeparted) addError('whenDeparted', 'Field required');
            if (!trip.destinationTo) addError('destinationTo', 'Field required');
            if (!trip.whenArrived) addError('whenArrived', 'Field required');
            if (trip.paxOn && !yupPax.isValidSync(trip.paxOn)) addError('paxOn', 'Invalid number');

            if (trip.stops && trip.stops?.length > 0) {
                trip.stops.forEach((stop: VoyageTripStop, stopIndex: number) => {
                    const addStopError = (field: keyof Omit<TripStopError, 'any'>, error: string) => {
                        errors.any = true;
                        errors.anyStops = true;
                        let stopError: TripError = errors[tripIndex] ?? undefined
                        if (stopError === undefined) {
                            stopError = {};
                        }
                        stopError.any = true;
                        if (stopError.stops === undefined) {
                            stopError.stops = {};
                        }
                        (stopError.stops as StopsErrors).any = true;
                        if ((stopError.stops as StopsErrors)[stopIndex] === undefined) {
                            (stopError.stops as StopsErrors)[stopIndex] = {};
                        }
                        (stopError.stops as StopsErrors)[stopIndex].any = true;
                        (stopError.stops as StopsErrors)[stopIndex][field] = error;
                        errors[tripIndex] = stopError;
                    };
                    if (!stop.location) addStopError('location', 'Field required');
                    if (!stop.whenArrived) addStopError('whenArrived', 'Field required');
                    if (stop.paxOn && !yupPax.isValidSync(stop.paxOn)) addStopError('paxOn', 'Invalid number');
                    if (stop.paxOff && !yupPax.isValidSync(stop.paxOff)) addStopError('paxOff', 'Invalid number');
                    if (!stop.whenDeparted) addStopError('whenDeparted', 'Field required');
                });
            }
        });
        setTripsErrors(errors);
    }, [trips, setTripsErrors]);

    const tabHasErrors = useMemo(() => {
        const hasErrors: TripError[] = [];
        for (let tripIndex = 0; tripIndex < trips.length; tripIndex++) {
            hasErrors[tripIndex] = {};
            if (tripsTouched && tripsErrors && tripsErrors[tripIndex]) {
                if (tripsTouched.all) {
                    hasErrors[tripIndex].any = tripsErrors[tripIndex].any;
                } else if (tripsTouched[tripIndex] && (tripsTouched[tripIndex] as TripFieldTouched).any) {
                    Object.keys(tripsErrors[tripIndex]).forEach((field: string) => {
                        if (field === 'any') return;
                        if (field === 'stops') {
                            if ((tripsErrors[tripIndex].stops as StopsErrors)?.any && (tripsTouched[tripIndex] as TripFieldTouched).stops) {
                                for (let stopIndex = 0; stopIndex < trips[tripIndex].stops!.length; stopIndex++) {
                                    if (((tripsErrors[tripIndex] as TripError).stops as StopsErrors)[stopIndex]) {
                                        Object.keys((tripsErrors[tripIndex].stops as StopsErrors)[stopIndex]).forEach((field: string) => {
                                            if (field === 'any') return;
                                            if ((tripsTouched[tripIndex] as TripFieldTouched).stops![stopIndex] && ((tripsTouched[tripIndex] as TripFieldTouched).stops![stopIndex] as TripStopTouched)[field as keyof TripStopTouched]) {
                                                hasErrors[tripIndex].any = true;
                                                hasErrors[tripIndex].stops = true;
                                            }
                                        });
                                    }
                                }
                            }
                        } else if ((tripsTouched[tripIndex] as TripFieldTouched)[field as keyof TripFieldTouched]) {
                            hasErrors[tripIndex].any = true;
                        }
                    });
                }
            }
        }
        return hasErrors;
    }, [trips, tripsErrors, tripsTouched]);

    const onDeleteLastTrip = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        confirmAction(`Are you sure you want to delete Trip ${trips.length}?`, `Yes, delete`).then(() => {
            let _trips = [...trips];
            _trips.splice(trips.length - 1, 1);
            setTrips(_trips);
            setTripTab(''+(_trips.length - 1));
            touchTrips(_trips);
        }).catch(() => {});
    };

    const onDeleteTripStop = (e: React.MouseEvent<Element, MouseEvent>, tripIndex: number, stopIndex: number) => {
        e.preventDefault();
        confirmAction(`Are you sure you want to delete Stop ${stopIndex + 1}?`, `Yes, delete`).then(() => {
            let _trips = [...trips];
            _trips[tripIndex].stops?.splice(stopIndex, 1);
            setTrips(_trips);
        }).catch(() => {});
    };

    const onAddTripTab = () => {
        setTripTab(''+trips.length);
        const previousTrip = trips[trips.length - 1];
        const previousVoyage = (voyages && voyages.all && voyages.all.length > 0) ? voyages.all[0] : undefined;
        setTrips([...trips, {
            masterId: previousTrip.masterId,
            crewInvolved: [...previousTrip.crewInvolved],
            departureFrom: previousTrip.destinationTo,
            whenDeparted: previousTrip.whenArrived,
            paxOn: '0',
            destinationTo: (previousVoyage?.destinationTo) ? (''+previousVoyage.destinationTo) : '',
            whenArrived: '',
            customText: makeInitialCustomFields(vessel?.logbookSettings?.customTextFields || [], [], ''),
        } as VoyageTrip]);
        if (tripsTouched.all) {
            const _tripsTouched = {...tripsTouched};
            delete _tripsTouched.all;
            setTripsTouched(_tripsTouched);
        }
    };

    const onAddTripStop = (index: number) => {
        if (tripsErrors?.anyStops) {
            touchTripStops(index);
            return;
        }
        const _trips = [...trips];
        if (_trips[index].stops === undefined) {
            _trips[index].stops = [];
        }
        _trips[index].stops?.push({
            location: '',
            whenArrived: formatTime(),
            paxOff: '0',
            paxOn: '0',
            whenDeparted: ''
        });
        setTrips(_trips);
        if (tripsTouched.all) {
            const _tripsTouched = {...tripsTouched};
            delete _tripsTouched.all;
            setTripsTouched(_tripsTouched);
        }
    };

    return (<>
        <SeaTabsGroup
            selectedTab={tripTab}
            setTab={setTripTab}
            mode="forms"
            micro={true}
            showPlus={true}
            plusText="Add Trip"
            disablePlus={tripsErrors?.any}
            //plsuDisabledMessage={}
            onAddTab={(e) => onAddTripTab()}
            onAddTabFailed={(e) => {
                touchTrips(trips);
            }}
            alwaysShowAllTabs={true}
        >
            {trips.map((trip, index: number) => {
                return (
                    <SeaTab
                        key={index}
                        tab={''+index}
                        hasErrors={tabHasErrors && tabHasErrors[index]?.any}
                        mode="forms"
                        width="84px"
                    >
                        Trip {index + 1}
                    </SeaTab>
                );
            })}
        </SeaTabsGroup>
        {trips.map((trip, index) => {
            return (
                <SeaTabContent
                    key={index}
                    tab={''+index}
                    selectedTab={tripTab}
                >
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="4">
                                <SeaSelect
                                    label="Master"
                                    name="masterId"
                                    value={trip.masterId}
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'masterId', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'masterId')}
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.masterId) ? tripsErrors[index]?.masterId : undefined}
                                >
                                    {vesselUserOptions?.map((option: {id: string, name: string}) => {
                                        return <IonSelectOption
                                            key={option.id}
                                            value={option.id}
                                        >   {option.name}
                                        </IonSelectOption>
                                    })}
                                </SeaSelect>
                            </IonCol>
                            <IonCol size="8">
                                <SeaMultiSelect
                                    label="Crew Onboard"
                                    values={trip.crewInvolved}
                                    setValues={(userIds: string[]) => {
                                        setTripValue(index, 'crewInvolved', userIds);
                                    }}
                                    options={vesselUserOptions}
                                    useAllOption={false}
                                    emptyText="Not Set"
                                    mode="popover"
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaSelectOrOther
                                    label="Departure Location"
                                    name="departureFrom"
                                    options={vessel?.logbookSettings?.destinations || []}
                                    value={trip.departureFrom}
                                    otherPlaceholder="Add New Location"
                                    //onchange={handleChange}
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'departureFrom', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'departureFrom')}
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.departureFrom) ? tripsErrors[index]?.departureFrom : undefined}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaDatetime
                                    label="Departure Time"
                                    value={trip.whenDeparted}
                                    inputType='time'
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'whenDeparted', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'whenDeparted')}
                                    id={`whenDeparted${index}`}
                                    setToCurrentTime={() => {
                                        setTripValue(index, 'whenDeparted', formatTime());
                                    }}
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.whenDeparted) ? tripsErrors[index]?.whenDeparted : undefined}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="PAX On"
                                    name="paxOn"
                                    value={trip.paxOn}
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'paxOn', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'paxOn')}
                                    zone="white"
                                    type="number"
                                    inputmode="numeric"
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.paxOn) ? tripsErrors[index]?.paxOn : undefined}
                                />
                            </IonCol>
                            <IonCol size="12">
                                <div className="form-line"></div>
                            </IonCol>
                            <IonCol size="12">
                                <SeaLabel>Trip stops / anchorages</SeaLabel>
                                <div style={{ height: '4px' }}></div>
                                {trip.stops && trip.stops?.length > 0 && trip.stops.map((stop, stopIndex) => {
                                    return (
                                        <React.Fragment key={stopIndex}>
                                            <div>
                                                <h2 style={{
                                                    margin: '0px 0px 6px 0px',
                                                    fontSize: '14px',
                                                    color: 'var(--bg-header)'
                                                }}>Stop {stopIndex + 1}</h2>
                                            </div>
                                            <div className="columns" style={{ marginBottom: '12px', marginTop: '-4px' }}>
                                                <div>
                                                    <div className="trip-stop-block"></div>
                                                </div>
                                                <div>
                                                    <IonGrid className="trip-stops form-grid">
                                                        <IonRow>
                                                            <IonCol size="12" style={{ paddingBottom: '4px' }}>
                                                                
                                                            </IonCol>
                                                            <IonCol size="3">
                                                                <SeaSelectOrOther
                                                                    label="Location"
                                                                    name="location"
                                                                    options={vessel?.logbookSettings?.stops || []}
                                                                    value={stop.location}
                                                                    otherPlaceholder="Add New Stop / Anchorage"
                                                                    onchange={(e: CustomEvent<any>) => {
                                                                        console.log('<><><> e', e)
                                                                        setTripStopValue(index, stopIndex, 'location', e.detail.value);
                                                                    }}
                                                                    onblur={(e) => touchTripStopField(index, stopIndex, 'location')}
                                                                    error={
                                                                        (tripsTouched.all || ((tripsTouched[index] as TripFieldTouched)?.stops && ((tripsTouched[index] as TripFieldTouched).stops?.[stopIndex] as TripStopTouched)?.location)) &&
                                                                        tripsErrors[index]?.stops ? ((tripsErrors[index].stops as TripsErrors)?.[stopIndex])?.location : undefined
                                                                    }
                                                                />
                                                            </IonCol>
                                                            <IonCol size="3">
                                                                <SeaDatetime
                                                                    label="Arrival Time"
                                                                    value={stop.whenArrived}
                                                                    inputType='time'
                                                                    onchange={(e: CustomEvent<any>) => {
                                                                        setTripStopValue(index, stopIndex, 'whenArrived', e.detail.value);
                                                                    }}
                                                                    onblur={(e) => touchTripStopField(index, stopIndex, 'whenArrived')}
                                                                    id={`whenArrived${index}-${stopIndex}`}
                                                                    setToCurrentTime={() => {
                                                                        setTripStopValue(index, stopIndex, 'whenArrived', formatTime());
                                                                    }}
                                                                    error={
                                                                        (tripsTouched.all || ((tripsTouched[index] as TripFieldTouched)?.stops && ((tripsTouched[index] as TripFieldTouched).stops?.[stopIndex] as TripStopTouched)?.whenArrived)) &&
                                                                        tripsErrors[index]?.stops ? (tripsErrors[index].stops as TripsErrors)[stopIndex]?.whenArrived : undefined
                                                                    }
                                                                />
                                                            </IonCol>
                                                            <IonCol size="3">
                                                                <div className="columns">
                                                                    <div>
                                                                        <SeaInput
                                                                            label="PAX Off"
                                                                            name="paxOff"
                                                                            value={stop.paxOff}
                                                                            onchange={(e: CustomEvent<any>) => {
                                                                                setTripStopValue(index, stopIndex, 'paxOff', e.detail.value);
                                                                            }}
                                                                            onblur={(e) => touchTripStopField(index, stopIndex, 'paxOff')}
                                                                            zone="white"
                                                                            type="number"
                                                                            inputmode="numeric"
                                                                            error={
                                                                                (tripsTouched.all || ((tripsTouched[index] as TripFieldTouched)?.stops && ((tripsTouched[index] as TripFieldTouched).stops![stopIndex] as TripStopTouched)?.paxOff)) &&
                                                                                tripsErrors[index]?.stops ? ((tripsErrors[index].stops as StopsErrors)[stopIndex] as TripStopError)?.paxOff : undefined
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div style={{ minWidth: 'var(--grid-gutter)', maxWidth: 'var(--grid-gutter)' }}></div>
                                                                    <div>
                                                                        <SeaInput
                                                                            label="PAX On"
                                                                            name="paxOn"
                                                                            value={stop.paxOn}
                                                                            onchange={(e: CustomEvent<any>) => {
                                                                                setTripStopValue(index, stopIndex, 'paxOn', e.detail.value);
                                                                            }}
                                                                            onblur={(e) => touchTripStopField(index, stopIndex, 'paxOn')}
                                                                            zone="white"
                                                                            type="number"
                                                                            inputmode="numeric"
                                                                            error={
                                                                                (tripsTouched.all || ((tripsTouched[index] as TripFieldTouched)?.stops && ((tripsTouched[index] as TripFieldTouched).stops![stopIndex] as TripStopTouched)?.paxOn)) &&
                                                                                tripsErrors[index]?.stops ? ((tripsErrors[index].stops as StopsErrors)[stopIndex] as TripStopError)?.paxOn : undefined
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </IonCol>
                                                            <IonCol size="3">
                                                                <SeaDatetime
                                                                    label="Departure Time"
                                                                    value={stop.whenDeparted}
                                                                    inputType='time'
                                                                    onchange={(e: CustomEvent<any>) => {
                                                                        setTripStopValue(index, stopIndex, 'whenDeparted', e.detail.value);
                                                                    }}
                                                                    onblur={(e) => touchTripStopField(index, stopIndex, 'whenDeparted')}
                                                                    id={`whenDeparted${index}-${stopIndex}`}
                                                                    setToCurrentTime={() => {
                                                                        setTripStopValue(index, stopIndex, 'whenDeparted', formatTime());
                                                                    }}
                                                                    error={
                                                                        (tripsTouched.all || ((tripsTouched[index] as TripFieldTouched)?.stops && ((tripsTouched[index] as TripFieldTouched).stops![stopIndex] as TripStopTouched)?.whenDeparted)) &&
                                                                        tripsErrors[index]?.stops ? ((tripsErrors[index].stops as StopsErrors)[stopIndex] as TripStopError)?.whenDeparted : undefined
                                                                    }
                                                                />
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                    <div style={{
                                                        textAlign: 'right',
                                                        color: 'var(--ion-color-primary)'
                                                    }}>
                                                        {/* <SeaLinkButton mode="standard-link" onClick={(e: React.MouseEvent<Element, MouseEvent>) => onDeleteTripStop(e, index, stopIndex)}>Delete Stop</SeaLinkButton> */}

                                                        <div
                                                            className="clickable pushy"
                                                            onClick={(e: React.MouseEvent<Element, MouseEvent>) => onDeleteTripStop(e, index, stopIndex)}
                                                            style={{
                                                                zIndex: 1,
                                                                position: 'relative',
                                                                display: 'inline-block'
                                                            }}
                                                        >
                                                            <SeaIcon icon='trash' slot="icon-only" />
                                                            <span style={{
                                                                fontWeight: '500',
                                                                paddingLeft: '4px',
                                                                position: 'relative',
                                                                top: '-5px'
                                                            }}>
                                                                Delete Stop
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                                <div
                                    className={`trip-stops sea-plus-button pushy no-select${(tripsErrors?.anyStops) ? ' disabled' : ''}`}
                                    onClick={(e) => onAddTripStop(index)}
                                >
                                    <div className="columns">
                                        <div>
                                            <SeaIcon slot='icon-only' icon='add' forceFontSize='30px'/>
                                        </div>
                                        <div style={{
                                            minWidth: '180px',
                                            paddingLeft: '8px',
                                            paddingTop: '6px',
                                            fontWeight: '500'
                                        }}>
                                            Add Stop / Anchorage
                                        </div>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="12">
                                <div className="form-line"></div>
                            </IonCol>
                            <IonCol size="4">
                                <SeaSelectOrOther
                                    label="Arrival Location"
                                    name="destinationTo"
                                    options={vessel?.logbookSettings?.destinations || []}
                                    value={trip.destinationTo}
                                    otherPlaceholder="Add New Location"
                                    //onchange={handleChange}
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'destinationTo', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'destinationTo')}
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.destinationTo) ? tripsErrors[index]?.destinationTo : undefined}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaDatetime
                                    label="Arrival Time"
                                    value={trip.whenArrived}
                                    inputType='time'
                                    onchange={(e: CustomEvent<any>) => {
                                        setTripValue(index, 'whenArrived', e.detail.value);
                                    }}
                                    onblur={(e) => touchTripField(index, 'whenArrived')}
                                    id={`whenArrived${index}`}
                                    setToCurrentTime={() => {
                                        setTripValue(index, 'whenArrived', formatTime());
                                    }}
                                    error={(tripsTouched.all || (tripsTouched[index] as TripFieldTouched)?.whenArrived) ? tripsErrors[index]?.whenArrived : undefined}
                                />
                            </IonCol>
                            
                            {trip.customText ? (
                                <>
                                    <IonCol size="12">
                                        <div className="form-line"></div>
                                    </IonCol>
                                    <SeaCustomFields
                                        data={trip.customText}
                                        setData={(data) => {
                                            setTripValue(index, 'customText', data as VoyageTrip[]);
                                        }}
                                        inputmode="text"
                                        type="text"
                                        maxLength={500}
                                    />
                                </>

                            ) : null}
                            
                            

                            {trips?.length > 1 && (index === trips.length - 1) && (
                                <>
                                    {trip.customText ? (
                                        <IonCol size="12">
                                            <div className="form-line"></div>
                                        </IonCol>
                                    ) : null}
                                    <IonCol size="4">
                                        <SeaLinkButton mode="standard-link" onClick={(e: React.MouseEvent<Element, MouseEvent>) => onDeleteLastTrip(e)}>Delete Trip</SeaLinkButton>
                                    </IonCol>
                                </>
                            )}
                        </IonRow>
                    </IonGrid>

                </SeaTabContent>
            );
        })}
    </>);
};

export default VoyageTrips;
