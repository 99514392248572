import { useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { Job } from "../../../shared-state/VesselMaintenance/jobs";
import { renderCategoryName } from '../../../lib/categories';
import { formatEmailReminder } from "../../../lib/datesAndTime";
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type JobListCsvProps = {
    onCompleted: () => void
}

const JobListCsv = ({ onCompleted }: JobListCsvProps) => {
    const jobs = sharedState.jobs.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const vesselSystems = sharedState.vesselSystems.use();

    const csvConfig: CsvConfig<Job> | undefined = useMemo(() => {
        if (!vesselLocations || !vesselSystems) {
            return undefined
        }
        return [
            {
                name: 'Task',
                value: (job) => job.task
            },
            {
                name: 'Description',
                value: (job) => job.description
            },
            {
                name: 'Job #',
                value: (job) => job.jobNum
            },
            {
                name: 'Assigned To',
                value: (job) => job.assignedTo?.name || ''
            },
            {
                name: 'Job Tags',
                value: (job) => (job.tags || []).join(', ')
            },
            {
                name: 'System',
                value: (job) => renderCategoryName(job.equipment?.systemId, vesselSystems)
            },
            {
                name: 'Equipment',
                value: (job) => job.equipment?.equipment || ''
            },
            {
                name: 'Location',
                value: (job) => renderCategoryName(job.equipment?.locationId, vesselLocations)
            },
            {
                name: 'Critical',
                value: (job) => formatCsvBoolean(job.equipment?.isCritical)
            },
            {
                name: 'Maintenance Tags',
                value: (job) => (job.maintenanceTags || [])?.join(', ')
            },
            {
                name: 'Date Added',
                value: (job) => formatCsvDate(job.whenAdded)
            },
            {
                name: 'Due Date',
                value: (job) => formatCsvDate(job.whenDue)
            },
            {
                name: 'Email Reminder',
                value: (job) => formatEmailReminder(job.emailReminder)
            },
            {
                name: 'Estimated Job Cost',
                value: (job) => job.estimatedCost?.toString() || ''
            }
        ];
    }, [vesselLocations, vesselSystems]);

    return <SeaCSVGenerator name={'Job-List'} onCompleted={onCompleted} config={csvConfig} data={jobs?.prioritised} />
};

export default JobListCsv