import { and, collection, onSnapshot, or, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from 'react';
import { LinkType } from './links';

//
// Loads all links containing multiple specific itemIds.
//

export const useMultipleLinks = (itemIds?: string[]) => {
    const [links, setLinks] = useState<LinkType[]>();

    useEffect(() => {
        setLinks(undefined);
        if (itemIds && itemIds.length > 0) {
            const unsubscribes: (() => void)[] = [];

            const queryPromises = itemIds.map((itemId) => {
                return new Promise<LinkType[]>((resolve) => {
                    const unsubscribe = onSnapshot(
                        query(
                            collection(firestore, 'links'),
                            and(
                                or(
                                    where('aId', '==', itemId),
                                    where('bId', '==', itemId)
                                ),
                                where('state', '==', 'active')
                            ),
                            orderBy('aType', 'asc')
                        ),
                        (snap) => {
                            const itemLinks: LinkType[] = snap.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data()
                            } as LinkType));
                            resolve(itemLinks);
                        }
                    );
                    unsubscribes.push(unsubscribe);
                });
            });

            Promise.all(queryPromises).then((results) => {
                const allLinks = results.flat();
                setLinks(allLinks);
            });

            return () => {
                unsubscribes.forEach((unsubscribe) => unsubscribe());
            };
        }
    }, [itemIds]);

    return links;
};