import { useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { Equipment } from "../../../shared-state/VesselMaintenance/equipment";
import { renderCategoryName } from '../../../lib/categories';
import { renderCamelCase } from '../../../lib/util';
import { formatInterval } from "../../../lib/datesAndTime";
import { ScheduledMaintenanceTask } from '../../../shared-state/VesselMaintenance/maintenanceSchedule';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type EquipmentListCsvProps = {
    onCompleted: () => void
}

const EquipmentListCsv = ({onCompleted}: EquipmentListCsvProps) => {
    const equipment = sharedState.equipment.use();
    const vesselSystems = sharedState.vesselSystems.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const contacts = sharedState.contacts.use();
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use();

    const csvConfig: CsvConfig<Equipment> | undefined = useMemo(() => {
        if (!vesselLocations || !vesselSystems  || !contacts || !scheduledMaintenanceTasks) {
            return undefined;
        }
        return [
            {
                name: 'System',
                value: (item) => renderCategoryName(item.systemId, vesselSystems)
            },
            {
                name: 'Equipment',
                value: (item) => item.equipment
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.locationId, vesselLocations)
            },
            {
                name: 'Critical',
                value: (item) => formatCsvBoolean(item.isCritical)
            },
            {
                name: 'Make',
                value: (item) => item.make || ''
            },
            {
                name: 'Model',
                value: (item) => item.model || ''
            },
            {
                name: 'Serial #',
                value: (item) => item.serial || ''
            },
            {
                name: 'Equipment Notes',
                value: (item) => item.notes || ''
            },
            {
                name: 'Contact/s',
                value: (item) => formatCsvNamesFromIds(item.contactIds, contacts.byId)
            },
            {
                name: 'Scheduled Tasks',
                items: (item) => scheduledMaintenanceTasks.byEquipmentId[item.id],
                columns: () => [
                    {
                        name: 'Task',
                        value: (item: ScheduledMaintenanceTask) => item.task || ''
                    },
                    {
                        name: 'Interval',
                        value: (item: ScheduledMaintenanceTask) => item.intervalType === 'engineHours' ? item.intervalEngineHours?.toString() || '' : formatInterval(item.intervalWeekMonth)
                    },
                    {
                        name: 'Next Due',
                        value: (item: ScheduledMaintenanceTask) => formatCsvDate(item.whenDue)
                    },
                    {
                        name: 'Status',
                        value: (item: ScheduledMaintenanceTask) => renderCamelCase(item.state)
                    }
                    
                ]
            },
        ];
    }, [contacts, scheduledMaintenanceTasks, vesselLocations, vesselSystems]);


    return <SeaCSVGenerator name={'Equipment-List'} onCompleted={onCompleted} config={csvConfig} data={equipment?.all} />;
};

export default EquipmentListCsv