import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { sharedState } from "../shared-state";
import { DrillReport } from './drillReports';

export const useVesselDrillReports = (dateRange: DateRange | undefined): DrillReport[] | undefined => {
    const vesselId = sharedState.vesselId.use();
    const [drillReports, setDrillReports] = useState<DrillReport[]>();

    useEffect(() => {
        setDrillReports(undefined);
    
        if (vesselId && dateRange) {
            let _drillReports: DrillReport[] = [];
    
            return onSnapshot(
                query(
                    collection(firestore, 'drillReports'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenCompleted', '>=', dateRange.from),
                    where('whenCompleted', '<=', dateRange.to),
                    orderBy('whenCompleted', 'desc')
                ),
                (snap) => {
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as DrillReport;
                        _drillReports.push(item);
                    });
                    setDrillReports(_drillReports);
                },
                (error) => {
                    console.log(`Failed to fetch drill reports for vesselId=${vesselId}`, error.message, error, {
                        vesselId,
                        dateRange,
                    });
                }
            );
        }
    }, [vesselId, dateRange]);

    return drillReports;
};