import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles, pixelsToCm } from '../../lib/pdf';
import { formatValue, formatTextAreaText, formatVessels } from '../../lib/util';
import { formatDateLonger, formatTime, formatDatetimeForCsv } from '../../lib/datesAndTime';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../lib/files';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { renderFullName } from '../../shared-state/Core/users';
import { SafetyMeetingReport } from '../../shared-state/HealthSafety/safetyMeetingReports';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';

interface HealthSafetyMeetingModalPdfProps {
    onCompleted: () => void,
    selectedItem: SafetyMeetingReport,
    signature?: any,
}

const HealthSafetyMeetingModalPdf: React.FC<HealthSafetyMeetingModalPdfProps> = ({
    onCompleted,
    selectedItem,
    signature
}) => {
    const vessels = sharedState.vessels.use(true);
    const safetyMeetingJobs = sharedState.safetyMeetingJobs.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Meeting Report - ${formatDatetimeForCsv(selectedItem.whenMeeting)}`;
        }
        return '';
    }, [selectedItem]);

    const removeBeforeUnderscore = (str: string) => {
        const parts = str.split('_');
        return parts.slice(1).join('_');
    }

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem.files || [], setImagesLoaded);
    }, [getImageSrcs, selectedItem.files]);

    const MakePDF = useCallback(() => {
        let hasJobs = false;
        if (!selectedItem) return (<></>);
        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={reportTitle}
                        subHeading=''
                        userFullName={renderFullName()}
                    />

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Date</Text>
                            <Text>
                                {(formatDateLonger(selectedItem?.whenMeeting) + ' ' + formatTime(selectedItem?.whenMeeting))}
                            </Text>
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Vessels / Facilities</Text>
                            <Text>
                                {(formatVessels(selectedItem?.vesselIds, vessels))}
                            </Text>
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Personnel Present</Text>
                            <Text>
                                {selectedItem?.personnelPresentIds?.map((crewId: string, index: number) => {
                                    if (renderFullNameForUserId(crewId)) {
                                        if (index === 0) {
                                            return renderFullNameForUserId(crewId)
                                        } else {
                                            return `, ${renderFullNameForUserId(crewId)}`
                                        }
                                    } else {
                                        return '-'
                                    }
                                })}
                            </Text>
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Notes from meeting</Text>
                            <Text>
                                {formatValue(formatTextAreaText(selectedItem?.notes))}
                            </Text>
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Jobs Created</Text>
                            {hasJobs = false}
                            {selectedItem?.jobIds && selectedItem.jobIds.map((jobId: string) => {
                                if (safetyMeetingJobs?.byId[jobId]) {
                                    hasJobs = true;
                                    return <Text key={jobId}>
                                        {safetyMeetingJobs.byId[jobId].task}
                                    </Text>;
                                }
                                return undefined;
                            })}
                            {!hasJobs && <Text>-</Text>}
                        </View>
                    </View>

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Images / Files</Text>
                            <Text>
                                {selectedItem?.files && selectedItem?.files?.length > 0 ? selectedItem?.files?.map((file: any, index: number) => {
                                    if (index === 0) {
                                        return removeBeforeUnderscore(file);
                                    } else {
                                        return `, ${removeBeforeUnderscore(file)}`;
                                    }
                                }) : ' - '}
                            </Text>
                        </View>
                    </View>

                    {/* Signature */}
                    <View style={pdfStyles.greyLine} />
                    <View wrap={false} style={pdfStyles.rowNoSpace}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Signed off by</Text>
                        </View>
                    </View>
                    {signature ? (
                        <View wrap={false} style={pdfStyles.rowNoSpace}>
                            <View
                                style={{
                                    padding: '0 0 0 0',
                                    width: pixelsToCm(352) + 'cm',
                                    height: pixelsToCm(202) + 'cm'
                                }}
                                wrap={false}
                            >
                                <Image
                                    src={signature}
                                    style={{
                                        width: pixelsToCm(350) + 'cm',
                                        height: pixelsToCm(200) + 'cm'
                                    }}
                                />
                            </View>
                        </View>
                    ) : (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text>-</Text>
                            </View>
                        </View>
                    )}

                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [reportTitle, safetyMeetingJobs?.byId, selectedItem, signature, vessels]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="HealthSafetyMeetingModal"
            />
        );
    } else return <></>
};
export default HealthSafetyMeetingModalPdf;