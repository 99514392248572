import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { TrainingTask } from "../Crew/trainingTasks";

export type DashboardVesselCrewTrainingData = {
    top5: TrainingTask[],
    totalExpiredOrDueSoon: number
};

export const dashboardVesselCrewTrainingConfig: SharedStateConfig<DashboardVesselCrewTrainingData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.dashboardVesselCrewTraining.current?.totalExpiredOrDueSoon ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (
            vesselId &&
            canView('crewTraining')
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'trainingTasks'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenDue', '<', getDayOffsetMillis(warnDays.crewTraining[0])),
                    orderBy('whenDue', 'asc')
                ),
                (snap) => {
                    done();
                    const upcoming = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as TrainingTask;
                    });
                    
                    set({
                        top5: upcoming.slice(0, 5),
                        totalExpiredOrDueSoon: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access crew training for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    }
};