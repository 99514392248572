import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { currentIncidentStatuses, renderIncidentState } from '../../../../pages/HealthSafety/Incidents/incidents-util';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Incident } from '../../../../shared-state/HealthSafety/incidents';
import reporting from '../../../../lib/reporting';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import IncidentStatusReportPdf from '../../../../exports/Reporting/HealthSafety/IncidentStatusReportPdf';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import './ViewIncidentStatusReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const incidentStatusOptions = Object.keys(currentIncidentStatuses).map((statusId: string) => {
    return {
        id: statusId,
        name: currentIncidentStatuses[statusId]
    };
});

interface ViewIncidentStatusReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredIncidents?: Incident[],
    categories?: string[],
    selectedIncidentStatuses?: string[],
    setSelectedIncidentStatuses?: React.Dispatch<React.SetStateAction<string[]>>
}

const ViewIncidentStatusReport: React.FC<ViewIncidentStatusReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredIncidents,
    categories,
    selectedIncidentStatuses,
    setSelectedIncidentStatuses
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('IncidentStatusReport', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Incident/Event Statuses"
                        values={selectedIncidentStatuses}
                        setValues={setSelectedIncidentStatuses}
                        options={incidentStatusOptions}
                        useAllOption={true}
                        //required={true}
                        //isSubmitting={isSubmitting}
                        emptyText="Not Set"
                    />
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                //units={demoIncidentsByStatus.units}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.incidentStatuses}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="incidents/events"
                    isLoading={!filteredIncidents}
                    hasNoData={filteredIncidents?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`incidents by-status has-status ${(filteredIncidents && filteredIncidents.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div>Date</div>
                        <div>Name</div>
                        <div>Vessel</div>
                        <div>Status</div>
                    </div>
                    {mapArrayWithLimit(filteredIncidents, (incident) => {
                        return (
                            <div
                                key={incident.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    {formatDate(incident.whenEvent)}
                                </div>
                                <div>
                                    {formatValue(incident.name)}
                                </div>
                                <div>
                                    {renderVesselName(incident.vesselId)}
                                </div>
                                <div className="truncate">
                                    {renderIncidentState(incident.state)}
                                </div>
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>
            {showModal && exportType === 'pdf' &&
                <IncidentStatusReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    categories={categories}
                    filteredIncidents={filteredIncidents}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
        </SeaModal>
    );
};

export default ViewIncidentStatusReport;
