import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { Drill } from '../../../../shared-state/VesselSafety/drills';
import { addMissingLinks, handleLinkUpdates, renderLinkName } from '../../../../lib/links';
import { LinkType } from '../../../../shared-state/Core/links';
import SeaLinkMultiList, { LinkOptionsType } from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import Yup from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditDrillProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: Drill,
    level?: number,
    existingLinks?: LinkType[]
}

const EditDrill: React.FC<EditDrillProps> = ({showModal, setShowModal, itemToUpdate, level = 1, existingLinks}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const drillReports = sharedState.drillReports.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    // TODO: Add links
    // const vesselSOPs = sharedState.vesselSOPs.use(showModal);
    // const vesselDocuments = sharedState.vesselDocuments.use(showModal);
    // const risks = sharedState.risks.use(showModal);
    // const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    // const [links, setLinks] = useState<LinkType[]>([]);
    // const [linkOptions, setLinkOptions] = useState<LinkOptionsType[] | undefined>(undefined);

    // handles generating link options and adding links that link to items not in sharedState
    // useEffect(() => {
    //     const fetchLinkOptions = () => {
    //         if (!vesselSOPs || !vesselDocuments || !risks || !scheduledMaintenanceTasks) {
    //             setLinkOptions(undefined);
    //             return Promise.resolve();
    //         }
    
    //         const options: LinkOptionsType[] = [
    //             {
    //                 collection: 'SOPs',
    //                 label: 'SOPs',
    //                 items: Object.entries(vesselSOPs.byId).map(([id, sop]) => ({
    //                     id: id,
    //                     name: renderLinkName(sop.title, sop.state)
    //                 }))
    //             },
    //             // {
    //             //     collection: 'vesselDocuments',
    //             //     label: 'Documents',
    //             //     items: Object.entries(vesselDocuments.byId).map(([id, document]) => ({
    //             //         id,
    //             //         name: document.title
    //             //     }))
    //             // },
    //             // {
    //             //     collection: 'risks',
    //             //     label: 'Risk Assessments',
    //             //     items: Object.entries(risks.byId).map(([id, risk]) => ({
    //             //         id,
    //             //         name: risk.name
    //             //     }))
    //             // },
    //             // {
    //             //     collection: 'scheduledMaintenanceTasks',
    //             //     label: 'Maintenance Tasks',
    //             //     items: Object.entries(scheduledMaintenanceTasks.byId).map(([id, task]) => ({
    //             //         id,
    //             //         name: task.task || 'placeholder task'
    //             //     }))
    //             // },
    //             {
    //                 collection: 'external',
    //                 label: 'External Links',
    //                 items: []
    //             }
    //         ];
    
    //         if (existingLinks) {
    //             return addMissingLinks(existingLinks, options, 'drills')
    //                 .then(() => {
    //                     setLinkOptions(options);
    //                 });
    //         } else {
    //             setLinkOptions(options);
    //             return Promise.resolve();
    //         }
    //     };
    
    //     fetchLinkOptions()
    //         .catch((error) => {
    //             console.error('Error fetching link options:', error);
    //         });
    // }, [vesselSOPs, vesselDocuments, risks, scheduledMaintenanceTasks, existingLinks]);


    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                interval: itemToUpdate.interval ? ''+itemToUpdate.interval : '',
            };
        } else {
            return {
                name: '',
                interval: '',
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        // TODO: Add links
        // setLinks(existingLinks ?? []);
        setValues(initialValues);
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid, validateForm } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            interval: Yup.string().max(4).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('drill')) { return; }
            if (!vesselId) {
                throw new Error("No vesselId");
            }
            // Process form
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'drills');

            let itemId: string;

            if (itemToUpdate) {
                itemId = itemToUpdate.id;
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = itemToUpdate.id;
                batch.set(
                    doc(firestore, 'drills', itemToUpdate.id),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        name: data.name,
                        interval: data.interval,
                        whenDue: itemToUpdate.whenLastCompleted ? addInterval(itemToUpdate.whenLastCompleted, data.interval) : deleteValue,
                        touched: serverTimestamp()
                    } as Partial<Drill>,
                    { merge: true }
                );
                logAction(
                    batch,
                    'Update',
                    'drills',
                    itemToUpdate.id,
                    data.name,
                    [itemToUpdate.vesselId]
                );

                // If interval changed we'll need to recalculate whenDue for all previously created reports
                if (itemToUpdate.interval !== data.interval) {
                    const reports = drillReports?.byDrillId[itemToUpdate.id]
                    reports?.forEach((report) => {
                        if (report?.drills?.length > 0) {
                            const newDrills = [...report.drills];
                            let needsUpdating = false;
                            for (let i = 0; i < newDrills.length; i++) {
                                if (newDrills[i]?.id === itemToUpdate.id) {
                                    newDrills[i].whenDue = addInterval(report.whenCompleted, data.interval);
                                    needsUpdating = true;
                                }
                            }
                            if (needsUpdating) {
                                batch.set(
                                    doc(firestore, 'drillReports', report.id),
                                    {
                                        drills: [...newDrills],
                                        touched: serverTimestamp()
                                    },
                                    { merge: true }
                                );
                            }
                        }
                    });
                    onCollectionUpdated(batch, 'drillReports');
                };

            } else {
                const newRef = doc(collection(firestore, 'drills'));
                itemId = newRef.id;
                batchTrace.exampleOperation = 'create';
                batchTrace.exampleDocId = newRef.id;
                batch.set(newRef, {
                    vesselId: vesselId,
                    addedBy: userId,
                    name: data.name,
                    interval: data.interval,
                    whenAdded: batchTrace.whenAction,
                    state: 'active',
                    touched: serverTimestamp()
                } as Omit<Drill, 'id'>);
                logAction(
                    batch,
                    'Add',
                    'drills',
                    newRef.id,
                    data.name,
                    [vesselId]
                );
            }

            onCollectionUpdated(batch, 'drills');
            
            // TODO: Add links
            // handleLinkUpdates(
            //     batch,
            //     links,
            //     existingLinks,
            //     itemId,
            //     'drills'
            // )

            batchTrace.data = {
                data,
                itemToUpdate
            };
            batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} drill ${data?.name}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    // Validate on every change - required as interval was showing required error despite being defined...
    useEffect(() => {
        validateForm(values);
    }, [values, validateForm]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);


    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Drill' : 'Add New Drill'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size={'thin'}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="Drill name"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaSelectInterval
                                label="Interval"
                                name="interval"
                                value={values.interval}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.interval && errors.interval ? errors.interval : ''}
                            />
                        </IonCol>
                    </IonRow>
                    <div className="end-info-line"></div>
                    {/* TODO: Add links */}
                    {/* <IonRow>
                        <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection='drills'
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                options={linkOptions}
                                deleteable
                                confirmDelete
                                level={level+1}
                            />
                        </IonCol>
                    </IonRow> */}
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Drill' : 'Save New Drill'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditDrill;
