import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { formatValue, formatTextAreaText } from '../../../lib/util';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../../lib/files';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { ScheduledMaintenanceTask } from '../../../shared-state/VesselMaintenance/maintenanceSchedule';
import { renderFullName } from '../../../shared-state/Core/users';
import { formatInterval } from '../../../lib/datesAndTime';

interface MaintenanceScheduleTaskPdfProps {
    onCompleted: () => void,
    selectedItem: ScheduledMaintenanceTask,
    signature?: any,
}

const MaintenanceScheduleTaskPdf: React.FC<MaintenanceScheduleTaskPdfProps> = ({
    onCompleted,
    selectedItem,
    signature
}) => {
    const vessel = sharedState.vessel.use(true);
    const contacts = sharedState.contacts.use(true);
    const equipment = sharedState.equipment.use(true);
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use(true);
    const vesselSystems = sharedState.vesselSystems.use(true);
    const vesselLocations = sharedState.vesselLocations.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return (selectedItem?.equipment?.equipment ?? '') + (selectedItem?.equipment?.state === 'deleted' ? ' (deleted)' : '');
        }
        return '';
    }, [selectedItem]);

    const selectedEquipment = useMemo(() => {
        if (selectedItem?.equipmentId && equipment?.byId[selectedItem.equipmentId]) {
            return equipment.byId[selectedItem.equipmentId];
        }
        return undefined;
    }, [equipment, selectedItem]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem?.equipment?.files? selectedItem?.equipment?.files : [], setImagesLoaded);
    }, [getImageSrcs, selectedItem?.equipment?.files]);

    const MakePDF = useCallback(() => {
        if (!selectedItem) return (<></>);
        return (
            <Document
                title={reportTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={reportTitle}
                        subHeading=''
                        userFullName={renderFullName()}
                    />

                    {/* Task Title and Interval */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Task Title</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.task))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Interval</Text>
                            <Text>
                                {(selectedItem.intervalType === 'weekMonth' || selectedItem.intervalType === 'weekMonthAndHours') && formatInterval(selectedItem.intervalWeekMonth)}
                                {selectedItem.intervalType === 'weekMonthAndHours' && <br />}
                                {(selectedItem.intervalType === 'engineHours' || selectedItem.intervalType === 'weekMonthAndHours') && `${selectedItem.intervalEngineHours} Hours`}
                            </Text>
                        </View>
                    </View>

                    {/* Task Description */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Task Description</Text>
                            <Text>
                                {formatValue(formatTextAreaText(selectedItem?.description))}
                            </Text>
                        </View>
                    </View>

                    {/* Maintenance Tags */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Maintenance Tags</Text>
                            <Text>
                                {formatValue(selectedItem?.maintenanceTags?.join(', '))}
                            </Text>
                        </View>
                    </View>

                    {/* System and Location */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>System</Text>
                            <Text>{renderCategoryName(selectedEquipment?.systemId, vesselSystems)}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Location</Text>
                            <Text>
                                {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                            </Text>
                        </View>
                    </View>

                    {selectedEquipment && (selectedEquipment.make || selectedEquipment.model || selectedEquipment.serial) &&
                        <>
                            {/* Critical Equipment and Make */}
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Critical Equipment</Text>
                                    <Text>{formatValue(selectedEquipment.isCritical ? 'Yes' : 'No')}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Make</Text>
                                    <Text>
                                        {formatValue(selectedEquipment.make)}
                                    </Text>
                                </View>
                            </View>
                            {/* Model and Serial */}
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Model</Text>
                                    <Text>{formatValue(selectedEquipment.model)}</Text>
                                </View>
                                <View style={pdfStyles.column2}>
                                    <Text style={pdfStyles.label}>Serial</Text>
                                    <Text>
                                        {formatValue(selectedEquipment.serial)}
                                    </Text>
                                </View>
                            </View>
                        </>
                    }

                    {/* Equipment Notes */}
                    {selectedEquipment?.notes &&
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Equipment Notes</Text>
                                <Text>
                                    {formatValue(formatTextAreaText(selectedEquipment.notes))}
                                </Text>
                            </View>
                        </View>
                    }

                    {/* Contacts / Suppliers */}
                    {selectedEquipment?.contactIds && selectedEquipment.contactIds.length > 0 &&
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Contacts / Suppliers</Text>
                                <Text>
                                    {(contacts?.byId) ? (
                                        selectedEquipment.contactIds.map((contactId: string) => {
                                            const contact = contacts.byId[contactId];
                                            return `${contact.name}${contact.company ? ` (${contact.company})` : ''}${contact.number ? `, ${contact.number}` : ''}${contact.email ? `, ${contact.email}` : ''}`
                                        }).join('\n \n')
                                    ) : (
                                        '-'
                                    )}
                                </Text>
                            </View>
                        </View>
                    }

                    {!vessel?.isShoreFacility && selectedEquipment?.equipmentDocumentIds && selectedEquipment.equipmentDocumentIds.length > 0 &&
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Manuals</Text>
                                <Text>
                                    {equipmentManualDocuments?.byId && selectedEquipment?.equipmentDocumentIds?.map((manualId: string) => {
                                        const manual = equipmentManualDocuments.byId[manualId];
                                        if (manual) {
                                            return (
                                                <div key={manualId} className="columns" style={{ marginBottom: '6px' }}>
                                                    <div>
                                                        {/* <SeaFileImage files={manual.files} size="tiny" showOthers/> */}
                                                    </div>
                                                    <div style={{ paddingLeft: '8px', alignSelf: 'center' }}>
                                                        {manual.title}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return undefined;
                                    })}
                                    {(!(selectedEquipment?.equipmentDocumentIds) || selectedEquipment.equipmentDocumentIds.length === 0) &&
                                        '-'
                                    }
                                </Text>
                            </View>
                        </View>
                    }
                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }

            </Document>
        )
    }, [contacts?.byId, equipmentManualDocuments?.byId, reportTitle, selectedEquipment, selectedItem, vessel?.isShoreFacility, vesselLocations, vesselSystems]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportTitle}
                MakePdf={MakePDF}
                requestedFrom="MaintenanceScheduleTask"
            />
        );
    } else return <></>
};
export default MaintenanceScheduleTaskPdf;