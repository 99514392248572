import { useEffect, useState } from "react";
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../../lib/firebase";
import { CustomFormTemplate } from "./customFormTemplates";
import { CustomFormVersion } from "./customFormVersions";
import { registerFiles } from "../../FileSyncSystem/filesToCache";

//
// Load a customFormVersion for a specific customFormTemplate
//

export type CustomFormTemplateVersion = CustomFormVersion;

export const useCustomFormTemplateVersions = (customFormTemplate?: CustomFormTemplate) => {
    const [customFormTemplateVersion, setCustomFormTemplateVersion] = useState<CustomFormVersion>();
    useEffect(() => {
        setCustomFormTemplateVersion(undefined);
        if (customFormTemplate) {
            return onSnapshot(
                query(
                    collection(firestore, 'customFormVersions'),
                    where('customFormId', '==', customFormTemplate.id),
                    where('version', '==', customFormTemplate.latestVersion),
                    limit(1)
                ),
                (snap) => {
                    if (snap.docs.length === 1) {
                        const customFormVersion = {
                            id: snap.docs[0].id,
                            ...snap.docs[0].data()
                        } as CustomFormVersion;
                        setCustomFormTemplateVersion(customFormVersion);

                        for (let key in customFormVersion.form) {
                            if (
                                customFormVersion.form.hasOwnProperty(key) &&
                                customFormVersion.form[key]?.help?.files
                            ) {
                                registerFiles(customFormVersion.form[key].help.files, 'customFormVersions', customFormVersion);
                            }
                        }
                    }
                }, (error) => {
                    console.log(`Failed to access latest form/checklist template version for ${customFormTemplate?.id} version=${customFormTemplate?.latestVersion}`, error);
                }
            );
        }
    }, [customFormTemplate]);

    return customFormTemplateVersion;
};
