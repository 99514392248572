import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, formatSeaDatetime, addInterval } from '../../../../lib/datesAndTime';
import { canComplete, canCreate } from '../../../../shared-state/Core/userPermissions';
import { renderCategoryName } from '../../../../lib/categories';
import { sharedState } from '../../../../shared-state/shared-state';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { SafetyCheckItem } from '../../../../shared-state/VesselSafety/safetyCheckItems';
import { SafetyCheckCompleted } from '../../../../shared-state/VesselSafety/useCompletedSafetyCheckItems';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface CompleteSafetyCheckProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowParentModal: (showModal: boolean) => void, // Allows us to also close parent modal
    historyItemToUpdate: SafetyCheckCompleted | undefined,
    selectedItem: SafetyCheckItem,
    completedSafetyCheckItems?: SafetyCheckCompleted[],
    level?: number
}

const CompleteSafetyCheck: React.FC<CompleteSafetyCheckProps> = ({showModal, setShowModal, setShowParentModal, historyItemToUpdate, selectedItem, completedSafetyCheckItems, level}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const onlineStatus = sharedState.onlineStatus.use(showModal);
    const safetyCheckItems = sharedState.safetyCheckItems.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vesselSafetyItems = sharedState.vesselSafetyItems.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (historyItemToUpdate) {
            return {
                whenCompleted: historyItemToUpdate.whenCompleted ? formatSeaDatetime(historyItemToUpdate.whenCompleted) : '',
                notes: historyItemToUpdate.notes ? ''+historyItemToUpdate.notes : '',
                shouldReportFault: historyItemToUpdate.shouldReportFault ? true : false,
            };
        } else {
            return {
                whenCompleted: formatSeaDatetime(),
                notes: '',
                shouldReportFault: false,
            };
        }
    }, [historyItemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(historyItemToUpdate?.files));
    };


    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            whenCompleted: Yup.date().required().min(...notTooOld),
            notes: Yup.string().max(5000),
            shouldReportFault: Yup.boolean(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('completeSafetyCheck')) { return; }
            if (!vesselId) {
                throw new Error("No vesselId");
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                const batch = splittableBatch(firestore, 20 - 1); // -1 because safetyCheckCompleted requires an extra security access call
                const batchTrace = makeBatchTrace(batch, 'safetyCheckCompleted');
                
                // Determine whenLatestCompleted
                let whenLatestCompleted = toMillis(data.whenCompleted) as number;
                let hasFault = data.shouldReportFault ? true : false;
                if (historyItemToUpdate && historyItemToUpdate.whenCompleted === selectedItem.whenLastChecked) {
                    // History item being edited is the latest one,
                    // so it's possible the second most recent one (if it exists) is now the latest
                    if (completedSafetyCheckItems && completedSafetyCheckItems.length > 1) {
                        if (completedSafetyCheckItems[1].whenCompleted > whenLatestCompleted) {
                            // The second most recent one is now the lastest
                            whenLatestCompleted = completedSafetyCheckItems[1].whenCompleted;
                            hasFault = completedSafetyCheckItems[1].whenCompleted ? true : false;
                        }
                    }
                } else {
                    // The most recent completed could still be the latest
                    if (selectedItem.whenLastChecked > whenLatestCompleted) {
                        whenLatestCompleted = selectedItem.whenLastChecked;
                        hasFault = selectedItem.hasFault ? true : false;
                    }
                }

                batch.set(
                    doc(firestore, 'safetyCheckItems', selectedItem.id),
                    {
                        whenLastChecked: whenLatestCompleted,
                        whenDue: whenLatestCompleted ? addInterval(toMillis(formatSeaDate(whenLatestCompleted)) as number, selectedItem.interval) : undefined,
                        hasFault: hasFault,
                        touched: serverTimestamp()
                    } as Partial<SafetyCheckItem>,
                    { merge: true }
                );

                if (historyItemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = historyItemToUpdate.id;
                    batch.set(
                        doc(firestore, 'safetyCheckCompleted', historyItemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            notes: data.notes ? data.notes : deleteValue,
                            whenCompleted: toMillis(data.whenCompleted), 
                            shouldReportFault: data.shouldReportFault,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                    logAction(
                        batch,
                        'Update',
                        'safetyCheckCompleted',
                        historyItemToUpdate.id,
                        `${renderCategoryName(selectedItem.itemId, vesselSafetyItems)}${ data.shouldReportFault ? ' (FAULT)' : '' }`,
                        [vesselId]
                    );
                    saveFileRefs(batch, files, 'safetyCheckCompleted', historyItemToUpdate.id);
                    if (data.shouldReportFault) {
                        sendVesselNotification(batch, 'safetyCheckFaultReported', 'safetyChecks', {
                            isUpdate: true,
                            id: selectedItem.id,
                            whenCompleted: toMillis(data.whenCompleted), 
                            notes: data.notes ? data.notes : undefined,
                            safetyItem: {
                                item: renderCategoryName(safetyCheckItems?.byId[selectedItem.id]?.itemId, vesselSafetyItems),
                                location: safetyCheckItems?.byId[selectedItem.id]?.location,
                                isCritical: safetyCheckItems?.byId[selectedItem.id]?.isCritical,
                                description: safetyCheckItems?.byId[selectedItem.id]?.description
                            }
                        }, files);
                    }
                } else {
                    const newRef = doc(collection(firestore, 'safetyCheckCompleted'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        safetyCheckId: selectedItem.id,
                        completedBy: userId,
                        vesselId: vesselId,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        notes: data.notes ? data.notes : undefined,
                        whenCompleted: toMillis(data.whenCompleted), 
                        shouldReportFault: data.shouldReportFault,
                        files: seaFilesToValue(files),
                        state: 'active',
                        touched: serverTimestamp()
                    });
                    saveFileRefs(batch, files, 'safetyCheckCompleted', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'safetyCheckCompleted',
                        newRef.id,
                        `${renderCategoryName(selectedItem.itemId, vesselSafetyItems)}${ data.shouldReportFault ? ' (FAULT)' : '' }`,
                        [vesselId]
                    );
                    if (data.shouldReportFault) {
                        sendVesselNotification(batch, 'safetyCheckFaultReported', 'safetyChecks', {
                            id: selectedItem.id,
                            whenCompleted: toMillis(data.whenCompleted), 
                            notes: data.notes ? data.notes : undefined,
                            safetyItem: {
                                item: renderCategoryName(safetyCheckItems?.byId[selectedItem.id]?.itemId, vesselSafetyItems),
                                location: safetyCheckItems?.byId[selectedItem.id]?.location,
                                isCritical: safetyCheckItems?.byId[selectedItem.id]?.isCritical,
                                description: safetyCheckItems?.byId[selectedItem.id]?.description
                            }
                        }, files);
                        // If this user has the ability to create jobs, we'll create an equivalent job.
                        // If this user doesn't have the ability to create jobs,
                        // this will be done by firebase function onSafetyCheckCompletedCreate.
                        if (canCreate('jobList')) {
                            const jobRef = doc(collection(firestore, 'jobs'));
                            batch.set(jobRef, {
                                vesselId: vesselId,
                                licenseeId: licenseeId,
                                addedBy: userId,
                                task: `FAULT - ${renderCategoryName(selectedItem.itemId, vesselSafetyItems)}`,
                                description: data.notes,
                                priority: '8urgent',
                                whenAdded: batchTrace.whenAction,
                                state: 'active',
                                location: selectedItem.location,
                                addedFromSafetyCheckCompletedId: newRef.id,
                                files: seaFilesToValue(files),
                                touched: serverTimestamp()
                            });
                            // Update safetyCheck to indicate there is a job to fix the fault
                            batch.set(
                                doc(firestore, 'safetyCheckItems', selectedItem.id),
                                {
                                    faultJobId: jobRef.id,
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                            saveFileRefs(batch, files, 'jobs', jobRef.id);

                            // Update overdue stats that jobs has changed
                            onCollectionUpdated(batch, 'jobs');
                            alertMessage('Note: An urgent job for this fault has also been created.');
                        } else if (onlineStatus?.isOnline) {
                            alertMessage('Note: An urgent job for this fault will also be created.');
                        } else {
                            alertMessage('Note: An urgent job for this fault will also be created when you\'re next online.');
                        }
                    } else {
                        // No fault, therefore check to see if we need to complete relevant job.
                        if (selectedItem.faultJobId && canComplete('jobList')) {
                            const newCompletedMaintenanceTaskRef = doc(collection(firestore, 'maintenanceTasksCompleted'));
                            const selectedJobRef = doc(firestore, 'jobs', selectedItem.faultJobId);
                            // Create maintenance history
                            batch.set(newCompletedMaintenanceTaskRef, {
                                completedBy: userId,
                                whenAdded: batchTrace.whenAction,
                                addedBy: userId,
                                vesselId: vesselId,
                                jobId: selectedItem.faultJobId,
                                task: `FAULT - ${renderCategoryName(selectedItem.itemId, vesselSafetyItems)}`,
                                description: data.notes,
                                state: 'completed',
                                location: selectedItem.location,
                                whenCompleted: toMillis(data.whenCompleted),
                                type: 'job',
                                touched: serverTimestamp()
                            });
                            // Update job
                            batch.set(selectedJobRef,
                                {
                                    whenCompleted: toMillis(data.whenCompleted),
                                    completedBy: userId,
                                    updatedBy: userId,
                                    whenUpdated: batchTrace.whenAction,
                                    state: 'completed',
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                            // Update safetyCheck to indicate there is now no job to fix any fault
                            batch.set(
                                doc(firestore, 'safetyCheckItems', selectedItem.id),
                                {
                                    faultJobId: deleteValue,
                                    touched: serverTimestamp()
                                },
                                { merge: true }
                            );
                            // Update overdue stats
                            onCollectionUpdated(batch, 'maintenanceTasksCompleted');
                            onCollectionUpdated(batch, 'jobs');
                        }
                    }
                }

                onCollectionUpdated(batch, 'safetyCheckItems');
                onCollectionUpdated(batch, 'safetyCheckCompleted');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedItem
                };
                batchTrace.save(`${historyItemToUpdate?'Update':'Add'} a completed safety check ${renderCategoryName(selectedItem?.itemId, vesselSafetyItems)}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
                if (!historyItemToUpdate) {
                    setTimeout(() => {
                        setShowParentModal(false);
                    }, 250);
                }
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload Completed Safety Check files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedItem
                    });
                }
            });

        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, historyItemToUpdate?.files)
        );
    }, [initialValues, values, files, historyItemToUpdate?.files]);


    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={historyItemToUpdate ? 'Edit Completed Safety Check' : 'Complete Safety Check'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDatetime
                                label="When Completed"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                placeholder="Add notes..."
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={150}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="safetyCheckCompleted"
                                field="files"
                            />

                        </IonCol>
                        <IonCol size="12">
                            <SeaCheckbox
                                name="shouldReportFault"
                                checked={values.shouldReportFault}
                                setFieldValue={setFieldValue}
                                checkedColor="primary"
                                error={touched.shouldReportFault ? errors.shouldReportFault : ''}
                            >
                                Report a Fault
                            </SeaCheckbox>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                <SeaButton zone="white" size="wide" type="submit">{historyItemToUpdate ? 'Update Completed Check' : 'Save Completed Check'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default CompleteSafetyCheck;
