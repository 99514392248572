import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { deleteValue, firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { VesselCertificate } from '../../../../shared-state/VesselDocuments/vesselCertificates';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import { makeCategoryId } from '../../../../lib/categories';

interface RenewVesselCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: VesselCertificate,
    level?: number,
    setShowParentModal: (showModal: boolean) => void,
}

const RenewVesselCertificate: React.FC<RenewVesselCertificateProps> = ({showModal, setShowModal, selectedItem, level, setShowParentModal}) => {
    const isMounted = useRef(false);
    const vesselId = sharedState.vesselId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselCertificateCategories = sharedState.vesselCertificateCategories.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const todayMillis = sharedState.todayMillis.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    const initialValues = useMemo(() => {
        return {
            certNum: (selectedItem?.certNum) ? ''+selectedItem.certNum : '',
            whenIssued: formatSeaDate(),
            whenExpires: '',
        };
    }, [selectedItem?.certNum, todayMillis]); // eslint-disable-line

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues, 
        validationSchema: Yup.object({
            whenIssued: Yup.date().required().min(...notTooOld),
            whenExpires: Yup.date().required().min(...notTooOld),
            certNum: Yup.string().max(500)
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('renewVesselCertificate')) { return; } 
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                let whenToRemind = undefined;
                if (selectedItem.type === 'renewable' && data.whenExpires && selectedItem.emailReminder) {
                    whenToRemind = subtractInterval(data.whenExpires, selectedItem.emailReminder);
                };

                const batch = splittableBatch(firestore, 20 - 0);
                const currentCertRef = doc(firestore, 'vesselCertificates', selectedItem.id);
                const newCertRef = doc(collection(firestore, 'vesselCertificates'));
                const batchTrace = makeBatchTrace(batch, 'vesselCertificates', 'create', newCertRef.id);

                batch.set(currentCertRef, {
                    archivedBy: userId,
                    whenArchived: batchTrace.whenAction,
                    state: 'archived',
                    touched: serverTimestamp()
                }, { merge: true });

                batch.set(newCertRef, {
                    vesselId: vesselId,
                    addedBy: userId,
                    whenAdded: batchTrace.whenAction,
                    title: selectedItem.title,
                    categoryId: makeCategoryId(
                        selectedItem.categoryId,
                        vesselCertificateCategories,
                        deleteValue,
                        batch,
                        'vesselCertificateCategories',
                        'vesselId',
                        vesselId as string,
                        {}
                    ),
                    certNum: data.certNum ? data.certNum : undefined,
                    issuedBy: selectedItem.issuedBy,
                    whenIssued: toMillis(data.whenIssued),
                    type: selectedItem.type,
                    whenExpires: toMillis(data.whenExpires),
                    emailReminder: selectedItem.emailReminder && selectedItem.emailReminder,
                    whenToRemind: whenToRemind,
                    state: 'active',
                    wasRenewed: true,
                    files: seaFilesToValue(files),
                    isShoreFacility: vessel?.isShoreFacility ? true : undefined,
                    touched: serverTimestamp()
                });
                
                onCollectionUpdated(batch, 'vesselCertificates');
                saveFileRefs(batch, files, 'vesselCertificates', newCertRef.id);
                logAction(
                    batch,
                    'Renew',
                    'vesselCertificates',
                    newCertRef.id,
                    selectedItem?.title,
                    [selectedItem.vesselId]
                );

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    selectedItem
                };
                batchTrace.save(`Renew vessel certificate ${selectedItem?.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
                setTimeout(() => {
                    if (!isMounted.current) return;
                    setShowParentModal(false);
                }, 250);
                showToast('Vessel certificate has been renewed');
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload vessel certificate files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        selectedItem
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues);
    }, [initialValues, values]);


    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={`Renew ${vessel?.isShoreFacility ? 'Certification' : 'Certificate'}`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='thin'
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaDate
                                label="New Issue Date"
                                name="whenIssued"
                                value={values.whenIssued}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenIssued ? errors.whenIssued : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaDate
                                label="New Expiry Date"
                                name="whenExpires"
                                value={values.whenExpires}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenExpires ? errors.whenExpires : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaInput
                                label="New Certificate #"
                                name="certNum"
                                value={values.certNum}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
								inputmode="text"
                                error={touched.certNum ? errors.certNum : ''}
                            />
                        </IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="vesselCertificates"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" size="wide" type="submit">Renew {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default RenewVesselCertificate;
