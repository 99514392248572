import React, { useCallback, useMemo } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDate } from '../../../lib/datesAndTime';
import { renderCrewList, renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import { sharedState } from '../../../shared-state/shared-state';
import { SeaTimeStats } from '../../../modals/Crew/CrewParticulars/ViewUserModal/SeaTimeTab/SeaTimeTab';
import { SeaTimeRecord } from '../../../shared-state/Crew/useSkipperSeaTimeRecords';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';



interface SeaTimeRecordsPdfProps {
    onCompleted: () => void,
    selectedUser?: UserType,
    filterSeaTimeType?: string,
    filterVesselId?: 'all' | string,
    filteredSeaTime?: SeaTimeRecord[],
    seaTimeStats?: SeaTimeStats,
    formatCrewHours: (crewHours: { [key: string]: number[] } | undefined, isOfficial?: boolean) => string
}

const SeaTimeRecordsPdf: React.FC<SeaTimeRecordsPdfProps> = ({
    onCompleted,
    selectedUser,
    filterSeaTimeType,
    filterVesselId,
    filteredSeaTime,
    seaTimeStats,
    formatCrewHours
}) => {
    const vessels = sharedState.vessels.use();

    const pdfTitle = useMemo(() => {
        if (selectedUser && filterSeaTimeType && filterVesselId && vessels) {
            let s = `${renderFullName(selectedUser)} -`;
            if (filterSeaTimeType === 'skipper') {
                s += ' Skipper'
            } else if (filterSeaTimeType === 'crew') {
                s += ' Crew'
            }
            s += ` Sea Time`
            if (filterVesselId !== 'all') {
                s += ` - ${vessels?.byId[filterVesselId]?.name}`
            }
            return s;
        }
        return '';
    }, [selectedUser, filterSeaTimeType, filterVesselId, vessels]);

    const MakePDF = useCallback(() => {
        return (
            <Document
                title={pdfTitle}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={pdfTitle}
                        userFullName={renderFullName()}
                    />
                    {seaTimeStats &&
                        <React.Fragment>
                            <View wrap={false} style={pdfStyles.rowInBetween}>
                                <View style={{ ...pdfStyles.column3, maxWidth: '5.5cm' }}>
                                    <Text>Total days at sea: </Text>
                                </View>
                                <View style={pdfStyles.column4}>
                                    <Text>{formatValue(seaTimeStats.days)}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.rowInBetween}>
                                <View style={{ ...pdfStyles.column3, maxWidth: '5.5cm' }}>
                                    <Text>Total hours at sea: </Text>
                                </View>
                                <View style={pdfStyles.column4}>
                                    <Text>{formatValue(seaTimeStats.hours)}</Text>
                                </View>
                            </View>
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={{ ...pdfStyles.column3, maxWidth: '5.5cm' }}>
                                    <Text>Total official hours at sea: </Text>
                                </View>
                                <View style={pdfStyles.column4}>
                                    <Text>{formatValue(seaTimeStats.officialHours)}</Text>
                                </View>
                            </View>
                        </React.Fragment>
                    }
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date</Text>
                        </View>
                        {filterVesselId === 'all' &&
                            <View style={styleItemColumn(1)}>
                                <Text>Vessel</Text>
                            </View>
                        }
                        <View style={styleItemColumn(1)}>
                            <Text>Skipper</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>From</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>To</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Days</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Hours</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Official Hours</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {filteredSeaTime?.map((item, index) => {
                        return (
                            <React.Fragment key={item.id}>
                                {index > 0 &&
                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                }
                                <View wrap={false} style={pdfStyles.itemsRow}>
                                    <View style={pdfStyles.dateColumn}>
                                        <Text>{item.whenVoyage && formatDate(item.whenVoyage)}</Text>
                                    </View>
                                    {filterVesselId === 'all' &&
                                        <View style={styleItemColumn(1)}>
                                            <Text>{formatValue(vessels?.byId[item.vesselId]?.name)}</Text>
                                        </View>
                                    }
                                    <View style={styleItemColumn(1)}>
                                        <Text>{renderCrewList(item.masterIds)}</Text>
                                    </View>
                                    <View style={styleItemColumn(1)}>
                                        <Text>{formatValue(item.fromLocation)}</Text>
                                    </View>
                                    <View style={styleItemColumn(1)}>
                                        <Text>{formatValue(item.toLocation)}</Text>
                                    </View>
                                    <View style={pdfStyles.numColumn}>
                                        <Text>{formatValue(item.days)}</Text>
                                    </View>
                                    <View style={pdfStyles.numColumn}>
                                        <Text>{formatCrewHours(item.hours)}</Text>
                                    </View>
                                    <View style={pdfStyles.numColumn}>
                                        <Text>{formatCrewHours(item.hours, true)}</Text>
                                    </View>
                                </View>
                            </React.Fragment>
                        );
                    })}
                </Page>
            </Document>
        );
    }, [filterVesselId, pdfTitle, filteredSeaTime, vessels, formatCrewHours, seaTimeStats]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={pdfTitle}
            MakePdf={MakePDF}
            requestedFrom="seaTimeRecords"
        />
    );
};

export default SeaTimeRecordsPdf;
