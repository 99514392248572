import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate } from '../../../../lib/datesAndTime';
import { CategoriesData, makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, seaFilesToValue } from '../../../../lib/files';
import { SOP } from '../../../../shared-state/VesselDocuments/vesselSOPS';
import { addMissingLinks, handleLinkUpdates, renderLinkName } from '../../../../lib/links';
import { LinkType } from '../../../../shared-state/Core/links';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaLinkMultiList, { LinkOptionsType } from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import SeaEditDocumentation, { SeaDocumentation, confirmAnyDocumentationLoss, getFilesToUploadForSeaDocumentation, hasSeaDocumentationChanged, makeSeaDocumentation, seaDocumentationToFilesValue, seaDocumentationToSfdocValue } from '../../../../components/SeaEditDocumentation/SeaEditDocumentation';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditSOPProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: SOP,
    vesselSopCategories: CategoriesData,
    existingLinks?: LinkType[]
}

const EditSOP: React.FC<EditSOPProps> = ({showModal, setShowModal, itemToUpdate, level=1, vesselSopCategories, existingLinks}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [documentation, setDocumentation] = useState<SeaDocumentation>({
        type: 'sfdoc',
        files: [],
        sfdoc: {}
    });
    // TODO: Add links
    // const vesselDrills = sharedState.vesselDrills.use(showModal);
    // const risks = sharedState.risks.use(showModal);
    // const trainingTasks = sharedState.trainingTasks.use(showModal);
    // const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(showModal);
    // const customForms = sharedState.customForms.use(showModal);
    // const [links, setLinks] = useState<LinkType[]>([]);
    // const [linkOptions, setLinkOptions] = useState<LinkOptionsType[] | undefined>(undefined);
     
    // handles generating link options and adding links that link to items not in sharedState
    // useEffect(() => {
    //     const fetchLinkOptions = () => {
    //         if (!vesselDrills || !risks || !trainingTasks || !scheduledMaintenanceTasks || !customForms) {
    //             setLinkOptions(undefined);
    //             return Promise.resolve();
    //         }
    
    //         const options: LinkOptionsType[] = [
    //             {
    //                 collection: 'drills',
    //                 label: 'Drills',
    //                 items: Object.entries(vesselDrills.byId).map(([id, drill]) => ({
    //                     id: id,
    //                     name: renderLinkName(drill.name, drill.state)
    //                 }))
    //             },
    //             {
    //                 collection: 'scheduledMaintenanceTasks',
    //                 label: 'Maintenance Tasks',
    //                 items: Object.entries(scheduledMaintenanceTasks.byId).map(([id, task]) => ({
    //                     id,
    //                     name: task.task || 'placeholder task'
    //                 }))
    //             },
    //             {
    //                 collection: 'risks',
    //                 label: 'Risk Assessments',
    //                 items: Object.entries(risks.byId).map(([id, risk]) => ({
    //                     id,
    //                     name: risk.name
    //                 }))
    //             },
    //             {
    //                 collection: 'customForms',
    //                 label: 'Forms/Checklists',
    //                 items: Object.entries(customForms.byId).map(([id, task]) => ({
    //                     id,
    //                     name: task.title
    //                 }))
    //             },
    //             {
    //                 collection: 'trainingTasks',
    //                 label: 'Crew Training',
    //                 items: Object.entries(trainingTasks.byId).map(([id, document]) => ({
    //                     id,
    //                     name: document.task
    //                 }))
    //             },
    //             {
    //                 collection: 'external',
    //                 label: 'External Links',
    //                 items: []
    //             }
    //         ];
    
    //         if (existingLinks) {
    //             return addMissingLinks(existingLinks, options, 'SOPs')
    //                 .then(() => {
    //                     setLinkOptions(options);
    //                 });
    //         } else {
    //             setLinkOptions(options);
    //             return Promise.resolve();
    //         }
    //     };
    
    //     fetchLinkOptions()
    //         .catch((error) => {
    //             console.error('Error fetching link options:', error);
    //         });
    // }, [vesselDrills, existingLinks, risks, trainingTasks, scheduledMaintenanceTasks]);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                whenIssued: itemToUpdate.whenIssued ? formatSeaDate(itemToUpdate.whenIssued) : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : ''
            };
        } else {
            return {
                title: '',
                whenIssued: formatSeaDate(),
                categoryId: ''
            };
        }
    }, [itemToUpdate]);



    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        // TODO: Add links
        // setLinks(existingLinks ?? []);
        setDocumentation(makeSeaDocumentation(itemToUpdate));
    }

    const onClosed = () => {
        setFieldValue('categoryId', ''); // Make sure selects do not remember old values
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            whenIssued: Yup.date().required().min(...notTooOld),
            categoryId: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('SOP')) { return; }
            confirmAnyDocumentationLoss(documentation, makeSeaDocumentation(itemToUpdate), confirmAction).then(() => {
                const filesToUpload = getFilesToUploadForSeaDocumentation(documentation);
                uploadFiles(filesToUpload).then(() => {
                    if (!vesselId) {
                        throw new Error('vesselId is not defined');
                    }
                    let itemId: string;
                    // Process form
                    const batch = splittableBatch(firestore, 20 - 0);
                    const batchTrace = makeBatchTrace(batch, 'SOPs');
                    const filesValue = seaDocumentationToFilesValue(documentation);
                    const sfdocValue = seaDocumentationToSfdocValue(documentation);
                    if (itemToUpdate) {
                        itemId = itemToUpdate.id;
                        batchTrace.exampleOperation = 'update';
                        batchTrace.exampleDocId = itemToUpdate.id;
                        batch.set(
                            doc(firestore, 'SOPs', itemToUpdate.id),
                            {
                                updatedBy: userId,
                                whenUpdated: batchTrace.whenAction,
                                title: data.title,
                                whenIssued: toMillis(data.whenIssued),
                                categoryId: makeCategoryId(
                                    data.categoryId,
                                    vesselSopCategories,
                                    deleteValue,
                                    batch,
                                    'vesselSopCategories',
                                    'vesselId',
                                    vesselId,
                                    {}
                                ),
                                files: filesValue ? filesValue : deleteValue,
                                sfdoc: sfdocValue ? sfdocValue : deleteValue,
                                touched: serverTimestamp(),
                            },
                            { merge: true }
                        );

                        saveFileRefs(batch, filesToUpload, 'SOPs', itemToUpdate.id);
                        logAction(
                            batch,
                            'Update',
                            'SOPs',
                            itemToUpdate.id,
                            data.title,
                            [itemToUpdate.vesselId]
                        );
                    } else {
                        const newRef = doc(collection(firestore, 'SOPs'));
                        itemId = newRef.id;
                        batchTrace.exampleOperation = 'create';
                        batchTrace.exampleDocId = newRef.id;
                        batch.set(newRef, {
                            vesselId: vesselId,
                            addedBy: userId,
                            title: data.title,
                            whenIssued: toMillis(data.whenIssued),
                            categoryId: makeCategoryId(
                                data.categoryId,
                                vesselSopCategories,
                                undefined,
                                batch,
                                'vesselSopCategories',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            whenAdded: batchTrace.whenAction,
                            state: 'active',
                            files: filesValue ? filesValue : undefined,
                            sfdoc: sfdocValue ? sfdocValue : undefined,
                            touched: serverTimestamp(),
                        });

                        saveFileRefs(batch, filesToUpload, 'SOPs', newRef.id);
                        logAction(
                            batch,
                            'Add',
                            'SOPs',
                            newRef.id,
                            data.title,
                            [vesselId]
                        );
                    }

                    onCollectionUpdated(batch, 'SOPs');

                    // TODO: Add links
                    // handleLinkUpdates(batch, links, existingLinks, itemId, 'SOPs');

                    batchTrace.data = {
                        data,
                        files: filesValue,
                        sfdoc: sfdocValue,
                        itemToUpdate
                    };
                    batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} SOP ${data?.title}`);
                    batch.commit().then(() => {
                        batchTrace.reportSuccess();
                    }).catch((error) => {
                        batchTrace.reportError(error.message, error);
                    });

                    setShowModal(false);
                }).catch((error: any) => {
                    if (!handleUploadError(error)) {
                        reportError(`Failed to upload SOP files`, error.message, error, {
                            filesToUpload: seaFilesToValue(filesToUpload),
                            data,
                            itemToUpdate
                        });
                    }
                });
            }).catch(() => {
                // Ignore cancellation of documentation loss
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            hasSeaDocumentationChanged(documentation, makeSeaDocumentation(itemToUpdate))
        );
    }, [values, initialValues, documentation, itemToUpdate]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);


    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Standard Operating Procedure' : 'Add New Standard Operating Procedure'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
            confirmDismissDirty={{
                title: "Are you sure you want to leave this form without saving first?",
                no: "Cancel",
                yes: "Yes, leave form"
            }}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaInput
								label="SOP Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSopCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
								label="Issue Date"
								name="whenIssued"
								value={values.whenIssued}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								error={touched.whenIssued ? errors.whenIssued : ''}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <SeaEditDocumentation
                                label="Documentation"
                                title={values.title}
                                documentation={documentation}
                                setDocumentation={setDocumentation}
                                collection="SOPs"
                            />
                        </IonCol>
                    </IonRow>
                    {/* TODO: Add links */}
                    {/* <IonRow>
                        <IonCol size="12">
                            <SeaLinkMultiList 
                                selectedCollection='SOPs'
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                options={linkOptions}
                                deleteable
                                confirmDelete
                                level={level+1}
                            />
                        </IonCol>
                    </IonRow> */}
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Save Changes' : 'Save New SOP'}
                    </SeaButton>
                </div>
            </form>

        </SeaModal>
    );
};

export default EditSOP;
