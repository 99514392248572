import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, SplittableBatch, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp, WriteBatch } from "firebase/firestore";
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { haveValuesChanged, hasArrayChanged, preventMultiTap } from '../../../../lib/util';
import { canDelete } from '../../../../shared-state/Core/userPermissions';
import { makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Equipment } from '../../../../shared-state/VesselMaintenance/equipment';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';

interface EditEquipmentProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowViewItemModal?: (showModal: boolean) => void,
    itemToUpdate?: Equipment,
    setNewEquipment?: (newEquipment: Partial<Equipment>) => void,
    defaultSystemId?: string,
    disableDelete?: boolean,
    level?: number
}

const EditEquipment: React.FC<EditEquipmentProps> = ({
    showModal,
    setShowModal,
    setShowViewItemModal,
    itemToUpdate,
    setNewEquipment,
    defaultSystemId,
    disableDelete = false,
    level
}) => {
    const userId = sharedState.userId.use(showModal);
    const contacts = sharedState.contacts.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const vesselSystems = sharedState.vesselSystems.use(showModal);
    const [equipmentDocumentIds, setEquipmentDocumentIds] = useState<string[]>();
    const [contactIds, setContactIds] = useState<string[]>();
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                systemId: (itemToUpdate?.systemId) ? ''+itemToUpdate.systemId : '',
                equipment: (itemToUpdate?.equipment) ? ''+itemToUpdate.equipment : '',
                locationId: (itemToUpdate?.locationId) ? ''+itemToUpdate.locationId : '',
                make: (itemToUpdate?.make) ? ''+itemToUpdate.make : '',
                model: (itemToUpdate?.model) ? ''+itemToUpdate.model : '',
                serial: (itemToUpdate?.serial) ? ''+itemToUpdate.serial : '',
                notes: (itemToUpdate?.notes) ? ''+itemToUpdate.notes : '',
                isCritical: (itemToUpdate?.isCritical) ? true : false
            };
        } else {
            return {
                systemId: defaultSystemId ? ''+defaultSystemId : '',
                equipment: '',
                locationId: '',
                make: '',
                model: '',
                serial: '',
                notes: '',
                isCritical: false
            };
        }
    }, [itemToUpdate, defaultSystemId]);

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            systemId: Yup.string().max(500).required(),
            equipment: Yup.string().max(500).required(),
            locationId: Yup.string().max(500),
            make: Yup.string().max(500),
            model: Yup.string().max(500),
            serial: Yup.string().max(500),
            notes: Yup.string().max(10000)
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('equipment')) { return; }
            if (!vesselId) {
                alertMessage('Vessel not found');
                return;
            }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'equipment');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'equipment', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            systemId: makeCategoryId(
                                data.systemId,
                                vesselSystems,
                                deleteValue,
                                batch,
                                'vesselSystems',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            equipment: data.equipment.trim(),
                            locationId: makeCategoryId(
                                data.locationId,
                                vesselLocations,
                                deleteValue,
                                batch,
                                'vesselLocations',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            make: data.make ? data.make : deleteValue,
                            model: data.model ? data.model : deleteValue,
                            serial: data.serial ? data.serial : deleteValue,
                            notes: data.notes ? data.notes : deleteValue,
                            files: seaFilesToValue(files),
                            equipmentDocumentIds: equipmentDocumentIds,
                            contactIds: contactIds,
                            isCritical: data.isCritical ? true : deleteValue,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'equipment', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'equipment',
                        itemToUpdate.id,
                        data.equipment.trim(),
                        [itemToUpdate.vesselId]
                    );
                } else {
                    // Add new scheduledMaintenanceTask
                    // We will also want to create the first maintenanceTasksCompleted item
                    const newRef = doc(collection(firestore, 'equipment'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    
                    const _locationId = makeCategoryId(
                        data.locationId,
                        vesselLocations,
                        undefined,
                        batch,
                        'vesselLocations',
                        'vesselId',
                        vesselId,
                        {}
                    );
                    const _systemId = makeCategoryId(
                        data.systemId,
                        vesselSystems,
                        undefined,
                        batch,
                        'vesselSystems',
                        'vesselId',
                        vesselId,
                        {}
                    )
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        systemId: _systemId,
                        equipment: data.equipment.trim(),
                        locationId: _locationId,
                        make: data.make ? data.make : undefined,
                        model: data.model ? data.model : undefined,
                        serial: data.serial ? data.serial : undefined,
                        notes: data.notes ? data.notes : undefined,
                        files: seaFilesToValue(files),
                        equipmentDocumentIds: equipmentDocumentIds,
                        contactIds: contactIds,
                        whenAdded: batchTrace.whenAction,
                        state: 'active',
                        isCritical: data.isCritical ? true : undefined,
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'equipment', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'equipment',
                        newRef.id,
                        data.equipment.trim(),
                        [vesselId]
                    );
                    if (setNewEquipment) {
                        setNewEquipment({
                            id: newRef.id,
                            equipment: data.equipment.trim(),
                            systemId: _systemId,
                            locationId: _locationId,
                            make: data.make ? data.make : undefined,
                            model: data.model ? data.model : undefined,
                            serial: data.serial ? data.serial : undefined,
                            notes: data.notes ? data.notes : undefined,
                            isCritical: data.isCritical ? true : undefined,
                        });
                    }
                }

                onCollectionUpdated(batch, 'equipment');

                batchTrace.data = {
                    data,
                    equipmentDocumentIds,
                    contactIds,
                    files: seaFilesToValue(files)
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Create'} equipment ${data.equipment}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload equipment files`, error.message, error);
                }
            });
        }
    });
    
    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
        setEquipmentDocumentIds(itemToUpdate?.equipmentDocumentIds);
        setContactIds(itemToUpdate?.contactIds);
    }

    const onClosed = () => {
        setFieldValue('systemId', '');
        setFieldValue('locationId', '');
    }

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files) ||
            hasArrayChanged(equipmentDocumentIds, itemToUpdate?.equipmentDocumentIds) ||
            hasArrayChanged(contactIds, itemToUpdate?.contactIds)
        );
    }, [initialValues, values, itemToUpdate, files, equipmentDocumentIds, contactIds]);

    const equipmentManualDocumentOptions = useMemo(() => {
        return equipmentManualDocuments?.documents?.map((document) => {
            return {
                id: document.id,
                name: document.title
            };
        });
    }, [equipmentManualDocuments]);

    const contactOptions = useMemo(() => {
        return contacts?.all?.map((contact) => {
            return {
                id: contact.id,
                name: contact.company ? `${contact.company}, ${contact.name}` : contact.name
            };
        });
    }, [contacts]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Equipment' : 'Add New Equipment'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselSystems}
                                label="System"
                                name="systemId"
                                initialCategoryId={(itemToUpdate?.systemId) ? itemToUpdate.systemId : (defaultSystemId ? defaultSystemId : '')}
                                categoryId={values.systemId}
                                otherPlaceholder="System"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.systemId ? errors.systemId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Equipment"
                                name="equipment"
                                value={values.equipment}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.equipment ? errors.equipment : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                categories={vesselLocations}
                                label="Location"
                                name="locationId"
                                initialCategoryId={(itemToUpdate?.locationId) ? ''+itemToUpdate.locationId : ''}
                                categoryId={values.locationId}
                                otherPlaceholder="Location"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.locationId ? errors.locationId : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                             <SeaCheckbox
                                mode="beside-input"
                                label="Critical Equipment"
                                name="isCritical"
                                checked={values.isCritical ? true : false}
                                setFieldValue={setFieldValue}
                                help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew.'}}
                            >
                               This equipment is critical
                            </SeaCheckbox>
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput
                                label="Make"
                                name="make"
                                value={values.make}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.make ? errors.make : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput
                                label="Model"
                                name="model"
                                value={values.model}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.model ? errors.model : ''}
                            />
                        </IonCol>
                        <IonCol size="4">
                            <SeaInput
                                label="Serial"
                                name="serial"
                                value={values.serial}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.serial ? errors.serial : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Equipment Notes"
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="equipment"
                                field="files"
                            />
                        </IonCol>
                        {!vessel?.isShoreFacility && (equipmentManualDocumentOptions?.length || 0) > 0 &&
                            <IonCol size="12">
                                <SeaMultiSelect
                                    label="Connect Equipment Manual"
                                    values={equipmentDocumentIds}
                                    setValues={setEquipmentDocumentIds}
                                    options={equipmentManualDocumentOptions}
                                    useAllOption={false}
                                    emptyText="Not Set"
                                    mode="tags"
                                />
                            </IonCol>
                        }
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Connect Contact"
                                values={contactIds}
                                setValues={setContactIds}
                                options={contactOptions}
                                useAllOption={false}
                                emptyText="Not Set"
                                mode="tags"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">
                        {itemToUpdate ? 'Update Equipment' : 'Save New Equipment'}
                    </SeaButton>
                    {itemToUpdate && !disableDelete && canDelete('maintenanceSchedule') &&
                        <>
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteIfConfirmed(
                                        'equipment',
                                        itemToUpdate.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'equipment');
                                            setShowModal(false);
                                            if (setShowViewItemModal) {
                                                setShowViewItemModal(false);
                                            }
                                        },
                                        'equipment',
                                        itemToUpdate?.equipment,
                                        [itemToUpdate.vesselId]
                                    ).then(() => {
                                        // setShowModal(false);
                                        // setShowViewItemModal(false);
                                    });
                                }}
                            >
                                Delete Item
                            </SeaLinkButton>
                        </>
                    }
                </div>
            </form>
        </SeaModal>
    );
};

export default EditEquipment;
