import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, preventMultiTap } from '../../../../lib/util';
import { formatSeaDate, subtractInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { VesselCertificate } from '../../../../shared-state/VesselDocuments/vesselCertificates';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CategoriesData, getDefaultCategoryId, makeCategoryId } from '../../../../lib/categories';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaSelectEmailReminder from '../../../../components/SeaSelectEmailReminder/SeaSelectEmailReminder';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaSelectCategory from '../../../../components/SeaSelectCategory/SeaSelectCategory';

interface EditVesselCertificateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    itemToUpdate?: VesselCertificate,
    vesselCertificateCategories: CategoriesData
}

const EditVesselCertificate: React.FC<EditVesselCertificateProps> = ({showModal, setShowModal, itemToUpdate, level, vesselCertificateCategories}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                title: itemToUpdate.title ? ''+itemToUpdate.title : '',
                certNum: itemToUpdate.certNum ? ''+itemToUpdate.certNum : '',
                issuedBy: itemToUpdate.issuedBy ? ''+itemToUpdate.issuedBy : '',
                whenIssued: itemToUpdate.whenIssued ? formatSeaDate(itemToUpdate.whenIssued) : '',
                type: itemToUpdate.type ? ''+itemToUpdate.type : '',
                whenExpires: itemToUpdate.whenExpires ? formatSeaDate(itemToUpdate.whenExpires) : '',
                emailReminder: itemToUpdate.emailReminder ? ''+itemToUpdate.emailReminder : '',
                categoryId: itemToUpdate.categoryId ? ''+itemToUpdate.categoryId : '',
            };
        } else {
            return {
                title: '',
                certNum: '',
                issuedBy: ''+renderFullName(),
                whenIssued: formatSeaDate(),
                type: 'renewable',
                whenExpires: '',
                emailReminder: '',
                categoryId: getDefaultCategoryId('General', vesselCertificateCategories)
            };
        }
    }, [itemToUpdate, vesselCertificateCategories]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().max(500).required(),
            certNum: Yup.string().max(500),
            issuedBy: Yup.string().max(500),
            whenIssued: Yup.date().max(formatSeaDate()).required().min(...notTooOld),
            type: Yup.string().max(200).required(),
            whenExpires: Yup.date().when('type', {is: 'renewable', then: (schema) => schema.required().min(...notTooOld)}),
            emailReminder: Yup.string().when('type', {is: 'renewable', then: (schema) => schema.max(200)}),
            categoryId: Yup.string().max(500).required()
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            if (preventMultiTap('vesselCertificate')) { return; }
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                if (!vesselId) {
                    throw new Error('vesselId is not defined');
                }
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'vesselCertificates');

                let whenToRemind: number | undefined = undefined;
                if (data.type === 'renewable' && data.whenExpires && data.emailReminder) {
                    whenToRemind = subtractInterval(data.whenExpires, data.emailReminder);
                };
                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'vesselCertificates', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            title: data.title,
                            certNum: data.certNum ? data.certNum : deleteValue,
                            issuedBy: data.issuedBy ? data.issuedBy : deleteValue,
                            whenIssued: toMillis(data.whenIssued),
                            whenExpires: data.whenExpires ? toMillis(data.whenExpires): deleteValue,
                            emailReminder: data.emailReminder ? data.emailReminder: deleteValue,
                            categoryId: makeCategoryId(
                                data.categoryId,
                                vesselCertificateCategories,
                                deleteValue,
                                batch,
                                'vesselCertificateCategories',
                                'vesselId',
                                vesselId,
                                {}
                            ),
                            whenToRemind: whenToRemind,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'vesselCertificates', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'vesselCertificates',
                        itemToUpdate.id,
                        data.title,
                        [itemToUpdate.vesselId]
                    );
                } else {
                    if (!vesselId) {
                        throw new Error('vesselId is not defined');
                    }
                    const newRef = doc(collection(firestore, 'vesselCertificates'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselId: vesselId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        title: data.title,
                        certNum: data.certNum ? data.certNum : undefined,
                        issuedBy: data.issuedBy ? data.issuedBy : undefined,
                        whenIssued: toMillis(data.whenIssued),
                        type: data.type,
                        whenExpires: (data.type === 'renewable' && data.whenExpires) ? toMillis(data.whenExpires): undefined,
                        emailReminder: (data.type === 'renewable' && data.emailReminder) ? data.emailReminder: undefined,
                        whenToRemind: whenToRemind,
                        state: 'active',
                        categoryId: makeCategoryId(
                            data.categoryId,
                            vesselCertificateCategories,
                            undefined,
                            batch,
                            'vesselCertificateCategories',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        files: seaFilesToValue(files),
                        isShoreFacility: vessel?.isShoreFacility ? true : undefined,
                        touched: serverTimestamp()
                    });

                    saveFileRefs(batch, files, 'vesselCertificates', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'vesselCertificates',
                        newRef.id,
                        data.title,
                        [vesselId]
                    );
                }

                onCollectionUpdated(batch, 'vesselCertificates');

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} vessel certificate ${data?.title}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload vessel certificates files`, error.message, error, {
                        files: seaFilesToValue(files),
                        data,
                        itemToUpdate
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files,itemToUpdate?.files]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={(itemToUpdate ? 'Edit ' : 'Add New ') + (vessel?.isShoreFacility ? 'Certification' : 'Certificate')}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                          	<SeaInput
								label={(vessel?.isShoreFacility ? 'Certification' : 'Certificate') + ' Title'}
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label={(vessel?.isShoreFacility ? 'Certification' : 'Certificate') + ' #'}
								name="certNum"
								value={values.certNum}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.certNum ? errors.certNum : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Issued By"
								name="issuedBy"
								value={values.issuedBy}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.issuedBy ? errors.issuedBy : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
								label="Issue Date"
								name="whenIssued"
								value={values.whenIssued}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								error={touched.whenIssued ? errors.whenIssued : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
							<SeaSelect
                                disabled={itemToUpdate ? true : false}
                                label={(vessel?.isShoreFacility ? 'Certification' : 'Certificate') + ' Type'}
                                name="type"
                                value={values.type}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.type ? errors.type : ''}
                            >
                                <IonSelectOption value="renewable">Renewable {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</IonSelectOption>
                                <IonSelectOption value="nonExpiring">Non-Expiring {vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelectCategory
                                disabled={!vesselCertificateCategories}
                                categories={vesselCertificateCategories}
                                label="Category"
                                name="categoryId"
                                initialCategoryId={initialValues.categoryId}
                                categoryId={values.categoryId}
                                otherPlaceholder="Add New Category"
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.categoryId ? errors.categoryId : ''}
                            />
                        </IonCol>
                        {values.type === 'renewable' && 
                            <>
                                <IonCol size="6">
                                    <SeaDate
                                        label="Expiry Date"
                                        name="whenExpires"
                                        value={values.whenExpires}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenExpires ? errors.whenExpires : ''}
                                    />
                                </IonCol>
                                <IonCol size="6">
                                    <SeaSelectEmailReminder
                                        label="Set Email Reminder"
                                        name="emailReminder"
                                        value={values.emailReminder}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        error={touched.emailReminder ? errors.emailReminder : ''}
                                    />
                                </IonCol>
                            </>
                        }
                        <IonCol size="6"></IonCol>
                        <IonCol size="12">

                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="vesselCertificates"
                                field="files"
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update ' : 'Save '}{vessel?.isShoreFacility ? 'Certification' : 'Certificate'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditVesselCertificate;
