import React, { useEffect, useState, useRef } from 'react';
import { IonSpinner, isPlatform } from '@ionic/react';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { pdf } from '@react-pdf/renderer';
import { reportError } from '../../managers/ErrorsManager/ErrorsManager';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import { convertBlobToBase64, toSafeFilename, writeBlobFile } from '../../lib/files';
import SeaAlert from '../SeaAlert/SeaAlert';
import './SeaPdfGenerator.css';

interface SeaPdfGeneratorProps {
    onCompleted: () => void;
    MakePdf: () => JSX.Element | null
    pdfTitle?: string,
    requestedFrom: string // Where was this PDF generation requested from?,
    timeoutMillis?: number // This was added as if there is a large amount of data to be processed then it overloads the app before the modal opens
}

const SeaPdfGenerator: React.FC<SeaPdfGeneratorProps> = ({ onCompleted, MakePdf, pdfTitle, requestedFrom, timeoutMillis = 300 }) => {
    const [generatingPdf, setGeneratingPdf] = useState(true);
    const [showAlert, setShowAlert] = useState(true);
    const [pdfReady, setPdfReady] = useState(false); // Only useful for desktop (not hybrid)
    const [pdfBase64, setPdfBase64] = useState<any>(undefined);
    const showRef = useRef(true);

    useEffect(() => {
        if (generatingPdf && MakePdf && MakePdf()) {
            setPdfReady(false);
            setPdfBase64(undefined);
            pdf(<MakePdf/>).toBlob().catch((e) => {
                console.error(e);
                if (e instanceof Error) {
                    console.error('Error message:', e.message);
                    console.error('Error stack:', e.stack);
                }
                throw e;
            }).then((blob) => {
                if (!showRef.current) return Promise.reject('cancelled');
                if (isPlatform('hybrid')) {
                    return writeBlobFile(
                        blob,
                        `${toSafeFilename(pdfTitle)}.pdf`,
                        Directory.Cache
                    ).catch((error) => {
                        console.log(`Failed to write blob!`, error);
                        alertMessage(`Failed to write blob! ${error.message}`);
                    }).then((uri: string | void) => {
                        if (!showRef.current) return Promise.reject('cancelled');
                        setGeneratingPdf(false);
                        setShowAlert(false);
                        if (uri) {
                            return FileOpener.open({filePath: uri, contentType: 'application/pdf', openWithDefault: true});
                        } else {
                            alertMessage(`Failed to open PDF. File not found.`);
                        }
                    });
                } else { // not hybrid
                    return convertBlobToBase64(blob).then((base64) => {
                        if (!showRef.current) return Promise.reject('cancelled');
                        setPdfBase64(base64);
                    });
                }
            }).then(() => {
                if (!showRef.current) return Promise.reject('cancelled');
                setPdfReady(true);
                if (isPlatform('hybrid')) {
                    setGeneratingPdf(false);
                    setShowAlert(false);
                }
                console.log('PDF ready');
            }).catch((error) => {
                if (error && error === 'cancelled') {
                    console.log('PDF generation cancelled');
                    setGeneratingPdf(false);
                    setShowAlert(false);
                // TODO: Unsure why this error occurs, but the result is successful.
                } else if (error instanceof Error && error.message.includes('Expected null or instance of Config')) {
                    console.warn('PDF generated successfully, but with a non-critical error:', error.message);
                    setPdfReady(true);
                    if (isPlatform('hybrid')) {
                        setGeneratingPdf(false);
                        setShowAlert(false);
                    }
                } else {
                    reportError(`Failed to generate PDF from ${requestedFrom} ${pdfTitle}`, error.message, error);
                }
            }).finally(() => {
                setGeneratingPdf(false);
            });
        }
    }, [generatingPdf, MakePdf, timeoutMillis, pdfTitle, setGeneratingPdf, requestedFrom]);

    return (
        <SeaAlert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            type="pdf"
            onClosed={onCompleted}
        >
            <div>
            {generatingPdf &&
                <div>
                    <IonSpinner name="crescent"
                        style={{
                            color: 'var(--ion-color-primary)',
                            width: '64px',
                            height: '64px'
                        }}
                    />
                    <br/>
                    <p>
                    Generating PDF, please wait...
                    </p>
                </div>
            }
            {!generatingPdf && pdfReady && !isPlatform('hybrid') &&
                <div>
                    PDF successfully generated.
                    <br/>
                    {/* Please use the following link...
                    <br/> */}
                    <p style={{ marginTop: '16px' }}>
                        <a
                            href={pdfBase64}
                            download={`${pdfTitle}.pdf`}
                            style={{
                                fontWeight: 500,
                                fontSize: '18px'
                            }}
                        >
                            Download <b>
                                {`${pdfTitle}.pdf`}
                            </b>
                        </a>
                    </p>
                </div>
            }
            </div>
        </SeaAlert>
    );
};

export default SeaPdfGenerator;
