import { useMemo, useState } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvUserNamesFromIds } from "../../lib/csv";
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { useVesselDrillReports } from '../../shared-state/VesselSafety/useVesselDrillReports';
import { DrillReport } from '../../shared-state/VesselSafety/drillReports';
import { sharedState } from '../../shared-state/shared-state';
import SeaExporterDateRange from '../../components/SeaExporter/SeaExporterDateRange';
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';

type SafetyDrillReportsCsvProps = {
    onCompleted: () => void
}

const SafetyDrillReportsCsv = ({onCompleted}: SafetyDrillReportsCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const vesselDrillsCompleted = useVesselDrillReports(dateRange);
    const vesselDrills = sharedState.vesselDrills.use(dateRange ? 1 : 0);

    const csvConfig: CsvConfig<DrillReport> | undefined = useMemo(() => {
        return [
            {
                name: 'Date Completed',
                value: (item) => formatCsvDate(item.whenCompleted)
            },
            {
                name: 'Drills Completed',
                value: (item) => 
                    (item.drills || [])?.map(drill => 
                        vesselDrills?.byId[drill.id]?.name || drill.name
                    ).join(', ')
            },
            {
                name: 'People Involved',
                value: (item) => formatCsvUserNamesFromIds(item.crewInvolvedIds)
            },
            {
                name: 'Location',
                value: (item) => item.location || ''
            },
            {
                name: 'Scenario',
                value: (item) => item.scenario || ''
            },
            {
                name: 'Equipment Used',
                value: (item) => item.equipment || ''
            },
            {
                name: 'Further Training',
                value: (item) => item.furtherTraining || ''
            },
            {
                name: 'Modification to Operating Procedures',
                value: (item) => item.modification || ''
            },
            {
                name: 'Signed Off',
                value: (item) => formatCsvBoolean(item.signature)
            }
        ];
    }, [vesselDrills?.byId]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Safety-Drill-Reports'} onCompleted={onCompleted} config={csvConfig} data={vesselDrillsCompleted} />
};

export default SafetyDrillReportsCsv