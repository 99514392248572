import React, { useMemo } from "react";
import { CsvConfig, formatCsvDate } from "../../lib/csv";
import { VesselCertificate } from "../../shared-state/VesselDocuments/vesselCertificates";
import { sharedState } from '../../shared-state/shared-state';
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';
import { renderCamelCase } from '../../lib/util';

type VesselCertificatesCsvProps = {
    onCompleted: () => void
}

export const VesselCertificatesCsv = ({onCompleted}: VesselCertificatesCsvProps) => {
    const vesselCertificates = sharedState.vesselCertificates.use();

    const csvConfig: CsvConfig<VesselCertificate> | undefined = useMemo(() => {
        return [
            {
                name: 'Name',
                value: (item) => item.title
            },
            {
                name: 'Certificate #',
                value: (item) => item.certNum || ''
            },
            {
                name: 'Issued By',
                value: (item) => item.issuedBy || ''
            },
            {
                name: 'Expiry',
                value: (item) => formatCsvDate(item.whenExpires)
            },
            {
                name: 'Status',
                value: (item) => renderCamelCase(item.state)
            },
            {
                name: 'Reminder',
                value: (item) => formatCsvDate(item.whenToRemind)
            }
        ];
    }, []);

    return <SeaCSVGenerator name={'Vessel-Certificates'} onCompleted={onCompleted} config={csvConfig} data={vesselCertificates?.all} />
};