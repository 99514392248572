import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { formatMonthISO } from '../../lib/datesAndTime';
import { canView } from '../Core/userPermissions';
import { Voyage } from './voyages';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { useEffect, useState } from 'react';

export type LicenseeVoyageData = {
    all: Voyage[],
    byVessel: { [key: string]: Voyage[] },
    vessels: string[],
    byCategory: { [key: string]: Voyage[] },
    categories: string[],
}

export const useLicenseeVoyages = (dateRange: DateRange | undefined, selectedVesselIds: string[] | undefined) => {
    const [completedVoyages, setCompletedVoyages] = useState<LicenseeVoyageData | undefined>(undefined);

    useEffect(() => {
        setCompletedVoyages(undefined);

        if (canView('logbook') && dateRange && selectedVesselIds?.length) {
            return onSnapshot(
                query(
                    collection(firestore, 'voyages'),
                    where('vesselId', 'in', selectedVesselIds),
                    where('state', 'in', ['started', 'completed']),
                    where('whenDeparted', '>=', dateRange.from),
                    where('whenDeparted', '<', dateRange.to + (24*60*60*1000)),
                    orderBy('whenDeparted', 'desc')
                ),
                (snap) => {
                    const _voyages = snap.docs.map((doc) => {
                        const voyage = {
                            id: doc.id,
                            ...doc.data()
                        } as Voyage;
                        return voyage;
                    }).sort((a, b) => {
                        if (a.state === 'started') return -1;
                        if (b.state === 'started') return 1;
                        if (a.whenArrived === undefined) return -1;
                        if (b.whenArrived === undefined) return 1;
                        return b.whenArrived - a.whenArrived;
                    });

                    const byVessel = {} as {
                        [category: string]: Voyage[]
                    };
                    const vessels: string[] = []; 
                    const byCategory = {} as {
                        [category: string]: Voyage[]
                    };
                    const categories: string[] = [];
                    _voyages.forEach((item) => {
                        // Setup vessels
                        if (byVessel[item.vesselId] === undefined) {
                            vessels.push(item.vesselId);
                            byVessel[item.vesselId] = [];
                        }
                        byVessel[item.vesselId].push(item);

                        // Setup categories
                        let whenArrived = item.whenArrived ? formatMonthISO(item.whenArrived) : formatMonthISO(Date.now());
                        if (byCategory[whenArrived] === undefined) {
                            categories.push(whenArrived);
                            byCategory[whenArrived] = [];
                        }
                        byCategory[whenArrived].push(item);
                    })
                    categories.sort().reverse();

                    setCompletedVoyages({
                        all: _voyages,
                        byVessel,
                        vessels,
                        byCategory,
                        categories
                    });

                }, (error) => {
                    // This should be very rare
                    console.log(`Failed to access voyages for vessels ${selectedVesselIds.join(', ')}, ${error.message}`);
                }
            );
        } 

    }, [dateRange, selectedVesselIds]);

    return completedVoyages;
}