import React, { useCallback } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { CategoriesData, renderCategoryName } from '../../../lib/categories';
import { ContactsData, Contact } from '../../../shared-state/Crew/contacts';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface CrewContactsListPdfProps {
    onCompleted: () => void,
    contactCategories: CategoriesData | undefined,
    contacts: ContactsData | undefined
}

const CrewContactsListPdf: React.FC<CrewContactsListPdfProps> = ({
    onCompleted,
    contactCategories,
    contacts
}) => {
    const vessel = sharedState.vessel.use();
    const title = 'Contacts / Suppliers' + (vessel !== undefined ? ' - ' + vessel.name : '');

    const MakePDF = useCallback(() => {
        if (!contactCategories || !contacts) return (<></>);

        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="landscape"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={styleItemColumn(1)}>
                            <Text>COMPANY</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Name</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>CONTACT #</Text>
                        </View>
                        <View style={styleItemColumn(2)}>
                            <Text>EMAIL</Text>
                        </View>
                        <View style={styleItemColumn(2)}>
                            <Text>ADDRESS</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {contacts && contactCategories?.ids?.map((categoryId: string) => {
                        return (
                            <React.Fragment key={categoryId}>
                                <View wrap={false} style={pdfStyles.itemsCategory}>
                                    <Text>{renderCategoryName(categoryId, contactCategories)}</Text>
                                </View>
                                {contacts.byCategoryId[categoryId].map((contact: Contact, index: number) => {
                                    return (
                                        <React.Fragment key={'contact-' + contact.id}>
                                            {index > 0 &&
                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                            }
                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(contact.company)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(contact.name)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(contact.number)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(2)}>
                                                    <Text>
                                                        {formatValue(contact.email)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(2)}>
                                                    <Text>
                                                        {formatValue(contact.address)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </React.Fragment>
                                    )
                                })}
                                <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                            </React.Fragment>
                        )
                    })}


                </Page>
            </Document>
        );
    }, [contactCategories, contacts, title]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="CrewTrainningList"
        />
    );
};

export default CrewContactsListPdf;
