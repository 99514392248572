import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './LexDropDown.css';

interface LexDropDownProps {
    children: ReactNode,
    title?: string,
    contentPrefix?: JSX.Element,
    defaultContent?: JSX.Element,
    value?: string,
    setValue?: (value: string) => void,
    onClick?: () => void,
    onChange?: (value: string) => void,
    disabled?: boolean,
    active?: boolean, // Used for toggles
    prefix?: JSX.Element,
    showSelectedContent?: boolean,
    maxWidth?: string
}

const LexDropDownContext = React.createContext({});

export function useLexDropDown(): any {
    return useContext(LexDropDownContext);
}

const LexDropDown: React.FC<LexDropDownProps> = ({
    children,
    title,
    contentPrefix,
    defaultContent = '',
    value,
    setValue,
    onClick,
    onChange,
    disabled,
    active,
    showSelectedContent = true,
    maxWidth
}) => {
    const [popover, setPopover] = useState({ show: false, x: 0, y: 0 });
    const [selectedContent, setSelectedContent] = useState<ReactNode>(defaultContent);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const hidePopover = () => {
        setPopover({ show: false, x: 0, y: 0 });
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (!popover.show) return;

            const target = event.target as Node;
            const isButtonClick = buttonRef.current?.contains(target);
            const isDropdownClick = dropdownRef.current?.contains(target);

            if (!isButtonClick && !isDropdownClick) {
                hidePopover();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [popover.show]);

    const shared = {
        selectedValue: value,
        setSelectedValue: setValue,
        setSelectedContent,
        onChange: (value: string) => {
            setPopover({ show: false, x: 0, y: 0 });
            if (onChange) {
                onChange(value);
            }
        },
        hidePopover
    };

    const calculateDropdownPosition = useCallback(() => {
        if (!dropdownRef.current || !buttonRef.current) return;
    
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
    
        let left = buttonRect.left;
        let top = buttonRect.bottom;
    
        // Check if dropdown overflows to the right
        if (left + dropdownRect.width > window.innerWidth) {
            left = Math.max(0, window.innerWidth - dropdownRect.width);
        }
    
        // Check if dropdown overflows the bottom of the viewport
        if (top + dropdownRect.height > window.innerHeight) {
            // If it overflows, position it above the button
            top = buttonRect.top - dropdownRect.height;
        }
    
        setPopover(current => ({
            ...current,
            x: left,
            y: top
        }));
    }, []);

    useEffect(() => {
        if (popover.show) {
            // Use requestAnimationFrame to ensure the DOM has updated
            requestAnimationFrame(() => {
                calculateDropdownPosition();
            });
        }
    }, [calculateDropdownPosition, popover.show]);

    useEffect(() => {
        if (value) {
            const selectedChild = React.Children.toArray(children).find(
                (child) => React.isValidElement(child) && child.props.value === value
            ) as React.ReactElement | undefined;

            if (selectedChild) {
                setSelectedContent(selectedChild.props.children);
            }
        }
    }, [value, children]);

    useEffect(() => {
        const handleResize = () => {
            if (popover.show) {
                calculateDropdownPosition();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [calculateDropdownPosition, popover.show]);

    return (
        <LexDropDownContext.Provider value={shared}>
            <div ref={buttonRef}>
                <button
                    className={`lex-button lex-drop-down-button no-select${disabled ? ' disabled' : ''}${active ? ' active' : ''}`}
                    disabled={disabled}
                    type="button"
                    title={title}
                    onClick={(e) => {
                        if (!buttonRef.current) return;
                        const rect = buttonRef.current.getBoundingClientRect();
                        setPopover((current) => ({
                            show: !current.show,
                            x: rect.left,
                            y: rect.bottom
                        }));
                        onClick?.();
                    }}
                    aria-label={title}
                    style={{
                        maxWidth: maxWidth ? maxWidth : 'unset'
                    }}
                >
                    {contentPrefix}{showSelectedContent && selectedContent}
                    <i className="lex-dropdown-icon" style={{ backgroundImage: 'url(/assets/lex/chevron-down.svg)' }}/>
                </button>
            </div>
            {popover.show && ReactDOM.createPortal(
                <div 
                    ref={dropdownRef}
                    className="lex-dropdown" 
                    style={{
                        position: 'fixed',
                        left: `${popover.x}px`,
                        top: `${popover.y}px`,
                        zIndex: 9999 // Ensure it appears above other elements
                    }}
                >
                    {children}
                </div>,
                document.body
            )}
        </LexDropDownContext.Provider>
    );
};

export default LexDropDown;
