import React, { useState, useMemo, useCallback } from 'react';
import { formatDp, formatValue } from '../../../../../lib/util';
import { formatDate } from '../../../../../lib/datesAndTime';
import { IonSelectOption } from '@ionic/react';
import { sharedState } from '../../../../../shared-state/shared-state';
import { renderCrewList } from '../../../../../shared-state/Core/users';
import { UserType } from '../../../../../shared-state/Core/user';
import { useCrewSeaTimeRecords } from '../../../../../shared-state/Crew/useCrewSeaTimeRecords';
import { openContextualHelp } from '../../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { SeaTimeRecord, useSkipperSeaTimeRecords } from '../../../../../shared-state/Crew/useSkipperSeaTimeRecords';
import SeaExporter, { ExportType } from '../../../../../components/SeaExporter/SeaExporter';
import SeaTimeRecordsPdf from '../../../../../exports/Crew/CrewParticulars/SeaTimeRecordsPdf';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import SeaSelect from '../../../../../components/SeaSelect/SeaSelect';
import SeaIcon from '../../../../../components/SeaIcon/SeaIcon';
import './SeaTimeTab.css';

export interface SeaTimeStats {
    days: number,
    hours: number,
    totalHours: number,
    totalDays: number
    officialHours: number
}

interface SeaTimeTabProps {
    showModal: boolean,
    selectedUser?: UserType
}

const SeaTimeTab: React.FC<SeaTimeTabProps> = ({
    showModal,
    selectedUser
}) => {
    const vessels = sharedState.vessels.use(showModal);
    const crewSeaTime = useCrewSeaTimeRecords(showModal ? selectedUser : undefined);
    const skipperSeaTime = useSkipperSeaTimeRecords(showModal ? selectedUser : undefined);
    const [filterSeaTimeType, setFilterSeaTimeType] = useState<string>('all');
    const [filterVesselId, setFilterVesselId] = useState<string>('all');
    const [exportType, setExportType] = useState<ExportType>();


    const filteredSeaTime: SeaTimeRecord[] | undefined = useMemo(() => {
        if (crewSeaTime && skipperSeaTime) {
            let records: SeaTimeRecord[];
            if (filterSeaTimeType === 'skipper') {
                records = [...skipperSeaTime];
            } else if (filterSeaTimeType === 'crew') {
                records = [...crewSeaTime];
            } else {
                records = [...skipperSeaTime, ...crewSeaTime];
                records.sort((a, b) => {
                    return b.whenVoyage - a.whenVoyage;
                });
            }
            if (filterVesselId !== 'all') {
                // Filter out records that don't match filterVesselId
                for (let i = records.length - 1; i >= 0; i--) {
                    if (records[i].vesselId !== filterVesselId) {
                        records.splice(i, 1);
                    }
                }
            }
            return records;
        }
        return undefined;
    }, [crewSeaTime, skipperSeaTime, filterVesselId, filterSeaTimeType]);

    const seaTimeStats: SeaTimeStats | undefined = useMemo(() => {
        if (selectedUser?.id && filteredSeaTime) {
            const stats = {
                days: 0,
                hours: 0,
                officialHours: 0,
                totalDays: 0,
                totalHours: 0
            };
            filteredSeaTime.forEach((record) => {
                stats.days += record.days as number;
                if (record.hours && record.hours[selectedUser.id as string]) {
                    record.hours[selectedUser.id as string].forEach((_hours: number) => {
                        stats.hours += _hours;
                        stats.officialHours += Math.min(_hours, 8);
                    });
                }
            });
            return stats;
        }
        return undefined;
    }, [filteredSeaTime, selectedUser?.id]);

    // const isUsingFilter = useMemo(() => {
    //     return (filterSeaTimeType && filterSeaTimeType !== 'all') || (filterVesselId && filterVesselId !== 'all');
    // }, [filterSeaTimeType, filterVesselId]);

    const formatCrewHours = useCallback((crewHours: { [key: string]: number[] } | undefined, isOfficial = false) => {
        if (
            selectedUser?.id &&
            crewHours &&
            crewHours[selectedUser.id] !== undefined
        ) {
            let hours = 0;
            if (isOfficial) {
                crewHours[selectedUser.id].forEach((_hours: number) => {
                    hours += Math.min(_hours, 8);
                });
            } else {
                crewHours[selectedUser.id].forEach((_hours: number) => {
                    hours += _hours;
                });
            }
            return ''+hours;
        }
        return '-';
    }, [selectedUser?.id]);

    return (<>
        <div className="sea-time-filters columns filters">
            <div>
                <SeaSelect
                    value={filterSeaTimeType}
                    width="200px"
                    zone="grey"
                    onchange={(e) => setFilterSeaTimeType(e.detail.value)}
                >
                    <IonSelectOption value='all'>All sea time</IonSelectOption>
                    <IonSelectOption value='skipper'>Skipper sea time</IonSelectOption>
                    <IonSelectOption value='crew'>Crew sea time</IonSelectOption>
                </SeaSelect>
            </div>
            <div>
                <SeaSelect
                    value={filterVesselId}
                    width="200px"
                    zone="grey"
                    onchange={(e) => setFilterVesselId(e.detail.value)}
                >
                    <IonSelectOption value="all">All Vessels</IonSelectOption>
                    {vessels?.all?.map((vessel) => {
                        return (
                            <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                        );
                    })}
                </SeaSelect>
            </div>
            <div style={{ flex: '0 1 50px', paddingRight: '0px' }}>
                <SeaExporter
                    zone="white"
                    setExportType={setExportType}
                    pdf={exportType === "pdf" && (
                        <SeaTimeRecordsPdf
                            onCompleted={() => setExportType(undefined)}
                            selectedUser={selectedUser}
                            filterSeaTimeType={filterSeaTimeType}
                            filterVesselId={filterVesselId}
                            filteredSeaTime={filteredSeaTime}
                            seaTimeStats={seaTimeStats}
                            formatCrewHours={formatCrewHours}
                        />
                    )}
                />
            </div>
            {seaTimeStats &&
                <div className="right">
                    <div className="sea-stat">
                        <div>{seaTimeStats.days}</div>
                        Days at sea
                    </div>
                    <div className="sea-stat">
                        <div>{formatDp(seaTimeStats.hours, 2, true)}</div>
                        Total hours
                    </div>
                    <div
                        className="sea-stat"
                        style={{
                            position: 'relative',
                            cursor: 'pointer'
                        }}
                        onClick={(e) => openContextualHelp(e, {
                            text: 'The purpose of official hours is, they can be used for applying for a new (or renewing) a Certificate of competency (COC). Official hours are calculated by using up to a maximum of 8 hours per day.'
                        })}
                    >
                        <div>{formatDp(seaTimeStats.officialHours, 2, true)}</div>
                        Official hours
                        <span style={{
                            //color: '#666',
                            position: 'absolute',
                            top: '3px',
                            right: '3px',
                            opacity: 0.4
                        }}>
                            <SeaIcon icon="help"/>
                        </span>
                    </div>
                </div>
            }
        </div>

        <SeaNoData
            dataName="sea time records"
            isLoading={!filteredSeaTime}
            hasNoData={filteredSeaTime && filteredSeaTime.length === 0}
            isUsingFilter={true}
        />

        <div className={`sea-time ${filterVesselId !== 'all' ? 'hide' : 'show'}-vessel ${filteredSeaTime && filteredSeaTime.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div>Date</div>
                <div>Vessel</div>
                <div>Skipper</div>
                <div>From</div>
                <div>To</div>
                <div>Days</div>
                <div>Hours</div>
                <div>Official Hours</div>
            </div>
            {filteredSeaTime?.map((item) => {
                return (
                    <div key={item.id} className="sea-card sea-row-history">
                        <div className="truncate">{formatDate(item.whenVoyage)}</div>
                        <div className="truncate">{formatValue(vessels?.byId[item.vesselId]?.name)}</div>
                        <div className="truncate">{renderCrewList(item.masterIds)}</div>
                        <div className="truncate">{formatValue(item.fromLocation)}</div>
                        <div className="truncate">{formatValue(item.toLocation)}</div>
                        <div className="truncate">{formatValue(item.days)}</div>
                        <div className="truncate">{formatCrewHours(item.hours)}</div>
                        <div className="truncate">{formatCrewHours(item.hours, true)}</div>
                    </div>
                );
            })}
        </div>
    </>);
};

export default SeaTimeTab;
