import { IonModal, createAnimation } from '@ionic/react';
import { OverlayEventDetail, IonModalCustomEvent } from '@ionic/core';
import React, { ReactNode, useEffect, useCallback, useRef, useState } from 'react';
import SeaScrollableArea from '../SeaScrollableArea/SeaScrollableArea';
import './SeaModalBox.css';

interface SeaModalBoxProps {
    children?: ReactNode,
    showModal: boolean,
    setShowModal: (showModalBox: boolean) => void,
    onOpened?: () => void,
    onClosed?: () => void,
    maxWidth?: string | number,
    maxHeight?: string | number
    width?: string | number
}

const SeaModalBox: React.FC<SeaModalBoxProps> = ({ children, showModal, maxHeight = 600, maxWidth = 600, width = '100%', setShowModal, onOpened, onClosed }) => {
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLIonModalElement>(null);

    useEffect(() => {
        if (showModal && onOpened) {
            onOpened();
        } else if (!showModal && onClosed) {
            setContainerHeight(0);
            onClosed();
        }
    }, [onClosed, onOpened, showModal]);

    const updateHeight = useCallback(() => {
        if (containerRef.current) {
            // setTimeout(() => {
                const height = containerRef.current!.getBoundingClientRect().height;
                setContainerHeight(height);
            // }, 50);
        }
    }, []);

    const onDidDismiss = (e: CustomEvent<OverlayEventDetail<any>>) => {
        setShowModal(false);
    }
    const onDidPresent = (e: IonModalCustomEvent<void>) => {
        updateHeight();
    }

    const enterAnimation = useCallback((baseEl: any) => {
        const backdropElement = baseEl.shadowRoot.querySelector('ion-backdrop');
        const wrapperElement = baseEl.shadowRoot.querySelector('.modal-wrapper');
        
        const backdropAnimation = createAnimation()
            .addElement(backdropElement!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')
    
        const wrapperAnimation = createAnimation()
            .addElement(wrapperElement!)
            .keyframes([
                { offset: 0, opacity: '0', transform: 'scale(0.8)' },
                { offset: 1, opacity: '0.99', transform: 'scale(1)' }
            ])
            .afterStyles({ opacity: '1' })
    
        const animation = createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(150)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    
        return animation;
    }, []);
    
    const leaveAnimation = useCallback((baseEl: any) => {
        const animation = enterAnimation(baseEl).direction('reverse');
        return animation;
    }, [enterAnimation]);

    return (
        <IonModal
            ref={modalRef}
            isOpen={showModal}
            mode="ios"
            showBackdrop={true}
            backdropDismiss={true}
            className={`sea-modal-box`}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            onDidDismiss={onDidDismiss}
            onDidPresent={onDidPresent}
            style={{
                // opacity: visible ? 1 : 0,
                '--width': maxWidth ? `min(${maxWidth}, calc(100vw - 40px))` : 'calc(100vw - 40px)',
                '--height': containerHeight ? `min(${maxHeight}, ${containerHeight}, calc(100vh - 40px))` : `min(${maxHeight}, calc(100vh - 40px))`
            }}
        >
            <SeaScrollableArea>
                <div
                    ref={containerRef}
                    className="modal-box-container"
                    style={{ 
                        maxWidth: maxWidth,
                        maxHeight: maxHeight,
                        width: maxWidth ? `min(${maxWidth}, calc(100vw - 40px))` : 'calc(100vw - 40px)',
                    }}
                >
                    {children}
                </div>
            </SeaScrollableArea>
        </IonModal>
    );
};

export default SeaModalBox;
