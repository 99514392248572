import { Directory, Filesystem } from "@capacitor/filesystem";
import { isPlatform } from "@ionic/react";
import { deleteFile } from "./filesToDelete";
import { convertBlobToBase64, FileExt, FileId, FileReference, FileState, getContentType, writeBlobFile } from "../../lib/files";
import { sharedState } from "../shared-state";
import { debugApp } from "../Core/debugging";

export type CacheMode = 'OFF' | 'LIMITED' | 'FULL';
export type CachedFileType = 'O' | 'F' | 'S' | 'T' | 'R'; // O=Original file, F=optimised full size image, T=Optimised thumbnail image, S=optimised signature png, R=Optimised rich text document
export type CachedFile = string; // <CachedFileType><FileId>.<FileExt>

export let filesDirectory = isPlatform('hybrid') ? Directory.Data : Directory.Cache;
export const filesFolder = 'SeaFluxFiles'; // Obsolete
export type FileCacheEntry = [
    state: FileState,
    id: FileId,
    ext: FileExt,
    collection: FileCollection | null,
    when: number | null,
    files: {
        [fileType in CachedFileType]?: number | null // file size (null indicates waiting to be downloaded)
    },
    numErrors?: number,
    error?: string
];

export let cachedFiles = {} as {
    [id: FileId]: FileCacheEntry
};

// IMPORTANT! This collection should be kept in sync with firebase/functions/files.js collections
export const fileCollections = {
    companyPlans: ['sfdoc'],
    companyDocuments: ['files','sfdoc'],
    crewCertificates: ['files'],
    customFormsCompleted: ['data...'],
    customFormVersions: ['form...'],
    dangerousGoods: ['imageFiles', 'msdsFiles'],
    drillReports: ['files','signature'],
    equipment: ['files'],
    equipmentManualDocuments: ['files'],
    hazards: ['files'],
    incidents: ['files', 'signature'],
    incidentReviews: ['files', 'signature'],
    jobs: ['files'],
    maintenanceTasksCompleted: ['files'],
    risks: ['files'],
    risksReviewed: ['files'],
    safetyCheckItems: ['files'],
    safetyCheckCompleted: ['files'],
    safetyEquipmentItems: ['files'],
    safetyEquipmentTaskCompleted: ['files'],
    safetyMeetingReports: ['files', 'signature'],
    SOPs: ['files','sfdoc'],
    // SOPsCompleted: ['files'], // Obsolete
    spareParts: ['files'],
    surveyReports: ['files'],
    trainingTasks: ['files'],
    trainingTaskReports: ['files'],
    // users: ['contractFiles','inductionFiles','skipperInductionFiles','crewInductionFiles'], // Obsolete
    // userDetails: ['contractFiles','inductionFiles','skipperInductionFiles','crewInductionFiles'], // Obsolete
    userDocuments: ['files'],
    vesselCertificates: ['files'],
    vesselDocuments: ['files', 'sfdoc'],
    vessels: ['images'],
    // voyages: ['files', 'signature'], // files obsolete
    voyages: ['signature'],
    voyageDocuments: ['files']
};

export type FileCollection = keyof typeof fileCollections;


// If in cache, return src with embedded base64
// If not, return Promise.reject()
export const getCachedFileSrc = (file: FileReference, fileType:CachedFileType = 'O', fallbackToThumbnail = false): Promise<string> => {
    const id = file.substring(1, 21);
    const load = (loadFileType: CachedFileType) => {
        const ext = file.substring(file.lastIndexOf('.') + 1);
        const contentType = getContentType(ext);
        return loadFileBase64(`${sharedState.licenseeId.current}/${loadFileType}${id}.${ext}`).then((base64) => {
            if (base64) {
                if (contentType === 'application/seaflux') {
                    return base64; // Just return data directly
                }
                return `data:${contentType};base64, ${base64}`;
            } else {
                return `data:text/plain;base64, ${base64}`;
            }
        }).catch((error) => {
            console.error('Error loading cached file', error);
            return Promise.reject();
        });
    };

    const entry = cachedFiles[id];
    if (entry) {
        const files = entry[5];
        if (files[fileType]) {
            return load(fileType);
        } else if (fileType === 'T' && files.F) {
            return load('F');
        } else if (files.O) {
            return load('O');
        } else if (files.T && fallbackToThumbnail) {
            return load('T'); // Worst case scenario: load thumbnail which will become pixelated
        }
        console.log(`[FileSync] Failed to load cached file with fileType ${fileType} for ${file} files.F=${files.F} entry.string=${JSON.stringify(entry)}`, entry);
        return Promise.reject(`FileType ${fileType} not found in cache for file ${file}`); // File not found in cache
    }
    console.log(`[FileSync] Failed to load cached file ${file}`, entry);
    return Promise.reject(`File not found in cache for file ${file}`); // File not found in cache
    // let cachedFile = cachedFiles[id];
    // if (cachedFile === undefined) {
    //     // try for original file
    //     cachedFile = cachedFiles[id];
    // }
    // if (cachedFile) {
    //     return loadFileBase64(`${filesFolder}/${sharedState.licenseeId.current}/${cachedFile}`).then((base64) => {
    //         if (base64) {
    //             if (contentType === 'application/seaflux') {
    //                 return base64; // Just return data directly
    //             }
    //             return `data:${contentType};base64, ${base64}`;
    //         } else {
    //             return `data:text/plain;base64, ${base64}`;
    //         }
    //     }).catch((error) => {
    //         console.error('Error loading cached file', error);
    //         return Promise.reject();
    //     });
    // } else {
    //     console.log(`Could not find cached file for ${file}!`);
    // }
};
// Get file URI for a cached file
export const getCachedFileUri = (file: FileReference, fileType: CachedFileType = 'O'): Promise<string> => {
    const id = file.substring(1, 21);

    const get = (getFileType: CachedFileType) => {
        const ext = file.substring(file.lastIndexOf('.') + 1);
        return Filesystem.getUri({
            path: `${sharedState.licenseeId.current}/${getFileType}${id}.${ext}`,
            directory: filesDirectory
        }).then((result) => {
            //console.log('[FileSync] getCachedFileUri result='+JSON.stringify(result));
            return result.uri;
        }).catch((error) => {
            console.error('[FileSync] Error getting cached file uri', error);
            return Promise.reject();
        });
    };

    const entry = cachedFiles[id];
    if (entry) {
        const files = entry[5];
        if (files[fileType]) {
            return get(fileType);
        } else if (fileType === 'T' && files.F) {
            return get('F');
        } else if (files.O) {
            return get('O');
        } else if (files.T) {
            return get('T'); // Worst case scenario: load thumbnail which will be magnified
        }
        console.log(`[FileSync] Failed to get URI fileType ${fileType} for ${file}`, entry);
        return Promise.reject(`FileType ${fileType} not found in cache for file ${file}`); // File not found in cache
    }
    return Promise.reject('Cached file missing: '+file);

    // let cachedFile = cachedFiles[`${fileType}${id}`];
    // if (cachedFile === undefined) {
    //     cachedFile = cachedFiles[id];
    // }
    // if (cachedFile) {
    //     return Filesystem.getUri({
    //         path: `${filesFolder}/${sharedState.licenseeId.current}/${cachedFile}`,
    //         directory: filesDirectory
    //     }).then((result) => {
    //         console.log('getCachedFileUri result='+JSON.stringify(result));
    //         return result.uri;
    //     }).catch((error) => {
    //         console.error('Error getting cached file uri', error);
    //         return Promise.reject();
    //     });
    // }
    // return Promise.reject('Cached file missing: '+file);
}












export const loadCachedFiles = () => {
    const cachedFilesJson = window.localStorage.getItem(`_${sharedState.licenseeId.current}_cachedFiles`);
    cachedFiles = cachedFilesJson ? JSON.parse(cachedFilesJson) : {};
};

let alreadySavingCachedFiles = false;
export const saveCachedFiles = () => {
    if (alreadySavingCachedFiles) return;
    alreadySavingCachedFiles = true;
    setTimeout(() => { // Stop cachedFiles database being saved more often than once per 0.1s
        window.localStorage.setItem(`_${sharedState.licenseeId.current}_cachedFiles`, JSON.stringify(cachedFiles));
        alreadySavingCachedFiles = false;
    }, 100);
};


















// Save file to local storage
export const saveFileToLocalStorage = (
    id: FileId,
    ext: FileExt,
    fileType: CachedFileType,
    blob: Blob,
    isState0 = false,
    onTimeout?: () => void
): Promise<void> => {
    let isCancelled = false;
    const timeout = setTimeout(() => {
        if (onTimeout) {
            isCancelled = true;
            onTimeout();
        }
    }, 60 * 1000);

    return writeBlobFile(
        blob,
        `${sharedState.licenseeId.current}/${fileType}${id}.${ext}`, 
        filesDirectory
    ).catch((error) => {
        debugApp('File Caching', `saveFileToLocalStorage error! ${fileType}${id}.${ext}`, error);
        return Promise.reject(error);
    }).then(() => {
        if (isCancelled) {
            debugApp('FileCaching', `saveFileToLocalStorage writeBlobFile timed out ${fileType}${id}.${ext}`);
            return Promise.reject('Timeout');
        }
        clearTimeout(timeout);
        //debugApp('File Caching', `saveFileToLocalStorage write_blob complete ${fileType}${id}.${ext}`);
        const size = blob.size; // bytes

        let entry = cachedFiles[id];
        const state = isState0 ? 0 : (fileType === 'O' ? 1 : 2);
        if (entry === undefined) {
            // Init FileCacheEntry
            entry = [state, id, ext, null, null, {}]
            cachedFiles[id] = entry;
        }
        entry[0] = Math.max(entry[0], state) as FileState; // Assume highest state is the real state
        const files = entry[5];
        files[fileType] = size; // Record file size (which means it exists)

        // Delete any obsolete files
        if (files.O && (
                (files.F && files.T) ||
                files.S ||
                files.R
            )
        ) {
            // Original file no longer needed
            deleteFile(`O${id}.${ext}`);
            delete files.O;
        }

        saveCachedFiles();

        //debugApp('File Caching', `saveFileToLocalStorage done! ${fileType}${id}.${ext}`);

        return Promise.resolve();
    });
};



export const loadFileBase64 = (path: string) => {
    return Filesystem.readFile({
        path: path,
        directory: filesDirectory
    }).then((content) => {
        if (typeof content.data === 'string') {
            return content.data as string;
        } else {
            return convertBlobToBase64(content.data).then((base64) => {
                // strip base64 content type preamble
                const index = (base64 as string).indexOf(';base64');
                if (index !== -1) {
                    return (base64 as string).substring(index + 8);
                }
                return base64 as string;
            });
        }
    });

    // This how we did it when we needed to turn large files into chunks...

    // const readBase64 = (base64s: string[], fileNumber: number): Promise<any> => {
    //     const path = `${_path}${(fileNumber > 1) ? `.${fileNumber}.__chunk` : ''}`;
    //     return Filesystem.readFile({
    //         path: path,
    //         directory: filesDirectory
    //     }).then((content) => {
    //         if (content?.data) {
    //             if (content.data && (content.data as string).length > 0) {
    //                 return readBase64([...base64s, content.data as string], fileNumber + 1);
    //             }
    //             return base64s;
    //         }
    //         return Promise.reject(`No data found for file ${path}`);
    //     }).catch((error) => {
    //         //if (error?.message && error.message.toLowerCase().indexOf('exist') !== -1) {
    //         if (fileNumber > 1) { // Probably just no *.__chunk file to load
    //             return base64s; // Nothing to add to base64
    //         }
    //         return Promise.reject(error?.message);
    //     });
    // };

    // return readBase64([], 1).then((base64s: string[]) => {
    //     if (base64s.length > 0) {
    //         if (base64s.length === 1) {
    //             return base64s[0];
    //         } else {
    //             // Need to combine base64 strings as you can't just concatenate them due to variable padding
    //             console.log(`Combining ${base64s.length} base64 chunks for ${_path}...`);
    //             const byteArrays = [] as Uint8Array[];
    //             let combinedLength = 0;

    //             for (let i = 0; i < base64s.length; i++) {
    //                 byteArrays[i] = toByteArray(base64s[i]);
    //                 combinedLength += byteArrays[i].length;
    //                 console.log(`>>> byteArrays[${i}].length=${byteArrays[i].length}`);
    //             }
    //             const combinedByteArray = new Uint8Array(combinedLength);
    //             let offset = 0;
    //             byteArrays.forEach(byteArray => {
    //                 combinedByteArray.set(byteArray, offset);
    //                 offset += byteArray.length;
    //             });

    //             return fromByteArray(combinedByteArray);
    //         }
    //     }
    //     return Promise.reject(`No data found for file ${_path}`);
    // });
};
