import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { UserType } from "../Core/user";
import { SeaTimeRecord } from "./useSkipperSeaTimeRecords";

export const useCrewSeaTimeRecords = (selectedUser?: UserType) => {
    const [crewSeaTimeRecords, setCrewSeaTimeRecords] = useState<SeaTimeRecord[]>();

    useEffect(() => {
        setCrewSeaTimeRecords(undefined);
        if (selectedUser) {
            return onSnapshot(
                query(collection(firestore, 'seaTimeRecords'), where('licenseeId', '==', selectedUser.licenseeId), where('state', '==', 'active'), where('crewIds', 'array-contains', selectedUser.id), orderBy('whenVoyage', 'desc')),
                (snap) => {
                    const items = snap.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        } as SeaTimeRecord;
                    });
                    setCrewSeaTimeRecords(items);
                },
                (error) => {
                    // This should be very rare
                    console.log(`Failed to access crew sea time records for user ${selectedUser.id} `, error);
                }
            );
        }
    }, [selectedUser]);

    return crewSeaTimeRecords;
};
