import React, { useCallback } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { getBasedOnBackgroundColour } from '../../../lib/util';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

const labelWidth = '3.5cm';
const categoryBackgroundColour = '#dcdcdc';
const categoryFontWeight = 600;
const labelBackgroundColour = '#ececec';
const rowStyle: Record<string, any> = {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.75pt 1cm 0.75pt 1cm',
    //alignItems: 'flex-end',
    alignItems: 'center'
};
const cellStyle: Record<string, any> = {
    fontSize: '9pt',
    fontWeight: 500,
    flex: '1 0 1',
    height: '100%',
    minHeight: '1.25cm',
    //backgroundColor: '#fff5f5',
    margin: '0.75pt 0.75pt 0.75pt 0.75pt',
    padding: '0.3cm',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
};
const headingStyle: Record<string, any> = {
    fontSize: '12pt',
    fontWeight: 600,
    color: '#004896',
    margin: '0.75cm 1cm 0.25cm 1cm'
};


interface RiskMatrixPdfProps {
    onCompleted: () => void,
}

const RiskMatrixPdf: React.FC<RiskMatrixPdfProps> = ({
    onCompleted
}) => {
    const licenseeSettings = sharedState.licenseeSettings.use()!;

    const MakePDF = useCallback(() => {
        return (
            <Document
                title="Risk Matrix"
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading="Risk Matrix"
                        //subHeading={(listType === 'prioritised') ? `Overdue & Due Within ${warnDays.safetyEquipmentChecks[0]} Days` : undefined}
                        userFullName={renderFullName()}
                        marginBottom="0"
                    />

                    <View style={{ height: '0.5cm' }}></View>
                    <View wrap={false} style={rowStyle}>
                        <View style={{
                            ...cellStyle,
                            flex: `0 1 ${labelWidth}`
                        }}>
                            <Text> </Text>
                        </View>
                        <View style={{
                            ...cellStyle,
                            backgroundColor: categoryBackgroundColour,
                            fontWeight: categoryFontWeight,
                            flex: `${licenseeSettings.riskRegister.consequences.columns.length} 0 1`
                        }}>
                            <Text>Consequence</Text>
                        </View>
                    </View>
                    <View wrap={false} style={rowStyle}>
                        <View style={{
                            ...cellStyle,
                            backgroundColor: categoryBackgroundColour,
                            fontWeight: categoryFontWeight,
                            flex: `0 1 ${labelWidth}`
                        }}>
                            <Text>Likelihood</Text>
                        </View>
                        {licenseeSettings.riskRegister.consequences.columns.map((id: number) => {
                            const consequence = licenseeSettings.riskRegister.consequences[`C${id}`] as { name: string, description: string };
                            return (
                                <View key={id} style={{
                                    ...cellStyle,
                                    backgroundColor: labelBackgroundColour
                                }}>
                                    <Text>
                                        {consequence.name}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                    {licenseeSettings.riskRegister.likelihoods.rows.map((rowId: number) => {
                        const likelihood = licenseeSettings.riskRegister.likelihoods[`L${rowId}`] as { name: string, description: string };
                        return (
                            <View key={rowId} wrap={false} style={rowStyle}>
                                <View style={{
                                    ...cellStyle,
                                    backgroundColor: labelBackgroundColour,
                                    flex: `0 1 ${labelWidth}`
                                }}>
                                    <Text>{likelihood.name}</Text>
                                </View>
                                {licenseeSettings.riskRegister.consequences.columns.map((columnId: number) => {
                                    const raw = licenseeSettings.riskRegister.matrix[`${rowId}-${columnId}`];
                                    const colour = raw.substring(0, 6);
                                    const name = raw.substring(7);
                                    return (
                                        <View key={columnId} style={{
                                            ...cellStyle,
                                            backgroundColor: `#${colour}`,
                                            color: getBasedOnBackgroundColour(colour, '#373946', '#FFFFFF'),
                                            fontWeight: getBasedOnBackgroundColour(colour, 500, 600)
                                        }}>
                                            <Text>{name}</Text>
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    })}
                    <View wrap={false}>
                        <View style={headingStyle}>
                            <Text>Evaluation Table</Text>
                        </View>
                        <View wrap={false} style={rowStyle}>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `0 1 ${labelWidth}`
                            }}>
                                <Text>Evaluation</Text>
                            </View>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `${licenseeSettings.riskRegister.consequences.columns.length} 0 1`,
                                textAlign: 'left'
                            }}>
                                <Text style={{ width: '100%' }}>Description</Text>
                            </View>
                        </View>
                        {licenseeSettings.riskRegister.evaluations.map((item: { name: string, color: string, description: string }) => {
                            return (
                                <View key={item.name} wrap={false} style={rowStyle}>
                                    <View
                                        style={{
                                            ...cellStyle,
                                            backgroundColor: `#${item.color}`,
                                            flex: `0 1 ${labelWidth}`,
                                            color: getBasedOnBackgroundColour(item.color, '#373946', '#FFFFFF'),
                                            fontWeight: getBasedOnBackgroundColour(item.color, 500, 600)
                                        }}
                                    >
                                        <Text>{item.name}</Text>
                                    </View>
                                    <View style={{
                                        ...cellStyle,
                                        textAlign: 'left',
                                        backgroundColor: labelBackgroundColour
                                    }}>
                                        <Text style={{ width: '100%' }}>{item.description}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View wrap={false}>
                        <View style={headingStyle}>
                            <Text>Likelihood Table</Text>
                        </View>
                        <View wrap={false} style={rowStyle}>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `0 1 ${labelWidth}`
                            }}>
                                <Text>Category</Text>
                            </View>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `${licenseeSettings.riskRegister.likelihoods.rows.length} 0 1`,
                                textAlign: 'left'
                            }}>
                                <Text style={{ width: '100%' }}>Description</Text>
                            </View>
                        </View>
                        {licenseeSettings.riskRegister.likelihoods.rows.map((id: number) => {
                            const likelihood = licenseeSettings.riskRegister.likelihoods[`L${id}`] as { name: string, description: string };
                            return (
                                <View key={id} wrap={false} style={rowStyle}>
                                    <View
                                        style={{
                                            ...cellStyle,
                                            backgroundColor: labelBackgroundColour,
                                            flex: `0 1 ${labelWidth}`
                                        }}
                                    >
                                        <Text>{likelihood.name}</Text>
                                    </View>
                                    <View style={{
                                        ...cellStyle,
                                        backgroundColor: labelBackgroundColour,
                                        textAlign: 'left'
                                    }}>
                                        <Text style={{ width: '100%' }}>{likelihood.description}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View wrap={false}>
                        <View style={headingStyle}>
                            <Text>Consequence Table</Text>
                        </View>
                        <View wrap={false} style={rowStyle}>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `0 1 ${labelWidth}`
                            }}>
                                <Text>Category</Text>
                            </View>
                            <View style={{
                                ...cellStyle,
                                backgroundColor: categoryBackgroundColour,
                                fontWeight: categoryFontWeight,
                                flex: `${licenseeSettings.riskRegister.consequences.columns.length} 0 1`,
                                textAlign: 'left'
                            }}>
                                <Text style={{ width: '100%' }}>Description</Text>
                            </View>
                        </View>
                        {licenseeSettings.riskRegister.consequences.columns.map((id: number) => {
                            const consequence = licenseeSettings.riskRegister.consequences[`C${id}`] as { name: string, description: string };
                            return (
                                <View key={id} wrap={false} style={rowStyle}>
                                    <View
                                        style={{
                                            ...cellStyle,
                                            backgroundColor: labelBackgroundColour,
                                            flex: `0 1 ${labelWidth}`
                                        }}
                                    >
                                        <Text>{consequence.name}</Text>
                                    </View>
                                    <View style={{
                                        ...cellStyle,
                                        backgroundColor: labelBackgroundColour,
                                        textAlign: 'left'
                                    }}>
                                        <Text style={{ width: '100%' }}>{consequence.description}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </Page>
            </Document>
        );
    }, [licenseeSettings]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle="Risk Matrix"
            MakePdf={MakePDF}
            requestedFrom="RiskMatrix"
        />
    );
};

export default RiskMatrixPdf;
