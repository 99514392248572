import React, { useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { formatValue, formatTextAreaText } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { DrillReport } from '../../../../shared-state/VesselSafety/drillReports';
import { deleteIfConfirmed } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { WriteBatch } from 'firebase/firestore';
import { SplittableBatch } from '../../../../lib/firebase';
import { useMultipleLinks } from '../../../../shared-state/Core/multiItemLinks';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import { getImgSrcFromString } from '../../../../lib/files';
import EditDrillReport from '../EditDrillReport/EditDrillReport';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaSignatureImage from '../../../../components/SeaSignatureImage/SeaSignatureImage';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import DrillReportPdf from '../../../../exports/VesselSafety/DrillReportPdf';


interface ViewDrillReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    selectedItem: DrillReport,
    level?: number,
    viewOnly?: boolean
}

const ViewDrillReport: React.FC<ViewDrillReportProps> = ({ showModal, setShowModal, selectedItem, level = 1 , viewOnly}) => {
    const vesselDrills = sharedState.vesselDrills.use(showModal);
    const drillIds = useMemo(() => selectedItem?.drills?.map(d => d.id) || [], [selectedItem?.drills]);
    const links = useMultipleLinks(drillIds);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();
    const signature = selectedItem?.signature ? getImgSrcFromString(selectedItem.signature, 'sig') : undefined;

    const onEditItem = () => {
        setShowEditItemModal(true);
    };

    // const links = useMemo(() => {
    //     const _links = [] as LinkType[];
    //     if (selectedItem?.drills) {
    //         for (const drill of selectedItem.drills) {
    //             if (drill.links) {
    //                 _links.push(...drill.links);
    //             }
    //         }
    //     }
    //     return _links;
    // }, [selectedItem?.drills]);

    return (
        <SeaModal
            title={`Drill Report by ${renderFullNameForUserId(selectedItem?.addedBy)}`}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            level={level}
            onOpened={() => { console.log(`Viewing drillReport ${selectedItem?.id}`); }}
            onPdf={() => setExportType('pdf')}
        >
            <div className="columns">
                <div className="rows">
                    <div>
                        <IonGrid className="form-grid view-item-with-image">
                            <IonRow>
                                <IonCol size="6">
                                    <div className="sea-label">Date Completed</div>
                                    <div>{formatDate(selectedItem?.whenCompleted)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Location</div>
                                    <div>{formatValue(selectedItem?.location)}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">People Involved</div>
                                    <div>
                                        {selectedItem?.crewInvolvedIds?.map((crewId, index) => {
                                            if (renderFullNameForUserId(crewId)) {
                                                if (index === 0) {
                                                    return renderFullNameForUserId(crewId);
                                                } else {
                                                    return `, ${renderFullNameForUserId(crewId)}`;
                                                }
                                            } else {
                                                return '-';
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Drills Completed</div>
                                    <div>
                                        {selectedItem?.drills?.map((drill, index) => {
                                            if (index === 0) {
                                                return (
                                                    vesselDrills?.byId[drill.id]
                                                        ? vesselDrills?.byId[drill.id].name
                                                        : drill.name
                                                )
                                            } else {
                                                return (
                                                    vesselDrills?.byId[drill.id]
                                                        ? `, ${vesselDrills?.byId[drill.id].name}`
                                                        : `, ${drill.name}`
                                                )
                                            }
                                        })}
                                    </div>
                                </IonCol>
                                {/* TODO: Add links */}
                                {/* <IonCol size="12">
                                    <SeaLinks ids={drillIds} links={links} viewOnly={viewOnly} level={level+1}/>
                                </IonCol> */}
                                <IonCol size="6">
                                    <div className="sea-label">Scenario</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.scenario))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Equipment</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.equipment))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Further Training</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.furtherTraining))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Modification to current operating procedures</div>
                                    <div>{formatValue(formatTextAreaText(selectedItem?.modification))}</div>
                                </IonCol>
                                <IonCol size="6">
                                    <div className="sea-label">Signed off by</div>
                                    <SeaSignatureImage file={selectedItem?.signature} />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
                <div className="right">
                    <SeaFileImage files={selectedItem?.files} size="medium" showOthers />
                </div>
            </div>

            <div style={{ height: '60px' }}></div>

            <div className="columns">
                <div className="columns">
                    <RequirePermissions
                        role="drills"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton zone="white" type="submit" onClick={(e) => onEditItem()}><SeaIcon icon="edit" slot="start" />Edit Report</SeaButton>
                        <RequirePermissions
                            role="companyDocuments"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => {
                                    deleteIfConfirmed(
                                        'drillReports',
                                        selectedItem.id,
                                        (batch: WriteBatch | SplittableBatch) => {
                                            onCollectionUpdated(batch, 'drillReports', selectedItem.vesselId);
                                            setShowModal(false);
                                        },
                                        'drill report',
                                        selectedItem?.drills?.map((drill) => drill.name).join(', ')
                                    );
                                }}
                            >
                                Delete Report
                            </SeaLinkButton>
                        </RequirePermissions>
                        <div className="spacer-wide"></div>
                    </RequirePermissions>
                </div>
            </div>

            {showModal &&
                <>
                    <EditDrillReport
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        itemToUpdate={selectedItem}
                        level={level + 1}
                    />
                    {exportType === 'pdf' && <DrillReportPdf
                        onCompleted={() => setExportType(undefined)}
                        selectedItem={selectedItem}
                        signature={signature}
                    />}
                </>
            }
        </SeaModal>
    );
};

export default ViewDrillReport;
