import React, { useMemo } from "react";
import { CsvConfig, formatCsvDate } from "../../lib/csv";
import { sharedState } from "../../shared-state/shared-state";
import { SurveyReport } from "../../shared-state/VesselDocuments/vesselSurveyReports";
import { renderCategoryName } from '../../lib/categories';
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';

type SurveyDocumentsProps = {
    onCompleted: () => void
}

const SurveyDocumentsCsv = ({onCompleted}: SurveyDocumentsProps) => {
    const surveyReports = sharedState.vesselSurveyReports.use();
    const vesselLocations = sharedState.vesselLocations.use();


    const csvConfig: CsvConfig<SurveyReport> | undefined = useMemo(() => {
        if (!vesselLocations) {
            return undefined;
        }
        return [
            {
                name: 'Title',
                value: (item) => item.title
            },
            {
                name: 'Date',
                value: (item) => formatCsvDate(item.whenSurveyed)
            },
            {
                name: 'Surveyor',
                value: (item) => item.surveyor || ''
            },
            {
                name: 'Present',
                value: (item) => item.personnelPresent || ''
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.location, vesselLocations)
            },
            {
                name: 'In/Out Water',
                value: (item) => item.inOrOutWater
            }
        ];
    }, [vesselLocations]);

    return <SeaCSVGenerator name={'Survey-Documents'} onCompleted={onCompleted} config={csvConfig} data={surveyReports?.all} />
};

export default SurveyDocumentsCsv