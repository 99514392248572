import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useState, useEffect } from "react";
import { sharedState } from "../shared-state";
import { SafetyEquipmentItem } from './safetyEquipmentItems';



export const useSafetyEquipmentItems = (shouldLoad?: boolean): SafetyEquipmentItem[] | undefined => {
    const vesselId = sharedState.vesselId.use();
    const [safetyEquipments, setSafetyEquipments] = useState<SafetyEquipmentItem[]>();

    useEffect(() => {
        setSafetyEquipments(undefined);
    
        if (shouldLoad && vesselId) {
            console.log('running useSafetyEquipmentItems', vesselId);
            return onSnapshot(
                query(
                    collection(firestore, 'safetyEquipmentItems'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    // where('whenAdded', '>=', dateRange.from),
                    // where('whenAdded', '<=', dateRange.to),
                    orderBy('whenAdded', 'desc'),
                ),
                (snap) => {
                    const items: SafetyEquipmentItem[] = [];
                    snap.docs.forEach((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as SafetyEquipmentItem;
                        items.push(item);
                    });
                    setSafetyEquipments(items);
                },
                (error) => {
                    console.log(`Failed to access SafetyEquipmentItems for vesselId=${vesselId}`, error.message, error, {
                        vesselId,
                    });
                }
            );
        }
    }, [shouldLoad, vesselId]);

    return safetyEquipments
};