import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { formatDatetime } from "../../lib/datesAndTime";
import { sharedState, SharedStateConfig } from "../shared-state";
import { firestore, storage } from "../../lib/firebase";
import { toByteArray } from 'base64-js';
import { doc, setDoc } from "firebase/firestore";
import { CachedFile, cachedFiles, FileCacheEntry, loadFileBase64, saveCachedFiles } from "./cachedFiles";
import { deleteFile } from "./filesToDelete";
import { FileId, getContentType } from "../../lib/files";
import { debugApp } from "../Core/debugging";

//
// Handles the queueing and uploading of files in the background.
// This is only used in hybrid apps. Desktop/browser uploading makes the user wait via FileUploadManager.
//

const maxSimultaneousFilesBeingUploaded = 1; // Must be 1 so that monitoring progress makes sense
const filesToUpload = [] as CachedFile[];
let filesBeingUploaded = 0;
const uploadErrorsAllowed = 3;
let uploadErrorsCount = 0;

export type FileUploadStatus = {
    totalFilesToUpload: number;
    filesLeft: number;
    progress: number; // Includes current bytes partially uploaded
    error?: any;
};

export const fileUploadStatusConfig: SharedStateConfig<FileUploadStatus> = {
    isAlwaysActive: true,
    default: {
        totalFilesToUpload: 0,
        filesLeft: 0,
        progress: 0
    }
};

export const triggerProcessFilesToUploadConfig: SharedStateConfig<string> = {
    isAlwaysActive: true,
    default: 'Never run',
    dependencies: ['isFileSyncReady', 'onlineStatus'],
    notes: 'Trigger processFilesToUpload when FileSync is ready and am online',
    run: (done, set, clear) => {
        done();
        if (!sharedState.isFileSyncReady.current) {
            set('FileSync not ready yet.');
        } else if (!sharedState.onlineStatus.current?.isOnline) {
            set('Waiting to come online.');
        } else {
            queueFilesStillWaitingToBeUploaded();
            processFilesToUpload();
            set(`Triggered ${formatDatetime()}`);
        }
    }
};

export const uploadFileInBackground = (filePath: CachedFile) => {
    // Push to filesToUploadQueue
    filesToUpload.push(
        filePath
    );
    //debugApp('File Uploading', `uploadFileInBackground ${filePath} filesToUpload.length=${filesToUpload.length}`);
    sharedState.fileUploadStatus.set((current) => {
        const totalFilesToUpload = (current?.totalFilesToUpload ?? 0) + 1;
        return {
            ...current,
            totalFilesToUpload: totalFilesToUpload,
            filesLeft: filesToUpload.length + filesBeingUploaded,
            progress: ((totalFilesToUpload - filesToUpload.length - filesBeingUploaded) / totalFilesToUpload)
        } as FileUploadStatus;
    });

    processFilesToUpload();
};

const queueFilesStillWaitingToBeUploaded  = () => {
    //debugApp('File Uploading', `queueFilesStillWaitingToBeUploaded`);
    const _filesToUpload = [] as CachedFile[];
    Object.values(cachedFiles).forEach((entry: FileCacheEntry) => {
        if (entry[0] === 0 && entry[5] && entry[5].O) { //  A 0 state file is waiting to be uploaded on this device
            _filesToUpload.push(`O${entry[1]}.${entry[2]}`);
        }
    });
    if (_filesToUpload.length > 0) {
        const alreadyUploading = {} as any;
        filesToUpload.forEach((fileToUpload: CachedFile) => {
            alreadyUploading[fileToUpload] = true;
        });
        _filesToUpload.forEach((fileToUpload: CachedFile) => {
            if (!alreadyUploading[fileToUpload]) {
                //debugApp('File Uploading', `queueFilesStillWaitingToBeUploaded uploadFileInBackground ${fileToUpload}`);
                uploadFileInBackground(fileToUpload);
            }
        });
    }
};


















const processFilesToUpload = () => {
    if (
        !sharedState.onlineStatus.current?.isOnline ||
        !sharedState.licenseeId.current ||
        filesBeingUploaded >= maxSimultaneousFilesBeingUploaded ||
        !filesToUpload?.length
    ) {
        //debugApp('File Uploading', `Giving up on processFilesToUpload`);
        if (filesToUpload.length === 0) {
            //debugApp('File Uploading', `There are no more filesToUpload`);
            sharedState.fileUploadStatus.clear();
        }
        return;
    }
    // Grab a fileToUpload from the queue to process
    filesBeingUploaded++;
    const fileToUpload = filesToUpload.shift() as string;

    // Process fileToUpload
    const id = fileToUpload.substring(1, 21) as FileId;
    //const fileType = fileToUpload[0] as CachedFileType;

    //debugApp('File Uploading', `fileToUpload=${fileToUpload}`);

    if (
        cachedFiles[id] &&
        //cachedFiles[id][5][fileType] &&
        cachedFiles[id][0] > 0
    ) {
        //debugApp('File Uploading', `File already uploaded`);
        console.log('[FileSync] File already must be uploaded ('+fileToUpload+')');
        deleteFile(fileToUpload);
        filesBeingUploaded--;
        sharedState.fileUploadStatus.set((current) => { // Update status
            return {
                ...current,
                filesLeft: filesToUpload.length + filesBeingUploaded,
                progress: ((current!.totalFilesToUpload - filesToUpload.length - filesBeingUploaded) / current!.totalFilesToUpload)
            } as FileUploadStatus
        });
        processFilesToUpload();
        return;
    }

    const ext = fileToUpload.substring(fileToUpload.lastIndexOf('.') + 1);
    let size = 0;
    //debugApp('File Uploading', `loadFileBase64 ${sharedState.licenseeId.current}/${fileToUpload}`);
    loadFileBase64(`${sharedState.licenseeId.current}/${fileToUpload}`).then((base64) => {
        console.log(`Uploading to storage files/${id}.${ext}`);
        //debugApp('File Uploading', `Uploading to storage files/${id}.${ext}`);
        const ref = storageRef(storage, `files/${id}.${ext}`);
        //const ref = storage.ref().child(`files/${id}.${ext}`);
        const now = Date.now();
        const metadata = {
            cacheControl: 'public,max-age=30000000',
            customMetadata: {
                licenseeIds: sharedState.licenseeId.current,
                whenUploaded: now,
                whenMetadata: now,
                //passcode: generateCode(20),
                fromDevice: true
                // Not available from device...
                // uploadedBy: userId
                // collection: file.collection,
                // field: file.field
            }
        } as any;
        // if (file.name) {
        //     metadata.contentDisposition = `attachment; filename="${file.name}"`;
        // }
        if (false) { //contentType) {
            //metadata.contentType = file.contentType;
        } else if (ext && getContentType(ext)) {
            metadata.contentType = getContentType(ext);
        }

        let uploadTask = undefined as any;
        if (base64) {
            //uploadTask = uploadString(ref, content.data, 'base64', metadata);
            //uploadTask = uploadString(ref, content.data, 'base64', metadata);
            const byteArray = toByteArray(base64);
            size = byteArray.length; // Can we just use Blob instead?
            uploadTask = uploadBytesResumable(
                ref,
                toByteArray(base64),
                metadata
            );
        }
        return doUploadTask(uploadTask);

    }).then(() => {
        //debugApp('File Uploading', `Completed file upload! ${fileToUpload}`);
        console.log('Completed file!', fileToUpload);
        
        // Update file state to 1 (uploaded)

        setDoc(
            doc(firestore, 'files', id),
            {
                state: 1
            },
            { merge: true }
        ).then(() => {
            // Successfully updated state in file document
            //debugApp('File Uploading', `Updated state to 1 for file id=${id}`);
            // Update cachedFiles database
            let entry = cachedFiles[id];
            if (entry === undefined) {
                entry = [1, id, ext, null, null, {}];
                cachedFiles[id] = entry;
            }
            entry[0] = 1;
            entry[5].O = size;
            saveCachedFiles();
            return Promise.resolve();

        }).catch((error: any) => {
            console.log(`Failed to update file.id=${id} after successfully uploading ${sharedState.licenseeId.current}/${id}.${ext}`);
            debugApp('File Uploading', `Failed to update file.id=${id} after successfully uploading ${sharedState.licenseeId.current}/${id}.${ext}`);
        });

        return Promise.resolve();
    }).catch((error) => {
        debugApp('File Uploading', `Failed to upload ${fileToUpload}. error.message=${error.message}`);
        console.log('ERROR processFilesToUpload', error);
        if (uploadErrorsCount > uploadErrorsAllowed) {
            uploadErrorsCount = 0;
            sharedState.fileUploadStatus.set((current) => { // Update status
                return {
                    ...current,
                    error: {
                        ...error,
                        data: {
                            cause: `uploadErrorsCount exceeded ${uploadErrorsAllowed}`
                        }
                    }
                } as FileUploadStatus
            });
        } else {
            console.log('Pushing '+fileToUpload+' to upload queue');
            if (sharedState.onlineStatus.current?.isOnline) {
                uploadErrorsCount++;
            }
            uploadFileInBackground(fileToUpload); // Queue up file to be attempted to be uploaded again
        }
    }).finally(() => {
        filesBeingUploaded--;
        sharedState.fileUploadStatus.set((current) => { // Update status
            return {
                ...current,
                filesLeft: filesToUpload.length + filesBeingUploaded,
                progress: ((current!.totalFilesToUpload - filesToUpload.length - filesBeingUploaded) / current!.totalFilesToUpload)
            } as FileUploadStatus
        });
        processFilesToUpload();
    });
};


const doUploadTask = (uploadTask: any): Promise<void> => {
    return new Promise((resolve, reject) => {
        //debugApp('File Uploading', `doUploadTask`);
        sharedState.fileUploadStatus.set((current) => {
            return {
                ...current!,
                progress: ((current!.totalFilesToUpload - filesBeingUploaded - filesToUpload.length) / current!.totalFilesToUpload)
            };
        });
        uploadTask.on('state_changed',
            (snap: any) => {
                let progress = (snap.bytesTransferred / snap.totalBytes);
                // progress *= (1.0 / filesToUpload.length);
                // progress += (1.0 / filesToUpload.length) * index;
                console.log('Progress: ' + progress);
                sharedState.fileUploadStatus.set((current) => {
                    return {
                        ...current!,
                        progress: (progress / current!.totalFilesToUpload)
                            + ((current!.totalFilesToUpload - filesBeingUploaded - filesToUpload.length) / current!.totalFilesToUpload)
                    };
                });
            },
            (error: any) => {
                console.error('upload error!', error);
                switch (error.code) {
                    case 'storage/unauthorized':
                        return reject({message: 'Access to upload was denied'});
                    case 'storage/canceled':
                        return reject({code: 'cancelled', message: 'Upload was cancelled'});
                    // case 'storage/unknown':
                    //     // Unknown error occurred, inspect error.serverResponse
                    //     break;
                }
                return reject(error);
            },
            () => {
                //debugApp('File Uploading', `uploaded ${filesToUpload}`);
                sharedState.fileUploadStatus.set((current) => {
                    return {
                        ...current!,
                        progress: ((current!.totalFilesToUpload - filesToUpload.length - filesBeingUploaded + 1) / current!.totalFilesToUpload)
                    };
                });
                resolve();
            }
        );
    });
};
