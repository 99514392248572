import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { useEffect, useState } from "react";
import { SeaTimeRecord } from "./useSkipperSeaTimeRecords";
import { sharedState } from '../shared-state';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';

type LicenseeSeaTimeRecordData = {
    byId: Record<string, SeaTimeRecord>
    byUserId: Record<string, SeaTimeRecord[]>,
};

export const useLicenseeSeaTime = (dateRange?: DateRange) => {
    const licenseeId = sharedState.licenseeId.use();
    const [crewSeaTimeRecords, setCrewSeaTimeRecords] = useState<LicenseeSeaTimeRecordData>();

    useEffect(() => {
        setCrewSeaTimeRecords(undefined);
        if (licenseeId && dateRange) {
            return onSnapshot(
                query(
                    collection(firestore, 'seaTimeRecords'),
                    where('licenseeId', '==', licenseeId),
                    where('state', '==', 'active'),
                    where('whenVoyage', '>=', dateRange?.from),
                    where('whenVoyage', '<=', dateRange?.to),
                    orderBy('whenVoyage', 'desc')
                ),
                (snap) => {
                    const byId: Record<string, SeaTimeRecord> = {};
                    const byUserId: Record<string, SeaTimeRecord[]> = {};
                    snap.docs.map((doc) => {
                        const record = {
                            id: doc.id,
                            ...doc.data(),
                        } as SeaTimeRecord;
                        byId[doc.id] = record;
                        if (record.masterIds) {
                            for (const skipperId of record.masterIds) {
                                if (!byUserId[skipperId]) {
                                    byUserId[skipperId] = [];
                                }
                                byUserId[skipperId].push(record);
                            }
                        }
                        for (const userId of record.crewIds) {
                            if (!byUserId[userId]) {
                                byUserId[userId] = [];
                            }
                            byUserId[userId].push(record);
                            
                        }
                        return record;
                    });
                    setCrewSeaTimeRecords({
                        byId,
                        byUserId
                    });
                },
                (error) => {
                    // This should be very rare
                    console.log(`Failed to access crew sea time records for licensee ${licenseeId} `, error);
                }
            );
        }
    }, [dateRange, licenseeId]);

    return crewSeaTimeRecords;
};
