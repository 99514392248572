import React, { useState, useEffect } from 'react';
import { makeFormElements } from '../../../../lib/customForms';
import { CustomFormTemplate } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormTemplates';
import { useCustomFormTemplateVersions } from '../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormTemplateVersions';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import CustomFormElement, { CustomFormElementType } from '../../../../components/CustomFormElement/CustomFormElement';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import './ViewCustomFormTemplate.css'

interface ViewCustomFormTemplateProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowBrowseTemplatesModal: (showModal: boolean) => void,
    setShowEditCustomFormModal: (showModal: boolean) => void,
    setEditCustomFormItem: (showModal: any) => void,
    selectedTemplate?: CustomFormTemplate;
    setTemplateToUse: (template: Partial<CustomFormTemplate>) => void,
    level?: number,
    //selectedItem?: CustomFormTemplate,
    //customFormVersions?: CustomFormVersion[] | undefined,
    //setShowEditCustomFormModal: (show: boolean) => void
}

const ViewCustomFormTemplate: React.FC<ViewCustomFormTemplateProps> = ({
    showModal,
    setShowModal,
    setShowBrowseTemplatesModal,
    setShowEditCustomFormModal,
    setEditCustomFormItem,
    selectedTemplate,
    setTemplateToUse,
    level
}) => {
    const [formElements, setFormElements] = useState<CustomFormElementType[]>([]);
    const templateVersion = useCustomFormTemplateVersions(selectedTemplate);

    useEffect(() => {
        setFormElements([]);
        if (templateVersion && selectedTemplate) {
            setFormElements(
                makeFormElements(templateVersion, selectedTemplate)
            );
        }
    }, [templateVersion, selectedTemplate]);

    const onUseTemplate = () => {
        setTemplateToUse({
            title: selectedTemplate?.title,
            version: templateVersion
        });
        setShowEditCustomFormModal(true);
        setEditCustomFormItem(undefined);
        setShowModal(false);
        setShowBrowseTemplatesModal(false);
    };

    const alignSelf = (id: string) => {
        return 'normal';
    }

    return (
        <SeaModal
            title={selectedTemplate?.title}
            showModal={showModal}
            setShowModal={setShowModal}
            level={level}
            size="semi-wide"
            noContentPadding={true}
        >
            <div className="custom-form-container complete-form preview-only">
                {formElements.map((element) => {
                    return (
                        <div key={element.n} style={{ width: `${element.width}%`, alignSelf: alignSelf(element.id) }}>
                            <CustomFormElement
                                element={element}
                                //setElement={(element: any) => updateElement(element)}
                                mode="view"
                                selectedElement={undefined}
                                onSelectElement={(element) => {
                                    // do nothing
                                }}
                            />
                        </div>
                    );
                })}
                <div className="view-modal-buttons" style={{ marginTop: '60px' }}>
                    <SeaButton onClick={(e) => onUseTemplate()}>
                        Use this template
                    </SeaButton>
                </div>
            </div>
        </SeaModal>
    );
};

export default ViewCustomFormTemplate;
