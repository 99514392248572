import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, View, Image, Text, Page } from '@react-pdf/renderer';
import { pdfStyles } from '../../../lib/pdf';
import { formatValue, formatTextAreaText, formatCurrency } from '../../../lib/util';
import { formatDate, formatEmailReminder } from '../../../lib/datesAndTime';
import { getImgSrc, getImgSrcFromExt, makeSeaFiles } from '../../../lib/files';
import { Job } from '../../../shared-state/VesselMaintenance/jobs';
import { renderCategoryName } from '../../../lib/categories';
import { sharedState } from '../../../shared-state/shared-state';
import { renderFullName } from '../../../shared-state/Core/users';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';

interface JobItemPdfProps {
    onCompleted: () => void,
    selectedItem: Job
}

const JobItemPdf: React.FC<JobItemPdfProps> = ({
    onCompleted,
    selectedItem
}) => {
    const equipment = sharedState.equipment.use(true);
    const vesselSystems = sharedState.vesselSystems.use(true);
    const vesselLocations = sharedState.vesselLocations.use(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imagesRef = useRef<{ reference?: number, element?: JSX.Element, src: string | Promise<string>, fileName: string }[]>([]);

    const reportTitle = useMemo(() => {
        if (selectedItem) {
            return `Job Item`;
        }
        return '';
    }, [selectedItem]);

    const reportFilename = useMemo(() => {
        if (selectedItem) {
            return `Job Item - ${selectedItem.jobNum}`;
        }
        return '';
    }, [selectedItem]);

    const selectedEquipment = useMemo(() => {
        if (
            equipment?.byId &&
            selectedItem?.equipmentId &&
            equipment.byId[selectedItem.equipmentId]
        ) {
            return equipment.byId[selectedItem.equipmentId];
        }
        return undefined;
    }, [equipment, selectedItem]);

    const getImageSrcs = useCallback((files: string[], setLoaded: (value: React.SetStateAction<boolean>) => void) => {
        imagesRef.current = [];
        if (files?.length) {
            for (const _file of files) {
                const file = makeSeaFiles([_file])[0];
                const ext = file.ext?.toLowerCase();
                const isImage = (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
                const imgSrc = (
                    isImage ?
                        getImgSrc(
                            file.state!,
                            file.id!,
                            file.ext!,
                            'full'
                        ) :
                        getImgSrcFromExt(file.ext, 'medium')
                );
                const fileName = file.name ? file.name : `${file.id}.${file.ext}`;
                if (isImage) {
                    imagesRef.current.push({
                        src: imgSrc,
                        fileName: fileName
                    });
                }
            }
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getImageSrcs(selectedItem.files, setImagesLoaded);
    }, [getImageSrcs, selectedItem.files]);



    const MakePDF = useCallback(() => {
        if (!selectedItem) return (<></>);
        return (
            <Document
                title={reportFilename}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={reportTitle}
                        subHeading=''
                        userFullName={renderFullName()}
                    />

                    {/* Task and Job */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Task</Text>
                            <Text>{formatValue(formatTextAreaText(selectedItem?.task))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Job #</Text>
                            <Text>
                                {formatValue(selectedItem?.jobNum)}
                            </Text>
                        </View>
                    </View>

                    {/* Description */}
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Description</Text>
                            <Text>
                                {formatTextAreaText(selectedItem?.description)}
                            </Text>
                        </View>
                    </View>

                    {/* Priority and Assigned To and Job Tags */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Priority</Text>
                                <Text>{selectedItem?.priority && selectedItem?.priority.charAt(1).toUpperCase() + selectedItem?.priority.slice(2)}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Assigned To</Text>
                                <Text>
                                    {formatValue(selectedItem?.assignedTo?.name)}
                                    {selectedItem?.assignedTo?.contactId && ' (Contact)'}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Job Tags</Text>
                                <Text>
                                    {formatValue(selectedItem?.tags?.join(', '))}
                                </Text>
                            </View>
                        </View>
                    }

                    {/* System and Equipment and Location */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>System</Text>
                                <Text>{formatValue(renderCategoryName(selectedEquipment?.systemId, vesselSystems))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Equipment</Text>
                                <Text>
                                    {formatValue(selectedEquipment?.equipment)}
                                    {selectedEquipment?.state === 'deleted' && ' (deleted)'}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Location</Text>
                                <Text>
                                    {formatValue(renderCategoryName(selectedItem?.locationId, vesselLocations))}
                                </Text>
                            </View>
                        </View>
                    }

                    {/* Maintenance Tags and Critical Equipment and Due Date */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Maintenance Tags</Text>
                                <Text>{formatValue(selectedItem?.maintenanceTags?.join(', '))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Critical Equipment</Text>
                                <Text>
                                    {formatValue(selectedEquipment ? selectedEquipment.isCritical ? 'Yes' : 'No' : '')}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Due Date</Text>
                                <Text>
                                    {formatValue(selectedItem?.whenDue && formatDate(selectedItem?.whenDue))}
                                </Text>
                            </View>
                        </View>
                    }

                    {/* Email Reminder and Estimated Job Cost */}
                    {
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Email Reminder</Text>
                                <Text>{formatValue(formatEmailReminder(selectedItem?.emailReminder))}</Text>
                            </View>
                            <View style={pdfStyles.column3}>
                                <Text style={pdfStyles.label}>Estimated Job Cost</Text>
                                <Text>
                                    {selectedItem?.estimatedCost ? formatCurrency(selectedItem.estimatedCost, 2) : '-'}
                                </Text>
                            </View>
                            <View style={pdfStyles.column3}>
                            </View>
                        </View>
                    }

                    {/* State */}
                    {(selectedItem?.state === 'completed' || selectedItem?.state === 'deleted') &&
                        <View wrap={false} style={pdfStyles.row}>
                            <Text>
                                Job has been marked as {selectedItem?.state}
                            </Text>
                        </View>
                    }
                    
                </Page>
                {imagesRef.current.length > 0 &&
                    <Page
                        style={{
                            ...pdfStyles.page
                        }}
                        orientation="portrait"
                        size="A4"
                        wrap={true}
                    >
                        {imagesRef.current.map((img: any, index: number) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        maxWidth: '19cm',
                                        margin: '0 1cm 0 1cm',
                                        paddingBottom: '0.5cm'
                                    }}
                                    wrap={false}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{
                                            alignSelf: 'center',
                                            padding: '0 0.02cm 0.25cm 0',
                                            fontSize: '7pt'
                                        }}>
                                            {index + 1}
                                        </Text>
                                        <Text>{img.fileName}</Text>
                                    </View>
                                    <Image
                                        src={img.src}
                                        style={{
                                            objectFit: 'scale-down',
                                            objectPositionX: 0,
                                            objectPositionY: 0,
                                        }}
                                    />
                                </View>
                            );
                        })}
                    </Page>
                }
            </Document>
        )
    }, [selectedItem, reportFilename, reportTitle, selectedEquipment, vesselLocations, vesselSystems]);

    if (imagesLoaded) {
        return (
            <SeaPdfGenerator
                onCompleted={onCompleted}
                pdfTitle={reportFilename}
                MakePdf={MakePDF}
                requestedFrom="JobItem"
            />
        );
    } else return <></>
};
export default JobItemPdf;