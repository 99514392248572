// const completedSafetyCheckItems = useCompletedSafetyCheckItems(showModal ? selectedItem : undefined);

import { useMemo, useState } from "react";
import { renderCategoryName } from "../../lib/categories";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvFiles, formatCsvString, formatCsvUserNamesFromIds } from "../../lib/csv";
import { sharedState } from "../../shared-state/shared-state";
import { SafetyCheckItem } from "../../shared-state/VesselSafety/safetyCheckItems";
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import { SafetyCheckCompleted } from '../../shared-state/VesselSafety/useCompletedSafetyCheckItems';
import { DateRange } from '../../components/SeaDateRange/SeaDateRange';
import { useSafetyCheckItems } from '../../shared-state/VesselSafety/useSafetyCheckItems';
import { useVesselCompletedSafetyCheckItems } from '../../shared-state/VesselSafety/useVesselCompletedSafetyCheckItems';
import { formatInterval } from "../../lib/datesAndTime";
import SeaExporterDateRange from '../../components/SeaExporter/SeaExporterDateRange';
import SeaCSVGenerator from '../../components/SeaCSV/SeaCSVGenerator';

type SafetyEquipmentChecksCsvProps = {
    onCompleted: () => void
}

const SafetyEquipmentChecksCsv = ({onCompleted}: SafetyEquipmentChecksCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const safetyChecks = useSafetyCheckItems(dateRange ? true : false);
    const completedSafetyChecksById = useVesselCompletedSafetyCheckItems(dateRange);

    const csvConfig: CsvConfig<SafetyCheckItem> | undefined = useMemo(() => {
        if (!completedSafetyChecksById) {
            return undefined
        }
        return [
            {
                name: 'Safety Item',
                value: (item) => renderCategoryName(item.itemId, sharedState.vesselSafetyItems.current)
            },
            {
                name: 'Location',
                value: (item) => renderCategoryName(item.locationId, sharedState.vesselLocations.current)
            },
            {
                name: 'Category',
                value: (item) => renderCategoryName(item.categoryId, sharedState.safetyCheckCategories.current)
            },
            {
                name: 'Critical',
                value: (item) => formatCsvBoolean(item.isCritical)
            },
            {
                name: 'Task Description',
                value: (item) => formatCsvString(item.description)
            },
            {
                name: 'Interval',    
                value: (item) => formatInterval(item.interval)
            },
            {
                name: 'Assigned To',
                value: (item) => formatCsvUserNamesFromIds(item.assignedTo)
            },
            {
                name: 'Files',
                value: (item) => formatCsvFiles(item.files),
            },
            {
                name: 'Next Check',
                value: (item) => formatCsvDate(item.whenDue)
            },
            {
                name: 'Completed',
                items: (item) => completedSafetyChecksById[item.id],
                columns: () => [
                    {
                        name: 'Date Checked',
                        value: (item: SafetyCheckCompleted) => formatCsvDate(item.whenCompleted)
                    },
                    {
                        name: 'Checked By',
                        value: (item: SafetyCheckCompleted) => renderFullNameForUserId(item.completedBy)
                    },
                    {
                        name: 'Notes',
                        value: (item: SafetyCheckCompleted) => formatCsvString(item.notes)
                    }
                ]
            },
        ]
    }, [completedSafetyChecksById]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Safety-Equipment-Checks'} onCompleted={onCompleted} config={csvConfig} data={safetyChecks} />
};

export default SafetyEquipmentChecksCsv