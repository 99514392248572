import React, { useMemo, useState } from 'react';
import SeaIcon from '../../SeaIcon/SeaIcon';
import SeaModalBox from '../../SeaModalBox/SeaModalBox';
import './SeaSelectRichTextHeading.css';

interface SeaSelectRichTextHeadingProps {
    value: string | undefined,
    setValue: (value: string | undefined) => void,
    sections?: any,
    scrollToTop?: () => void
}

const SeaSelectRichTextHeading: React.FC<SeaSelectRichTextHeadingProps> = ({
    value,
    setValue,
    sections,
    scrollToTop
}) => {
    const [showModal, setShowModal] = useState(false);

    const selectedSection = useMemo(() => {
        if (value && sections) {
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].key === value) {
                    return sections[i];
                }
            }
        }
        return undefined;
    }, [value, sections]);

    return (
        <>
            <div
                className="sea-input sea-select multi rich-text-headings"
                onClick={() => setShowModal(true)}
            >
                <div className={`select-text no-select ${selectedSection ? selectedSection.tag : ''}`}>
                    {selectedSection ? selectedSection.title : ''}
                </div>
                <div className="select-icon">
                    <div className="select-icon-inner"></div>
                </div>
            </div>
            <SeaModalBox showModal={showModal} setShowModal={setShowModal} maxWidth='500px'>
                <>
                    {/* <div className="sea-select-multi-title">
                        Sections
                    </div> */}
                    <div
                        className="sea-select-multi-box"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div style={{ height: '4px' }}></div>
                        {scrollToTop &&
                            <div
                                className={`lex-section-button h1 pushy no-select`}
                                onClick={(e) => {
                                    //setValue(section.key);
                                    scrollToTop();
                                    setShowModal(false);
                                }}
                            >
                                <SeaIcon
                                    //slot="icon-only"
                                    icon="moveUp"
                                    marginTop={0}
                                    forceFontSize="16px"
                                />
                                <span style={{ verticalAlign: 'top', position: 'relative', left: '4px', top: '0px' }}>Top</span>
                            </div>
                        }
                        {sections?.map((section: any) => {
                            return (
                                <div
                                    key={section.key}
                                    className={`lex-section-button ${section.tag} pushy no-select`}
                                    onClick={(e) => {
                                        console.log('section', section);
                                        setValue(section.key);
                                        setShowModal(false);
                                    }}
                                >
                                    {section.title}
                                </div>
                            );
                        })}
                        <div style={{ height: '8px' }}></div>
                    </div>
                </>
            </SeaModalBox>
        </>
    )
};

export default SeaSelectRichTextHeading;
