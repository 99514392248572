import React, { useEffect, useState, useMemo } from 'react';
import { IonCheckbox } from '@ionic/react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import { extractHeadingFromCategoryName, stripCategoryHeadingFromCategoryName } from '../../lib/categories';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaButton from '../SeaButton/SeaButton';
import SeaModalBox from '../SeaModalBox/SeaModalBox';
import './SeaMultiSelect.css';

/*

Uses of this:
    crew > vessel access
    voyage > Personnel involved
    drills > create report > drill type (JUST STRINGS)
    drills > create report > personell involved
    maintenance schedule > connect equipment document
    maintenance schedule > connect contact
    health & safety > create meeting report > personell present

*/

interface SeaMultiSelectProps {
    name?: string,
    mode?: 'tags' | 'popover',
    zone?: 'blue' | 'white' | 'grey',
    label?: string,
    help?: SeaHelp,
    values?: string[],
    setValues?: (values: string[]) => void, // callback to set values
    useAllOption?: boolean,
    options?: any[], // [{id: x, name: x} ...]
    //error?: string,
    required?: boolean, // if true will show an error when not set.
    requiredError?: string,
    onChanged?: (values: string[]) => void,
    isSubmitting?: boolean, // Pass in formik's isSubmitting so we can tell if form has been submitted (count as touched)
    modalTitle?: string,
    disabled?: boolean,
    emptyText?: string,
}

const SeaMultiSelect: React.FC<SeaMultiSelectProps> = ({
    name,
    mode = 'popover',
    zone,
    label,
    help,
    values,
    setValues,
    useAllOption,
    options,
    required,
    requiredError,
    isSubmitting,
    onChanged,
    modalTitle,
    disabled,
    emptyText,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState<boolean[]>();
    const [touched, setTouched] = useState(false);

    const _modalTitle = useMemo(() => {
        if (modalTitle) {
            return modalTitle;
        } else if (label) {
            return label;
        }
        return undefined;
    }, [label, modalTitle]);

    useEffect(() => {
        let isActive = true;
        setTouched(false);
        setTimeout(() => {
            if (!isActive) return;
            setTouched(false);
        }, 100);
        return () => { isActive = false; };
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            setTouched(true);
        }
    }, [isSubmitting]);

    let error = '';
    if (required && touched && (values === undefined || values.length === 0)) {
        error = requiredError ? requiredError : 'Please select at least one option';
    }

    useEffect(() => {
        if (options) {
            let _selected: boolean[] = new Array(options.length).fill(false);
            
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    const index = options.findIndex(option => option.id === value);
                    if (index !== -1) {
                        _selected[index] = true;
                    }
                });
            }
            
            setSelected(_selected);
        } else {
            setSelected(undefined);
        }
    }, [values, options]);

    const toggleOption = (optionId: string) => {
        setTouched(true);
        if (options && selected !== undefined) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === optionId) {
                    const _selected = [...selected];
                    _selected[i] = !_selected[i];
                    setSelected(_selected);
                    updateValues(_selected);
                }
            }
        }
    }

    const toggleAll = (allCurrentlySelected: boolean) => {
        setTouched(true);
        if (options) {
            const _selected: boolean[] = [];
            for (let i = 0; i < options.length; i++) {
                _selected[i] = !allCurrentlySelected;
            }
            setSelected(_selected);
            updateValues(_selected);
        }
    };

    const updateValues = (_selected: boolean[]) => {
        const _values: string[] = [];
        if (_selected?.length > 0 && options) {
            for (let i = 0; i < _selected.length; i++) {
                if (_selected[i]) {
                    _values.push(options[i].id);
                }
            }
        }
        if (setValues) {
            setValues(_values);
        }
        if (onChanged) {
            onChanged(_values);
        }
    }

    const areAllSelected = (selected: boolean[] | undefined) => {
        if (selected) {
            for (let i = 0; i < selected?.length; i++) {
                if (!selected[i]) {
                    return false;
                }
            }
        }
        return true;
    };

    let allSelected = areAllSelected(selected);

    const multiSelectSummary = useMemo(() => {
        if (mode === 'popover') {
            let s = '';
            options?.forEach((option) => {
                if (values?.includes(option.id)) {
                    if (s.length > 0) {
                        s += ', ';
                    }
                    s += stripCategoryHeadingFromCategoryName(option.name).trim();
                }
            });
            if (s.length === 0 && emptyText) {
                return emptyText;
            }
            return s;
        }
        return undefined;
    }, [mode, options, emptyText, values]);

    if (mode === 'popover') {
        let currentHeading = '';
        const stopClick = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const togglePopoverOption = (e: any, id: string) => {
            e.preventDefault();
            e.stopPropagation();

            const localValues = [...(values ?? [])];
            const index = localValues.indexOf(id);

            if (index >= 0) {
                localValues.splice(index, 1);
            } else {
                localValues.push(id);
                const orderedValues = [] as string[];
                options?.forEach((option) => {
                    if (localValues.includes(option.id)) {
                        orderedValues.push(option.id);
                    }
                });
                setValues?.(orderedValues);
                onChanged?.(orderedValues);
                return;
                // localValues.length = 0;
                // _values.forEach((value) => {
                //     values?.push(value);
                // });
            }
            setValues?.(localValues);
            onChanged?.(localValues);
        };

        const selectAll = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const _values = [...(values ?? [])];
            _values.length = 0;
            options?.forEach((option) => {
                _values?.push(option.id);
            });
            setValues?.([..._values]);
            onChanged?.([..._values]);
        };
        const selectNone = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!values || values.length === 0) return;
            const _values = [...(values ?? [])];
            _values.length = 0;
            setValues?.([..._values]);
            onChanged?.([..._values]);
        };
        
        return (
            <>
                {label && <div><SeaLabel zone={zone} help={help}>{label}</SeaLabel></div>}
                <div
                    className={`sea-input sea-select multi ${zone}-zone ${error ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}
                    onClick={() => !disabled && setShowModal(true)}
                >
                    <div className="select-text no-select">
                        {multiSelectSummary}
                    </div>
                    <div className="select-icon">
                        <div className="select-icon-inner"></div>
                    </div>
                </div>
                <SeaInputError alignLeft>{error}</SeaInputError>
                <SeaModalBox showModal={showModal} setShowModal={setShowModal} maxWidth='295px'>
                    {_modalTitle &&
                        <div className="sea-select-multi-title">
                            {_modalTitle}
                        </div>
                    }
                    {useAllOption &&
                        <div className="sea-select-multi-links" onClick={stopClick}>
                            <div className={`${(values && options && values.length >= options.length) ? 'disabled' : 'pushy'}`} onClick={selectAll}>
                                SELECT ALL
                            </div>
                            <div className={`${((values && options && values.length === 0) || !values) ? 'disabled' : 'pushy'}`} onClick={selectNone}>
                                CLEAR
                            </div>
                        </div>
                    }
                    <div
                        className="sea-select-multi-box"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {options?.map((option) => {
                            const heading = extractHeadingFromCategoryName(option.name);
                            const item = (
                                <div key={option.id} className="option" onClick={(e) => togglePopoverOption(e, option.id)}>
                                    <IonCheckbox
                                        className="sea-checkbox"
                                        checked={values?.includes(option.id)}
                                        color="secondary"
                                    />
                                    <div>
                                        {stripCategoryHeadingFromCategoryName(option.name)}
                                    </div>
                                </div>
                            );
                            if (heading !== currentHeading) {
                                currentHeading = heading;
                                return (
                                    <React.Fragment key={heading}>
                                        <div className="category-heading">{heading}</div>
                                        {item}
                                    </React.Fragment>
                                );
                            }
                            return item;
                        })}
                    </div>
                    <div className="sea-select-multi-actions" onClick={stopClick}>
                        <SeaButton size="small" mini onClick={() => setShowModal(false)}>
                            OK
                        </SeaButton>
                    </div>
                </SeaModalBox>
            </>
        );
    }

    // tags mode
    return (
        <>
            {label && <div><SeaLabel help={help}>{label}</SeaLabel></div>}
            {useAllOption &&
                <div
                    className={`sea-input sea-multi-option no-select ${allSelected ? 'selected' : ''}`}
                    onClick={(e) => toggleAll(allSelected)}
                >
                    All
                </div>
            }
            {options && options.map((option, index) => {
                return (
                    <div
                        key={option.id}
                        className={`sea-input sea-multi-option no-select ${(selected && selected[index]) ? 'selected' : ''} ${error ? 'warn' : ''}`}
                        onClick={(e) => toggleOption(option.id)}
                    >
                        {stripCategoryHeadingFromCategoryName(option.name)}
                    </div>
                );
            })}
            <SeaInputError alignLeft>{error}</SeaInputError>
        </>
    );
};

export default SeaMultiSelect;
