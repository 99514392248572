import { SharedStateConfig, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { VesselCertificate } from "../VesselDocuments/vesselCertificates";
import { registerFiles } from "../FileSyncSystem/filesToCache";

export type DashboardVesselCertificatesData = {
    byId: {
        [id: string]: VesselCertificate;
    };
    top5: VesselCertificate[];
    numHighestPriority: number;
};

export const dashboardVesselCertificatesConfig: SharedStateConfig<DashboardVesselCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['vesselId'],
    countLiveDocs: () => sharedState.dashboardVesselCertificates.current?.numHighestPriority ?? 0,
    run: (done, set, clear) => {
        clear();
        const vesselId = sharedState.vesselId.current;
        if (vesselId && canView('vesselCertificates')) {
            return onSnapshot(
                query(
                    collection(firestore, 'vesselCertificates'),
                    where('vesselId', '==', vesselId),
                    where('state', '==', 'active'),
                    where('whenExpires', '<', getDayOffsetMillis(warnDays.vesselCertificates[0])),
                    orderBy('whenExpires', 'asc')
                ),
                (snap) => {
                    done();
                    const byId: { [id: string]: VesselCertificate } = {};
                    const upcoming = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                        } as VesselCertificate;
                        byId[doc.id] = item;
                        registerFiles(item.files, 'vesselCertificates', item);
                        return item;
                    });

                    set({
                        byId,
                        top5: upcoming.slice(0, 5),
                        numHighestPriority: upcoming.length,
                    });
                },
                (error) => {
                    done();
                    console.log(`Failed to access certificates for vessel ${vesselId} on vessel dashboard`, error);
                }
            );
        } else {
            done();
        }
    },
};
