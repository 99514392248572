import React, { useState, useEffect, useMemo, Profiler } from 'react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue, formatVessels } from '../../../lib/util';
import { formatDate, formatMonthLonger, formatMonthISO, warnDays } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { SafetyMeetingReport } from '../../../shared-state/HealthSafety/safetyMeetingReports';
import { Job } from '../../../shared-state/VesselMaintenance/jobs';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import EditHealthSafetyMeeting from '../../../modals/HealthSafety/HealthSafetyMeetings/EditHealthSafetyMeeting/EditHealthSafetyMeeting';
import ViewHealthSafetyMeeting from '../../../modals/HealthSafety/HealthSafetyMeetings/ViewHealthSafetyMeeting/ViewHealthSafetyMeeting';
import EditHealthSafetyMeetingSettings from '../../../modals/HealthSafety/HealthSafetyMeetings/EditHealthSafetyMeetingSettings/EditHealthSafetyMeetingSettings';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import ViewJobList from '../../../modals/VesselMaintenance/JobList/ViewJobList/ViewJobList';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import SeaSelectVesselFilter from '../../../components/SeaSelectVesselFilter/SeaSelectVesselFilter';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import HealthSafetyMeetingsCsv from '../../../exports/HealthSafety/HealthSafetyMeetings/HealthSafetyMeetingsCsv';
import HealthSafetyMeetingsPdf from '../../../exports/HealthSafety/HealthSafetyMeetings/HealthSafetyMeetingsPdf';
import './HealthSafetyMeetings.css';

interface HealthSafetyMeetingsProps {
    visible: boolean
}

const HealthSafetyMeetings: React.FC<HealthSafetyMeetingsProps> = ({visible}) => {
    const safetyMeetingReports = sharedState.safetyMeetingReports.use(visible ? 1 : 101);
    const safetyMeetingJobs = sharedState.safetyMeetingJobs.use(visible ? 1 : 101);
    const vessels = sharedState.vessels.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter(15);
    const [filterVesselId, setFilterVesselId] = useState<string>();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showViewJobModal, setShowViewJobModal] = useState(false);
    const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SafetyMeetingReport>();
    const [selectedJob, setSelectedJob] = useState<Job>();
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('HealthSafety/HealthSafetyMeetings');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    useEffect(() => {
        resetPageLimit();
    }, [filterVesselId, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && safetyMeetingReports?.byId[selectedItem.id]) { 
            setSelectedItem(safetyMeetingReports.byId[selectedItem.id])
        };
    }, [safetyMeetingReports, selectedItem?.id]);

    // Keep selectedJob fresh
    useEffect(() => {
        if (selectedJob?.id && safetyMeetingJobs?.byId[selectedJob.id]) { 
            setSelectedJob(safetyMeetingJobs.byId[selectedJob.id]);
        };
    }, [safetyMeetingJobs]); // eslint-disable-line react-hooks/exhaustive-deps

    // Convert array of meeting reports to be categorised
    const categoriseMeetingReports = (reports: SafetyMeetingReport[]) => {
        const result = {
            categories: [] as string[],
            byCategory: {} as { [key: string]: SafetyMeetingReport[] }
        };
        if (reports && reports.length > 0) {
            reports.forEach((report) => {
                const whenMeeting = formatMonthISO(report.whenMeeting);
                if (result.byCategory[whenMeeting] === undefined) {
                    result.byCategory[whenMeeting] = [];
                    result.categories.push(whenMeeting);
                }
                result.byCategory[whenMeeting].push(report);
            });
        }
        return result;
    };

    const isUsingFilter = useMemo(() => {
        return filterVesselId && filterVesselId !== 'all' ? true : false;
    }, [filterVesselId]);

    const filteredMeetingReports = useMemo(() => {
        if (!safetyMeetingReports) {
            return categoriseMeetingReports([]);
        }
        if (
            filterVesselId &&
            filterVesselId !== 'all' &&
            safetyMeetingReports?.byVesselId
        ) {
            return categoriseMeetingReports(
                safetyMeetingReports.byVesselId[filterVesselId] ? safetyMeetingReports.byVesselId[filterVesselId] : []
            );
        }
        return categoriseMeetingReports(safetyMeetingReports?.all);
    }, [safetyMeetingReports, filterVesselId]);

    const hasMeetingInterval = useMemo(() => {
        if (filterVesselId && filterVesselId !== 'all') {
            return (
                vessels?.byId &&
                vessels.byId[filterVesselId] &&
                vessels.byId[filterVesselId].safetyMeetingSettings?.interval
            );
        }
        return true;
    }, [filterVesselId, vessels]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: SafetyMeetingReport) => {
        if (item?.id) {
            console.log(`Viewing Health & Safety Meeting ${item.id}`);
        }
        setShowViewItemModal(true);
        setSelectedItem(item)
    };
    const onViewSettings = () => {
        setShowEditSettingsModal(true);
    };
    const onViewJob = (e: React.MouseEvent, job: Job) => {
        e.stopPropagation();
        setShowViewJobModal(true);
        setSelectedJob(job)
    };

    return (
        <RequirePermissions
            role="healthSafetyMeetings"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="health-safety-meetings page-head">
                <div style={{ marginRight: '12px' }}><h2>Health &amp; Safety Meetings</h2></div>
                {isUsingFilter && filterVesselId && hasMeetingInterval && <div className="status">
                    <SeaStatusDueDate whenDue={vessels?.byId[filterVesselId]?.safetyMeetingSettings?.whenDue} warnDays={warnDays.healthSafetyMeetings[0]}/>
                </div>}
                <RequirePermissions
                    role="healthSafetyMeetings"
                    level={permissionLevels.CREATE}
                >
                    <div className="actions">
                        <SeaSelectVesselFilter
                            filterVesselId={filterVesselId}
                            setFilterVesselId={setFilterVesselId}
                        />
                        <SeaButton
                            onClick={(e) => onAddNewItem()}
                            zone="grey"
                            disabled={!hasMeetingInterval}
                        >
                            <SeaIcon slot="start" icon="add"/>
                            Create Meeting Report
                        </SeaButton>
                        <SeaExporter 
                            setExportType={setExportType} 
                            csv={exportType === "csv" && <HealthSafetyMeetingsCsv onCompleted={() => setExportType(undefined)} />}
                            pdf={exportType === "pdf" && <HealthSafetyMeetingsPdf onCompleted={() => setExportType(undefined)} filterVesselId={filterVesselId} filteredMeetingReports={filteredMeetingReports} />} 
                        />
                        <RequirePermissions
                            role="healthSafetyMeetings"
                            level={permissionLevels.EDIT}
                        >
                            <div className="spacer"></div>
                            <SeaButton zone="grey" shape="circle" onClick={(e) => onViewSettings()}>
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </div>
                </RequirePermissions>
            </div>

            {(isUsingFilter && !hasMeetingInterval && filterVesselId) ? (
                <div className="sea-no-data columns">
                    <div><SeaIcon icon="info"/></div>
                    <div>
                        Before you can start having meetings for {vessels?.byId[filterVesselId]?.name} you need to confirm its meeting settings.
                        <br/>
                        You can do this by clicking the settings button <SeaIcon icon="settings"/>
                    </div>
                </div>
            ) : (
                <SeaNoData
                    dataName="health &amp; safety meeting reports"
                    isLoading={!safetyMeetingReports}
                    hasNoData={filteredMeetingReports?.categories && filteredMeetingReports.categories.length === 0}
                    isUsingFilter={isUsingFilter}
                />
            )}

            <div className={`health-safety-meetings ${isUsingFilter ? 'hide-vessels' : 'show-vessels'} ${filteredMeetingReports?.categories && filteredMeetingReports.categories.length > 0 ? 'reveal' : 'conceal' }`
            }>
                <div className="sea-row headings">
                    <div>Date</div>
                    <div>Vessels / Vacilities</div>
                    <div>Notes</div>
                    <div>Personnel Present</div>
                    <div>Jobs Created</div>
                    <div></div>
                </div>
                <Profiler id="healthSafety.safetyMeetingReports" onRender={onProfilerRender}>
                    {filteredMeetingReports && filteredMeetingReports.categories?.map((category: string) => {
                        if (isLimitReached()) {
                            return undefined;
                        }
                        return (
                            <React.Fragment key={category}>
                                <div className="category-heading">{formatMonthLonger(category)}</div>
                                {mapArrayWithLimit(filteredMeetingReports?.byCategory[category], (item: SafetyMeetingReport) => {
                                    let numJobs = 0;
                                    return (
                                        <div key={item.id} className="sea-card sea-row" onClick={(e) => onViewItem(item)}>
                                            <div className="bold truncate-2">{formatDate(item.whenMeeting)}</div>
                                            <div className="truncate-2">{formatVessels(item.vesselIds, vessels)}</div>
                                            <div className="truncate-2">{formatValue(item.notes)}</div>
                                            <div className="truncate-2">
                                                {item?.personnelPresentIds?.map((crewId: string, index: number) => {
                                                    if (renderFullNameForUserId(crewId)) {
                                                        if (index === 0) {
                                                            return renderFullNameForUserId(crewId)
                                                        } else {
                                                            return `, ${renderFullNameForUserId(crewId)}`
                                                        }
                                                    } else {
                                                        return '-'
                                                    }
                                                })}
                                            </div>
                                            <div className="truncate-2">
                                                {safetyMeetingJobs && item?.jobIds && item.jobIds?.map((jobId: string) => {
                                                    if (safetyMeetingJobs.byId[jobId]) {
                                                        numJobs++;
                                                        return <div key={jobId}>
                                                            <a onClick={(e) => onViewJob(e, safetyMeetingJobs.byId[jobId])}>
                                                                <u>{safetyMeetingJobs.byId[jobId].task}</u>
                                                            </a>
                                                        </div>;
                                                    }
                                                    return undefined;
                                                })}
                                                {(numJobs === 0) && '-'}
                                            </div>
                                            <div>
                                                <SeaFileImage files={item.files} size="tiny"/>
                                            </div>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    {selectedJob && <ViewJobList
                        showModal={showViewJobModal}
                        setShowModal={setShowViewJobModal}
                        selectedItem={selectedJob}
                    />}
                    <EditHealthSafetyMeeting
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    {selectedItem && <ViewHealthSafetyMeeting
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />}
                    <EditHealthSafetyMeetingSettings
                        showModal={showEditSettingsModal}
                        setShowModal={setShowEditSettingsModal}
                        defaultVesselId={isUsingFilter ? filterVesselId : undefined}
                        setFilterVesselId={setFilterVesselId}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default HealthSafetyMeetings;
