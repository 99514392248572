import { useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvFiles } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { DangerousGood } from '../../../shared-state/HealthSafety/dangerousGoods';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type DangerousGoodsCsvProps = {
    onCompleted: () => void
}

const DangerousGoodsCsv = ({ onCompleted }: DangerousGoodsCsvProps) => {
    const dangerousGoods = sharedState.dangerousGoods.use();

    const csvConfig: CsvConfig<DangerousGood> | undefined = useMemo(() => {
        return [
            {
                name: 'Chemical',
                value: (item) => item.name
            },
            {
                name: 'Quantity',
                value: (item) => item.quantity || ''
            },
            {
                name: 'Location',
                value: (item) => item.location || ''
            },
            {
                name: 'Hazardous',
                value: (item) => formatCsvBoolean(item.isHazardous)
            },
            {
                name: 'DG Class',
                value: (item) => item.class || ''
            },
            {
                name: 'SDS',
                value: (item) => formatCsvFiles(item.msdsFiles)
            },
            {
                name: 'Expiry',
                value: (item) => formatCsvDate(item.whenExpires)
            },
        ];
    }, []);

    return <SeaCSVGenerator name={'Dangerous-Goods'} onCompleted={onCompleted} config={csvConfig} data={dangerousGoods?.all} />
};

export default DangerousGoodsCsv;