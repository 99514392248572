import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { pdfStyles, styleItemColumn } from "../../../lib/pdf";
import { formatValue, renderCamelCase, toMillis } from "../../../lib/util";
import { formatDate, formatMonthLonger } from '../../../lib/datesAndTime';
import { renderFullName, renderFullNameForUserId } from "../../../shared-state/Core/users";
import { sharedState } from '../../../shared-state/shared-state';
import { MaintenanceTaskCompleted } from '../../../shared-state/VesselMaintenance/maintenanceTasksCompleted';
import { renderCategoryName } from "../../../lib/categories";
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import SeaPdfGenerator from "../../../components/SeaPdfGenerator/SeaPdfGenerator";
import PdfItemsHeader from "../../../components/PdfItemsHeader/PdfItemsHeader";
import SeaExporterDateRange from '../../../components/SeaExporter/SeaExporterDateRange';

interface MaintenanceHistoryPdfProps {
    onCompleted: () => void,
    maintenanceHistoryItems: Record<string, MaintenanceTaskCompleted[]> | undefined
    monthCategories: string[] | undefined
}

const MaintenanceHistoryPdf: React.FC<MaintenanceHistoryPdfProps> = ({
    onCompleted,
    maintenanceHistoryItems,
    monthCategories
}) => {
    const vesselSystems = sharedState.vesselSystems.use();
    const vessel = sharedState.vessel.use();
    const title = `Maintenance History - ${vessel?.name}`;
    const [dateRange, setDateRange] = useState<DateRange>();
    const [items, setItems] = useState<Record<string, MaintenanceTaskCompleted[]>>();
    const [categories, setCategories] = useState<string[]>();
    
    useEffect(() => {
        if (!dateRange) {
            return;
        }
        if (!monthCategories && !maintenanceHistoryItems) {
            alertMessage("Please select at least one category or item!");
            return;
        }
        const whenFrom = dateRange.from;
        let whenTo = dateRange.to;
        if (whenFrom >= whenTo) { 
            alertMessage("Date Start must be before Date End!");
            return;
        }
        const items: {
            [key: string]: MaintenanceTaskCompleted[]
        } = {};
        const categories: string[] = [];
        
        if (monthCategories) {
            for (let i = 0; i < monthCategories?.length; i++) {
                const month = toMillis(monthCategories[i]);
                if (!month || (month > whenTo || month < whenFrom)) {
                    continue;
                }
                categories.push(monthCategories[i]);
            } 
        }

        if (maintenanceHistoryItems) {
            for (const [key, value] of Object.entries(maintenanceHistoryItems)) {
                const date = toMillis(key);
                if (!date || (date > whenTo || date < whenFrom)) {
                    continue;
                }
                items[key] = value;
            }
        }

        if (Object.keys(items).length === 0) {
            alertMessage(
                "No completed maintenance reports were found in that date range."
            );
            console.log("[][] No items found for the given date range.");
            return;
        }

        setItems(items);
        setCategories(categories);
    }, [dateRange, maintenanceHistoryItems, monthCategories]);


    const MakePDF = useCallback(() => {
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Date</Text>
                        </View>
                        <View style={pdfStyles.numColumn}>
                            <Text>Engine Hours</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>System</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Equipment</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Task</Text>
                        </View>
                        <View style={styleItemColumn(0.9)}>
                            <Text>Type</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Completed by</Text>
                        </View>
                    </View>
                    <View
                        wrap={false}
                        style={pdfStyles.itemHeadingsLine}
                    ></View>

                    {categories?.map((category: string, index: number) => 
                        <React.Fragment key={category}>
                            {index ? <View style={pdfStyles.itemsCategorySpacer} /> : null}
                            <View style={pdfStyles.labelHeading}>
                                <Text style={pdfStyles.itemsCategory}>
                                    {formatMonthLonger(category)}
                                </Text>
                            </View>
                            {items?.[category]?.map(
                                (item: MaintenanceTaskCompleted, index: number) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            {index > 0 && (
                                                <View
                                                    style={pdfStyles.itemsRowSpacer}
                                                ></View>
                                            )}
                                            <View
                                                wrap={false}
                                                style={pdfStyles.itemsRow}
                                            >
                                                <View style={pdfStyles.dateColumn}>
                                                    <Text>
                                                        {formatDate(item.whenCompleted)}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.numColumn}>
                                                    <Text>
                                                        {item?.engineHours}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(renderCategoryName(item.equipment?.systemId, vesselSystems))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(item.equipment?.equipment)}
                                                        {item.equipment?.state === 'deleted' && ' (deleted)'}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {formatValue(item.task as string)}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(0.9)}>
                                                    <Text>
                                                        {formatValue(item.type === 'unscheduled' ? 'Job' : renderCamelCase(item.type))}
                                                    </Text>
                                                </View>
                                                <View style={styleItemColumn(1)}>
                                                    <Text>
                                                        {renderFullNameForUserId(item.completedBy)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </React.Fragment>
                    )}
                </Page>
            </Document>
        );
    }, [title, categories, items, vesselSystems]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    if (!items || !categories) {
        return <></>;
    }

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="MaintenanceHistory"
        />
    );
};

export default MaintenanceHistoryPdf;
