import React, { useEffect, useMemo, useState } from 'react';
import { isTouchDevice } from '../../../lib/util';
import { G, Path, Rect, Text } from '@react-pdf/renderer';
import { pdfColours } from '../../../lib/pdf';
import { GraphData } from '../SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { sharedState } from '../../../shared-state/shared-state';
import reporting, { ColourMap } from '../../../lib/reporting';
import SvgText from '../SvgText/SvgText';
import SeaGraph from '../SeaGraph/SeaGraph';
import SvgHoverInfo, { HoverInfo } from '../SvgHoverInfo/SvgHoverInfo';
import './SeaPieGraph.css';

interface SeaPieGraphProps {
    title?: string,
    subTitle?: string,
    mode: 'dashboard' | 'modal' | 'pdf'
    visible?: boolean,
    n?: number,
    data?: GraphData[],
    sortData?: boolean,
    showAllLabels?: boolean,
    onClick?: (e: React.MouseEvent) => void,
    renderValue?: (value: number) => string,
    renderPercent?: (value: number) => string,
    colourPalette?: string[],
    hashNamesForColours?: boolean,
    modalWidth?: number // Specifiy width of modal this is placed within
}

const SeaPieGraph: React.FC<SeaPieGraphProps> = ({
    title,
    subTitle,
    mode,
    visible = true,
    n = 0,
    data,
    sortData = true,
    showAllLabels = false,
    onClick,
    renderValue = (value: number) => {
        return Math.round(value).toLocaleString();
    },
    renderPercent = (ratio: number) => {
        if (isNaN(ratio) || ratio <= 0) {
            return '0%';
        }
        if (ratio < 0.01) {
            return '<1%';
        }
        return `${Math.round(ratio * 100)}%`;
    },
    colourPalette = reporting.colours.default,
    hashNamesForColours,
    modalWidth
}) => {
    // Remove the use of sharedState for PDF mode
    const modalSpace = mode === 'pdf' ? undefined : sharedState.modalSpace.use();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isReady, setIsReady] = useState(false); // Has completed animating and is ready to be interacted with
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | undefined>();

    useEffect(() => {
        let isActive = true;
        setIsReady(false);
        if (visible) {
            setTimeout(() => {
                if (!isActive) return;
                setIsReady(true);
            }, (n * 100) + 500);
        }
        return () => { isActive = false; };
    }, [visible, n]);

    const total = useMemo(() => {
        let t = 0;
        if (data) {
            data.forEach((item) => {
                if (item.value !== undefined) {
                    t += item.value;
                }
            });
        }
        return t;
    }, [data]);

    const sortedData = useMemo(() => {
        if (data) {
            const sorted = [];
            for (let i = 0; i < data.length; i++) {
                if (!data[i].value) {
                    data[i].value = 0;
                }
                if (data[i].value! > 0) {
                    sorted.push({
                        ...data[i],
                        index: i
                    });
                }
            }
            if (sortData) {
                sorted.sort((a, b) => {
                    return (b.value || 0) - (a.value || 0);
                });
            }
            return sorted;
        }
        return undefined;
    }, [data, sortData]);

    const labelData = useMemo(() => {
        if (data) {
            if (showAllLabels) {
                const labels = [] as any[];
                data.forEach((item, index) => {
                    if (item.name) {
                        labels.push({
                            ...item,
                            index: index
                        });
                    }
                });
                return labels;
            } else {
                return sortedData;
            }
        }
        return undefined;
    }, [data, sortedData, showAllLabels]);

    const minRatioToLabel = 0.02;
    const minPercentToLabel = 0.06;

    const colourMap = useMemo(() => {
        if (data) {
            if (hashNamesForColours) {
                const map = reporting.makeColourMapUsingNames(data, colourPalette);
                return map;
            }
            return reporting.makeColourMapUsingIndexes(data, colourPalette);
        }
        return {} as ColourMap;
    }, [data, colourPalette, hashNamesForColours]);
    
    const graph = useMemo(() => {
        return reporting.getConfig(mode, modalSpace, colourPalette, modalWidth);
    }, [mode, modalSpace, colourPalette, modalWidth]);

    // Calculate pie container size by working out available vertical space
    const pie = {
        square: graph.height - graph.headerHeight,
        padding: graph.padding + ((mode === 'pdf') ? 16 : 8)
    } as any;
    pie.cx = pie.square / 2;
    pie.cy = (pie.square / 2) + graph.headerHeight;
    pie.r = (pie.square - pie.padding - pie.padding) / 2;
    pie.rHole = pie.r * 0.4;
    pie.box = {
        ...reporting.labelBox,
        x: pie.square + 8
    };
    if (mode === 'pdf') {
        pie.box.x = pie.square + 40;
    }
    pie.label = {
        x: pie.box.x + pie.box.w + pie.box.textGap
    }

    let runningTotal = 0;

    const renderPiePath = (angleFrom: number, angleTo: number) => {
        if (angleTo - angleFrom >= Math.PI * 0.9) {
            // angles greater than half a circle need to be rendered using 2 paths
            const angleMid = (angleTo - angleFrom) / 2 + angleFrom;
            return `
                M ${pie.cx + Math.sin(angleFrom) * pie.rHole},${pie.cy + Math.cos(angleFrom) * pie.rHole}
                L ${pie.cx + Math.sin(angleFrom) * pie.r},${pie.cy + Math.cos(angleFrom) * pie.r}
                A ${pie.r} ${pie.r} ${(angleMid - angleFrom) * 360} 0 0 ${pie.cx + Math.sin(angleMid) * pie.r},${pie.cy + Math.cos(angleMid) * pie.r}
                A ${pie.r} ${pie.r} ${(angleTo - angleMid) * 360} 0 0 ${pie.cx + Math.sin(angleTo) * pie.r},${pie.cy + Math.cos(angleTo) * pie.r}
                L ${pie.cx + Math.sin(angleTo) * pie.rHole},${pie.cy + Math.cos(angleTo) * pie.rHole}
                A ${pie.rHole} ${pie.rHole} ${(angleMid - angleTo) * 360} 0 1 ${pie.cx + Math.sin(angleMid) * pie.rHole},${pie.cy + Math.cos(angleMid) * pie.rHole}
                A ${pie.rHole} ${pie.rHole} ${(angleFrom - angleMid) * 360} 0 1 ${pie.cx + Math.sin(angleFrom) * pie.rHole},${pie.cy + Math.cos(angleFrom) * pie.rHole}
            `;
        }
        return `
            M ${pie.cx + Math.sin(angleFrom) * pie.rHole},${pie.cy + Math.cos(angleFrom) * pie.rHole}
            L ${pie.cx + Math.sin(angleFrom) * pie.r},${pie.cy + Math.cos(angleFrom) * pie.r}
            A ${pie.r} ${pie.r} ${(angleTo - angleFrom) * 360} 0 0 ${pie.cx + Math.sin(angleTo) * pie.r},${pie.cy + Math.cos(angleTo) * pie.r}
            L ${pie.cx + Math.sin(angleTo) * pie.rHole},${pie.cy + Math.cos(angleTo) * pie.rHole}
            A ${pie.rHole} ${pie.rHole} ${(angleFrom - angleTo) * 360} 0 1 ${pie.cx + Math.sin(angleFrom) * pie.rHole},${pie.cy + Math.cos(angleFrom) * pie.rHole}
        `;
    };


    if (data === undefined || sortedData === undefined || labelData === undefined) {
        return reporting.graphSpinner;
    }

    if (mode === 'pdf') {
        return (
            <SeaGraph
                mode={mode}
                n={n}
                colourMode={graph.colourMode}
                width={graph.width}
                height={graph.height}
            >
                {sortedData && sortedData.length === 0 &&
                    <>
                        <Path
                            d={renderPiePath(0, 2 * Math.PI)}
                            fill={graph.labelColour}
                            fillOpacity={0.1}
                        />
                        {(labelData === undefined || labelData.length === 0) &&
                            <SvgText
                                text="No data found"
                                x={pie.box.x}
                                y={graph.headerHeight + (pie.square / 2)}
                                forPdf={true}
                                style={reporting.pdf.label}
                                fill={pdfColours.textColor}
                                //textFadeGradient="textFade"
                            />
                        }
                    </>
                }
                {sortedData?.map((item) => {
                    if (!item.value) {
                        item.value = 0
                    }
                    const angleTo = (runningTotal / total) * Math.PI * -2 + Math.PI;
                    const angleFrom =   ((runningTotal + item.value) / total) * Math.PI * -2 + Math.PI;
                    runningTotal += (item.value);
                    const d = renderPiePath(angleFrom, angleTo);

                    return (
                        <G key={item.index}>
                            <Path
                                d={d}
                                fill={graph.renderColour(item.index, colourMap)}
                                stroke={graph.renderColour(item.index, colourMap)}
                                strokeWidth={1}
                            />
                            {(item.value / total >= minRatioToLabel) &&
                                <Text
                                    style={{
                                        ...reporting.pdf.label,
                                        fontWeight: 500, // bolder
                                        textAnchor: 'middle' // centered
                                    }}
                                    x={pie.cx + Math.sin((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole)}
                                    y={pie.cy + Math.cos((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole) + 2 + ((item.value / total >= minPercentToLabel) ? -2 : 4)}
                                    fill={graph.renderTextColour(item.index, colourMap)}
                                >
                                    {renderValue(item.value)}
                                </Text>
                            }
                            {(item.value / total >= minPercentToLabel) &&
                                <Text
                                    style={reporting.pdf.xValue}
                                    x={pie.cx + Math.sin((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole)}
                                    y={pie.cy + Math.cos((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole) + 2 + 15}
                                    fill={graph.renderTextColour(item.index, colourMap)}
                                >
                                    {`(${renderPercent(item.value / total)})`}
                                </Text>
                            }
                        </G>
                    );
                })}

                {labelData?.map((item, index) => {
                    const y = (
                        graph.headerHeight +
                        (pie.square / 2) -
                        ((labelData.length * pie.box.h + (labelData.length - 1) * pie.box.spacing) / 2) +
                        (index * (pie.box.h + pie.box.spacing))
                    );
                    return (
                        <G key={item.index}>
                            <Rect
                                x={pie.box.x}
                                y={y}
                                width={pie.box.w}
                                height={pie.box.h}
                                fill={graph.renderColour(item.index, colourMap)}
                            />
                            <SvgText
                                text={item.name}
                                x={pie.label.x}
                                y={y + (pie.box.h / 2) + 1 + 5}
                                maxWidth={graph.width - pie.label.x - 8}
                                forPdf={true}
                                style={reporting.pdf.label}
                                fill={pdfColours.textColor}
                                //textFadeGradient="textFade"
                            />
                        </G>
                    );
                })}
                

                {/* {sortedData?.map((item, index) => {
                    const y = (
                        graph.headerHeight +
                        (pie.square / 2) -
                        ((sortedData.length * pie.box.h + (sortedData.length - 1) * pie.box.spacing) / 2) +
                        (index * (pie.box.h + pie.box.spacing))
                    );
                    return (
                        <G key={item.index}>
                            <Rect
                                x={pie.box.x}
                                y={y}
                                width={pie.box.w}
                                height={pie.box.h}
                                fill={graph.renderColour(item.index, colourMap)}
                            />
                            <SvgText
                                text={item.name}
                                x={pie.label.x}
                                y={y + (pie.box.h / 2) + 1 + 5}
                                maxWidth={graph.width - pie.label.x - 8}
                                forPdf={true}
                                style={reporting.pdf.label}
                                fill={pdfColours.textColor}
                                //textFadeGradient="textFade"
                            />
                        </G>
                    );
                })} */}
            </SeaGraph>
        );
    }

    return (
        <SeaGraph
            mode={mode}
            n={n}
            divStyle={graph.divStyle}
            onClick={onClick}
            onMouseOut={() => {
                setActiveIndex(-1);
                setHoverInfo(undefined);
            }}
            colourMode={graph.colourMode}
            width={graph.width}
            height={graph.height}
            showHeader={graph.showHeader}
            padding={graph.padding}
            title={title}
            subTitle={subTitle}
        >
            <SvgText
                text={(activeIndex > -1 && isReady && data[activeIndex]) ? data[activeIndex].name : ''}
                x={pie.cx}
                y={pie.cy - 6}
                maxWidth={pie.rHole * 2 - 16}
                className="active-name"
            />
            <SvgText
                text={(activeIndex > -1 && isReady && data[activeIndex]) ? `(${renderPercent((data[activeIndex].value || 0) / total)})` : ''}
                x={pie.cx}
                y={pie.cy + 10}
                maxWidth={pie.rHole * 2 - 16}
                className="active-name percentage"
            />

            {sortedData && sortedData.length === 0 &&
                <g className="label">
                    <path
                        className="fill"
                        d={renderPiePath(0, 2 * Math.PI)}
                        fill={graph.labelColour}
                        fillOpacity={0.1}
                    />
                    {(labelData === undefined || labelData.length === 0) &&
                        <SvgText
                            text="No data found"
                            x={pie.box.x}
                            y={graph.headerHeight + (pie.square / 2)}
                            fillOpacity={0.5}
                        />
                    }
                </g>
            }

            {sortedData?.map((item, index) => {
                if (!item.value) {
                    return item.value = 0;
                }
                const angleTo = (runningTotal / total) * Math.PI * -2 + Math.PI;
                const angleFrom =   ((runningTotal + item.value) / total) * Math.PI * -2 + Math.PI;
                runningTotal += (item.value);
                const d = renderPiePath(angleFrom, angleTo);

                return (
                    <g
                        key={item.index}
                        style={{
                            '--hover-move-x': `${Math.sin((angleFrom + angleTo) / 2) * 8}px`,
                            '--hover-move-y': `${Math.cos((angleFrom + angleTo) / 2) * 8}px`,
                            '--order': index,
                        } as React.CSSProperties}
                    >
                        <g className={`pie-piece${((item.index === activeIndex && isReady) ? ' active' : '')}`}>
                            <g className="in">
                                <path
                                    className="fill"
                                    d={d}
                                    fill={graph.renderColour(item.index, colourMap)}
                                    stroke={graph.renderColour(item.index, colourMap)}
                                    strokeWidth={1}
                                />
                                {(item.value / total >= minRatioToLabel) &&
                                    <text
                                        className="pie-value"
                                        x={pie.cx + Math.sin((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole)}
                                        y={pie.cy + Math.cos((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole) + 2 + ((item.value / total >= minPercentToLabel) ? -4 : 2)}
                                        fill={graph.renderTextColour(item.index, colourMap)}
                                    >
                                        {renderValue(item.value)}
                                    </text>
                                }
                                {(item.value / total >= minPercentToLabel) &&
                                    <text
                                        className="pie-value percentage"
                                        x={pie.cx + Math.sin((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole)}
                                        y={pie.cy + Math.cos((angleFrom + angleTo) / 2) * ((pie.r - pie.rHole) / 2 + pie.rHole) + 2 + 12}
                                        fill={graph.renderTextColour(item.index, colourMap)}
                                    >
                                        ({renderPercent(item.value / total)})
                                    </text>
                                }
                            </g>
                        </g>
                        <path
                            d={d}
                            //fill={'#ffffff'}
                            fillOpacity={0}
                            onMouseOver={(e) => setActiveIndex(item.index)}
                            onClick={(e) => {
                                if (isTouchDevice) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setActiveIndex(item.index);
                                }
                            }}
                            onMouseOut={(e) => setActiveIndex(-1)}
                        />
                    </g>
                );
            })}
            {labelData?.map((item, index) => {
                const y = (
                    graph.headerHeight +
                    (pie.square / 2) -
                    ((labelData.length * pie.box.h + (labelData.length - 1) * pie.box.spacing) / 2) +
                    (index * (pie.box.h + pie.box.spacing))
                );

                return (
                    <React.Fragment key={item.index}>
                        <g
                            className={`label${((item.index === activeIndex && isReady) ? ' active' : '')}`}
                            style={{
                                '--order': index
                            } as React.CSSProperties}
                        >
                            <g className="in">
                                <rect
                                    x={pie.box.x}
                                    y={y}
                                    width={pie.box.w}
                                    height={pie.box.h}
                                    fill={graph.renderColour(item.index, colourMap)}
                                />
                                <SvgText
                                    text={item.name}
                                    x={pie.label.x}
                                    y={y + (pie.box.h / 2) + 5}
                                    maxWidth={graph.width - pie.label.x - 8}
                                    setHoverInfo={setHoverInfo}
                                    triggerHoverInfo={activeIndex === item.index}
                                />
                            </g>
                        </g>
                        <rect
                            x={pie.box.x}
                            y={y-8}
                            width={graph.width - pie.square}
                            height={pie.box.h+16}
                            fillOpacity={0}
                            onMouseOver={(e) => setActiveIndex(item.index)}
                            onClick={(e) => {
                                if (isTouchDevice) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setActiveIndex(item.index);
                                }
                            }}
                            onMouseOut={(e) => {
                                setActiveIndex(-1);
                                setHoverInfo(undefined);
                            }}
                        />
                    </React.Fragment>
                );
            })}
            {hoverInfo && isReady &&
                <SvgHoverInfo
                    graphWidth={graph.width}
                    graphHeight={graph.height}
                    x={hoverInfo.x}
                    y={hoverInfo.y}
                >
                    <SvgText
                        text={hoverInfo.text}
                        fill="white"
                        className="hover-text"
                    />
                </SvgHoverInfo>
            }
        </SeaGraph>
    );
};

export default SeaPieGraph;
