import React, { useState, useEffect, useContext, useMemo } from 'react';
import { IonSpinner } from '@ionic/react';
import { canView } from '../../shared-state/Core/userPermissions';
import { sharedState } from '../../shared-state/shared-state';
import SeaBreadcrumb from '../../components/SeaBreadcrumb/SeaBreadcrumb';
import SeaLink from '../../components/SeaLink/SeaLink';
import StandardPageLayout from '../../layouts/StandardPageLayout/StandardPageLayout';
import SeaTabsGroup from '../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../components/SeaTab/SeaTab';
import SeaTabContent from '../../components/SeaTabContent/SeaTabContent';
import MaintenanceReporting from './MaintenanceReporting/MaintenanceReporting';
import SafetyReporting from './SafetyReporting/SafetyReporting';
import HealthSafetyReporting from './HealthSafetyReporting/HealthSafetyReporting';
import SeaReportSettings from '../../components/SeaReportSettings/SeaReportSettings';
import SeaIcon from '../../components/SeaIcon/SeaIcon';
import DocumentReporting from './DocumentReporting/DocumentReporting';
import CrewReporting from './CrewReporting/CrewReporting';
import LogbookReporting from './LogbookReporting/LogbookReporting';
import RequireLicenseeSettings from '../../components/RequireLicenseeSettings/RequireLicenseeSettings';
import './Reporting.css';

const ReportSettingsContext = React.createContext({});

export function useReportSettings(): any {
    return useContext(ReportSettingsContext);
}

const Reporting: React.FC = () => {
    const vesselIds = sharedState.vesselIds.use();
    const vessels = sharedState.vessels.use();
    const todayMillis = sharedState.todayMillis.use()!
    const selectedSectionTab = sharedState.selectedSectionTab.use();
    const licenseeSettings = sharedState.licenseeSettings.use();
    const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState({
        from: todayMillis - (365 * 24 * 60 * 60 * 1000), // default 12 months
        // to: todayMillis,
        //from: 0, // TMP: for testing, use full history by default
        to: todayMillis
    });
    const [dateRangeDescription, setDateRangeDescription] = useState("");

    useEffect(() => {
        if (vesselIds) {
            setSelectedVesselIds([...vesselIds]);
        }
    }, [vesselIds]);

    // Per section, prepare graph availability according to userPermissions
    // including a count for number of graphs available
    const graphAvailabilitySections = useMemo(() => {
        let hasPermissions = false;
        // Order is important
        const o = {
            logbook: {
                count: 0,
                voyages: false,
                fuelUsed: false,
            },
            safety: {
                count: 0,
                safetyChecks: false,
                completedSafetyChecks: false,
                safetyEquipment: false
            },
            maintenance: {
                count: 0,
                maintenanceTaskStatus: false,
                completedTasks: false,
                jobPriorities: false
            },
            healthSafety: {
                count: 0,
                incidentStatus: false,
                incidentTypes: false,
                incidentCategories: false,
                contributingFactors: false,
                injuryTypes: false,
                injuryLocations: false,
                incidentsNotified: false,
                incidentPersonnelInvolved: false,
                incidentInjuryOutcomes: false
            },
            documents: {
                count: 0,
                vesselCertificateStatus: false
            },
            crew: {
                count: 0,
                crewCertificateStatus: false,
                crewActionsLogged: false
            },
        };
        if (canView('safetyEquipmentChecks')) {
            hasPermissions = true;
            o.safety.safetyChecks = true;
            o.safety.completedSafetyChecks = true;
            o.safety.count += 2;
        }
        if (canView('safetyEquipmentList')) {
            hasPermissions = true;
            o.safety.safetyEquipment = true;
            o.safety.count++;
        }
        if (canView('maintenanceSchedule')) {
            hasPermissions = true;
            o.maintenance.maintenanceTaskStatus = true;
            o.maintenance.count++;
        }
        if (canView('maintenanceHistory')) {
            hasPermissions = true;
            o.maintenance.completedTasks = true;
            o.maintenance.count++;
        }
        if (canView('jobList')) {
            hasPermissions = true;
            o.maintenance.jobPriorities = true;
            o.maintenance.count++;
        }
        if (canView('incidentAccidentMedicalRegister') && licenseeSettings?.hasIncidents) {
            hasPermissions = true;
            o.healthSafety.incidentStatus = true;
            o.healthSafety.incidentTypes = true;
            o.healthSafety.incidentCategories = true;
            o.healthSafety.contributingFactors = true;
            o.healthSafety.injuryTypes = true;
            o.healthSafety.injuryLocations = true;
            o.healthSafety.incidentsNotified = true;
            o.healthSafety.incidentPersonnelInvolved = true;
            o.healthSafety.incidentInjuryOutcomes = true;
            o.healthSafety.count += 9;
        }
        if (canView('vesselCertificates')) {
            hasPermissions = true;
            o.documents.vesselCertificateStatus = true;
            o.documents.count++;
        }
        if (canView('crewParticulars')) {
            hasPermissions = true;
            o.crew.crewCertificateStatus = true;
            o.crew.crewActionsLogged = true;
            o.crew.count += 2;
        }
        if (canView('logbook')) {
            hasPermissions = true;
            o.logbook.voyages = true;
            o.logbook.fuelUsed = true;
            o.logbook.count += 2;
        }
        if (!hasPermissions) {
            return undefined;
        }

        // Determine the initial tab
        const sections = Object.keys(o);
        const firstAvailableSection = sections.find(section => o[section as keyof typeof o].count > 0);
        
        if (firstAvailableSection) {
            const capitalizedSection = firstAvailableSection.charAt(0).toUpperCase() + firstAvailableSection.slice(1);
            sharedState.selectedSectionTab.set(capitalizedSection);
        }
        return o;
    }, [licenseeSettings?.hasIncidents]);

    const showDateRange = useMemo(() => {
        return !(
            selectedSectionTab === 'Documents'
        );
    }, [selectedSectionTab]);

    const selectedVesselId = useMemo(() => {
        if (selectedVesselIds.length === 1) {
            return selectedVesselIds[0];
        }
        return undefined;
    }, [selectedVesselIds]);

    const value = {
        selectedVesselIds,
        setSelectedVesselIds,
        dateRange,
        setDateRange,
        dateRangeDescription,
        setDateRangeDescription,
        selectedVesselId
    };

    return (
        <StandardPageLayout section="reporting">
            <ReportSettingsContext.Provider value={value}>

                <SeaBreadcrumb>
                    <SeaLink to="/reporting">Reporting</SeaLink>
                </SeaBreadcrumb>

                {!graphAvailabilitySections ? (
                        <div className="sea-no-data columns">
                            <div><SeaIcon icon="info"/></div>
                            <div>You do not have permission to view any reports.</div>
                        </div>
                ) : (
                    <>
                        <SeaTabsGroup
                            id="Reporting"
                            selectedTab={selectedSectionTab}
                            setTab={sharedState.selectedSectionTab.set}
                            mode="page-never-hide"
                        >
                            <SeaTab
                                tab="Logbook"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.logbook.count === 0}
                            >
                                Logbook
                            </SeaTab>
                            <SeaTab
                                tab="Safety"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.safety.count === 0}
                            >
                                Safety
                            </SeaTab>
                            <SeaTab
                                tab="Maintenance"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.maintenance.count === 0}
                            >
                                Maintenance
                            </SeaTab>
                            {licenseeSettings?.hasIncidents ? <SeaTab
                                tab="HealthSafety"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.healthSafety.count === 0}
                            >
                                Health & Safety
                            </SeaTab> : null}
                            <SeaTab
                                tab="Documents"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.documents.count === 0}
                            >
                                Documents
                            </SeaTab>
                            <SeaTab
                                tab="Crew"
                                setTab={sharedState.selectedSectionTab.set}
                                hide={graphAvailabilitySections.crew.count === 0}
                            >
                                Crew
                            </SeaTab>
                        </SeaTabsGroup>

                        <SeaReportSettings
                            mode="dashboard"
                            showDateRange={showDateRange}
                        />

                        {(vesselIds && vessels) ? (
                            <>
                                <SeaTabContent tab="Logbook" selectedTab={selectedSectionTab}>
                                    <LogbookReporting
                                        visible={selectedSectionTab === 'Logbook'}
                                        graphAvailability={graphAvailabilitySections.logbook}
                                    />
                                </SeaTabContent>
                                <SeaTabContent tab="Safety" selectedTab={selectedSectionTab}>
                                    <SafetyReporting
                                        visible={selectedSectionTab === 'Safety'}
                                        graphAvailability={graphAvailabilitySections.safety}
                                    />
                                </SeaTabContent>
                                <SeaTabContent tab="Maintenance" selectedTab={selectedSectionTab}>
                                    <MaintenanceReporting
                                        visible={selectedSectionTab === 'Maintenance'}
                                        graphAvailability={graphAvailabilitySections.maintenance}
                                    />
                                </SeaTabContent>
                                <SeaTabContent tab="HealthSafety" selectedTab={selectedSectionTab}>
                                    <RequireLicenseeSettings role="hasIncidents" showDenial>
                                        <HealthSafetyReporting
                                        visible={selectedSectionTab === 'HealthSafety'}
                                        graphAvailability={graphAvailabilitySections.healthSafety}
                                        />
                                    </RequireLicenseeSettings>
                                </SeaTabContent>
                                <SeaTabContent tab="Documents" selectedTab={selectedSectionTab}>
                                    <DocumentReporting
                                        visible={selectedSectionTab === 'Documents'}
                                        graphAvailability={graphAvailabilitySections.documents}
                                    />
                                </SeaTabContent>
                                <SeaTabContent tab="Crew" selectedTab={selectedSectionTab}>
                                    <CrewReporting
                                        visible={selectedSectionTab === 'Crew'}
                                        graphAvailability={graphAvailabilitySections.crew}
                                    />
                                </SeaTabContent>
                            </>
                        ) : (
                            <IonSpinner name="crescent" className="sea-spinner"/>
                        )}
                    </>
                )}


            </ReportSettingsContext.Provider>
        </StandardPageLayout>
    );
};

export default Reporting;
