import React, { useCallback } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { formatDate, warnDays } from '../../../lib/datesAndTime';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { CertificateHolderInfo, CrewCertificate } from '../../../shared-state/Crew/crewCertificates';
import { canView } from '../../../shared-state/Core/userPermissions';
import { getImgSrcFromString } from '../../../lib/files';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';

interface CrewCertificatesListPdfProps {
    onCompleted: () => void,
    listType: string;
    crewCertificateHolder: CertificateHolderInfo[],
    prioritisedCrewCertificates: CrewCertificate[]
}

const CrewCertificatesListPdf: React.FC<CrewCertificatesListPdfProps> = ({
    onCompleted,
    listType,
    crewCertificateHolder,
    prioritisedCrewCertificates
}) => {
    const vessel = sharedState.vessel.use();
    const title = 'Crew Certificates' + (vessel !== undefined ? ' - ' + vessel.name : '');
    const userId = sharedState.userId.use(1);
    const crewCertificatesState = sharedState.crewCertificates.use(1);
    const subHeading = (listType === 'all') ? 'All' : (listType === 'gap') ? 'Gap Analysis' : `Overdue & Due Within ${listType} Days`;

    const MakePDF = useCallback(() => {
        if (!crewCertificateHolder || !prioritisedCrewCertificates) return (<></>);

        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subHeading}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(2)}>
                            <Text>Certificate</Text>
                        </View>
                        <View style={styleItemColumn(2)}>
                            <Text>Held By</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Issued By</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Expiry</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    <>
                        {
                            prioritisedCrewCertificates.map((item: CrewCertificate, index) => {
                                const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                if (!canView('crewCertificates')) {

                                    if (item.heldBy === userId) {
                                        return (
                                            <React.Fragment key={'prior-me-' + index}>
                                                {index > 0 &&
                                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                                }
                                                <View wrap={false} style={pdfStyles.itemsRow}>
                                                    <View style={pdfStyles.thumbColumn}>
                                                        {imgSrc &&
                                                            <Image
                                                                src={imgSrc}
                                                                style={pdfStyles.thumbImg}
                                                            />
                                                        }
                                                    </View>
                                                    <View style={styleItemColumn(2)}>
                                                        <Text wrap={true}>
                                                            {item.title}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(2)}>
                                                        <Text>
                                                            {formatValue(crewCertificatesState?.holders[item.heldBy].name)}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text>
                                                            {formatValue(item.issuedBy)}
                                                        </Text>
                                                    </View>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text>
                                                            {item.whenExpires ? formatDate(item.whenExpires) : null}
                                                        </Text>
                                                    </View>
                                                    <View style={pdfStyles.statusColumn}>
                                                        {item.state === 'missing' ? (
                                                            <Text></Text>
                                                        ) : item.whenExpires ? (
                                                            <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]} />
                                                        ) : <Text></Text>}
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                        );
                                    }
                                }

                                return (
                                    <React.Fragment key={'prior-all-' + index}>
                                        {index > 0 &&
                                            <View style={pdfStyles.itemsRowSpacer}></View>
                                        }
                                        <View wrap={false} style={pdfStyles.itemsRow}>
                                            <View style={pdfStyles.thumbColumn}>
                                                {imgSrc &&
                                                    <Image
                                                        src={imgSrc}
                                                        style={pdfStyles.thumbImg}
                                                    />
                                                }
                                            </View>
                                            <View style={styleItemColumn(2)}>
                                                <Text wrap={true}>
                                                    {item.title}
                                                </Text>
                                            </View>
                                            <View style={styleItemColumn(2)}>
                                                <Text>
                                                    {formatValue(crewCertificatesState?.holders[item.heldBy].name)}
                                                </Text>
                                            </View>
                                            <View style={styleItemColumn(1)}>
                                                <Text>
                                                    {formatValue(item.issuedBy)}
                                                </Text>
                                            </View>
                                            <View style={pdfStyles.dateColumn}>
                                                <Text>
                                                    {item.whenExpires ? formatDate(item.whenExpires) : null}
                                                </Text>
                                            </View>
                                            <View style={pdfStyles.statusColumn}>
                                                {item.state === 'missing' ? (
                                                    <Text></Text>
                                                ) : item.whenExpires ? (
                                                    <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]} />
                                                ) : <Text></Text>}
                                            </View>
                                        </View>
                                    </React.Fragment>
                                );
                            })
                        }
                    </>

                    <>
                        {crewCertificateHolder.map((holder, index) => {
                            if (!canView('crewCertificates')) {
                                if (holder.name === renderFullName()) {
                                    return (
                                        <React.Fragment key={'holder-' + index}>
                                            <View wrap={false} style={pdfStyles.itemsCategory}>
                                                <Text>{holder.name}</Text>
                                            </View>
                                            {
                                                holder.certificates.map((item: CrewCertificate, index1) => {
                                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                                    return (
                                                        <React.Fragment key={'holder-me-' + index1}>
                                                            {index1 > 0 &&
                                                                <View style={pdfStyles.itemsRowSpacer}></View>
                                                            }
                                                            <View wrap={false} style={pdfStyles.itemsRow}>
                                                                <View style={pdfStyles.thumbColumn}>
                                                                    {imgSrc &&
                                                                        <Image
                                                                            src={imgSrc}
                                                                            style={pdfStyles.thumbImg}
                                                                        />
                                                                    }
                                                                </View>
                                                                <View style={styleItemColumn(2)}>
                                                                    <Text wrap={true}>
                                                                        {item.title}
                                                                    </Text>
                                                                </View>
                                                                <View style={styleItemColumn(2)}>
                                                                    <Text>
                                                                        {formatValue(crewCertificatesState?.holders[item.heldBy].name)}
                                                                    </Text>
                                                                </View>
                                                                <View style={styleItemColumn(1)}>
                                                                    <Text>
                                                                        {formatValue(item.issuedBy)}
                                                                    </Text>
                                                                </View>
                                                                <View style={pdfStyles.dateColumn}>
                                                                    <Text>
                                                                        {item.whenExpires ? formatDate(item.whenExpires) : null}
                                                                    </Text>
                                                                </View>
                                                                <View style={pdfStyles.statusColumn}>
                                                                    {item.state === 'missing' ? (
                                                                        <Text></Text>
                                                                    ) : item.whenExpires ? (
                                                                        <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]} />
                                                                    ) : <Text></Text>}
                                                                </View>
                                                            </View>
                                                        </React.Fragment>);

                                                })
                                            }
                                            <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                                        </React.Fragment>

                                    );
                                }
                            }
                            return (
                                <React.Fragment key={'holder-' + index}>
                                    <View wrap={false} style={pdfStyles.itemsCategory}>
                                        <Text>{holder.name}</Text>
                                    </View>
                                    {
                                        holder.certificates.map((item: CrewCertificate, index1) => {
                                            const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                            const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                            return (
                                                <React.Fragment key={'holder-all-' + index1}>
                                                    {index1 > 0 &&
                                                        <View style={pdfStyles.itemsRowSpacer}></View>
                                                    }
                                                    <View wrap={false} style={pdfStyles.itemsRow}>
                                                        <View style={pdfStyles.thumbColumn}>
                                                            {imgSrc &&
                                                                <Image
                                                                    src={imgSrc}
                                                                    style={pdfStyles.thumbImg}
                                                                />
                                                            }
                                                        </View>
                                                        <View style={styleItemColumn(2)}>
                                                            <Text wrap={true}>
                                                                {item.title}
                                                            </Text>
                                                        </View>
                                                        <View style={styleItemColumn(2)}>
                                                            <Text>
                                                                {formatValue(crewCertificatesState?.holders[item.heldBy].name)}
                                                            </Text>
                                                        </View>
                                                        <View style={styleItemColumn(1)}>
                                                            <Text>
                                                                {formatValue(item.issuedBy)}
                                                            </Text>
                                                        </View>
                                                        <View style={pdfStyles.dateColumn}>
                                                            <Text>
                                                                {item.whenExpires ? formatDate(item.whenExpires) : null}
                                                            </Text>
                                                        </View>
                                                        <View style={pdfStyles.statusColumn}>
                                                            {item.state === 'missing' ? (
                                                                <SeaStatusDueDate forPdf={true} isMissing />
                                                            ) : item.whenExpires ? (
                                                                <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.crewCertificates[0]} />
                                                            ) : <Text></Text>}
                                                        </View>
                                                    </View>
                                                </React.Fragment>);

                                        })
                                    }
                                    <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                                </React.Fragment>

                            );
                        })}
                    </>

                </Page>
            </Document>
        );
    }, [crewCertificateHolder, crewCertificatesState?.holders, prioritisedCrewCertificates, subHeading, title, userId]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="CrewCertificatesList"
        />
    );
};

export default CrewCertificatesListPdf;
