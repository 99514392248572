import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { ArchivableDocument, CreateableDocument, SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";
import { canView } from "../../shared-state/Core/userPermissions";
import { getDayOffsetMillis, warnDays } from '../../lib/datesAndTime';
import { renderFullNameForUserId } from "../Core/users";
import { registerFiles } from "../FileSyncSystem/filesToCache";
import { renderCategoryName } from '../../lib/categories';

//
// Loads crew certificates (active)
//

export interface CrewCertificate extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    emailReminder?: string;
    files: string[];
    heldBy: string;
    issuedBy?: string;
    licenseeId: string;
    state: string;
    searchText?: string;
    title: string;
    titleId: string;
    type: string;
    wasRenewed?: boolean;
    whenExpires?: number;
    whenIssued: number;
    whenToRemind?: number;
}

export type CertificateHolderInfo = {
    id: string;
    name: string,
    roleIds: string[],
    certificates: CrewCertificate[]
};

export type CrewCertificatesData = {
    prioritised: CrewCertificate[],
    top5: CrewCertificate[],
    byId: {
        [id: string]: CrewCertificate
    },
    holdersSorted: CertificateHolderInfo[],
    holders: {
        [holderId: string]: CertificateHolderInfo
    },
    numHighestPriority: number,
    count: number
};

export const crewCertificatesConfig: SharedStateConfig<CrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'licenseeId', 'users', 'crewCertificateTitles'],
    countLiveDocs: () => sharedState.crewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();
        const userId = sharedState.userId.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        const crewCertificateTitles = sharedState.crewCertificateTitles.current;
        if (
            userId &&
            licenseeId &&
            users &&
            crewCertificateTitles
        ) {
            return onSnapshot(
                query(
                    collection(firestore, 'crewCertificates'),
                    canView('crewCertificates') ? (
                        where('licenseeId', '==', licenseeId)
                    ) : (
                        where('heldBy', '==', userId)
                    ),
                    where('state', '==', 'active')
                ),
                (snap) => {
                    done();
                    const filteredCertificates = [] as CrewCertificate[];
                    const holders = {} as {
                        [holderId: string]: CertificateHolderInfo
                    };
                    const holdersSorted: CertificateHolderInfo[] = [];
                    const byId = {} as {
                        [id: string]: CrewCertificate
                    };
                    const _certificates = snap.docs.map((doc) => {
                        const item = {
                            id: doc.id,
                            ...doc.data(),
                            title: renderCategoryName(doc.data().titleId, crewCertificateTitles),
                        } as CrewCertificate;
                        let searchText = item.title.toLowerCase();
                        if (item.heldBy) {
                            searchText += renderFullNameForUserId(item.heldBy).toLowerCase();
                        }
                        if (item.issuedBy) {
                            searchText += item.issuedBy;
                        }
                        // Check if the certificate has no files attached
                        // if (!item.files || item.files.length === 0) {
                        //     item.state = 'missing';
                        // }
                        return {
                            ...item,
                            searchText
                        }
                    }).sort((a, b) => {
                        return a.searchText.localeCompare(b.searchText);
                    })


                    for (const certificate of _certificates) {
                        registerFiles(certificate.files, 'crewCertificates', certificate);
                        if (users.byId[certificate.heldBy] && users.byId[certificate.heldBy].state === 'active') { // Only include certificates that are held by active users
                            filteredCertificates.push(certificate);
                            byId[certificate.id] = certificate;
                            if (holders[certificate.heldBy]) {
                                holders[certificate.heldBy].certificates.push(certificate);
                            } else {
                                holders[certificate.heldBy] = {
                                    id: certificate.heldBy,
                                    name: renderFullNameForUserId(certificate.heldBy),
                                    roleIds: users.byId[certificate.heldBy].roleIds ?? [],
                                    certificates: [certificate]
                                };
                                holdersSorted.push(holders[certificate.heldBy]);
                            }
                        }
                    }

                    // Create a map of roles to compulsory certificate titles
                    const compulsoryCertificatesByRole: { [roleId: string]: string[] } = {};
                    for (const title of Object.values(crewCertificateTitles.byId)) {
                        if (title.compulsoryForRoles?.length && title.state === 'active') {
                            for (const roleId of title.compulsoryForRoles) {
                                if (compulsoryCertificatesByRole[roleId] === undefined) {
                                    compulsoryCertificatesByRole[roleId] = [];
                                }
                                compulsoryCertificatesByRole[roleId].push(title.id);
                            }
                        }
                    }

                    // Check for compulsory certificates and add missing ones
                    for (const user of Object.values(users.byId)) {
                        if (user.state === 'active') {
                            for (const roleId of user.roleIds || []) {
                                const compulsoryTitles = compulsoryCertificatesByRole[roleId] || [];
                                for (const titleId of compulsoryTitles) {
                                    if (!user.id || !user.roleIds?.length) {
                                        continue;
                                    }
                                    const hasCertificate = filteredCertificates.find(cert => cert.heldBy === user.id && cert.titleId === titleId);
                                    if (!hasCertificate || !hasCertificate.files.length) {
                                        const title = crewCertificateTitles.byId[titleId];
                                        const missingCertificate: CrewCertificate = hasCertificate ?? {
                                            id: `missing-${user.id}-${title.id}`,
                                            files: [],
                                            heldBy: user.id,
                                            licenseeId: licenseeId,
                                            state: 'missing',
                                            title: title.name,
                                            titleId: title.id,
                                            type: 'compulsory',
                                            whenIssued: Date.now(),
                                            whenAdded: Date.now(),
                                            addedBy: '',
                                        };
                                        if (!hasCertificate) {
                                            filteredCertificates.push(missingCertificate);
                                            byId[missingCertificate.id] = missingCertificate;
                                        }
                                        if (holders[user.id]) {
                                            holders[user.id].certificates.push(missingCertificate);
                                        } else {
                                            holders[user.id] = {
                                                id: user.id,
                                                name: renderFullNameForUserId(user.id),
                                                roleIds: user.roleIds,
                                                certificates: [missingCertificate]
                                            };
                                            holdersSorted.push(holders[user.id]);
                                        }
                                    }
                                }
                                if (user.roleIds && user.roleIds.indexOf(roleId) === user.roleIds.length - 1) {
                                    if (user.id && holders[user.id]) {
                                        holders[user.id].certificates.sort((a, b) => a.title.localeCompare(b.title));
                                    }
                                }
                            }
                        }
                    }
                    holdersSorted?.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    
                    let prioritised = [...filteredCertificates] as CrewCertificate[];
                    prioritised.sort((a, b) => {
                        return ((a.type === 'renewable' ? a.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) -
                                (b.type === 'renewable' ? b.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER));
                    });
                    // prioritised should only contain whenExpires up to a set amount days in the future
                    // (and should not contain any nonExpiring either)
                    const maxWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[warnDays.crewCertificates.length - 1]);
                    const minWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[0]);
                    let numHighestPriority = 0
                    for (let i = 0; i < prioritised.length; i++) {
                        if (prioritised[i].whenExpires && prioritised[i].whenExpires as number < minWhenExpires && prioritised[i].type !== 'nonExpiring') {
                            numHighestPriority++
                        }
                        if (prioritised[i].type === 'nonExpiring' || (prioritised[i].whenExpires && prioritised[i].whenExpires as number >= maxWhenExpires)) {
                            prioritised = prioritised.slice(0, i);
                            break;
                        };
                    };
                    const top5 = prioritised.slice(0, 5).filter((p) => p.whenExpires && p.whenExpires < minWhenExpires);
                    set({
                        prioritised,
                        top5,
                        byId,
                        holdersSorted,
                        holders,
                        numHighestPriority,
                        count: _certificates.length
                    });
    
                }, (error) => {
                    // This should be very rare
                    done();
                    console.log('Failed to access crew certificates ', error);
                }
            );
        }
    }
};
