import React, { useState, useCallback } from 'react';
import { IonGrid, IonRow } from '@ionic/react';
import { firestore, splittableBatch } from '../../../lib/firebase';
import { doc, serverTimestamp } from "firebase/firestore";
import { toInt } from '../../../lib/util';
import { logAction } from '../../../shared-state/General/actionLog';
import { sharedState } from '../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../managers/ErrorsManager/ErrorsManager';
import { Engine } from '../../../shared-state/VesselMaintenance/engines';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaEngines, { EditEngine } from '../../../components/SeaEngines/SeaEngines';
import './EditEnginesModal.css';

interface EditEnginesModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    //licenseeUserId?: string | undefined,
}

const EditEnginesModal: React.FC<EditEnginesModalProps> = ({showModal, setShowModal}) => {
    const userId = sharedState.userId.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const [editEngines, setEditEngines] = useState<EditEngine[]>([]);

    const onOpened = () => {
        const _editEngines = [] as EditEngine[];
        engines?.all?.forEach((engine: Engine) => {
            _editEngines.push({
                id: engine.id,
                name: engine.name,
                initialHours: ''+engine.hours,
                hours: ''+engine.hours,
                error: ''
            });
        });
        setEditEngines(_editEngines);
    }

    const hasFormErrors = (): boolean => {
        if (editEngines && editEngines.length > 0) {
            for (let i = 0; i < editEngines.length; i++) {
                if (editEngines[i].error) {
                    return true;
                }
            }
        }
        return false;
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!hasFormErrors()) {
            if (!userId) {
                throw new Error('userId is not defined');
            }
            if (!vesselId) {
                throw new Error('vesselId is not defined');
            }
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'engines', 'update');

            let somethingChanged = false;
            editEngines.forEach((editEngine) => {
                if (editEngine.hours !== editEngine.initialHours && editEngine.id) {
                    // Hours have changed so we need to update them
                    somethingChanged = true;
                    batchTrace.exampleDocId = editEngine.id;

                    batch.set(
                        doc(firestore, 'engines', editEngine.id),
                        {
                            hours: toInt(editEngine.hours, 0),
                            updatedBy: userId,
                            updatedVia: 'editEngines',
                            whenUpdated: batchTrace.whenAction,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    logAction(
                        batch,
                        'Update',
                        'engines',
                        editEngine.id,
                        `${editEngine.name}, ${editEngine.hours} hours`,
                        [vesselId]
                    );
                }
            });

            if (somethingChanged) {
                onCollectionUpdated(batch, 'engines');

                batchTrace.data = {editEngines};
                batchTrace.save('Update engine hours');
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });
            }

            setShowModal(false);
        }
    };

    const isModalDirty = useCallback(() => {
        for (let i = 0; i < editEngines.length; i++) {
            if (editEngines[i].hours !== editEngines[i].initialHours) {
                return true;
            }
        }
        return false;
    }, [editEngines]);

    return (
        <SeaModal
            title="Edit Engine/Generator Hours"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="thin"
        >
            <form onSubmit={onSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <SeaEngines
                            name="editEngines"
                            editEngines={editEngines}
                            setEditEngines={setEditEngines}
                            colSize="12"
                        />
                    </IonRow>
                </IonGrid>
                <p style={{ marginTop: '20px' }}>
                    {
                        ((engines?.all?.length || 0) > 0)
                        ? "Note: Engines/generators can be edited in "
                        : "You currently have no engines/generators. You can add engines/generators in "
                    }
                    Vessel Settings
                </p>

                <div style={{ height: '40px' }}></div>
                <SeaButton zone="white" type="submit">Submit New Hours</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditEnginesModal;
