import { DocumentData, QueryDocumentSnapshot, collection, where } from "firebase/firestore";
import { firestore, setupArrayQueryListener } from "../../../lib/firebase";
import { useEffect, useState } from "react";
import { sharedState } from "../../shared-state";
import { ReportingEquipmentByIdData } from './useReportingEquipmentById';
import { ScheduledMaintenanceTask } from "../../VesselMaintenance/maintenanceSchedule";
import { useEnginesFromVessels } from '../../VesselMaintenance/useEnginesFromVessels';
import { warnDays } from "../../../lib/datesAndTime";

const makeTasks = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    return docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        } as ScheduledMaintenanceTask;
    });
};

type ScheduledMaintenanceTaskData = {
    prioritised: ScheduledMaintenanceTask[];
    byId: { [id: string]: ScheduledMaintenanceTask };
    byPriority: {
        overdue: ScheduledMaintenanceTask[];
        upcoming: ScheduledMaintenanceTask[];
    };
};

export const useReportingMaintenanceTasks = (
    isActive: boolean,
    selectedVesselIds: string[],
    selectedTaskStatuses: string[] | undefined,
    equipmentById: ReportingEquipmentByIdData | undefined,
    equipmentCriticality: string | undefined
) => {
    const todayMillis = sharedState.todayMillis.use()!;
    const engines = useEnginesFromVessels(isActive ? selectedVesselIds : undefined)!;
    const [reportingMaintenanceTasks, setReportingMaintenanceTasks] = useState<ScheduledMaintenanceTaskData>();

    useEffect(() => {
        setReportingMaintenanceTasks(undefined);
        if (!selectedTaskStatuses || selectedTaskStatuses.length === 0 || selectedVesselIds.length === 0) {
            return;
        }

        if (
            isActive &&
            selectedVesselIds?.length &&
            engines
        ) {
            const includeOverdue = (selectedTaskStatuses.indexOf('overdue') !== -1);
            const includeUpcoming = (selectedTaskStatuses.indexOf('upcoming') !== -1);

            if (!includeOverdue && !includeUpcoming) {
                return;
            }

            return setupArrayQueryListener(
                'scheduledMaintenanceTasks',
                collection(firestore, 'scheduledMaintenanceTasks'),
                [where('state', '==', 'active')],
                'vesselId',
                'in',
                selectedVesselIds,
                [],
                (
                    docs: QueryDocumentSnapshot<DocumentData>[],
                    isCombined: boolean
                ) => {
                    let all = makeTasks(docs);
                    const filteredTasks: ScheduledMaintenanceTask[] = [];
                    const byId: { [id: string]: ScheduledMaintenanceTask } = {};
                    const byPriority: ScheduledMaintenanceTaskData['byPriority'] = {
                        overdue: [],
                        upcoming: []
                    };

                    all.forEach(task => {
                        let priority = Number.MAX_SAFE_INTEGER;
                        let useHours = false;

                        if (task.whenDue) {
                            const daysAway = (task.whenDue - todayMillis) / (24 * 60 * 60 * 1000);
                            priority = daysAway;
                        }

                        if (task.engineId && task.engineHoursDue && engines?.byId[task.engineId]?.hours !== undefined) {
                            task.engineHoursLeft = task.engineHoursDue - engines.byId[task.engineId].hours;
                            const engineDaysOrder = (task.engineHoursLeft / 50) * 7;
                            if (engineDaysOrder < priority) {
                                useHours = true;
                                priority = engineDaysOrder;
                            }
                        }

                        const isOverdue = priority < 0;
                        const isUpcoming = priority >= 0 && priority < warnDays.maintenanceSchedule[0];

                        if ((includeOverdue && isOverdue) || (includeUpcoming && isUpcoming)) {
                            task.priority = priority;
                            task.useHours = useHours;
                            filteredTasks.push(task);
                            byId[task.id] = task;

                            if (isOverdue) {
                                byPriority.overdue.push(task);
                            } else if (isUpcoming) {
                                byPriority.upcoming.push(task);
                            }
                        }
                    });

                    // Filter by criticality
                    let finalTasks = filteredTasks.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                    if (equipmentCriticality === 'critical' && equipmentById) {
                        finalTasks = finalTasks.filter((item) => equipmentById[item.equipmentId]?.isCritical);
                    } else if (equipmentCriticality === 'nonCritical' && equipmentById) {
                        finalTasks = finalTasks.filter((item) => !equipmentById[item.equipmentId]?.isCritical);
                    }

                    if (isCombined) {
                        filteredTasks.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                    }
                    setReportingMaintenanceTasks({
                        prioritised: finalTasks,
                        byId,
                        byPriority
                    });
                }
            );
        }
    }, [isActive, todayMillis, selectedVesselIds, selectedTaskStatuses, equipmentById, equipmentCriticality, engines]);

    return reportingMaintenanceTasks;
};
