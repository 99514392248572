import React, { useEffect, useMemo, useState } from 'react';
import { useReportSettings } from '../Reporting';
import { logPageView } from '../../../lib/firebase';
import { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { renderVesselName } from '../../../shared-state/Core/vessels';
import { useLicenseeVoyages } from '../../../shared-state/VesselLogbook/useLicenseeVoyages';
import { Voyage } from '../../../shared-state/VesselLogbook/voyages';
import { calcFuelUsedFromVoyage } from '../../../lib/util';
import SeaHorizontalStackedBarGraph from '../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../lib/reporting';
import ViewVoyagesReport from '../../../modals/Reporting/Logbook/ViewVoyagesReport/ViewVoyagesReport';
import ViewFuelUsedReport from '../../../modals/Reporting/Logbook/ViewFuelUsedModal/ViewFuelUsedReport';

interface LogbookReportingProps {
    visible: boolean,
    graphAvailability: {
        count: number,
        voyages: boolean,
        fuelUsed: boolean
    }
}

const LogbookReporting: React.FC<LogbookReportingProps> = ({
    visible,
    graphAvailability
}) => {
    const { selectedVesselIds, selectedVesselId, dateRange, dateRangeDescription } = useReportSettings();
    const licenseeVoyages = useLicenseeVoyages(
        visible ? dateRange : undefined, 
        visible ? selectedVesselIds : undefined
    );
    const [modalToShow, setModalToShow] = useState<string>();

    useEffect(() => {
        if (visible) {
            logPageView('Reporting/LogbookReporting');
        }
    }, [visible]);

    
    const filteredVoyages = useMemo(() => {
        const all: Voyage[] = []
        if (
            visible &&
            graphAvailability.count > 0 &&
            licenseeVoyages?.byVessel &&
            selectedVesselIds?.length > 0
        ) {
            selectedVesselIds.forEach((vesselId: string) => {
                if (licenseeVoyages.byVessel[vesselId]) {
                    all.push(...licenseeVoyages.byVessel[vesselId]);
                }
            });
            return all;
        }
        return [];
    }, [visible, graphAvailability, licenseeVoyages, selectedVesselIds]);
    

    const currentVoyagesGraphData = useMemo(() => {
        if (filteredVoyages && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByVoyageType = {
                'singleDay': 0,
                'multiDay': 1,
                'multiTrip': 2,
            };
            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0, 0]
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });
            filteredVoyages.forEach((voyage) => { // Updated to use filteredItems.byVesselId
                if (byVesselId[voyage.vesselId] && indexByVoyageType[voyage.tripType as keyof typeof indexByVoyageType] !== undefined) {
                    const voyageIndex: number = indexByVoyageType[voyage.tripType as keyof typeof indexByVoyageType];
                    byVesselId[voyage.vesselId].values![voyageIndex]++;
                }
            });
            return array;
        }
        return undefined;
    }, [filteredVoyages, selectedVesselIds]);

    const currentFuelUsedGraphData = useMemo(() => {
        if (filteredVoyages && selectedVesselIds) {
            const array: GraphData[] = [];
            const byVesselId: {
                [vesselId: string]: GraphData
            } = {};
            const indexByVoyageType = {
                'singleDay': 0,
                'multiDay': 1,
                'multiTrip': 2,
            };
            selectedVesselIds.forEach((vesselId: string) => {
                const item = {
                    name: renderVesselName(vesselId),
                    values: [0, 0, 0]
                };
                byVesselId[vesselId] = item;
                array.push(item);
            });
            filteredVoyages.forEach((voyage) => {
                if (byVesselId[voyage.vesselId] && indexByVoyageType[voyage.tripType as keyof typeof indexByVoyageType] !== undefined) {
                    const voyageIndex: number = indexByVoyageType[voyage.tripType as keyof typeof indexByVoyageType];
                    console.log(`voyageIndex: ${voyageIndex}, voyage.fuelStart: ${voyage.fuelStart}, voyage.fuelEnd: ${voyage.fuelEnd}, voyage.fuelBunkered: ${voyage.fuelBunkered}`);
                    byVesselId[voyage.vesselId].values![voyageIndex] += calcFuelUsedFromVoyage(voyage);
                }
            });
            return array;
        }
    }, [filteredVoyages, selectedVesselIds]);

    const dateRangeSubTitle = useMemo(() => `(${dateRangeDescription})`, [dateRangeDescription]);

    const subtitle = useMemo(() => `(${selectedVesselId ? `${renderVesselName(selectedVesselId)}, ` : ''}${dateRangeDescription})`, [dateRangeDescription, selectedVesselId]);

    if (!visible) {
        return null;
    }

    let n = 0;

    return (
        <>
            <div className={`reporting-grid max-${graphAvailability.count}-graphs`}>
                {graphAvailability.voyages &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={"Voyages by Vessel"}
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('logbookEntries');
                        }}
                        data={currentVoyagesGraphData}
                        units="Voyages"
                        categories={['Single Day', 'Multi Day', 'Multi Trip']}
                        colourPalette={reporting.colours.default}
                    />
                }
                {graphAvailability.fuelUsed &&
                    <SeaHorizontalStackedBarGraph
                        n={n++}
                        title={"Fuel Used by Vessel (L)"}
                        subTitle={dateRangeSubTitle}
                        mode="dashboard"
                        visible={visible}
                        onClick={(e) => {
                            setModalToShow('fuelUsed');
                        }}
                        data={currentFuelUsedGraphData}
                        units="Litres"
                        categories={['Single Day', 'Multi Day', 'Multi Trip']}
                        colourPalette={reporting.colours.default}
                    />
                }
            </div>
            {visible && graphAvailability.voyages &&
                <ViewVoyagesReport
                    title="Voyages by Vessel"
                    subTitle={subtitle}
                    showModal={modalToShow === 'logbookEntries'}
                    setShowModal={(showModal) => {
                        setModalToShow(showModal ? 'logbookEntries' : undefined);
                    }}
                    graphData={currentVoyagesGraphData}
                    filteredItems={filteredVoyages}
                    categories={['Single Day', 'Multi Day', 'Multi Trip']}
                />
            }
            {visible && graphAvailability.fuelUsed &&
                <ViewFuelUsedReport
                    title="Fuel Used by Vessel (L)"
                    subTitle={subtitle}
                    showModal={modalToShow === 'fuelUsed'}
                    setShowModal={(showModal) => {
                        setModalToShow(showModal ? 'fuelUsed' : undefined);
                    }}
                    graphData={currentFuelUsedGraphData}
                    filteredItems={filteredVoyages}
                    categories={['Single Day', 'Multi Day', 'Multi Trip']}
                />
            }
        </>
    );
};

export default LogbookReporting;