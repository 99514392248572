import React, { useCallback, useEffect, useMemo, useState } from "react";
import SeaModal from "../../../../components/SeaModal/SeaModal";
import { firestore, splittableBatch } from "../../../../lib/firebase";
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from "../../../../lib/util";
import { logAction } from "../../../../shared-state/General/actionLog";
import { renderFullName } from "../../../../shared-state/Core/users";
import { sharedState } from '../../../../shared-state/shared-state';
import { makeBatchTrace } from "../../../../managers/ErrorsManager/ErrorsManager";
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { UserPermissions, areUserPermissionsEqual, permissionRoles } from '../../../../shared-state/Core/userPermissions';
import { UserPermissionDefaults, UserPermissionDefaultsData, defaultUserPermissionDefaults } from '../../../../shared-state/Crew/userPermissionDefaults';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { haveCategoriesChanged, makeCategoryIdsForEditing, makeCategoryNamesForEditing, saveCategoryChanges } from '../../../../lib/categories';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import SeaUserPermissions from "../../../../components/SeaUserPermissions/SeaUserPermissions";
import SeaTabsGroup from "../../../../components/SeaTabsGroup/SeaTabsGroup";
import SeaTab from "../../../../components/SeaTab/SeaTab";
import SeaButton from "../../../../components/SeaButton/SeaButton";
import EditUserPermissionDefaultModal from "./EditUserPermissionDefaultsModal";
import SeaLinkButton from "../../../../components/SeaLinkButton/SeaLinkButton";
import SeaTabContent from "../../../../components/SeaTabContent/SeaTabContent";
import SeaInputList from '../../../../components/SeaInputList/SeaInputList';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import './CrewSettingsModal.css'

interface CrewSettingsModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

const CrewSettingsModal = ({ showModal, setShowModal }: CrewSettingsModalProps) => {
    const userId = sharedState.userId.use(showModal);
    const userPermissionDefaults = sharedState.userPermissionDefaults.use(showModal) as UserPermissionDefaultsData;
    const onlineStatus = sharedState.onlineStatus.use(showModal);
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userRoles = sharedState.userRoles.use(showModal);
    const [categoryIds, setCategoryIds] = useState<string[]>();
    const [categoryNames, setCategoryNames] = useState<string[]>();
    const [tab, setTab] = useState("userPermissionDefaults");
    const [showPermissionDefaultsModal, setShowPermissionDefaultsModal] = useState(false);
    const [userPermissionDefaultsArray, setUserPermissionDefaultsArray] = useState<UserPermissionDefaults[]>();
    const [userPermissionDefaultsTab, setUserPermissionDefaultsTab] = useState('0');
    const [permissionDefaultName, setPermissionDefaultName] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isAddingPermissionDefault, setIsAddingPermissionDefault] = useState(false);
    const formRef = React.createRef<HTMLFormElement>();

    useEffect(() => {
        if (userRoles) {
            setCategoryIds(makeCategoryIdsForEditing(userRoles));
            setCategoryNames(makeCategoryNamesForEditing(userRoles));
        }
    }, [userRoles])

    const selectedIndex = useMemo(() => {
        return parseInt(userPermissionDefaultsTab);
    }, [userPermissionDefaultsTab]);
    

    const setUserPermissionsData: React.Dispatch<React.SetStateAction<UserPermissions | undefined>> = (userPermissionsAction) => {
        setUserPermissionDefaultsArray((prevUserPermissionDefaultsArray) => {
            if (!prevUserPermissionDefaultsArray) return prevUserPermissionDefaultsArray;

            const newArray = [...prevUserPermissionDefaultsArray];
            const updatedPermissions = typeof userPermissionsAction === 'function'
                ? userPermissionsAction(newArray[selectedIndex])
                : userPermissionsAction;

            newArray[selectedIndex] = {
                ...newArray[selectedIndex],
                ...updatedPermissions
            } as UserPermissionDefaults;

            return newArray;
        });
    };

    useEffect(() => {
        if (userPermissionDefaults?.all && userPermissionDefaultsArray === undefined) {
            setUserPermissionDefaultsArray(
                userPermissionDefaults.all.map((userPermissionDefault: UserPermissionDefaults) => {
                    const o = {
                        id: userPermissionDefault.id,
                        name: userPermissionDefault.name
                    } as UserPermissionDefaults;
                    permissionRoles.forEach((permissionRole) => {
                        o[permissionRole] = userPermissionDefault[permissionRole];
                    });
                    return o;
                })
            );
        }
    }, [userPermissionDefaults, userPermissionDefaultsArray]);

    const onClosed = () => {
        setUserPermissionDefaultsArray(undefined);
        setUserPermissionDefaultsTab('0');
    };

    const hasUserPermissionDefaultChanged = useCallback((userPermissionDefault: UserPermissionDefaults) => {
        if (userPermissionDefault.id === undefined) {
            return true;
        }
        const existingUserPermissionDefault = userPermissionDefaults.byId[userPermissionDefault.id];
        if (
            existingUserPermissionDefault === undefined ||
            existingUserPermissionDefault.name !== userPermissionDefault.name ||
            !areUserPermissionsEqual(existingUserPermissionDefault, userPermissionDefault)
        ) {
            return true;
        }
    }, [userPermissionDefaults]);

    const isModalDirty = useCallback(() => {
        if (userPermissionDefaultsArray && userPermissionDefaults) {
            if (userPermissionDefaultsArray.length !== userPermissionDefaults.all.length) {
                return true;
            }
            for (let i = 0; i < userPermissionDefaultsArray.length; i++) {
                const userPermissionDefault = userPermissionDefaultsArray[i];
                if (
                    userPermissionDefault.id === undefined ||
                    userPermissionDefaults.byId[userPermissionDefault.id] === undefined ||
                    userPermissionDefault.name !== userPermissionDefaults.byId[userPermissionDefault.id].name ||
                    !areUserPermissionsEqual(userPermissionDefault, userPermissionDefaults.byId[userPermissionDefault.id])
                ) {
                    return true;
                }
            }
        }
        if (haveCategoriesChanged(categoryIds, categoryNames, userRoles)) {
            return true;
        }
        return false;
    }, [userPermissionDefaultsArray, userPermissionDefaults, categoryIds, categoryNames, userRoles]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (preventMultiTap('editCrewSettings')) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
            return; 
        }

        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, 'userPermissionDefaults', 'update', licenseeId);

        // Sort userPermissionDefaultsArray to move deleted permission defaults to the end
        const sortedUserPermissionDefaultsArray = [...userPermissionDefaultsArray!].sort((a, b) => {
            if (a.state === 'deleted' && b.state !== 'deleted') {
                return 1;
            }
            if (a.state !== 'deleted' && b.state === 'deleted') {
                return -1;
            }
            return 0;
        });

        sortedUserPermissionDefaultsArray?.forEach((userPermissionDefault: UserPermissionDefaults, index: number) => {
            if (!userId || !licenseeId) {
                return;
            }
            if (userPermissionDefault.id) {
                if (hasUserPermissionDefaultChanged(userPermissionDefault) || userPermissionDefault.order !== index) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = licenseeId;
                    const { id, ..._userPermissionDefault } = userPermissionDefault;

                    batch.set(
                        doc(firestore, 'userPermissionDefaults', id),
                        {
                            ..._userPermissionDefault,
                            whenUpdated: batchTrace.whenAction,
                            updatedBy: userId,
                            order: index,
                            touched: serverTimestamp(),
                        } as Partial<UserPermissionDefaults>,
                        { merge: true }
                    );
                    logAction(
                        batch,
                        'Update',
                        'userPermissionDefaults',
                        userId,
                        renderFullName(),
                        undefined,
                        [licenseeId]
                    );
                    batchTrace.data = {
                        licenseeId,
                        ...userPermissionDefault,
                    };
                    batchTrace.save(`Update ${userPermissionDefault.name} user permission default for ${licenseeId}`);
                }
            } else {
                const newUserPermissionDefaultsRef = doc(collection(firestore, 'userPermissionDefaults'));
                batchTrace.exampleOperation = 'create';
                batchTrace.exampleDocId = newUserPermissionDefaultsRef.id;

                batch.set(newUserPermissionDefaultsRef, {
                    ...userPermissionDefault,
                    licenseeId: licenseeId,
                    whenAdded: batchTrace.whenAction,
                    addedBy: userId,
                    state: 'active',
                    order: index,
                    touched: serverTimestamp(),
                } as Partial<UserPermissionDefaults>);
                logAction(
                    batch,
                    'Add',
                    'userPermissionDefaults',
                    newUserPermissionDefaultsRef.id,
                    `${userPermissionDefault.name} created`,
                    undefined,
                    [newUserPermissionDefaultsRef.id]
                );
                batchTrace.data = {
                    licenseeId,
                    ...userPermissionDefault,
                    whenAdded: batchTrace.whenAction,
                    addedBy: userId,
                    state: 'active',
                    touched: serverTimestamp(),
                };
                batchTrace.save(`Add ${userPermissionDefault.name} user permission default for ${licenseeId}`);
            }
        });

        userPermissionDefaults.all.forEach((existingUserPermissionDefault: UserPermissionDefaults) => {
            let foundMatch = false;
            sortedUserPermissionDefaultsArray?.forEach((userPermissionDefault: UserPermissionDefaults) => {
                if (existingUserPermissionDefault.id === userPermissionDefault.id) {
                    foundMatch = true;
                }
            });
            if (!foundMatch) {
                batchTrace.exampleOperation = 'delete';
                batchTrace.exampleDocId = licenseeId;
                const { id, ..._existingUserPermissionDefault } = existingUserPermissionDefault;

                batch.set(
                    doc(firestore, 'userPermissionDefaults', id),
                    {
                        ..._existingUserPermissionDefault,
                        state: 'deleted',
                        whenDeleted: batchTrace.whenAction,
                        deletedBy: userId,
                        touched: serverTimestamp(),
                    } as Partial<UserPermissionDefaults>,
                    { merge: true }
                );
                batchTrace.data = {
                    licenseeId,
                    whenDeleted: batchTrace.whenAction,
                    deletedBy: userId,
                    ...existingUserPermissionDefault,
                };
                batchTrace.save(`Delete ${existingUserPermissionDefault.name} user permission default for ${licenseeId}`);
            }
        });

        onCollectionUpdated(batch, 'userPermissionDefaults');
        batchTrace.save(`Update userPermissionDefaults`);

        if (haveValuesChanged({ ids: categoryIds, names: categoryNames }, userRoles)) {
            saveCategoryChanges(
                batch,
                batchTrace,
                categoryIds,
                categoryNames,
                undefined,
                userRoles,
                'userRoles',
                'licenseeId',
                licenseeId
            );

            batchTrace.save(`Update role/department categories`);
        }

        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            batchTrace.reportError(error.message, error);
        });

        setShowModal(false);
    };

    const handleRenamePermissionDefault = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsAddingPermissionDefault(false);
        setPermissionDefaultName(userPermissionDefaultsArray![selectedIndex].name);
        setShowPermissionDefaultsModal(true);
    };

    const handleDeletePermissionDefault = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        confirmAction(
            'Are you sure you want to delete this permission default?'
        ).then((response) => {
            setUserPermissionDefaultsArray((prev) => {
                if (!prev) {
                    return prev;
                }
                const newArray = [...prev];
                newArray.splice(selectedIndex, 1);
                return newArray;
            });
            setUserPermissionDefaultsTab((Math.max(0, selectedIndex - 1)).toString());
        }).catch(() => {});
    };

    const setUserPermissionDefaultName = (name: string) => {
        let index = selectedIndex;
        const newArray = [...userPermissionDefaultsArray!];
        if (isAddingPermissionDefault) {
            const newPermissionDefault = { ...defaultUserPermissionDefaults.crew, name: name };
            newArray.push(newPermissionDefault);
            index = newArray.length - 1;
        }
        newArray[index].name = name;
        setUserPermissionDefaultsArray(newArray);
        setUserPermissionDefaultsTab((Math.max(0, index)).toString());
    };

    const validatePermissionDefaultName = (name: string) => {
        const newErrors: { [key: string]: string } = {};
        if (!name) {
            newErrors.permissionDefaultName = 'Permission Default name is required';
        } else if (userPermissionDefaultsArray?.find((userPermissionDefault) => userPermissionDefault.name === name)) {
            newErrors.permissionDefaultName = 'Permission Default name already exists';
        }
        setErrors(newErrors);
        return newErrors;
    };

    const handleSubmitPermissionDefault = () => {
        setHasSubmitted(true);
        const validationErrors = validatePermissionDefaultName(permissionDefaultName);
        if (Object.keys(validationErrors).length === 0) {
            setUserPermissionDefaultName(permissionDefaultName);
            setShowPermissionDefaultsModal(false);
        }
    };

    const handleDragEnd = (startIndex: number, endIndex: number) => {
        setUserPermissionDefaultsArray((prevArray) => {
            if (!prevArray) return prevArray;
            const newArray = arrayMove(prevArray, startIndex, endIndex);
            let newTab = '0';
            if (selectedIndex === startIndex) {
                newTab = endIndex.toString();
            } else if (selectedIndex > startIndex && selectedIndex <= endIndex) {
                newTab = (selectedIndex - 1).toString();
            } else if (selectedIndex < startIndex && selectedIndex >= endIndex) {
                newTab = (selectedIndex + 1).toString();
            }
            if (newTab !== userPermissionDefaultsTab) {
                setUserPermissionDefaultsTab(newTab);
            }
            return newArray;
        });
    };

    const arrayMove = (arr: UserPermissionDefaults[], fromIndex: number, toIndex: number): UserPermissionDefaults[] => {
        const newArray = [...arr];
        const [movedItem] = newArray.splice(fromIndex, 1);
        newArray.splice(toIndex, 0, movedItem);
        return newArray;
    };

    return (
        <SeaModal
            title="Crew Settings"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            size="wide"
            onClosed={onClosed}
            tabsPanel={
                <>
                    <SeaTabsGroup key="crew-settings" selectedTab={tab} setTab={setTab} mode="forms">
                        <SeaTab tab="userPermissionDefaults" mode="forms">
                            User Permission Defaults
                        </SeaTab>
                        <SeaTab tab="userRoles" mode="forms">
                            Roles / Departments
                        </SeaTab>
                    </SeaTabsGroup>
                    <SeaTabContent tab="userPermissionDefaults" selectedTab={tab}>
                    {onlineStatus?.isOnline ? (
                        <SeaTabsGroup
                            selectedTab={userPermissionDefaultsTab}
                            setTab={(tab) => setUserPermissionDefaultsTab(tab)}
                            mode="forms"
                            mini
                            showPlus
                            plusText="Add Permission Default"
                            onAddTab={() => {
                                setIsAddingPermissionDefault(true);
                                setPermissionDefaultName('');
                                setShowPermissionDefaultsModal(true);
                            }}
                            draggable
                            onDragEnd={handleDragEnd}
                        >
                            {userPermissionDefaultsArray?.map((permissionDefault, index) => {
                                if (permissionDefault.state === 'deleted') {
                                    return null;
                                }
                                return (
                                    <SeaTab key={index} tab={index.toString()} mode="forms">
                                        {permissionDefault.name}
                                    </SeaTab>
                                );
                            })}
                        </SeaTabsGroup>
                    ) : (
                        <div className="offline-message">
                            <SeaIcon icon="offline"/>
                            <p>You cannot edit user permission defaults while offline.</p>
                        </div>
                    )}
                    </SeaTabContent>
                </>
            }
            actionPanel={
                <SeaButton type="submit" onClick={() => formRef.current?.requestSubmit()}>
                    Save Settings
                </SeaButton>
            }
        >
            <form onSubmit={onSubmit} ref={formRef}>
                <SeaTabContent tab="userPermissionDefaults" selectedTab={tab}>
                    {onlineStatus?.isOnline ? (
                        <div className="user-permission-defaults">
                            {userPermissionDefaultsArray?.[selectedIndex] ? (
                                <SeaUserPermissions data={userPermissionDefaultsArray[selectedIndex]} setData={setUserPermissionsData} keyPrefix={userPermissionDefaultsTab} />
                            ) : null}
                            {userPermissionDefaultsArray?.length && selectedIndex >= 0 ? (
                                <div className="view-modal-buttons">
                                    <SeaLinkButton mode="standard-link" onClick={handleRenamePermissionDefault}>Rename Permission Default</SeaLinkButton>
                                    <div className="spacer-wide"></div>
                                    <SeaLinkButton mode="standard-link" onClick={handleDeletePermissionDefault}>Delete Permission Default</SeaLinkButton>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </SeaTabContent>
                <SeaTabContent tab="userRoles" selectedTab={tab}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="12">
                                <SeaInputList
                                    name="userRoles"
                                    label="Roles / Departments"
                                    maxWidth="350px"
                                    ids={categoryIds}
                                    setIds={setCategoryIds}
                                    values={categoryNames}
                                    setValues={setCategoryNames}
                                    addNewText="Add Role/Department"
                                    confirmDelete={true}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SeaTabContent>

               
            </form>

            <EditUserPermissionDefaultModal
                showModal={showPermissionDefaultsModal}
                setShowModal={setShowPermissionDefaultsModal}
                permissionDefaultName={permissionDefaultName}
                setPermissionDefaultName={(r: string) => {
                    if (hasSubmitted) {
                        validatePermissionDefaultName(r);
                    }
                    setPermissionDefaultName(r)
                }}
                error={errors.permissionDefaultName || ''}
                handleSubmit={handleSubmitPermissionDefault}
            />
        </SeaModal>
    );
};

export default CrewSettingsModal;