import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, sharedState } from "../shared-state";
import { UserType } from "./user";

//
// List of users within the licensee
// Will load ALL users including deleted and archived users so we can render their names for historical entries
// Note: This means we are loading ALL users even without having the crewParticulars permission, but this
// should be fine as more sensitive information is stored in userDetails
//

export type UsersData = {
    byId: {
        [userId: string]: UserType
    },
    byVesselId: {
        [userId: string]: UserType[]
    },
    all: UserType[],
    staff: UserType[],
    nonStaff: UserType[],
    archived: UserType[],
    renderFullName: (userId: string) => string
};

export const renderFullName = (user?: { firstName?: string, lastName?: string} | undefined) => {
    if (user === undefined) {
        user = sharedState.user.current;
    }
    if (user) {
        if (user.firstName) {
            if (user.lastName) {
                return `${user.firstName.trim()} ${user.lastName.trim()}`;
            }
            return user.firstName.trim();
        } else if (user.lastName) {
            return user.lastName.trim();
        }
    }
    return '';
};

export const renderFullNameForUserId = (userId: string | undefined): string => {
    if (userId && sharedState.users.current?.byId[userId]) {
        return renderFullName(sharedState.users.current.byId[userId]);
    }
    return '';
};

export const renderCrewList = (crewIds: string[] | undefined, defaultValue = '', separator = ', ') => {
    let s = '';
    if (crewIds && crewIds.length > 0 && sharedState.users.current?.byId) {
        crewIds.forEach((crewId: string) => {
            if (sharedState.users.current?.byId[crewId]) {
                if (s.length > 0) {
                    s += separator;
                }
                s += renderFullNameForUserId(crewId);
            }
        });
    }
    if (s === '') {
        return defaultValue;
    }
    return s;
};

export const usersConfig: SharedStateConfig<UsersData> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.users.current ? Object.keys(sharedState.users.current.byId).length : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onSnapshot(
                query(
                    collection(firestore, 'users'),
                    where('licenseeId', '==', licenseeId),
                    //where('state', '==', 'active'),
                    orderBy('firstName'),
                    orderBy('lastName')
                ),
                (snap) => {
                    done();
                    const byId = {} as {
                        [userId: string]: UserType
                    };
                    const byVesselId = {} as {
                        [userId: string]: UserType[]
                    };
                    const all = [] as UserType[];
                    const staff = [] as UserType[];
                    const nonStaff = [] as UserType[];
                    const archived = [] as UserType[];

                    snap.docs.forEach((doc) => {
                        const u = {
                            id: doc.id,
                            ...doc.data()
                        } as UserType;
                        all.push(u);
                        byId[doc.id] = u;

                        if (u.state === 'active') {
                            if (u.isStaff) {
                                staff.push(u);
                                u.vesselIds?.forEach((vesselId: string) => {
                                    if (byVesselId[vesselId] === undefined) {
                                        byVesselId[vesselId] = [];
                                    }
                                    byVesselId[vesselId].push(u);
                                });
                                // if (u.isLicensee) {
                                //     analytics.setUserProperties({
                                //         licenseeName: `${u.firstName} ${u.lastName}`,
                                //         licenseeCompany: u.companyName ? u.companyName : 'Unknown'
                                //     });
                                // }
                            } else {
                                nonStaff.push(u);
                            }
                        } else if (u.state === 'archived') {
                            archived.push(u);
                        }
                    });

                    // Sort archived users by whenArchived
                    archived.sort((a, b) => {
                        const aTime = typeof a.whenArchived === 'number' ? a.whenArchived : 0;
                        const bTime = typeof b.whenArchived === 'number' ? b.whenArchived : 0;
                        return bTime - aTime;
                    });

                    set({
                        byId,
                        byVesselId,
                        staff,
                        nonStaff,
                        archived,
                        all,
                        renderFullName: (userId: string) => {
                            return renderFullName(sharedState.users.current?.byId[userId]);
                        }
                    });

                }, (error) => {
                    done();
                    console.log(`error getting users for licensee ${licenseeId}`, error);
                }
            );

        }
    },
};
