import React from 'react';
import { formatDate, formatInterval } from '../../../../../lib/datesAndTime';
import { formatValue } from '../../../../../lib/util';
import { openContextualHelp } from '../../../../../managers/ContextualHelpManager/ContextualHelpManager';
import { UserType } from '../../../../../shared-state/Core/user';
import { useUserDrillReports } from '../../../../../shared-state/Crew/useUserDrillReports';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import './UserDrillsTab.css';

interface UserDrillsTabProps {
    showModal?: boolean,
    selectedUser?: UserType;
}

const UserDrillsTab: React.FC<UserDrillsTabProps> = ({showModal, selectedUser}) => {
    const userDrillReports = useUserDrillReports(showModal ? selectedUser : undefined);
   
    return (<>
        <SeaNoData
            dataName="drills"
            isLoading={!userDrillReports}
            hasNoData={userDrillReports?.vessels && userDrillReports.vessels.length === 0}
            isUsingFilter={false}
        />
        <div className={`user-drills ${userDrillReports?.vessels && userDrillReports.vessels.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div>Drill</div>
                <div>Interval</div>
                <div>Last Done</div>
                <div>Next Due</div>
                <div>History</div>
            </div>
            {userDrillReports?.vessels && userDrillReports.vessels.map((_vessel) => {
                return (
                    <React.Fragment key={_vessel.id}>
                        <div className="category-heading">
                            {_vessel.name}
                        </div>
                        {userDrillReports.byVesselId[_vessel.id].map((drillInfo) => {
                            return (
                                <div
                                    key={drillInfo.drill.id}
                                    className="sea-card sea-row-history"
                                >
                                    <div
                                        className="truncate"
                                        onClick={(e) => openContextualHelp(e, {text: formatValue(drillInfo.drill.name)})}
                                    >
                                        {drillInfo.drill.name}
                                    </div>
                                    <div className="truncate">
                                        {drillInfo.drill.interval && formatInterval(drillInfo.drill.interval)}
                                    </div>
                                    <div className="truncate">
                                        {(drillInfo.daysOverdue && drillInfo.daysOverdue > 0) ? (
                                            <span style={{ color: 'var(--ion-color-danger)' }}>
                                                {formatDate(drillInfo.lastDone)}
                                            </span>
                                        ) : (
                                            formatDate(drillInfo.lastDone)
                                        )}
                                    </div>
                                    <div className="truncate">
                                        {
                                            (drillInfo.daysOverdue && drillInfo.daysOverdue > 0) ? (
                                                <span style={{ color: 'var(--ion-color-danger)' }}>
                                                    {drillInfo.daysOverdue} day{drillInfo.daysOverdue !== 1 && 's'} OD
                                                </span>
                                            ) : (
                                                drillInfo.daysOverdue !== undefined &&
                                                (
                                                    <span>
                                                        {-drillInfo.daysOverdue} day{drillInfo.daysOverdue !== -1 && 's'}
                                                    </span>
                                                )
                                            )
                                        }
                                    </div>
                                    <div
                                        className="truncate"
                                        onClick={(e) => openContextualHelp(e, {text: formatValue(drillInfo.history)})}
                                    >
                                        {drillInfo.history}
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </div>
    </>);
};

export default UserDrillsTab;
