import React, { useCallback, useEffect, useState } from 'react';
import { isPlatform, IonHeader } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { renderFullName } from '../../shared-state/Core/users';
import { UserType } from '../../shared-state/Core/user';
import { Timestamp } from 'firebase/firestore';
import { formatShortTimeDuration, formatTime } from '../../lib/datesAndTime';
import { isDesktopPretendingToBeHybrid } from '../../App';
import { alertMessage } from '../../managers/AlertManager/AlertManager';
import SeaIcon from '../SeaIcon/SeaIcon';
import SyncFilesAndData from '../SyncFilesAndData/SyncFilesAndData';
import PerformanceStatsModal from '../../modals/Utilities/PerformanceStatsModal/PerformanceStatsModal';
import SeaWindyMapFullscreen from '../SeaWindyMapFullscreen/SeaWindyMapFullscreen';
import WindIcon from '../../assets/svg/side-bar/Wind';
import './SeaHeaderBar.css';

const renderMaintenanceTimeLeft = (whenMaintenance: Timestamp | undefined) => {
    if (!whenMaintenance) return '';
    const when = whenMaintenance.toMillis();
    const now = Date.now();
    if (now > when) {
        return 'Imminent'
    }
    return formatShortTimeDuration(when - now, true);
};

const renderMaintenanceMessage = (whenMaintenance: Timestamp | undefined, firstTime = false) => {
    if (!whenMaintenance) return '';
    const timeLeft = renderMaintenanceTimeLeft(whenMaintenance);
    if (timeLeft === 'Imminent') {
        return `Sea Flux is planned to go down for maintenance any moment now...`;
    }
    return `${firstTime ? 'System Alert: ' : ''}Sea Flux is planned to go down for maintenance at ${formatTime(whenMaintenance.toMillis())}. Note: You will not be able to use the app during this time. Thankyou for your patience.`;
}

interface SeaHeaderBarProps {
    showMenu: boolean,
    setShowMenu: (showMenu: boolean) => void,
    openViewUserModal: (user: UserType) => void,
    attemptLogout: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    openActionLog: () => void
}

const SeaHeaderBar: React.FC<SeaHeaderBarProps> = ({
    showMenu,
    setShowMenu,
    openViewUserModal,
    attemptLogout,
    openActionLog
}) => {
    const serverInfo = sharedState.serverInfo.use();
    const user = sharedState.user.use();
    const superAdmin = sharedState.superAdmin.use();
    const licenseeSettings = sharedState.licenseeSettings.use();

    const [showPerformanceStats, setShowPerformanceStats] = useState(false);
    const [showWindyMap, setShowWindyMap] = useState(false);
    const [timeLeft, setTimeLeft] = useState('');

    const isHybrid = isPlatform('hybrid');

    const updateTimeLeft = useCallback(() => {
        if (sharedState.serverInfo.current?.whenMaintenance && !sharedState.serverInfo.current.underMaintenance) {
            const _timeLeft = renderMaintenanceTimeLeft(sharedState.serverInfo.current.whenMaintenance);
            setTimeLeft(_timeLeft);
            if (_timeLeft !== 'Imminent') {
                setTimeout(() => {
                    updateTimeLeft();
                }, 1000);
            }
        } else {
            setTimeLeft('');
        }
    }, []);

    useEffect(() => {
        updateTimeLeft();
        if (serverInfo?.whenMaintenance) {
            alertMessage(renderMaintenanceMessage(serverInfo.whenMaintenance, true));
        }
    }, [updateTimeLeft, serverInfo?.whenMaintenance]);

    function onActionLog(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        openActionLog();
    }
    function onWindyMap(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        setShowWindyMap(true);
    }
    function gotoSuperAdmin(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        //pageNavigator.fadeTo('/admin');
        e.preventDefault();
        sharedState.showBlankScreen.set(true);
        window.location.href = '/admin';
    }
    function onEditProfile(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        if (user) {
            openViewUserModal(user);
        }
    }
    // function onAboutOffline(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    //     e.preventDefault();
    //     alertMessage('While offline you will only be seeing local cached data.\nAny changes you make will not be saved until you next connect online.');
    // }

    return (
        <IonHeader className="sea-header">
            <div className="bar">
                <div>
                    <img src="/assets/seaflux.png" alt="Sea Flux Logo" className="logo"/>
                    <div className="hamburger pushy" onClick={(e) => setShowMenu(!showMenu)}>
                        <SeaIcon icon="menu"/>
                    </div>
                </div>
                <div>
                    <div className="items">
                        {timeLeft &&
                            <div
                                className="item name pushy"
                                style={{ color: 'var(--ion-color-danger)' }}
                                onClick={(e) => alertMessage(renderMaintenanceMessage(serverInfo!.whenMaintenance))}
                            >
                                <div style={{ paddingRight: '8px' }}><SeaIcon icon="maintenance"/></div>
                                <div style={{ fontFamily: 'monospace' }}>{timeLeft}</div>
                            </div>
                        }

                        {((isHybrid || isDesktopPretendingToBeHybrid) && licenseeSettings?.hasOffline) &&
                            <SyncFilesAndData />
                        }

                        {/* {!onlineStatus?.isOnline && <>
                            <div className="item name status pushy" onClick={(e) => onAboutOffline(e)}>
                                <div><SeaIcon icon="offline"/></div>
                                <div style={{ fontWeight: 700 }} className="not-mobile">Offline mode</div>
                            </div>
                        </>} */}

                        {superAdmin && <>
                            <div className="item pushy" onClick={(e) => { gotoSuperAdmin(e) }}>
                                <div style={{ color: 'var(--ion-color-warning)', fontWeight: 'normal' }}><b>Super Admin</b> {renderFullName(superAdmin)}</div>
                            </div>
                        </>}
                        {user &&
                            <div className="item name pushy not-mobile" onClick={(e) => onEditProfile(e)}>
                                <div><SeaIcon icon="profile"/></div>
                                <div>{renderFullName(user)}</div>
                            </div>
                        }
                        {/* {(measureQueries || measureRendering) &&
                            <div className="item pushy" onClick={(e) => setShowPerformanceStats(true)} style={{ paddingTop: '4px' }}>
                                <div><SeaIcon icon="timer"/></div>
                            </div>
                        } */}
                        <div className="item pushy" onClick={(e) => onWindyMap(e)} style={{ paddingTop: '8px' }}>
                            <div>
                                {/* <span className="windy-icon" style={{ fontSize: '28px', paddingRight: '0px' }}>|</span> */}
                                <WindIcon style={{
                                    marginTop: '-4.5px',
                                    transform: 'scale(0.85)'
                                }}/>
                            </div>
                        </div>
                        <div className="item pushy" onClick={(e) => onActionLog(e)} style={{ paddingTop: '4px' }}>
                            <div><SeaIcon icon="log"/></div>
                        </div>
                        <div className="item pushy not-mobile" onClick={(e) => attemptLogout(e)}>
                            <div><SeaIcon icon="logout"/></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {showFilesTest &&
                <FilesTest
                    showModal={showFilesTest}
                    setShowModal={setShowFilesTest}
                />
            } */}
            <PerformanceStatsModal
                showModal={showPerformanceStats}
                setShowModal={setShowPerformanceStats}
            />
            <SeaWindyMapFullscreen
                show={showWindyMap}
                setShow={setShowWindyMap}
            />
        </IonHeader>
    );
};

export default SeaHeaderBar;