import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Document, Page, Text, View, Canvas, Svg, Circle } from '@react-pdf/renderer';
import { pieColours, pdfStyles, columnifyData } from '../../../lib/pdf';
import { formatValue, haveValuesChanged, toMillis, toFloat, formatTextAreaText } from '../../../lib/util';
import { formatSeaDate, formatDate, formatDatetime, subtractInterval } from '../../../lib/datesAndTime';
import { useFormik } from 'formik';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { renderTripType, renderDayName, calculateTotalHours, calculateTotalVoyageHours } from '../voyage-util';
import { renderCrewList, renderFullNameForUserId } from '../../../shared-state/Core/users';
import { getEngineName } from '../../../shared-state/Core/vessel';
import { sharedState } from '../../../shared-state/shared-state';
import { alertMessage } from '../../../managers/AlertManager/AlertManager';
import { Voyage, VoyageDay } from '../../../shared-state/VesselLogbook/voyages';
import { SeaCrewHour } from '../../../components/SeaCrewHours/SeaCrewHours';
import { ExportType } from '../../../components/SeaExporter/SeaExporter';
import Yup, { notTooOld } from '../../../lib/yup';
import SeaModal from '../../../components/SeaModal/SeaModal';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaInput from '../../../components/SeaInput/SeaInput';
import SeaDate from '../../../components/SeaDate/SeaDate';
import SeaTextarea from '../../../components/SeaTextarea/SeaTextarea';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';


export interface CustomHours {
    id?: string,
    name?: string,
    value?: number,
    totalHours: number
}

interface CrewHours {
    id: string,
    totalHours: number
}

interface CrewById {
    [crewId: string]: CrewHours
}

interface CustomHoursById {
    [name: string]: CustomHours
}

interface EngineHourRanges {
    id: string,
    startHours: number,
    endHours: number,
    name?: string
}

interface EngineHourRangesById {
    [name: string]: EngineHourRanges
}

interface CreateLogbookReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}

export interface VoyageReport {
    dateRange?: string,
    dateStart?: string,
    dateEnd?: string,
    title: string,
    notes: string,
    engineHourRanges?: EngineHourRanges[][],
    crew?: CustomHours[][],
    customHours?: CustomHours[][],
    customHoursNames: string[],
    customHoursTotals: number[],
    totalCustomHours: number,
    voyages: Voyage[]
}


const CreateLogbookReport: React.FC<CreateLogbookReportProps> = ({showModal, setShowModal}) => {
    const todayMillis = sharedState.todayMillis.use();
    const vessel = sharedState.vessel.use();
    const voyages = sharedState.voyages.use()?.all;
    const [exportType, setExportType] = useState<ExportType>();
    const [initialValues, setInitialValues] = useState<Partial<VoyageReport>>();
    const [report, setReport] = useState<VoyageReport>();

    const defaultValues = useMemo(() => {
        return {
            dateStart: formatSeaDate(subtractInterval(todayMillis as number, '6d')),
            dateEnd: formatSeaDate(),
            title: '',
            notes: ''
        };
    }, [todayMillis]);

    const renderReportTitlePostfix = (voyage: Voyage) => {
        if (voyage) {
            return `- ${formatDate(voyage.whenDeparted)} - ${voyage.name ? voyage.name : renderCrewList(voyage.masterIds)}`;
        }
        return '';
    };

    const onOpened = () => {
        resetForm();
        setInitialValues(defaultValues);
        setValues(defaultValues);
        setReport(undefined);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm } = useFormik({
        initialValues: defaultValues,
        validationSchema: Yup.object({
            dateStart: Yup.date().required().min(...notTooOld),
            dateEnd: Yup.date().required().min(...notTooOld),
            title: Yup.string().max(500).required(),
            notes: Yup.string().max(5000)
        }), onSubmit: (data) => {
            if (!voyages) {
                alertMessage('No voyages found to report on.');
                return;
            }
            const whenFrom = toMillis(data.dateStart) as number;
            let whenTo = (toMillis(data.dateEnd) as number) + (24*60*60*1000);
            if (whenFrom >= whenTo) {
                alertMessage('Date Start must be before Date End!');
                return;
            }
            const reportVoyages: Voyage[] = [];
            for (let i = 0; i < voyages.length; i++) {
                if (voyages[i].whenDeparted < whenTo && voyages[i].state === 'completed') {
                    while (i < voyages.length && voyages[i].whenDeparted >= whenFrom) {
                        reportVoyages.push({
                            ...voyages[i]
                        });
                        i++;
                    }
                }
            }
            if (reportVoyages.length === 0) {
                alertMessage('No completed voyages were found in that date range to report on.');
                return;
            }

            setExportType('pdf');

            // Calculate aggregate values
            const crewById: CrewById = {};
            const crew: CustomHours[] = []
            const customHoursById: CustomHoursById = {};
            const customHours: CustomHours[] = [];
            const engineHourRangesById: EngineHourRangesById = {};
            const engineHourRanges: EngineHourRanges[] = [];
            let totalCustomHours = 0;
            reportVoyages.forEach((voyage) => {
                voyage?.crewHours?.forEach((item) => {
                    if (crewById[item.id] === undefined) {
                        crewById[item.id] = {
                            id: item.id,
                            totalHours: 0
                        }
                        crew.push(crewById[item.id]);
                    }
                    crewById[item.id].totalHours += item.hours;
                });
                if (voyage.tripType === 'singleDay') {
                    voyage.customHourFields?.forEach((item) => {
                        if (item.name && customHoursById[item.name] === undefined) {
                            customHoursById[item.name] = {
                                name: item.name as string,
                                totalHours: 0
                            }
                            customHours.push(customHoursById[item.name]);
                        }
                        customHoursById[item.name].totalHours += toFloat(item.value, 0);
                        totalCustomHours += toFloat(item.value, 0);
                    });
                } else if (voyage.tripType === 'multiDay') {
                    const voyageCrewById: {
                        [crewId: string]: {
                            id: string,
                            hours: number
                        }
                    } = {};
                    voyage.crewHours = []; // Aggregate crew hours over the days
                    voyage.days?.forEach((day) => {
                        day.crewHours && Object.values(day.crewHours)?.forEach((item) => {
                            if (crewById[item.id] === undefined) {
                                crewById[item.id] = {
                                    id: item.id,
                                    totalHours: 0
                                }
                                crew.push(crewById[item.id]);
                            }
                            crewById[item.id].totalHours += item.hours;
                            if (voyageCrewById[item.id] === undefined) {
                                voyageCrewById[item.id] = {
                                    id: item.id,
                                    hours: 0
                                }
                                if (!voyage.crewHours) {
                                    voyage.crewHours = [];
                                }
                                voyage.crewHours.push(voyageCrewById[item.id]);
                            }
                            voyageCrewById[item.id].hours += item.hours;
                        });
                        day.customHours?.forEach((item) => {
                            if (item.name) {
                                if (customHoursById[item.name] === undefined) {
                                    customHoursById[item.name] = {
                                        name: item.name as string,
                                        totalHours: 0
                                    }
                                    customHours.push(customHoursById[item.name]);
                                }
                                customHoursById[item.name].totalHours += toFloat(item.value, 0);
                                totalCustomHours += toFloat(item.value, 0);
                            }
                        });
                    })
                }
                voyage?.engineHours.forEach((item) => {
                    if (engineHourRangesById[item.id] === undefined) {
                        engineHourRangesById[item.id] = {
                            id: item.id,
                            endHours: item.hours,
                            startHours: item.startHours ? item.startHours : 0
                        }
                        engineHourRanges.push(engineHourRangesById[item.id]);
                    }
                    if (item.startHours !== undefined) {
                        engineHourRangesById[item.id].startHours = item.startHours;
                    } else if (item.hours !== undefined) {
                        engineHourRangesById[item.id].startHours = item.hours;
                    }
                });
            });

            // Construct report
            setReport({
                title: data.title,
                dateRange: `${formatDate(data.dateStart)} - ${formatDate(data.dateEnd)}`,
                notes: data.notes,
                engineHourRanges: columnifyData(engineHourRanges, 3),
                crew: columnifyData(crew, 3), // contains totalHours
                customHours: columnifyData(customHours, 3),
                customHoursNames: customHours.map((item) => {return item.name as string}),
                customHoursTotals: customHours.map((item) => {return item.totalHours}),
                totalCustomHours: totalCustomHours,
                voyages: reportVoyages
            });

        }
    });

    useEffect(() => {
        if (!exportType) {
            // baseLine initialValues
            setInitialValues(values);
        }
    }, [exportType]); // eslint-disable-line react-hooks/exhaustive-deps

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues ? initialValues : {});
    }, [values, initialValues]);

    // Report PDF node structure based on report
    const MakeReportPDF = useCallback(() => {
        if (report === undefined) {
            return null; // requires a report to construct
        }
        return (
            <Document
                title="Sea Flux Logbook Report"
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <View style={pdfStyles.header}>
                        <Text style={pdfStyles.heading}>Logbook Report</Text>
                        <Text style={pdfStyles.subHeading}>{report.title}</Text>
                        <Text style={pdfStyles.subHeading}>{report.dateRange}</Text>
                        <Text style={pdfStyles.subHeading}>Vessel: {vessel?.name}</Text>
                    </View>
                    
                    {report.notes ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Notes</Text>
                                <Text>{formatValue(report.notes)}</Text>
                            </View>
                        </View>
                    ) : undefined}
                    <View wrap={false}>
                        {report.engineHourRanges ?
                            <View style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Total Engine Hours at end of last voyage</Text>
                                </View>
                            </View>
                        : undefined }
                        {report.engineHourRanges ? ((report.engineHourRanges as EngineHourRanges[][]).map((columns: EngineHourRanges[], index: number) => {
                            return (
                                <View key={columns[0].id} style={report.engineHourRanges && index < report.engineHourRanges.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                    {columns.map((item) => {
                                        return (
                                            <View key={item.id} style={pdfStyles.column3}>
                                                <Text>
                                                    <Text style={pdfStyles.field}>{getEngineName(item.id)}:</Text> {item.endHours}
                                                </Text>
                                            </View>
                                        );
                                    })}
                                </View>
                            );
                        })) : undefined}
                    </View>
                    <View wrap={false}>
                        {report.engineHourRanges ?
                            <View style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Engine Hours Over Range</Text>
                                </View>
                            </View>
                        : undefined }
                        {report.engineHourRanges ? (report.engineHourRanges.map((columns: EngineHourRanges[], index: number) => {
                            return (
                                <View key={columns[0].id} style={report.engineHourRanges && index < report.engineHourRanges.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                    {columns.map((item) => {
                                        return (
                                            <View key={item.id} style={pdfStyles.column3}>
                                                <Text>
                                                    <Text style={pdfStyles.field}>{getEngineName(item.id)}:</Text> {(item.endHours - item.startHours)}
                                                </Text>
                                            </View>
                                        );
                                    })}
                                </View>
                            );
                        })) : undefined}
                    </View>
                    {!(vessel?.logbookSettings?.crewHours === 'hidden') &&
                        <View wrap={false}>
                            {report.crew ?
                                <View style={pdfStyles.rowNoSpace}>
                                    <View style={pdfStyles.column1}>
                                        <Text style={pdfStyles.label}>Total Crew Hours</Text>
                                    </View>
                                </View>
                            : undefined }
                            {report.crew ? (report.crew.map((columns: CustomHours[], index: number) => {
                                return (
                                    <View key={columns[0].id} style={report.crew && index < report.crew.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                        {columns.map((item) => {
                                            return (
                                                <View key={item.id} style={pdfStyles.column3}>
                                                    <Text>
                                                        <Text style={pdfStyles.field}>{renderFullNameForUserId(item.id)}:</Text> {item.totalHours}
                                                    </Text>
                                                </View>
                                            );
                                        })}
                                    </View>
                                );
                            })) : undefined}
                        </View>
                    }
                    <View wrap={false}>
                        {report.customHours ?
                            <View style={pdfStyles.rowNoSpace}>
                                <View style={pdfStyles.column1}>
                                    <Text style={pdfStyles.label}>Task Hours Breakdown</Text>
                                </View>
                            </View>
                        : undefined }
                        {report.customHours ? (report.customHours.map((columns: CustomHours[], index: number) => {
                            return (
                                <View key={columns[0].name} style={report.customHours && index < report.customHours.length - 1 ? pdfStyles.rowInBetween : pdfStyles.row}>
                                    {columns.map((item) => {
                                        return (
                                            <View key={item.name} style={pdfStyles.column3}>
                                                <Text>
                                                    <Text style={pdfStyles.field}>{item.name}:</Text> {item.totalHours}
                                                </Text>
                                            </View>
                                        );
                                    })}
                                </View>
                            );
                        })) : undefined}
                    </View>
                    {report.customHours ?
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Total Hours</Text>
                                <Text>{report.totalCustomHours ? report.totalCustomHours : 0}</Text>
                            </View>
                        </View>
                    : undefined}
                    <View wrap={false}>
                        {report.customHours && report.totalCustomHours > 0 ? 
                            <View>
                                <Canvas style={pdfStyles.pie} paint={(painter, w, h) => {
                                    const drawWedge = (
                                        cx: number,
                                        cy: number,
                                        r: number,
                                        startP: number, // 0-1
                                        endP: number,   // 0-1
                                        colour: string
                                    ) => {
                                        const points = [];
                                        points.push([cx, cy]);

                                        let angle = (startP * Math.PI * 2) - (Math.PI / 2);
                                        const endAngle = (endP * Math.PI * 2) - (Math.PI / 2);
                                        while (angle < endAngle) {
                                            points.push([
                                                cx + Math.cos(angle) * r,
                                                cy + Math.sin(angle) * r
                                            ]);
                                            angle += 0.05;
                                        }
                                        points.push([
                                            cx + Math.cos(endAngle) * r,
                                            cy + Math.sin(endAngle) * r
                                        ]);

                                        painter.polygon(...points)
                                        .fill(colour);
                                    };
                                    const drawPie = (
                                        cx: number,
                                        cy: number,
                                        r: number,
                                        values: number[]
                                    ) => {
                                        let total = 0;
                                        let running = 0;
                                        values.forEach((value) => {
                                            total += value;
                                        });
                                        for (let i = 0; i < values.length; i++) {
                                            drawWedge(cx, cy, r, running / total, (running + values[i]) / total, pieColours[i % pieColours.length])
                                            running += values[i];
                                        }
                                    };
                                    
                                    //drawWedge(w/2, h/2, h/2, 0, 0.25, '#009');
                                    drawPie(w/2, h/2, h/2, report.customHoursTotals);

                                    return null;
                                }} />
                            </View>
                        : undefined}
                        {report.customHours && report.totalCustomHours > 0 ?
                            <View style={pdfStyles.pieKeys}>
                                {report.customHoursNames.map((name: string, index: number) => {
                                    return (
                                        <View key={name} style={pdfStyles.key}>
                                            <Svg style={pdfStyles.keyIcon} viewBox="0 0 10 10"><Circle cx="5" cy="5" r="5" fill={pieColours[index % pieColours.length]}/></Svg>
                                            <Text style={pdfStyles.keyText}>{name}</Text>
                                        </View>
                                    );
                                })}
                            </View>
                        : undefined}
                    </View>
                </Page>
                {report.voyages.map((voyage) => {
                    return makeVoyagePage(voyage);
                })}
            </Document>
        );
    }, [report]);

    const makeVoyagePage = useCallback((voyage: Voyage) => {
        return (
            <Page
                key={voyage.id}
                style={pdfStyles.page}
                orientation="portrait"
                size="A4" // 21cm x 29.7cm
                wrap={true}
            >
                <View wrap={false} style={pdfStyles.header}>
                    <Text style={pdfStyles.heading}>Voyage Report {renderReportTitlePostfix(voyage)}</Text>
                </View>
                <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>Trip Type</Text>
                        <Text>{renderTripType(voyage.tripType)}</Text>
                    </View>
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>{voyage.skipper ? 'Skipper' : `Master${ voyage.masterIds.length === 1 ? '' : 's' }`}</Text>
                        <Text>{voyage.skipper ? formatValue(voyage.skipper) : renderCrewList(voyage.masterIds, '-')}</Text>
                    </View>
                </View>
                {voyage.tripType !== 'multiTrip' &&
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Crew Onboard</Text>
                            <Text>{formatValue(voyage.personnelInvolved?.map((userId) => {
                                return renderFullNameForUserId(userId);
                            }).join(', '))}</Text>
                        </View>
                        <View style={pdfStyles.column2}>
                            <Text style={pdfStyles.label}>Departure</Text>
                            <Text>{voyage.departureFrom ? `${voyage.departureFrom} - ` : ''} {formatDatetime(voyage.whenDeparted, ', ')}</Text>
                        </View>
                    </View>
                }
<               View wrap={false} style={pdfStyles.row}>
                    {(voyage.tripType !== 'multiTrip') && (
                        voyage.stopsOrRouteDetails ? (
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Stops or route details</Text>
                                <Text>{formatValue(voyage.stopsOrRouteDetails)}</Text>
                            </View>
                        ) : (
                            <View style={pdfStyles.column2}>
                                <Text style={pdfStyles.label}>Stops / Anchorages</Text>
                                <Text>{formatValue(formatTextAreaText(voyage.stops ? voyage.stops.join(', ') : '-'))}</Text>
                            </View>
                        )
                    )}
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>{voyage.tripType === 'multiTrip' ? 'Total ' : ''} PAX Carried</Text>
                        <Text>{voyage.pax !== undefined ? formatValue(''+voyage.pax) : '-'}</Text>
                    </View>
                </View>
                {voyage.additionalPassengersOrDetails &&
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Additional Passengers &amp; Details</Text>
                            <Text>{formatValue(voyage.additionalPassengersOrDetails)}</Text>
                        </View>
                    </View>
                }
                {voyage.tripType !== 'multiTrip' &&
                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <Text style={pdfStyles.label}>Arrival</Text>
                            <Text>{voyage.destinationTo ? `${voyage.destinationTo} - ` : ''} {formatDatetime(voyage.whenArrived, ', ')}</Text>
                        </View>
                    </View>
                }

                <View style={pdfStyles.greyLine}></View>

                <View wrap={false} style={pdfStyles.row}>
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>Startup Notes</Text>
                        <Text>{formatValue(voyage.startupNotes)}</Text>
                    </View>
                    <View style={pdfStyles.column2}>
                        <Text style={pdfStyles.label}>Shutdown Notes</Text>
                        <Text>{formatValue(voyage.shutdownNotes)}</Text>
                    </View>
                </View>

                {voyage.tripType !== 'multiDay' &&
                    <>
                        <View style={pdfStyles.greyLine}></View>
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Voyage Notes</Text>
                                <Text>{formatValue(voyage.voyageNotes)}</Text>
                            </View>
                        </View>
                    </>
                }
                {voyage.tripType === 'singleDay' && (
                    (voyage.customTextFields && voyage.customTextFields?.length > 0) ||
                    (voyage.customHourFields && voyage.customHourFields?.length > 0)
                ) && (
                    <>
                        <View wrap={false} style={pdfStyles.rowNoSpace}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Voyage Data</Text>
                            </View>
                        </View>







                        {(voyage.customTextFields && voyage.customTextFields.length > 0) &&
                            <View wrap={false}>
                                {(() => {
                                    const rows = columnifyData(voyage.customTextFields, 3);
                                    if (!rows) return undefined;
                                    return rows.map((columns: CustomHours[], index: number) => {
                                        return (
                                            <View key={index} style={(index < rows.length - 1) ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                {columns.map((item, index: number) => {
                                                    return (
                                                        <View key={index} style={pdfStyles.column3}>
                                                            <Text>
                                                                <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                {formatValue(item.value)}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        );
                                    });
                                })()}
                            </View>
                        }
                        {(voyage.customHourFields && voyage.customHourFields.length > 0) &&
                            <View wrap={false}>
                                {(() => {
                                    const rows = columnifyData(voyage.customHourFields, 3) as CustomHours[][];
                                    if (!rows) return undefined;
                                    return rows.map((columns, index) => {
                                        return (
                                            <View key={index} style={(index < rows.length - 1) ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                {columns.map((item, index: number) => {
                                                    return (
                                                        <View key={index} style={pdfStyles.column3}>
                                                            <Text>
                                                                <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                {formatValue(item.value)}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        );
                                    });
                                })()}
                                <View style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text style={pdfStyles.label}>Total Task Hours</Text>
                                        <Text>{calculateTotalHours(voyage.customHourFields)}</Text>
                                    </View>
                                </View>
                            </View>
                        }
                    </>
                )}
                {voyage.tripType === 'multiDay' &&
                    voyage.days?.map((day: VoyageDay, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <View style={pdfStyles.greyLine}></View>
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text style={pdfStyles.labelHeading}>{renderDayName(voyage.whenDeparted, index)}</Text>
                                    </View>
                                </View>
                                <View wrap={false} style={pdfStyles.row}>
                                    <View style={pdfStyles.column1}>
                                        <Text style={pdfStyles.label}>Notes</Text>
                                        <Text>{formatValue(day.notes)}</Text>
                                    </View>
                                </View>
                                {(day.customText && day.customText.length > 0) &&
                                    <View wrap={false}>
                                        {(() => {
                                            const rows = columnifyData(day.customText, 3);
                                            if (!rows) return undefined;
                                            return rows.map((columns: CustomHours[], index: number) => {
                                                return (
                                                    <View key={index} style={(index < rows.length - 1) ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                        {columns.map((item, index: number) => {
                                                            return (
                                                                <View key={index} style={pdfStyles.column3}>
                                                                    <Text>
                                                                        <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                        {formatValue(item.value)}
                                                                    </Text>
                                                                </View>
                                                            );
                                                        })}
                                                    </View>
                                                );
                                            });
                                        })()}
                                    </View>
                                }
                                {(day.customHours && day.customHours.length > 0) &&
                                    <View wrap={false}>
                                        {(() => {
                                            const rows = columnifyData(day.customHours, 3);
                                            if (!rows) return undefined;
                                            return rows.map((columns: CustomHours[], index: number) => {
                                                return (
                                                    <View key={index} style={(index < rows.length - 1) ? pdfStyles.rowInBetween : pdfStyles.row}>
                                                        {columns.map((item, index: number) => {
                                                            return (
                                                                <View key={index} style={pdfStyles.column3}>
                                                                    <Text>
                                                                        <Text style={pdfStyles.field}>{item.name}: </Text>
                                                                        {formatValue(item.value)}
                                                                    </Text>
                                                                </View>
                                                            );
                                                        })}
                                                    </View>
                                                );
                                            });
                                        })()}
                                        <View style={pdfStyles.row}>
                                            <View style={pdfStyles.column1}>
                                                <Text style={pdfStyles.label}>Total Task Hours</Text>
                                                <Text>{calculateTotalHours(day.customHours)}</Text>
                                            </View>
                                        </View>
                                        {(index === (voyage.days || []).length - 1) && index > 0 &&
                                            <View style={pdfStyles.row}>
                                                <View style={pdfStyles.column1}>
                                                    <Text style={{ ...pdfStyles.label, ...pdfStyles.bold }}>Total Voyage Task Hours</Text>
                                                    <Text>{calculateTotalVoyageHours(voyage.days || [])}</Text>
                                                </View>
                                            </View>
                                        }
                                    </View>
                                }

                            </React.Fragment>
                        );
                    })
                }

                {!(vessel?.logbookSettings?.crewHours === 'hidden') &&
                    <>
                        <View wrap={false} style={pdfStyles.rowNoSpace}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Crew Hours</Text>
                            </View>
                        </View>
                        {(voyage.crewHours && voyage.crewHours.length > 0) ? (
                            (() => {
                                const rows = columnifyData(voyage.crewHours, 3);
                                if (!rows) return undefined;
                                return rows.map((columns: SeaCrewHour[], index: number) => {
                                    return (
                                        <View wrap={false} key={`${voyage.id}${columns[0].id}`} style={(index < rows.length - 1) ? pdfStyles.rowInBetween : pdfStyles.row}>
                                            {columns.map((item) => {
                                                return (
                                                    <View key={`${voyage.id}${item.id}`} style={pdfStyles.column3}>
                                                        <Text>
                                                            <Text style={pdfStyles.field}>{renderFullNameForUserId(item.id)}: </Text> {item.hours}
                                                        </Text>
                                                    </View>
                                                );
                                            })}
                                        </View>
                                    );
                                });
                            })()
                        ) : (
                            <View wrap={false} style={pdfStyles.row}>
                                <View style={pdfStyles.column1}>
                                    <Text>-</Text>
                                </View>
                            </View>
                        )}
                    </>
                }
            </Page>
        );
    }, [report]);

    return (
        <SeaModal
            title="Create Report"
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size='semi-thin'
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                          	<SeaDate
                                label="Date Start"
                                name="dateStart"
                                value={values.dateStart}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateStart ? errors.dateStart : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                          	<SeaDate
                                label="Date End"
                                name="dateEnd"
                                value={values.dateEnd}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.dateEnd ? errors.dateEnd : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                          	<SeaInput
								label="Report Title"
								name="title"
								value={values.title}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.title ? errors.title : ''}
                          	/>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Notes"
                                name="notes"
                                value={values.notes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                height={200}
                                inputmode="text"
                                error={touched.notes ? errors.notes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <div>
                                <SeaButton zone="white" type="submit" size='wide'>Create Report</SeaButton>
                            </div>
                            <p className="sea-label note" style={{marginTop: '20px'}}>
                                Please Note: Reports are not stored in the Sea Flux app. They are exported as a PDF for you to save/print on your device.
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </form>
            {showModal && exportType === 'pdf' &&
                <SeaPdfGenerator
                    onCompleted={() => setExportType(undefined)}
                    pdfTitle={report?.title}
                    MakePdf={MakeReportPDF}
                    requestedFrom="CreateLogbook"
                />
            }
        </SeaModal>
    );
};

export default CreateLogbookReport;
