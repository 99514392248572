import React, { useCallback } from 'react';
import { Document, View, Page,  Text, Image } from '@react-pdf/renderer';
import { PdfPageLimiter, UnloadedPdfRows, pdfStyles } from '../../../lib/pdf';
import { formatValue } from '../../../lib/util';
import { useReportSettings } from '../../../pages/Reporting/Reporting';
import { renderFullName } from '../../../shared-state/Core/users';
import { sharedState } from '../../../shared-state/shared-state';
import { getImgSrcFromString } from '../../../lib/files';
import { colours } from '../../../lib/reporting';
import { formatInterval } from '../../../lib/datesAndTime';
import { SafetyCheckCompleted } from '../../../shared-state/VesselSafety/useCompletedSafetyCheckItems';
import SeaHorizontalBarGraph, { GraphData } from '../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import SeaNoDataPDF from '../../../components/SeaNoDataPDF';
import SeaPdfGenerator from '../../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../../components/PdfItemsHeader/PdfItemsHeader';
import CriticalFlagPDF from '../../../assets/svg/CriticalFlagPDF';

interface CompletedSafetyCheckReportPDFProps {
    onCompleted: () => void,
    title: string,
    subTitle?: string,
    graphData?: GraphData[],
    filteredItems?: SafetyCheckCompleted[],
}

const CompletedSafetyCheckReportPDF: React.FC<CompletedSafetyCheckReportPDFProps> = ({
    onCompleted,
    title,
    subTitle,
    graphData,
    filteredItems,
}) => {
    const vessels = sharedState.vessels.use();
    const { selectedVesselId, selectedVesselIds } = useReportSettings();
    const counterRef = React.useRef(0);
    const {  mapPdfArrayWithLimit } = PdfPageLimiter(counterRef);

    const MakePDF = useCallback(() => {
        counterRef.current = 0;
        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={{
                        ...pdfStyles.page
                    }}
                    orientation="portrait"
                    size="A4"
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subTitle}
                        userFullName={renderFullName()}
                    />
                    {vessels && selectedVesselIds?.length > 1 ? (
                        <View wrap={false} style={pdfStyles.row}>
                            <View style={pdfStyles.column1}>
                                <Text style={pdfStyles.label}>Vessels</Text>
                                <Text>{selectedVesselIds.map((id: string) => vessels.byId[id].name).join(', ')}</Text>
                            </View>
                        </View>
                    ) : null}

                    <View wrap={false} style={pdfStyles.row}>
                        <View style={pdfStyles.column1}>
                            <SeaHorizontalBarGraph
                                n={0}
                                mode="pdf"
                                visible={true}
                                data={graphData}
                                sortData={true}
                                units="Tasks Completed"
                                colourPalette={[colours.mint]}
                                hashNamesForColours={false}
                            />
                        </View>
                    </View>

                    <SeaNoDataPDF 
                        dataName="safety check tasks"
                        hasNoData={!filteredItems?.length}
                        isUsingFilter={true}
                    />
                    {filteredItems?.length ? (
                        <View style={pdfStyles.dataContainer}>
                                <View style={pdfStyles.dataHeaderRow}>
                                    <View style={pdfStyles.thumbColumn}></View>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.column2x3]}>Safety Item</Text>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.timesCompletedColumn]}>Times{'\n'}Completed</Text>
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.intervalColumn]}>Interval</Text>
                                    {!selectedVesselId ? <Text style={[pdfStyles.dataHeaderText, pdfStyles.column1]}>Vessel</Text> : null}
                                    <Text style={[pdfStyles.dataHeaderText, pdfStyles.criticalColumn]}>Critical</Text>
                                </View>
                                {mapPdfArrayWithLimit(filteredItems, (item) => {
                                    const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                    const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                    return (
                                    <View wrap={false} key={item.id} style={pdfStyles.greyDataRow}>
                                        <View style={pdfStyles.thumbColumn}>
                                            {imgSrc && <Image src={imgSrc} style={pdfStyles.thumbImgRound} />}
                                        </View>
                                        <Text style={[pdfStyles.truncate, pdfStyles.column2x3, pdfStyles.bold]}>{formatValue(item.name)}</Text>
                                        <Text style={pdfStyles.timesCompletedColumn}>{formatValue(item.timesCompleted)}</Text>
                                        <Text style={pdfStyles.intervalColumn}>{formatInterval(item.interval)}</Text>
                                        {!selectedVesselId ? <Text style={[pdfStyles.truncate, pdfStyles.column1]}>{vessels?.byId[item.vesselId]?.name}</Text> : null}
                                        <View style={pdfStyles.criticalColumn}>{item.isCritical && <CriticalFlagPDF />}</View>
                                    </View>
                                )})}
                                <UnloadedPdfRows length={filteredItems?.length} />
                        </View>
                    ) : null}
                </Page>
            </Document>
        )
    }, [title, subTitle, vessels, selectedVesselIds, graphData, filteredItems, selectedVesselId, mapPdfArrayWithLimit]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="completedSafetyCheckReport"
        />
    );

};

export default CompletedSafetyCheckReportPDF;
