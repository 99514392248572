import React, { useCallback } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { pdfStyles, styleItemColumn } from '../../lib/pdf';
import { formatValue } from '../../lib/util';
import { formatDate, warnDays } from '../../lib/datesAndTime';
import { renderFullName } from '../../shared-state/Core/users';
import { sharedState } from '../../shared-state/shared-state';
import { VesselCertificate, VesselCertificatesData } from '../../shared-state/VesselDocuments/vesselCertificates';
import { CategoriesData, renderCategoryName } from '../../lib/categories';
import { getImgSrcFromString } from '../../lib/files';
import SeaPdfGenerator from '../../components/SeaPdfGenerator/SeaPdfGenerator';
import PdfItemsHeader from '../../components/PdfItemsHeader/PdfItemsHeader';
import SeaStatusDueDate from '../../components/SeaStatusDueDate/SeaStatusDueDate';

interface VesselCertificatesPdfProps {
    onCompleted: () => void,
    prioritisedVesselCertificates: VesselCertificate[] | undefined,
    vesselCertificateCategories: CategoriesData | undefined,
    vesselCertificates: VesselCertificatesData | undefined,
    listType: string,
}

const VesselCertificatesPdf: React.FC<VesselCertificatesPdfProps> = ({
    onCompleted,
    prioritisedVesselCertificates,
    vesselCertificateCategories,
    vesselCertificates,
    listType
}) => {
    const vessel = sharedState.vessel.use();
    const title = 'Vessel Certificates' + (vessel !== undefined ? ' - ' + vessel.name : '');
    const subHeading = (listType === 'all') ? 'All' : `Overdue & Due Within ${listType} Days`;

    const MakePDF = useCallback(() => {
        if (!vesselCertificates) return (<></>);

        return (
            <Document
                title={title}
                author="Sea Flux"
                creator="Sea Flux"
                producer="Sea Flux"
            >
                <Page
                    style={pdfStyles.page}
                    orientation="portrait"
                    size="A4" // 21cm x 29.7cm
                    wrap={true}
                >
                    <PdfItemsHeader
                        heading={title}
                        subHeading={subHeading}
                        userFullName={renderFullName()}
                    />
                    <View wrap={false} style={pdfStyles.itemHeadingsRow}>
                        <View style={pdfStyles.thumbColumn}></View>
                        <View style={styleItemColumn(2)}>
                            <Text>Name</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Certificate #</Text>
                        </View>
                        <View style={styleItemColumn(1)}>
                            <Text>Issued By</Text>
                        </View>
                        <View style={pdfStyles.dateColumn}>
                            <Text>Expiry</Text>
                        </View>
                        <View style={pdfStyles.statusColumn}>
                            <Text>Status</Text>
                        </View>
                    </View>
                    <View wrap={false} style={pdfStyles.itemHeadingsLine}></View>
                    {
                        listType !== 'all' && prioritisedVesselCertificates && prioritisedVesselCertificates.map((item: VesselCertificate, index) => {
                            const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                            const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                            return (
                                <React.Fragment key={'prior-' + index}>
                                    {index > 0 &&
                                        <View style={pdfStyles.itemsRowSpacer}></View>
                                    }
                                    <View wrap={false} style={pdfStyles.itemsRow}>
                                        <View style={pdfStyles.thumbColumn}>
                                            {imgSrc &&
                                                <Image
                                                    src={imgSrc}
                                                    style={pdfStyles.thumbImg}
                                                />
                                            }
                                        </View>
                                        <View style={styleItemColumn(2)}>
                                            <Text wrap={true}>
                                                {item.title}
                                            </Text>
                                        </View>
                                        <View style={styleItemColumn(1)}>
                                            <Text>
                                                {formatValue(item.certNum)}
                                            </Text>
                                        </View>
                                        <View style={styleItemColumn(1)}>
                                            <Text>
                                                {formatValue(item.issuedBy)}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.dateColumn}>
                                            <Text>
                                                {item.whenExpires ? formatDate(item.whenExpires) : null}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.statusColumn}>
                                            {item.state === 'missing' ? (
                                                <Text></Text>
                                            ) : item.whenExpires ? (
                                                <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.vesselCertificates[0]} />
                                            ) : <Text></Text>}
                                        </View>
                                    </View>
                                </React.Fragment>
                            );


                        })
                    }

                    {listType === 'all' && vesselCertificateCategories?.ids?.map((categoryId, index) => {
                        const items = vesselCertificates?.byCategoryId[categoryId];
                        return (
                            <React.Fragment key={'categoryId-' + categoryId}>
                                <View wrap={false} style={pdfStyles.itemsCategory}>
                                    <Text>{renderCategoryName(categoryId, vesselCertificateCategories)}</Text>
                                </View>
                                {
                                    items.map((item: VesselCertificate, index1) => {
                                        const file = (item.files && item.files.length > 0) ? item.files[0] : undefined;
                                        const imgSrc = file ? getImgSrcFromString(file, 'tiny') : undefined;
                                        const isLast = index === items.length - 1;
                                        return (
                                            <React.Fragment key={'vessel-cert-' + index1}>
                                                {index1 > 0 &&
                                                    <View style={pdfStyles.itemsRowSpacer}></View>
                                                }
                                                <View wrap={false} style={pdfStyles.itemsRow}>
                                                    <View style={pdfStyles.thumbColumn}>
                                                        {imgSrc &&
                                                            <Image
                                                                src={imgSrc}
                                                                style={pdfStyles.thumbImg}
                                                            />
                                                        }
                                                    </View>
                                                    <View style={styleItemColumn(2)}>
                                                        <Text wrap={true}>
                                                            {item.title}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(2)}>
                                                        <Text>
                                                            {formatValue(item.certNum)}
                                                        </Text>
                                                    </View>
                                                    <View style={styleItemColumn(1)}>
                                                        <Text>
                                                            {formatValue(item.issuedBy)}
                                                        </Text>
                                                    </View>
                                                    <View style={pdfStyles.dateColumn}>
                                                        <Text>
                                                            {item.whenExpires ? formatDate(item.whenExpires) : null}
                                                        </Text>
                                                    </View>
                                                    <View style={pdfStyles.statusColumn}>
                                                        {item.state === 'missing' ? (
                                                            <Text></Text>
                                                        ) : item.whenExpires ? (
                                                            <SeaStatusDueDate forPdf={true} whenDue={item.whenExpires} warnDays={warnDays.vesselCertificates[0]} />
                                                        ) : <Text></Text>}
                                                    </View>
                                                </View>
                                                {isLast && (
                                                    <View style={pdfStyles.itemsCategorySpacer} />
                                                )}
                                            </React.Fragment>);
                                    })
                                }
                                <View wrap={false} style={pdfStyles.itemsCategorySpacer}></View>
                            </React.Fragment>

                        );



                    })}

                </Page>
            </Document>
        );
    }, [prioritisedVesselCertificates, vesselCertificateCategories, vesselCertificates, listType, subHeading, title]);

    return (
        <SeaPdfGenerator
            onCompleted={onCompleted}
            pdfTitle={title}
            MakePdf={MakePDF}
            requestedFrom="VesselCertificates"
        />
    );
};

export default VesselCertificatesPdf;
