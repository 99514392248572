import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { arrayUnion, collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis, toInt, preventMultiTap, cleanupStringArray, hasArrayChanged } from '../../../../lib/util';
import { addInterval, formatSeaDatetime, formatSeaDate } from '../../../../lib/datesAndTime';
import { makeCategoryId } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { ScheduledMaintenanceTask } from '../../../../shared-state/VesselMaintenance/maintenanceSchedule';
import { LinkType } from '../../../../shared-state/Core/links';
import { useLinks } from '../../../../shared-state/Core/itemLinks';
import { addMissingLinks, handleLinkUpdates, renderLinkName } from '../../../../lib/links';
import { useLicenseeVesselDocuments } from '../../../../shared-state/VesselDocuments/useLicenseeVesselDocuments';
import SeaLinkMultiList, { LinkOptionsType } from '../../../../components/SeaLinkMultiList/SeaLinkMultiList';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaSelectInterval from '../../../../components/SeaSelectInterval/SeaSelectInterval';
import SeaEquipment from '../../../../components/SeaEquipment/SeaEquipment';
import SeaDatetime from '../../../../components/SeaDatetime/SeaDatetime';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import SeaTagsInput from '../../../../components/SeaTagsInput/SeaTagsInput';

interface EditMaintenanceScheduleProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    itemToUpdate?: ScheduledMaintenanceTask,
    fromEquipmentId?: string;
    fromLocationId?: string;
    level?: number,
}

const EditMaintenanceSchedule: React.FC<EditMaintenanceScheduleProps> = ({showModal, setShowModal, itemToUpdate, level = 1, fromEquipmentId, fromLocationId}) => {
    const userId = sharedState.userId.use(showModal);
    const vessel = sharedState.vessel.use(showModal);
    const vesselId = sharedState.vesselId.use(showModal);
    const equipment = sharedState.equipment.use(showModal);
    const vesselLocations = sharedState.vesselLocations.use(showModal);
    const engines = sharedState.engines.use(showModal);
    const [equipmentId, setEquipmentId] = useState<string>();
    const [locationId, setLocationId] = useState<string>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [maintenanceTags, setMaintenanceTags] = useState<string[]>([]);
    // TODO: Add links
    // const existingLinks = useLinks(itemToUpdate?.id);
    // const vesselSOPs = sharedState.vesselSOPs.use(showModal);
    // const companyDocuments = sharedState.companyDocuments.use(showModal);
    // const risks = sharedState.risks.use(showModal);
    // const vesselDocuments = useLicenseeVesselDocuments(showModal);
    // const [links, setLinks] = useState<LinkType[]>([]);
    // const [linkOptions, setLinkOptions] = useState<LinkOptionsType[] | undefined>(undefined);

    // handles generating link options and adding links that link to items not in sharedState
    // useEffect(() => {
    //     const fetchLinkOptions = () => {
    //         if (!vesselSOPs || !companyDocuments || !vesselDocuments || !risks) {
    //             setLinkOptions(undefined);
    //             return Promise.resolve();
    //         }
    
    //         const options: LinkOptionsType[] = [
    //             {
    //                 collection: 'vesselDocuments',
    //                 label: 'Vessel Documents',
    //                 items: Object.entries(vesselDocuments.byId).map(([id, item]) => ({
    //                     id: id,
    //                     name: renderLinkName(item.title, item.state)
    //                 }))
    //             },
    //             {
    //                 collection: 'SOPs',
    //                 label: 'SOPs',
    //                 items: Object.entries(vesselSOPs.byId).map(([id, item]) => ({
    //                     id: id,
    //                     name: renderLinkName(item.title, item.state)
    //                 }))
    //             },
    //             {
    //                 collection: 'risks',
    //                 label: 'Risk Assessments',
    //                 items: Object.entries(risks.byId).map(([id, item]) => ({
    //                     id: id,
    //                     name: renderLinkName(item.name, item.state)
    //                 }))
    //             },
    //             {
    //                 collection: 'companyDocuments',
    //                 label: 'Company Documents',
    //                 items: Object.entries(companyDocuments.byId).map(([id, item]) => ({
    //                     id: id,
    //                     name: renderLinkName(item.title, item.state)
    //                 }))
    //             },
    //             {
    //                 collection: 'external',
    //                 label: 'External Links',
    //                 items: []
    //             }
    //         ];
    
    //         if (existingLinks) {
    //             return addMissingLinks(existingLinks, options, 'scheduledMaintenanceTasks')
    //                 .then(() => {
    //                     setLinkOptions(options);
    //                 });
    //         } else {
    //             setLinkOptions(options);
    //             return Promise.resolve();
    //         }
    //     };
    
    //     fetchLinkOptions()
    //         .catch((error) => {
    //             console.error('Error fetching link options:', error);
    //         });
    // }, [companyDocuments, existingLinks, risks, vesselDocuments, vesselSOPs]);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                task: itemToUpdate.task ? ''+itemToUpdate.task : '',
                description: itemToUpdate.description ? ''+itemToUpdate.description : '',
                intervalType: itemToUpdate.intervalType ? ''+itemToUpdate.intervalType : '',
                intervalWeekMonth: itemToUpdate.intervalWeekMonth ? ''+itemToUpdate.intervalWeekMonth : '',
                whenLastService: itemToUpdate.whenLastService ? formatSeaDatetime(itemToUpdate.whenLastService) : '',
                intervalEngineHours: itemToUpdate.intervalEngineHours ? ''+itemToUpdate.intervalEngineHours : '',
                engineHoursLastService: (itemToUpdate?.engineHoursLastService !== undefined && itemToUpdate.engineHoursLastService as unknown !== '') ? ''+itemToUpdate.engineHoursLastService : '',
                engineId: itemToUpdate.engineId ? ''+itemToUpdate.engineId : '',
            };
        } else {
            return {
                task: '',
                description: '',
                intervalType: 'weekMonth',
                intervalWeekMonth: '',
                whenLastService: formatSeaDatetime(),
                intervalEngineHours: '',
                engineHoursLastService: '',
                engineId: '',
            };
        }
    }, [itemToUpdate]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setHasSubmitted(false);
        // TODO: Add links
        // setLinks(existingLinks ?? []);
        setValues(initialValues);
        setMaintenanceTags((itemToUpdate?.maintenanceTags) ? itemToUpdate.maintenanceTags : []);
        setEquipmentId(itemToUpdate?.equipmentId ?? fromEquipmentId ?? '');
        setLocationId(itemToUpdate?.locationId ?? fromLocationId ?? '');
        setTimeout(() => {
            // Prevent equipment.locationId overriding itemToUpdate.locationId, unless fromLocationId has been supplied
            setLocationId(itemToUpdate?.locationId ?? fromLocationId ?? '');
        }, 100);
    }

    const onClosed = () => {
        setEquipmentId('');
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            task: Yup.string().max(5000),
            description: Yup.string().max(5000),
            intervalType: Yup.string().max(500).required(),
            intervalWeekMonth: Yup.string().max(500).when('intervalType', {
                is: (value: 'weekMonth' | 'weekMonthAndHours') => (value === 'weekMonth' || value === 'weekMonthAndHours'),
                then: (schema) => schema.required()
            }),
            whenLastService: Yup.date().required().min(...notTooOld),
            intervalEngineHours: Yup.string().max(500).when('intervalType', {
                is: (value: 'engineHours' | 'weekMonthAndHours') => (value === 'engineHours' || value === 'weekMonthAndHours'),
                then: (schema) => schema.required()
            }),
            engineHoursLastService: Yup.number().when('intervalType', {
                is: (value: 'engineHours' | 'weekMonthAndHours') => (value === 'engineHours' || value === 'weekMonthAndHours'),
                then: (schema) => schema.min(0).required()
            }),
            engineId: Yup.string().when('intervalType', {
                is: (value: 'engineHours' | 'weekMonthAndHours') => (value === 'engineHours' || value === 'weekMonthAndHours'),
                then: (schema) => schema.max(32).required()
            }),
        }), onSubmit: (data) => {
            setHasSubmitted(true);
            // Make sure equipment has been selected
            if (preventMultiTap('maintenanceSchedule') || !equipmentId) {
                return;
            }
            if (!vesselId) {
                showToast('Vessel not found');
                return;
            }
            // Process form
            if (vessel?.isShoreFacility) {
                data.intervalType = 'weekMonth';
            }
            const useWeekMonth = (data.intervalType === 'weekMonth' || data.intervalType === 'weekMonthAndHours');
            const useEngineHours = (data.intervalType === 'engineHours' || data.intervalType === 'weekMonthAndHours');
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'scheduledMaintenanceTasks');

            let itemId: string;

            if (itemToUpdate) {
                itemId = itemToUpdate.id;
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = itemToUpdate.id;
                batch.set(
                    doc(firestore, 'scheduledMaintenanceTasks', itemToUpdate.id),
                    {
                        updatedBy: userId,
                        whenUpdated: batchTrace.whenAction,
                        equipmentId: equipmentId,
                        locationId: makeCategoryId(
                            locationId,
                            vesselLocations,
                            deleteValue,
                            batch,
                            'vesselLocations',
                            'vesselId',
                            vesselId,
                            {}
                        ),
                        task: data.task ? data.task : deleteValue,
                        description: data.description ? data.description : deleteValue,
                        maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : deleteValue,
                        intervalType: data.intervalType,
                        intervalWeekMonth: useWeekMonth ? data.intervalWeekMonth : deleteValue,
                        //whenLastService: (useWeekMonth && data.whenLastService) ? toMillis(data.whenLastService) : deleteValue,
                        whenDue: (useWeekMonth && data.whenLastService) ? addInterval(toMillis(formatSeaDate(data.whenLastService)) as number, data.intervalWeekMonth) : deleteValue,
                        intervalEngineHours: useEngineHours ? toInt(data.intervalEngineHours, deleteValue) : deleteValue,
                        //engineHoursLastService: useEngineHours ? toInt(data.engineHoursLastService, deleteValue) : deleteValue,
                        engineId: useEngineHours ? data.engineId : deleteValue,
                        engineHoursDue: useEngineHours ? (toInt(data.engineHoursLastService, 0) + toInt(data.intervalEngineHours, 0)) : deleteValue,
                        touched: serverTimestamp(),
                        // eqiupmentManualDocumentIds:
                        // contactIds:
                        // Todo: whenDue for engine hours
                    },
                    { merge: true }
                );

                logAction(
                    batch,
                    'Update',
                    'scheduledMaintenanceTasks',
                    itemToUpdate.id,
                    equipment?.byId[equipmentId]?.equipment ?? '',
                    [itemToUpdate.vesselId]
                );
            } else {
                // Add new scheduledMaintenanceTask
                // We will also want to create the first maintenanceTasksCompleted item
                const newRef = doc(collection(firestore, 'scheduledMaintenanceTasks'));
                itemId = newRef.id;
                batchTrace.exampleOperation = 'create';
                batchTrace.exampleDocId = newRef.id;
                
                const _locationId = makeCategoryId(
                    locationId,
                    vesselLocations,
                    undefined,
                    batch,
                    'vesselLocations',
                    'vesselId',
                    vesselId,
                    {}
                );

                batch.set(newRef, {
                    vesselId: vesselId,
                    addedBy: userId,
                    equipmentId: equipmentId,
                    locationId: _locationId,
                    task: data.task ? data.task : undefined,
                    description: data.description ? data.description : undefined,
                    maintenanceTags: maintenanceTags ? cleanupStringArray(maintenanceTags) : undefined,
                    intervalType: data.intervalType,
                    intervalWeekMonth: (useWeekMonth && data.intervalWeekMonth) ? data.intervalWeekMonth : undefined,
                    whenLastService: data.whenLastService ? toMillis(data.whenLastService) : undefined,
                    whenDue: (useWeekMonth && data.whenLastService) ? addInterval(data.whenLastService, data.intervalWeekMonth) : undefined,
                    intervalEngineHours: useEngineHours ? toInt(data.intervalEngineHours, undefined) : undefined,
                    engineHoursLastService: useEngineHours ? toInt(data.engineHoursLastService, undefined) : undefined,
                    engineId: (useEngineHours && data.engineId) ? data.engineId : undefined,
                    engineHoursDue: (useEngineHours && data.engineHoursLastService !== undefined && data.engineHoursLastService !== '') ? (toInt(data.engineHoursLastService, 0) + toInt(data.intervalEngineHours, 0)) : undefined,
                    whenAdded: batchTrace.whenAction,
                    state: 'active',
                    touched: serverTimestamp(),
                });

                logAction(
                    batch,
                    'Add',
                    'scheduledMaintenanceTasks',
                    newRef.id,
                    equipment?.byId[equipmentId].equipment ?? '',
                    [vesselId]
                );

                // Create first history item
                batch.set(
                    doc(collection(firestore, 'maintenanceTasksCompleted')),
                    {
                        completedBy: userId,
                        vesselId: vesselId,
                        whenAdded: batchTrace.whenAction,
                        addedBy: userId,
                        maintenanceTaskId: newRef.id,
                        equipmentId: equipmentId,
                        location: _locationId,
                        task: data.task ? data.task : undefined,
                        description: data.description ? data.description : undefined,
                        whenCompleted: toMillis(data.whenLastService), 
                        files: undefined,
                        type: 'scheduled',
                        notes: '',
                        engineHours: useEngineHours ? toInt(data.engineHoursLastService, undefined) : undefined,
                        engineId: (useEngineHours && data.engineId) ? data.engineId : undefined,
                        state: 'completed',
                        touched: serverTimestamp(),
                    }
                );

                if (
                    data.engineHoursLastService !== undefined &&
                    data.engineHoursLastService !== '' &&
                    data.engineId &&
                    engines?.byId[data.engineId] &&
                    toInt(data.engineHoursLastService, 0) > engines.byId[data.engineId].hours
                ) {
                    // engineHoursLastService entered is ahead of current engine's hours, therefore we should update the engine
                    batch.set(
                        doc(firestore, 'engines', data.engineId),
                        {
                            hours: toInt(data.engineHoursLastService, 0),
                            whenUpdated: batchTrace.whenAction,
                            updatedVia: 'editMaintenanceSchedule',
                            updatedBy: userId,
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'engines');
                    logAction(
                        batch,
                        'Update',
                        'engines',
                        data.engineId,
                        `${engines?.byId[data.engineId]?.name}, ${data.engineHoursLastService} hours`,
                        [vesselId]
                    );
                    showToast(`Updated ${engines?.byId[data.engineId]?.name} to be ${data.engineHoursLastService} hours`);
                }

                onCollectionUpdated(batch, 'maintenanceTasksCompleted');
            }

            onCollectionUpdated(batch, 'scheduledMaintenanceTasks');

            // TODO: Add links
            // handleLinkUpdates(
            //     batch,
            //     links,
            //     existingLinks,
            //     itemId,
            //     'scheduledMaintenanceTasks'
            // )

            if (maintenanceTags && vessel) {
                cleanupStringArray(maintenanceTags)?.forEach((tag) => {
                    if (vessel.possibleMaintenanceTags === undefined || vessel.possibleMaintenanceTags.indexOf(tag) === -1) {
                        batch.set(
                            doc(firestore, 'vessels', vesselId),
                            { possibleMaintenanceTags: arrayUnion(tag) },
                            { merge: true }
                        );
                    }
                });
            }

            batchTrace.data = {
                data,
                locationId,
                equipmentId,
                itemToUpdate,
                maintenanceTags,
            };
            batchTrace.save(`${itemToUpdate ? 'Update' : 'Create'} scheduled maintenance task`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });
    
    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    const isModalDirty = useCallback(() => {
        return haveValuesChanged(values, initialValues) ||
            equipmentId !== ((itemToUpdate?.equipmentId) ? itemToUpdate.equipmentId : '') ||
            locationId !== ((itemToUpdate?.locationId) ? itemToUpdate.locationId : '') ||
            hasArrayChanged(maintenanceTags, itemToUpdate?.maintenanceTags)
    }, [values, initialValues, equipmentId, itemToUpdate?.equipmentId, itemToUpdate?.locationId, itemToUpdate?.maintenanceTags, locationId, maintenanceTags]);

    // const equipmentManualDocumentOptions = useMemo(() => {
    //     return equipmentManualDocuments?.documents?.map((document) => {
    //         return {
    //             id: document.id,
    //             name: document.title
    //         };
    //     });
    // }, [equipmentManualDocuments]);

    // const contactOptions = useMemo(() => {
    //     return contacts?.all?.map((contact) => {
    //         return {
    //             id: contact.id,
    //             name: contact.company ? `${contact.company}, ${contact.name}` : contact.name
    //         };
    //     });
    // }, [contacts]);

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Maintenance Task' : 'Add New Maintenance Task'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            onClosed={onClosed}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <SeaEquipment
                            equipmentId={equipmentId}
                            setEquipmentId={setEquipmentId}
                            initialLocationId={(itemToUpdate?.locationId) ? itemToUpdate.locationId : ''}
                            locationId={locationId}
                            setLocationId={setLocationId}
                            includeMakeModelSerial={true}
                            includeContactsAndManuals={true}
                            includeCritical={true}
                            hasSubmitted={hasSubmitted}
                        />
                        <IonCol size="12">
                            <SeaInput
                                label="Task Title"
                                name="task"
                                value={values.task}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.task ? errors.task : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Task Description"
                                name="description"
                                value={values.description}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.description ? errors.description : ''}
                            />
                        </IonCol>

                        {!vessel?.isShoreFacility && <>
                            <IonCol size="6">
                                <SeaSelect
                                    disabled={itemToUpdate ? true : false}
                                    label="Interval Type"
                                    name="intervalType"
                                    value={values.intervalType}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.intervalType ? errors.intervalType : ''}
                                >
                                    <IonSelectOption value="weekMonth">Week / Month</IonSelectOption>
                                    <IonSelectOption value="engineHours">Hours</IonSelectOption>
                                    <IonSelectOption value="weekMonthAndHours">Week / Month &amp; Hours</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                        </>}
                        <IonCol size="6">
                            <SeaTagsInput
                                label="Maintenance Tags"
                                tags={maintenanceTags}
                                setTags={setMaintenanceTags}
                                options={vessel?.possibleMaintenanceTags}
                            />
                        </IonCol>
                        {(values.intervalType === 'weekMonth' || values.intervalType === 'weekMonthAndHours') && <>
                            <IonCol size="6">
                                <SeaSelectInterval
                                    label="Interval Week / Month"
                                    name="intervalWeekMonth"
                                    value={values.intervalWeekMonth}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.intervalWeekMonth ? errors.intervalWeekMonth : ''}
                                />
                            </IonCol>
                            <IonCol size="6">
                                <SeaDatetime
                                    disabled={itemToUpdate ? true : false}
                                    label="Last Service"
                                    name="whenLastService"
                                    value={values.whenLastService}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    zone="white"
                                    error={touched.whenLastService ? errors.whenLastService : ''}
                                />
                            </IonCol>
                        </>}
                        {(values.intervalType === 'engineHours' || values.intervalType === 'weekMonthAndHours') && <>
                            <IonCol size="6">
                                <SeaSelect
                                    label="Interval Hours"
                                    name="intervalEngineHours"
                                    zone="white"
                                    value={values.intervalEngineHours}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.intervalEngineHours ? errors.intervalEngineHours : ''}
                                >
                                    <IonSelectOption value="">Not Set</IonSelectOption>
                                    <IonSelectOption value="50">50</IonSelectOption>
                                    <IonSelectOption value="20">20</IonSelectOption>
                                    <IonSelectOption value="100">100</IonSelectOption>
                                    <IonSelectOption value="125">125</IonSelectOption>
                                    <IonSelectOption value="150">150</IonSelectOption>
                                    <IonSelectOption value="200">200</IonSelectOption>
                                    <IonSelectOption value="250">250</IonSelectOption>
                                    <IonSelectOption value="300">300</IonSelectOption>
                                    <IonSelectOption value="350">350</IonSelectOption>
                                    <IonSelectOption value="375">375</IonSelectOption>
                                    <IonSelectOption value="400">400</IonSelectOption>
                                    <IonSelectOption value="450">450</IonSelectOption>
                                    <IonSelectOption value="500">500</IonSelectOption>
                                    <IonSelectOption value="600">600</IonSelectOption>
                                    <IonSelectOption value="700">700</IonSelectOption>
                                    <IonSelectOption value="750">750</IonSelectOption>
                                    <IonSelectOption value="1000">1,000</IonSelectOption>
                                    <IonSelectOption value="1200">1,200</IonSelectOption>
                                    <IonSelectOption value="1250">1,250</IonSelectOption>
                                    <IonSelectOption value="1500">1,500</IonSelectOption>
                                    <IonSelectOption value="2000">2,000</IonSelectOption>
                                    <IonSelectOption value="2500">2,500</IonSelectOption>
                                    <IonSelectOption value="3000">3,000</IonSelectOption>
                                    <IonSelectOption value="4000">4,000</IonSelectOption>
                                    <IonSelectOption value="4500">4,500</IonSelectOption>
                                    <IonSelectOption value="4800">4,800</IonSelectOption>
                                    <IonSelectOption value="5000">5,000</IonSelectOption>
                                    <IonSelectOption value="6000">6,000</IonSelectOption>
                                    <IonSelectOption value="7500">7,500</IonSelectOption>
                                    <IonSelectOption value="8000">8,000</IonSelectOption>
                                    <IonSelectOption value="9000">9,000</IonSelectOption>
                                    <IonSelectOption value="10000">10,000</IonSelectOption>
                                    <IonSelectOption value="12000">12,000</IonSelectOption>
                                    <IonSelectOption value="15000">15,000</IonSelectOption>
                                    <IonSelectOption value="20000">20,000</IonSelectOption>
                                    <IonSelectOption value="24000">24,000</IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            <IonCol size="6">
                                <SeaSelect
                                    label="Connect to Engine / generator"
                                    name="engineId"
                                    zone="white"
                                    value={values.engineId}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.engineId ? errors.engineId : ''}
                                >
                                    <IonSelectOption value="">Not Set</IonSelectOption>
                                    {engines?.all?.map((engine) => {
                                        return (
                                            <IonSelectOption key={engine.id} value={engine.id}>
                                                {engine.name}
                                            </IonSelectOption>
                                        );
                                    })}
                                </SeaSelect>
                            </IonCol>
                            {(values.intervalType === 'engineHours') && 
                                <IonCol size="6">
                                    <SeaDatetime
                                        disabled={itemToUpdate ? true : false}
                                        label="Last Service"
                                        name="whenLastService"
                                        value={values.whenLastService}
                                        onchange={handleChange}
                                        onblur={handleBlur}
                                        zone="white"
                                        error={touched.whenLastService ? errors.whenLastService : ''}
                                    />
                                </IonCol>
                            }
                            <IonCol size="6">
                                <SeaInput
                                    disabled={itemToUpdate ? true : false}
                                    label="Engine hours when last serviced"
                                    name="engineHoursLastService"
                                    value={values.engineHoursLastService}
                                    onchange={handleChange}
                                    onblur={handleBlur}
                                    error={touched.engineHoursLastService ? errors.engineHoursLastService : ''}
                                    type="number"
                                    inputmode="numeric"
                                />
                            </IonCol>
                        </>}
                        {/* TODO: Add links */}
                        {/* <IonCol size="12">
                            <SeaLinkMultiList
                                selectedCollection='scheduledMaintenanceTasks'
                                selectedItemId={itemToUpdate?.id || ''}
                                values={links}
                                setValues={setLinks}
                                options={linkOptions}
                                deleteable
                                confirmDelete
                                level={level+1}
                            />
                        </IonCol> */}
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && !!equipmentId}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Task' : 'Save New Task'}</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditMaintenanceSchedule;
