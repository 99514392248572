import React, { useState } from 'react';
import { IonPopover, IonContent } from '@ionic/react';
import SeaButton from '../SeaButton/SeaButton';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaExporter.css';

export type SeaExportOption = 'logbook' | 'safety-checks' | 'safety-equipment' | 'drill-reports' | 'maintenance-schedule' | 'job-list' | 'spare-parts' | 'equipment-list' | 'equipment-manuals' | 'maintenance-history' | 'vessel-certificates' | 'vessel-documents' | 'survey-documents' | 'sops' | 'incidents' | 'risk-assessments' | 'hs-meetings' | 'dangerous-goods' | 'company-documents' | 'crew-particulars' | 'crew-certificates' | 'crew-training' | 'contacts';
export type ExportType = 'csv' | 'pdf' | undefined;

export interface SeaExporterProps {
    setExportType: (type: ExportType) => void;
    csv?: React.ReactNode; // Do not set to false if not wanted. Leave undefined or the option will be visible
    pdf?: React.ReactNode; // Do not set to false if not wanted. Leave undefined or the option will be visible
    zone?: "grey" | "blue" | "white" | "gold";
}

const SeaExporter = ({ setExportType, csv, pdf, zone = 'grey' }: SeaExporterProps) => {
    const [showModal, setShowModal] = useState(false);
    const [popoverEvent, setPopoverEvent] = useState<Event | undefined>(undefined);
    
    const handleButtonClick = (e: React.MouseEvent) => {
        setPopoverEvent(e.nativeEvent);
        setShowModal(true);
    };

    const onExportSelected = (type: 'csv' | 'pdf') => {
        setExportType(type);
        setShowModal(false);
    };

    return (
        <div>
            <SeaButton
                onClick={handleButtonClick}
                zone={zone}
                shape="circle"
            >
                <SeaIcon slot="icon-only" icon="download" />
            </SeaButton>
            <IonPopover
                isOpen={showModal}
                event={popoverEvent}
                onDidDismiss={() => setShowModal(false)}
                showBackdrop={false}
                translucent={true}
                mode="ios"
                className="sea-exporter-popover"
            >
                <IonContent>
                    {csv !== undefined && <div className="sea-exporter-dropdown-item" onClick={() => onExportSelected('csv')}>
                        <SeaIcon icon="document" /> <p>Export as CSV</p>
                    </div>}
                    {pdf !== undefined && <div className="sea-exporter-dropdown-item" onClick={() => onExportSelected('pdf')}>
                        <SeaIcon icon="pdf" /> <p>Export as PDF</p>
                    </div>}
                </IonContent>
            </IonPopover>
            {pdf}
            {csv}
        </div>
    );
}

export default SeaExporter;