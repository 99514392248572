import React from 'react';
import { formatDateSimplify, formatTime } from '../../lib/datesAndTime';
import { renderFullNameForUserId } from '../../shared-state/Core/users';
import './SeaAuthorship.css';

//
// Assumes that item contains addedBy and whenAdded
//
interface SeaAuthorshipProps {
    item?: {
        addedBy?: string,
        whenAdded?: string | number,
        completedBy?: string,
        whenCompleted?: string | number,
        updatedBy?: string,
        whenUpdated?: string | number
    },
    creationText?: string,
    completeText?: string,
    updateText?: string,
    includeTime?: boolean
}

const SeaAuthorship: React.FC<SeaAuthorshipProps> = ({ item, creationText = 'Created', completeText = 'Completed', updateText = 'Last updated', includeTime = true }) => {
    if (item?.completedBy && item.completedBy) {
        if (item.addedBy && item.whenAdded && item.whenAdded !== item.completedBy) {
            return <div className="sea-authorship">
                {`${creationText} by ${renderFullNameForUserId(item.addedBy)}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
                <br/>
                {`${completeText}${(item.completedBy === item.addedBy) ? '' : ` by ${renderFullNameForUserId(item.completedBy)},`} ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                {(item?.updatedBy && item.whenUpdated && item.whenUpdated !== item.whenCompleted) &&
                    <>
                        <br/>
                        {`${updateText}${(item.updatedBy === item.completedBy) ? '' : ` by ${renderFullNameForUserId(item.updatedBy)},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
                    </>
                }
            </div>;
        }
        if (item?.updatedBy && item.whenUpdated) {
            return <div className="sea-authorship">
                {`${completeText} by ${renderFullNameForUserId(item.completedBy)}, ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
                <br/>
                {`${updateText}${(item.updatedBy === item.completedBy) ? '' : ` by ${renderFullNameForUserId(item.updatedBy)},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
            </div>;
        }
        return <div className="sea-authorship">
            {`${completeText} by ${renderFullNameForUserId(item.completedBy)}, ${formatDateSimplify(item.whenCompleted)}${includeTime ? ` at ${formatTime(item.whenCompleted)}` : ''}`}
        </div>;
    }
    if (item?.updatedBy && item.whenUpdated) {
        return <div className="sea-authorship">
            {`${creationText} by ${renderFullNameForUserId(item.addedBy)}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
            <br/>
            {`${updateText}${(item.updatedBy === item.addedBy) ? '' : ` by ${renderFullNameForUserId(item.updatedBy)},`} ${formatDateSimplify(item.whenUpdated)}${includeTime ? ` at ${formatTime(item.whenUpdated)}` : ''}`}
        </div>;
    }
    if (item?.addedBy && item.whenAdded) {
        return <div className="sea-authorship">
            {`${creationText} by ${renderFullNameForUserId(item.addedBy)}, ${formatDateSimplify(item.whenAdded)}${includeTime ? ` at ${formatTime(item.whenAdded)}` : ''}`}
        </div>;
    }
    return null;
};

export default SeaAuthorship;
