import React, { useState, useEffect, Profiler, useMemo } from "react";
import {
    engineHoursLeftToClassName,
    whenDueToClassName,
    formatInterval,
    formatValue,
    extractSearchTerms,
} from "../../../lib/util";
import { formatDate, warnDays } from '../../../lib/datesAndTime';
import { logPageView } from "../../../lib/firebase";
import { onProfilerRender } from "../../../lib/performance";
import { usePageLimiter } from "../../../hooks/usePageLimiter";
import { IonSelectOption } from "@ionic/react";
import { permissionLevels } from "../../../shared-state/Core/userPermissions";
import { renderCategoryName } from "../../../lib/categories";
import { sharedState } from '../../../shared-state/shared-state';
import { ScheduledMaintenanceTask } from '../../../shared-state/VesselMaintenance/maintenanceSchedule';
import { MaintenanceScheduleCsv } from '../../../exports/VesselMaintenance/MaintenanceSchedule/MaintenanceScheduleCsv';
import SeaStatusIcon from "../../../components/SeaStatusIcon/SeaStatusIcon";
import EditMaintenanceScheduleSettings from '../../../modals/VesselMaintenance/MaintenanceSchedule/EditMaintenanceScheduleSettings/EditMaintenanceScheduleSettings';
import SeaIcon from "../../../components/SeaIcon/SeaIcon";
import SeaButton from "../../../components/SeaButton/SeaButton";
import SeaStatusDueDate from "../../../components/SeaStatusDueDate/SeaStatusDueDate";
import SeaStatusDueHours from "../../../components/SeaStatusDueHours/SeaStatusDueHours";
import EditMaintenanceSchedule from "../../../modals/VesselMaintenance/MaintenanceSchedule/EditMaintenanceSchedule/EditMaintenanceSchedule";
import SeaSelect from "../../../components/SeaSelect/SeaSelect";
import SeaNoData from "../../../components/SeaNoData/SeaNoData";
import RequirePermissions from "../../../components/RequirePermissions/RequirePermissions";
import ViewMaintenanceSchedule from "../../../modals/VesselMaintenance/MaintenanceSchedule/ViewMaintenanceSchedule/ViewMaintenanceSchedule";
import SeaSearchbar from "../../../components/SeaSearchbar/SeaSearchbar";
import MaintenanceSchedulePdf from "../../../exports/VesselMaintenance/MaintenanceSchedule/MaintenanceSchedulePdf";
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import "./MaintenanceSchedule.css";

interface MaintenanceScheduleProps {
    visible: boolean;
}

const MaintenanceSchedule: React.FC<MaintenanceScheduleProps> = ({
    visible,
}) => {
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(visible ? 1 : 101);
    const vesselSystems = sharedState.vesselSystems.use(visible ? 1 : 101);
    const equipment = sharedState.equipment.use(visible ? 1 : 101);
    sharedState.engines.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.vesselLocations.use(visible ? 51 : 0); // Prepare for modals to access
    sharedState.spareParts.use(visible ? 52 : 0); // Prepare for modals to access
    sharedState.contacts.use(visible ? 52 : 0); // Prepare for modals to access
    sharedState.equipmentManualDocuments.use(visible ? 52 : 0); // Prepare for modals to access
    sharedState.maintenanceTasksCompleted.use(visible ? 52 : 0); // Prepare for modals to access
    const {
        limitTriggerElement,
        mapArrayWithLimit,
        resetPageLimit,
        isLimitReached,
    } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [listType, setListType] = useState(warnDays.maintenanceSchedule[0].toString());
    const [exportType, setExportType] = useState<ExportType>();
    const [viewScheduledMaintenanceTaskModal, setViewScheduledMaintenanceTaskModal] = useState<{
        show: boolean;
        selectedItem: ScheduledMaintenanceTask | undefined;
        level: number;
    }>({
        show: false,
        selectedItem: undefined,
        level: 1,
    });
    const [searchText, setSearchText] = useState("");
    const [systemFilter, setSystemFilter] = useState("");
    const [equipmentFilter, setEquipmentFilter] = useState("");
    const [filteredScheduledMaintenanceTasks, setFilteredScheduledMaintenanceTasks] = useState<{
        isEmpty?: boolean;
        prioritised?: ScheduledMaintenanceTask[];
        [key: string]: ScheduledMaintenanceTask[] | number | boolean | undefined;
    }>();
    const [showMaintenanceScheduleSettings, setShowMaintenanceScheduleSettings] = useState(false);

    useEffect(() => {
        if (visible) {
            logPageView("VesselMaintenance/MaintenanceSchedule");
        }
        resetPageLimit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, resetPageLimit]);

    const isUsingFilters = useMemo(() => {
        return (searchText || systemFilter || equipmentFilter) ? true : false;
    }, [searchText, systemFilter, equipmentFilter]);

    useEffect(() => {
        resetPageLimit();
    }, [resetPageLimit, searchText, systemFilter, equipmentFilter]);

    // Filter categories
    useEffect(() => {
        setFilteredScheduledMaintenanceTasks(undefined);
        if (vesselSystems && scheduledMaintenanceTasks && equipment?.byId) {
            const checkTaskAgainstFilter = (task: ScheduledMaintenanceTask, terms: string[]) => {
                if (systemFilter && task.equipment !== undefined) {
                    const _equipment = equipment.byId[task.equipmentId];
                    if (_equipment.systemId !== systemFilter) {
                        return false;
                    }
                } else if (systemFilter && task.equipment === undefined) {
                    return false;
                }
                if (
                    equipmentFilter &&
                    (task.equipment === undefined ||
                        task.equipment.id !== equipmentFilter)
                ) {
                    return false;
                }
                if (terms.length > 0) {
                    for (let j = 0; j < terms.length; j++) {
                        if (task.searchText?.indexOf(terms[j]) === -1) {
                            return false;
                        }
                    }
                }
                return true;
            };

            const _categories = {
                isEmpty: true
            } as {
                isEmpty: boolean;
                prioritised?: ScheduledMaintenanceTask[];
                [systemId: string]: ScheduledMaintenanceTask[] | boolean | undefined;
            };

            if (listType === "all" || listType === "critical") {
                const terms = extractSearchTerms(searchText, true);
                scheduledMaintenanceTasks.systemIds.forEach((systemId: string) => {
                    const tasks = scheduledMaintenanceTasks.bySystemId[systemId];
                    tasks?.forEach((task: ScheduledMaintenanceTask) => {
                        if (checkTaskAgainstFilter(task, terms)) {
                            if (task.equipmentId) {
                                const _equipment = equipment.byId[task.equipmentId];
                                if (_equipment && (listType === "all" || _equipment.isCritical)) {
                                    _categories.isEmpty = false;
                                    if (_categories[_equipment.systemId] === undefined) {
                                        _categories[_equipment.systemId] = [];
                                    }
                                    (_categories[_equipment.systemId] as ScheduledMaintenanceTask[] | undefined)?.push(task);
                                }
                            }
                        }
                    });
                });
            } else {
                const terms = extractSearchTerms(searchText, true);
                const prioritised = scheduledMaintenanceTasks.prioritised;
                if (prioritised) {
                    for (let i = 0; i < prioritised.length; i++) {
                        const task = prioritised[i];
                        if (task.priority && task.priority >= Number(listType)) {
                            break;
                        }
                        if (checkTaskAgainstFilter(task, terms)) {
                            if (_categories.prioritised === undefined) {
                                _categories.prioritised = [];
                            }
                            _categories.prioritised.push(task);
                            _categories.isEmpty = false;
                        }
                    }
                }
            }
            setFilteredScheduledMaintenanceTasks(_categories);
        }
    }, [
        scheduledMaintenanceTasks,
        searchText,
        systemFilter,
        equipmentFilter,
        vesselSystems,
        equipment,
        listType
    ]);

    // Keep viewScheduledMaintenanceTaskModal.selectedItem fresh
    useEffect(() => {
        if (
            viewScheduledMaintenanceTaskModal?.selectedItem?.id &&
            scheduledMaintenanceTasks?.byId[
                viewScheduledMaintenanceTaskModal.selectedItem.id
            ]
        ) {
            setViewScheduledMaintenanceTaskModal((current) => {
                return {
                    ...current,
                    selectedItem: viewScheduledMaintenanceTaskModal.selectedItem?.id ? scheduledMaintenanceTasks?.byId[
                        viewScheduledMaintenanceTaskModal.selectedItem.id
                    ] : undefined
                }
            });
        }
    }, [scheduledMaintenanceTasks, viewScheduledMaintenanceTaskModal?.selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onEditSettings = () => {
        setShowMaintenanceScheduleSettings(true);
    };
    
    const onViewItem = (item: ScheduledMaintenanceTask) => {
        console.log(`Viewing scheduled maintenance task ${item?.id}`);
        setViewScheduledMaintenanceTaskModal({
            show: true,
            selectedItem: item,
            level: 1,
        });
    };

    const hasFilters = useMemo(() => {
        return (
            (scheduledMaintenanceTasks?.filterOptions?.systemIds?.length || 0) > 0 ||
            (scheduledMaintenanceTasks?.filterOptions?.equipmentIds?.length || 0) > 0
        );
    }, [scheduledMaintenanceTasks]);

    const equipmentFilterOptions = useMemo(() => {
        const _equipment: string[] = [];
        if (scheduledMaintenanceTasks?.filterOptions?.equipmentIds?.length) {
            for (const id of scheduledMaintenanceTasks.filterOptions.equipmentIds) {
                if (systemFilter && equipment?.byId[id]?.systemId !== systemFilter) {
                    continue;
                }
                _equipment.push(id);
            }
        }
        return _equipment;
    }, [
        scheduledMaintenanceTasks?.filterOptions?.equipmentIds,
        equipment?.byId,
        systemFilter,
    ]);


    return (
        <RequirePermissions
            role="maintenanceSchedule"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="maintenance-schedule page-head">
                <div>
                    <h2>Maintenance Schedule</h2>
                </div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="240px"
                        zone="grey"
                        onchange={(e) => {
                            setListType(e.detail.value);
                        }}
                    >
                        {warnDays.maintenanceSchedule.map((days: number) => (
                            <IonSelectOption
                                key={days}
                                value={days.toString()}
                            >
                                Overdue &amp; Due Within {days} Days
                            </IonSelectOption>
                        ))}
                        <IonSelectOption value="critical">Critical</IonSelectOption>
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="maintenanceSchedule"
                        level={permissionLevels.CREATE}
                    >
                        <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New Task
                        </SeaButton>
                    </RequirePermissions>
                    <div className="spacer"></div>
                    <SeaExporter 
                        setExportType={setExportType}
                        csv={exportType === "csv" && <MaintenanceScheduleCsv onCompleted={() => setExportType(undefined)} />}
                        pdf={exportType === "pdf" && (
                            <MaintenanceSchedulePdf
                                onCompleted={() => setExportType(undefined)}
                                listType={listType}
                                maintenanceTasks={filteredScheduledMaintenanceTasks?.prioritised || []}
                            />)}
                    />
                    <RequirePermissions
                        role="vesselSettings"
                        level={permissionLevels.EDIT}
                    >
                        <SeaButton
                            zone="grey"
                            shape="circle"
                            onClick={(e) => onEditSettings()}
                        >
                            <SeaIcon slot="icon-only" icon="settings" />
                        </SeaButton>
                    </RequirePermissions>
                </div>
            </div>
            {/* Filters */}
            <div className={`columns wrap filters reveal`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
                {(scheduledMaintenanceTasks?.filterOptions?.systemIds?.length || 0) > 0 && (
                    <div>
                        <SeaSelect
                            name="systemFilter"
                            value={systemFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => {
                                setSystemFilter(e.detail.value);
                                setEquipmentFilter("");
                                resetPageLimit();
                            }}
                        >
                            <IonSelectOption value="">
                                Filter by System
                            </IonSelectOption>
                            {scheduledMaintenanceTasks?.filterOptions?.systemIds?.map((id: string) => {
                                return (
                                    <IonSelectOption key={id} value={id}>
                                        {renderCategoryName(
                                            id,
                                            vesselSystems
                                        )}
                                    </IonSelectOption>
                                );
                            })}
                        </SeaSelect>
                    </div>
                )}
                {equipment && (scheduledMaintenanceTasks?.filterOptions?.equipmentIds?.length || 0) > 0 && (
                    <div>
                        <SeaSelect
                            name="equipmentFilter"
                            value={equipmentFilter}
                            width="195px"
                            zone="grey"
                            onchange={(e) => {
                                setEquipmentFilter(e.detail.value);
                                resetPageLimit();
                            }}
                        >
                            <IonSelectOption value="">
                                Filter by Equipment
                            </IonSelectOption>
                            {equipmentFilterOptions.map((id: string) => (
                                <IonSelectOption key={id} value={id}>
                                    {equipment?.byId[id]?.equipment}
                                </IonSelectOption>
                            ))}
                        </SeaSelect>
                    </div>
                )}
                {hasFilters && (
                    <div>
                        <SeaButton
                            onClick={(e) => {
                                setSearchText("");
                                setSystemFilter("");
                                setEquipmentFilter("");
                            }}
                            zone="grey"
                        >
                            Reset
                        </SeaButton>
                    </div>
                )}
            </div>
            <SeaNoData
                dataName="maintenance schedule tasks"
                isLoading={!filteredScheduledMaintenanceTasks}
                hasNoData={filteredScheduledMaintenanceTasks?.isEmpty}
                isUsingFilter={isUsingFilters}
            />
            <div
                className={`maintenance-schedule has-status ${!(filteredScheduledMaintenanceTasks?.isEmpty) ? 'reveal' : 'conceal'}`}
            >
                {(listType === "all" || listType === "critical" )? (
                    <div>
                        <div className="sea-row headings">
                            <div>Equipment</div>
                            <div>Maintenance Task</div>
                            <div>Tags</div>
                            <div>Interval</div>
                            <div>Next Due</div>
                            <div>Status</div>
                            <div>Critical</div>
                        </div>
                        <Profiler
                            id="maintenance.schedule.all"
                            onRender={onProfilerRender}
                        >
                            {filteredScheduledMaintenanceTasks && vesselSystems?.ids?.map((systemId: string) => {
                                if (
                                    isLimitReached() ||
                                    filteredScheduledMaintenanceTasks[
                                        systemId
                                    ] === undefined
                                ) {
                                    return undefined;
                                }
                                return (
                                    <React.Fragment key={systemId}>
                                        <div className="category-heading">
                                            {renderCategoryName(
                                                systemId,
                                                vesselSystems
                                            )}
                                        </div>
                                        {mapArrayWithLimit(
                                            filteredScheduledMaintenanceTasks[
                                                systemId
                                            ] as ScheduledMaintenanceTask[],
                                            (item: ScheduledMaintenanceTask) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className={`sea-card sea-row ${
                                                            item.useHours
                                                                ?
                                                                item.engineHoursLeft && engineHoursLeftToClassName(
                                                                    item.engineHoursLeft
                                                                )
                                                                :
                                                                whenDueToClassName(
                                                                    item.whenDue,
                                                                    warnDays.maintenanceSchedule[0]
                                                                )
                                                        }`}
                                                        onClick={(e) =>
                                                            onViewItem(item)
                                                        }
                                                    >
                                                        <div className="bold truncate">
                                                            {item.equipment?.equipment}
                                                            {(item.equipment?.state ==="deleted") && " (deleted)"}
                                                        </div>
                                                        <div className={`truncate ${(item.equipment?.isCritical) ? 'has-icon' : ''}`}>
                                                            {formatValue(
                                                                item.task
                                                            )}
                                                        </div>
                                                        <div className="truncate">
                                                            {item.maintenanceTags?.join(", ")}
                                                        </div>
                                                        <div className="truncate">
                                                            {(item.intervalType ===
                                                                "weekMonth" ||
                                                                item.intervalType ===
                                                                    "weekMonthAndHours") &&
                                                                formatInterval(
                                                                    item.intervalWeekMonth
                                                                )}
                                                            {item.intervalType ===
                                                                "weekMonthAndHours" && (
                                                                <br />
                                                            )}
                                                            {(item.intervalType ===
                                                                "engineHours" ||
                                                                item.intervalType ===
                                                                    "weekMonthAndHours") &&
                                                                `${item.intervalEngineHours} Hours`}
                                                        </div>
                                                        <div className="truncate">
                                                            {(item.intervalType ===
                                                                "weekMonth" ||
                                                                item.intervalType ===
                                                                    "weekMonthAndHours") &&
                                                                formatDate(
                                                                    item.whenDue
                                                                )}
                                                            {item.intervalType ===
                                                                "weekMonthAndHours" && (
                                                                <br />
                                                            )}
                                                            {(item.intervalType ===
                                                                "engineHours" ||
                                                                item.intervalType ===
                                                                    "weekMonthAndHours") &&
                                                                `${item.engineHoursDue} Hours`}
                                                        </div>
                                                        <div className="truncate">
                                                            {item.useHours ? (
                                                                <SeaStatusDueHours
                                                                    engineHoursLeft={
                                                                        item.engineHoursLeft as number
                                                                    }
                                                                />
                                                            ) : (
                                                                <SeaStatusDueDate
                                                                    whenDue={
                                                                        item.whenDue as number
                                                                    }
                                                                    warnDays={
                                                                        warnDays.maintenanceSchedule[0]
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="truncate is-icon">
                                                            {item.equipment?.isCritical && (
                                                                <SeaStatusIcon
                                                                    icon="flag"
                                                                    className="bottom critical"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Profiler>
                    </div>
                ) : (
                    <>
                        <div className="sea-row headings prioritised">
                            <div>Equipment</div>
                            <div>Maintenance Task</div>
                            <div>Tags</div>
                            <div>Interval</div>
                            <div>Next Due</div>
                            <div>Status</div>
                            <div>Critical</div>
                        </div>
                        <Profiler
                            id="maintenance.schedule.prioritised"
                            onRender={onProfilerRender}
                        >
                            {mapArrayWithLimit(
                                filteredScheduledMaintenanceTasks?.prioritised,
                                (item: ScheduledMaintenanceTask) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className={`sea-card sea-row prioritised ${
                                                item.useHours
                                                    ? item.engineHoursLeft && engineHoursLeftToClassName(
                                                          item.engineHoursLeft
                                                      )
                                                    : whenDueToClassName(
                                                          item.whenDue,
                                                          warnDays.maintenanceSchedule[0]
                                                      )
                                                }`}
                                            onClick={(e) => onViewItem(item)}
                                        >
                                            <div className="bold truncate">
                                                {item.equipment?.equipment}
                                                {item.equipment?.state ===
                                                "deleted"
                                                    ? " (deleted)"
                                                    : ""}
                                            </div>
                                            <div className={`truncate ${item.equipment?.isCritical ? 'has-icon' : ''}`}>
                                                {formatValue(item.task)}
                                            </div>
                                            <div className="truncate">
                                                {item.maintenanceTags?.join(", ")}
                                            </div>
                                            <div className="truncate">
                                                {(item.intervalType ===
                                                    "weekMonth" ||
                                                    item.intervalType ===
                                                        "weekMonthAndHours") &&
                                                    formatInterval(
                                                        item.intervalWeekMonth
                                                    )}
                                                {item.intervalType ===
                                                    "weekMonthAndHours" && (
                                                    <br />
                                                )}
                                                {(item.intervalType ===
                                                    "engineHours" ||
                                                    item.intervalType ===
                                                        "weekMonthAndHours") &&
                                                    `${item.intervalEngineHours} Hours`}
                                            </div>
                                            <div className="truncate">
                                                {(item.intervalType ===
                                                    "weekMonth" ||
                                                    item.intervalType ===
                                                        "weekMonthAndHours") &&
                                                    formatDate(item.whenDue)}
                                                {item.intervalType ===
                                                    "weekMonthAndHours" && (
                                                    <br />
                                                )}
                                                {(item.intervalType ===
                                                    "engineHours" ||
                                                    item.intervalType ===
                                                        "weekMonthAndHours") &&
                                                    `${item.engineHoursDue} Hours`}
                                            </div>
                                            <div className="truncate">
                                                {item.useHours ? (
                                                    <SeaStatusDueHours
                                                        engineHoursLeft={
                                                            item.engineHoursLeft as number
                                                        }
                                                    />
                                                ) : (
                                                    <SeaStatusDueDate
                                                        whenDue={item.whenDue as number}
                                                        warnDays={
                                                            warnDays.maintenanceSchedule[0]
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="truncate is-icon">
                                                {item.equipment?.isCritical && (
                                                    <SeaStatusIcon
                                                        icon="flag"
                                                        className="bottom critical"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </Profiler>
                    </>
                )}
                {visible && limitTriggerElement}
            </div>
            {visible && (
                <>
                    <ViewMaintenanceSchedule
                        showModal={viewScheduledMaintenanceTaskModal.show}
                        setShowModal={(showModal: boolean) =>
                            setViewScheduledMaintenanceTaskModal({
                                ...viewScheduledMaintenanceTaskModal,
                                show: showModal,
                            })
                        }
                        selectedItem={
                            viewScheduledMaintenanceTaskModal.selectedItem
                        }
                        level={viewScheduledMaintenanceTaskModal.level}
                    />
                    <EditMaintenanceSchedule
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                    />
                    <EditMaintenanceScheduleSettings
                        showModal={showMaintenanceScheduleSettings}
                        setShowModal={setShowMaintenanceScheduleSettings}
                    />
                </>
            )}
        </RequirePermissions>
    );
};

export default MaintenanceSchedule;
