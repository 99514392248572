import React from 'react';
import {  formatDp } from '../../lib/util';
import { makeDateTime } from '../../lib/datesAndTime';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../lib/pdf';
import {
    radiansToCompassDirection,
    windGradient,
    wavesGradient,
    //tempGradient,
    getGradientBackgroundImage
} from '../../lib/mapping';
import SeaIcon from '../SeaIcon/SeaIcon';
import './SeaWindyForecastTable.css';

interface SeaWindyForecastTableProps {
    mode: 'interact-with-map' | 'view-only' | 'pdf',
    forecastData: any,
    //windyUtilsRef: React.MutableRefObject<any>,
    selectedTime?: number,
    selectTime?: (t: number, layer: string | undefined) => void,
    marginLeft?: string
}

const calcWindSpeed = (data: any, index: number) => {
    const x = data.windX[index];
    const y = data.windY[index];
    return Math.sqrt(x * x + y * y);
};

const SeaWindyForecastTable: React.FC<SeaWindyForecastTableProps> = ({
    forecastData,
    //windyUtilsRef,
    selectedTime,
    selectTime,
    mode,
    marginLeft = '0px'
}) => {

    const onDataClicked = (t: number, layer: string | undefined) => {
        if (selectTime) {
            selectTime(t, layer);
        }
    };

    let day = '';

    if (mode === 'pdf') {
        return (
            <React.Fragment>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}></Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const time = makeDateTime(t);
                        if (time.toFormat('EEE d') !== day) {
                            day = time.toFormat('EEE d');
                            return <View key={t} style={{ ...pdfStyles.windyColumn, ...pdfStyles.semiBold }}>
                                <Text>{day}</Text>
                            </View>;
                        } else {
                            return <View key={t} style={pdfStyles.windyColumn}>
                                <Text></Text>
                            </View>;
                        }
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}>Hours</Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const time = makeDateTime(t);
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{time.toFormat('HH')}:00</Text>
                            </View>
                        );
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}>Wind</Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const windDirection = Math.atan2(forecastData.windX[index], forecastData.windY[index]) * (180 / Math.PI);
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{radiansToCompassDirection(windDirection * (Math.PI / 180))}</Text>
                            </View>
                        );
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}></Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const windNow = calcWindSpeed(forecastData, index);
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{Math.round(windNow)}<Text style={pdfStyles.windyUnits}>kt</Text></Text>
                            </View>
                        );
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}>Gusts</Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{Math.round(forecastData.gust[index])}<Text style={pdfStyles.windyUnits}>kt</Text></Text>
                            </View>
                        );
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}>Waves</Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const waveRadians = (forecastData.waveD[index] * Math.PI / 180);
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{radiansToCompassDirection(waveRadians + Math.PI)}</Text>
                            </View>
                        );
                    })}
                </View>
                <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}></Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{formatDp(forecastData.waveH[index], 1)}<Text style={pdfStyles.windyUnits}>m</Text></Text>
                            </View>
                        );
                    })}
                </View>
                {/* <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}></Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{formatDp(forecastData.waveP[index], 1)}<Text style={pdfStyles.windyUnits}>s</Text></Text>
                            </View>
                        );
                    })}
                </View> */}
                {/* <View wrap={false} style={pdfStyles.rowNoSpace}>
                    <View style={pdfStyles.windyLabelColumn}>
                        <Text style={pdfStyles.label}>Temp</Text>
                    </View>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return (
                            <View key={t} style={pdfStyles.windyColumn}>
                                <Text>{forecastData.temp[index]}&deg;</Text>
                            </View>
                        );
                    })}
                </View> */}
            </React.Fragment>
        );
    }

    return (
        <table className={`forecast-table ${mode}`} style={{ marginLeft: marginLeft }}>
            <thead>
                <tr>
                    <td></td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const time = makeDateTime(t);
                        if (time.toFormat('EEE d') !== day) {
                            day = time.toFormat('EEE d');
                            return <td key={t} style={{
                                borderStyle: 'solid',
                                borderColor: 'var(--input-border-color)',
                                borderWidth: '0px 0px 0px 1px'
                            }}>{day}</td>;
                        } else {
                            return <td key={t}></td>;
                        }
                    })}
                </tr>
            </thead>
            <tbody>
                <tr className="times">
                    <td className="sea-label">Hours</td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const time = makeDateTime(t);
                        return <td
                            key={t}
                            className={(t === selectedTime) ? 'selected-time' : ''}
                            onClick={(e) => onDataClicked(t, undefined)}
                        >
                            {time.toFormat('HH')}:00
                        </td>;
                    })}
                </tr>
                <tr className="wind">
                    <td className="sea-label">Wind</td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        // const wind = windyUtilsRef.current.wind2obj([
                        //     forecastData.windX[index],
                        //     forecastData.windY[index]
                        // ]);
                        const windNow = calcWindSpeed(forecastData, index);
                        const windPrevious = calcWindSpeed(forecastData, Math.max(index - 1, 0));
                        const windNext = calcWindSpeed(forecastData, Math.min(index + 1, forecastData.windX.length - 1));
                        const windDirection = Math.atan2(forecastData.windX[index], forecastData.windY[index]) * (180 / Math.PI);

                        return <td
                            key={t}
                            style={{
                                backgroundImage: getGradientBackgroundImage(
                                    windPrevious,
                                    windNow,
                                    windNext,
                                    windGradient,
                                    50
                                )
                            }}
                            onClick={(e) => onDataClicked(t, 'wind')}
                        >
                            <div style={{
                                transform: `rotate(${windDirection}deg)`
                            }}>
                                <SeaIcon icon="windArrow" />
                            </div>
                            {/* <span>{radiansToCompassDirection(wind.dir * (Math.PI / 180) - Math.PI)}</span>
                            <br/> */}
                            <span>{radiansToCompassDirection(windDirection * (Math.PI / 180))}</span>
                            <br/>
                            {Math.round(windNow)}<span>kt</span>
                            {/* <br/>
                            {Math.round(windDirection)}&deg; */}
                        </td>;
                    })}
                </tr>
                <tr className="gust">
                    <td className="sea-label">Gusts</td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return <td
                            key={t}
                            style={{
                                backgroundImage: getGradientBackgroundImage(
                                    forecastData.gust[Math.max(index - 1, 0)],
                                    forecastData.gust[index],
                                    forecastData.gust[Math.min(index + 1, forecastData.gust.length - 1)],
                                    windGradient,
                                    50
                                )
                            }}
                            onClick={(e) => onDataClicked(t, 'gust')}
                        >
                            {Math.round(forecastData.gust[index])}<span>kt</span>
                        </td>;
                    })}
                </tr>
                <tr className="waves">
                    <td className="sea-label">Waves</td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        const waveRadians = (forecastData.waveD[index] * Math.PI / 180);
                        return <td
                            key={t}
                            style={{
                                backgroundImage: getGradientBackgroundImage(
                                    forecastData.waveH[Math.max(index - 1, 0)],
                                    forecastData.waveH[index],
                                    forecastData.waveH[Math.min(index + 1, forecastData.waveH.length - 1)],
                                    wavesGradient,
                                    50
                                )
                            }}
                            onClick={(e) => onDataClicked(t, 'waves')}
                        >
                            <div style={{
                                transform: `rotate(${waveRadians + (Math.PI * 0.75)}rad)`,
                            }}>
                                <SeaIcon icon="waveArrow" />
                            </div>
                            <span>{radiansToCompassDirection(waveRadians + Math.PI)}</span>
                            <br/>
                            {formatDp(forecastData.waveH[index], 1)}<span>m</span>
                            {/* <br/>
                            {formatDp(forecastData.waveP[index], 1)}<span>s</span> */}
                        </td>;
                    })}
                </tr>
                {/* <tr className="temp">
                    <td className="sea-label">Temp.</td>
                    {forecastData?.t?.map((t: number, index: number) => {
                        return <td
                            key={t}
                            style={{
                                backgroundImage: getGradientBackgroundImage(
                                    forecastData.temp[Math.max(index - 1, 0)],
                                    forecastData.temp[index],
                                    forecastData.temp[Math.min(index + 1, forecastData.temp.length - 1)],
                                    tempGradient,
                                    50
                                )
                            }}
                            onClick={(e) => onDataClicked(t, 'temp')}
                        >
                            {forecastData.temp[index]}&deg;
                        </td>;
                    })}
                </tr> */}
            </tbody>
        </table>
    );
};

export default SeaWindyForecastTable;
