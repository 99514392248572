import { useMemo } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvNamesFromIds } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { SafetyMeetingReport } from '../../../shared-state/HealthSafety/safetyMeetingReports';
import { renderCamelCase } from '../../../lib/util';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type HealthSafetyMeetingsCsvProps = {
    onCompleted: () => void
}

const HealthSafetyMeetingsCsv = ({ onCompleted }: HealthSafetyMeetingsCsvProps) => {
    const healthSafetyRecords = sharedState.safetyMeetingReports.use();
    const safetyMeetingJobs = sharedState.safetyMeetingJobs.use();
    const vessels = sharedState.vessels.use();

    const csvConfig: CsvConfig<SafetyMeetingReport> | undefined = useMemo(() => {
        if (!safetyMeetingJobs || !vessels) {
            return undefined;
        }
        return [
            {
                name: 'Date',
                value: (item) => formatCsvDate(item.whenAdded)
            },
            {
                name: 'Notes',
                value: (item) => item.notes || ''
            },
            {
                name: 'Personnel present',
                value: (item) => (item.personnelPresentNames || []).join(', ')
            },
            {
                name: 'Jobs created',
                value: (item) => formatCsvNamesFromIds(item.jobIds, safetyMeetingJobs.byId, 'task')
            },
            {
                name: 'Vessels/facilities',
                value: (item) => formatCsvNamesFromIds(item.vesselIds, vessels.byId) || ''
            },
            {
                name: 'Date & time of meeting',
                value: (item) => formatCsvDate(item.whenMeeting)
            },
            {
                name: 'Type',
                value: (item) => renderCamelCase(item.type)
            },
            {
                name: 'Meeting notes',
                value: (item) => item.notes || ''
            },
            {
                name: 'Send meeting to crew???',
                value: (item) => formatCsvBoolean(item.sendToCrew)
            },
        ];
    }, [safetyMeetingJobs, vessels]);

    return <SeaCSVGenerator name={'Health-Safety-Meetings'} onCompleted={onCompleted} config={csvConfig} data={healthSafetyRecords?.all} />
};

export default HealthSafetyMeetingsCsv