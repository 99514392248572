import React, { useState, useEffect, Profiler, useMemo } from 'react';
import { IonSelectOption } from '@ionic/react';
import { logPageView } from '../../../lib/firebase';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { formatValue, whenDueToClassName } from '../../../lib/util';
import { formatDate, getDayOffsetMillis, warnDays } from '../../../lib/datesAndTime';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { renderCategoryName } from '../../../lib/categories';
import { VesselCertificate } from '../../../shared-state/VesselDocuments/vesselCertificates';
import { sharedState } from '../../../shared-state/shared-state';
import { VesselCertificatesCsv } from '../../../exports/VesselDocuments/VesselCertificatesCsv';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../components/SeaSelect/SeaSelect';
import SeaStatusDueDate from '../../../components/SeaStatusDueDate/SeaStatusDueDate';
import EditVesselCertificate from '../../../modals/VesselDocuments/VesselCertificates/EditVesselCertificate/EditVesselCertificate';
import ViewVesselCertificate from '../../../modals/VesselDocuments/VesselCertificates/ViewVesselCertificate/ViewVesselCertificate';
import ArchiveVesselCertificate from '../../../modals/VesselDocuments/VesselCertificates/ArchiveVesselCertificate/ArchiveVesselCertificate';
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import SeaFileImage from '../../../components/SeaFileImage/SeaFileImage';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import EditVesselCertificateSettings from '../../../modals/VesselDocuments/VesselCertificates/EditVesselCertificateSettings/EditVesselCertificateSettings';
import VesselCertificatesPdf from '../../../exports/VesselDocuments/VesselCertificatesPdf';
import './VesselCertificates.css';

interface VesselCertificatesProps {
    visible: boolean
}

const VesselCertificates: React.FC<VesselCertificatesProps> = ({ visible }) => {
    const vesselCertificates = sharedState.vesselCertificates.use(visible ? 1 : 101);
    const vessel = sharedState.vessel.use(visible ? 1 : 101);
    const vesselCertificateCategories = sharedState.vesselCertificateCategories.use(visible ? 1 : 101);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showViewItemModal, setShowViewItemModal] = useState(false);
    const [showArchivedModal, setShowArchivedModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<VesselCertificate>();
    const [listType, setListType] = useState(warnDays.vesselCertificates[0].toString());
    const [showEditVesselCertificateSettings, setShowEditVesselCertificateSettings] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();

    useEffect(() => {
        if (visible) {
            logPageView('VesselDocuments/VesselCertificates');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedItem fresh
    useEffect(() => {
        if (selectedItem?.id && vesselCertificates?.byId[selectedItem.id]) {
            setSelectedItem(vesselCertificates.byId[selectedItem.id])
        };
    }, [vesselCertificates, selectedItem?.id]);

    const onAddNewItem = () => {
        setShowEditItemModal(true);
    };
    const onViewItem = (item: VesselCertificate) => {
        setShowViewItemModal(true);
        setSelectedItem(item)
    };
    const onViewArchived = () => {
        setShowArchivedModal(true);
    };
    const onSettings = () => {
        setShowEditVesselCertificateSettings(true);
    };

    const prioritisedVesselCertificates = useMemo(() => {
        if (listType === 'all') return [];
        const whenExpires = getDayOffsetMillis(Number(listType));
        return vesselCertificates?.prioritised?.filter((item) => item.whenExpires && item.whenExpires < whenExpires)
    }, [listType, vesselCertificates]);

    return (
        <RequirePermissions
            role="vesselCertificates"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="vessel-certificates page-head">
                <div><h2>{vessel?.isShoreFacility ? 'Vehicle Expiries' : 'Vessel Certificates'}</h2></div>
                <div className="actions">
                    <SeaSelect
                        name="listType"
                        value={listType}
                        width="250px"
                        zone="grey"
                        onchange={(e) => setListType(e.detail.value)}
                    >
                        {warnDays.vesselCertificates.map((days) => {
                            return <IonSelectOption key={days} value={days.toString()}>Overdue &amp; Due Within {days} Days</IonSelectOption>
                        })}
                        <IonSelectOption value="all">All</IonSelectOption>
                    </SeaSelect>
                    <RequirePermissions
                        role="vesselCertificates"
                        level={permissionLevels.VIEW}
                    >
                        <RequirePermissions
                            role="vesselCertificates"
                            level={permissionLevels.CREATE}
                        >

                            <SeaButton onClick={(e) => onAddNewItem()} zone="grey">
                                <SeaIcon slot="start" icon="add"/>
                                Add New

                            </SeaButton>
                            <RequirePermissions
                                role="vesselCertificates"
                                level={permissionLevels.FULL}
                            >
                                <div className="spacer"></div>
                                <SeaButton onClick={(e) => onViewArchived()} zone="grey">
                                    <SeaIcon slot="start" icon="archive"/>
                                    Archived
                                </SeaButton>
                            </RequirePermissions>
                        </RequirePermissions>
                    </RequirePermissions>
                    <RequirePermissions
                        role="vesselCertificates"
                        level={permissionLevels.VIEW}
                    >
                        <div className="spacer"></div>
                        <SeaExporter
                            setExportType={setExportType}
                            pdf={exportType === 'pdf' && (
                                <VesselCertificatesPdf
                                    onCompleted={() => setExportType(undefined)}
                                    prioritisedVesselCertificates={prioritisedVesselCertificates}
                                    vesselCertificateCategories={vesselCertificateCategories}
                                    vesselCertificates={vesselCertificates}
                                    listType={listType}
                                />
                            )}
                            csv={exportType === "csv" && <VesselCertificatesCsv onCompleted={() => setExportType(undefined)} />}
                        />
                        <RequirePermissions
                            role="vesselCertificates"
                            level={permissionLevels.CREATE}
                        >
                            <SeaButton zone="grey" shape="circle" onClick={(e) => onSettings()}>
                                <SeaIcon slot="icon-only" icon="settings"/>
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>

                </div>
            </div>

            <SeaNoData
                dataName={vessel?.isShoreFacility ? 'vehicle expiries' : 'vessel certificates'}
                isLoading={!vesselCertificates}
                hasNoData={(listType === 'all' && vesselCertificates && vesselCertificates.all.length === 0) || (listType !== 'all' && prioritisedVesselCertificates?.length === 0)}
                isUsingFilter={listType !== 'all'}
            />

            <div className={`vessel-certificates has-thumbs has-status ${(
                (listType === 'all' && ((vesselCertificates?.all?.length || 0) > 0))
                ||
                (listType !== 'all' && (prioritisedVesselCertificates?.length || 0) > 0)
            )
                ? 'reveal' : 'conceal'}`
            }>
                <div className="sea-row headings">
                    <div></div>
                    <div>Name</div>
                    <div>{vessel?.isShoreFacility ? 'Certification' : 'Certificate'} #</div>
                    <div>Issued By</div>
                    <div>Expiry</div>
                    <div>Status</div>
                    <div>Reminder</div>
                </div>
                <Profiler id="documents.certificates" onRender={onProfilerRender}>
                    {/* Prioritised */}
                    {listType !== 'all' && (prioritisedVesselCertificates?.length || 0) > 0 && !isLimitReached() && <>
                        {mapArrayWithLimit(prioritisedVesselCertificates, (item: VesselCertificate) => {
                            return (
                                <div key={item.id} className={`sea-card sea-row ${whenDueToClassName(item.whenExpires, warnDays.vesselCertificates[0])}`} onClick={(e) => onViewItem(item)}>
                                    <div>
                                        <SeaFileImage files={item.files} size="tiny" />
                                    </div>
                                    <div className="bold truncate-2">{item.title}</div>
                                    <div className="truncate-2">{formatValue(item.certNum)}</div>
                                    <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                    <div>{formatDate(item.whenExpires)}</div>
                                    <div><SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.vesselCertificates[0]} /></div>
                                    <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                </div>
                            );
                        })}
                    </>}
                    {listType === 'all' && vesselCertificateCategories?.ids?.map((categoryId: string) => {
                        const items = vesselCertificates?.byCategoryId[categoryId];
                        if (!items || items.length === 0 || isLimitReached()) return null;

                        return (
                            <React.Fragment key={categoryId}>
                                <div className="category-heading">{renderCategoryName(categoryId, vesselCertificateCategories)}</div>
                                {items.map((item: VesselCertificate) => (
                                    <div key={item.id} className={`sea-card sea-row ${item.type === 'renewable' ? whenDueToClassName(item.whenExpires, warnDays.vesselCertificates[0]) : ''}`} onClick={(e) => onViewItem(item)}>
                                        <div>
                                            <SeaFileImage files={item.files} size="tiny" />
                                        </div>
                                        <div className="bold truncate-2">{item.title}</div>
                                        <div className="truncate-2">{formatValue(item.certNum)}</div>
                                        <div className="truncate-2">{formatValue(item.issuedBy)}</div>
                                        {item.type === 'renewable' && <>
                                            <div>{formatDate(item.whenExpires)}</div>
                                            <div><SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.vesselCertificates[0]} /></div>
                                            <div>{item.emailReminder && <SeaIcon icon='mail' />}</div>
                                        </>}
                                        {item.type === 'nonExpiring' && <>
                                            <div className="not-card"></div>
                                            <div className="not-card"></div>
                                            <div className="not-card"></div>
                                        </>}
                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>
            {visible &&
                <>
                    {vesselCertificateCategories && <EditVesselCertificate
                        showModal={showEditItemModal}
                        setShowModal={setShowEditItemModal}
                        vesselCertificateCategories={vesselCertificateCategories}
                    />}

                    <EditVesselCertificateSettings
                        showModal={showEditVesselCertificateSettings}
                        setShowModal={setShowEditVesselCertificateSettings}
                        vesselCertificateCategories={vesselCertificateCategories}
                    />
                    <ViewVesselCertificate
                        showModal={showViewItemModal}
                        setShowModal={setShowViewItemModal}
                        selectedItem={selectedItem}
                    />
                    <ArchiveVesselCertificate
                        showModal={showArchivedModal}
                        setShowModal={setShowArchivedModal}
                    />
                </>
            }
        </RequirePermissions>
    );
};

export default VesselCertificates;
