import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, toMillis,  } from '../../../../lib/util';
import { formatSeaDate, addInterval } from '../../../../lib/datesAndTime';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName } from '../../../../shared-state/Core/users';
import { UserType } from '../../../../shared-state/Core/user';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { haveFilesChanged, SeaFile } from '../../../../lib/files';
import { TrainingTasksData } from '../../../../shared-state/Crew/trainingTasks';
import { TrainingTaskReportsData } from '../../../../shared-state/Crew/useTrainingTaskReports';
import Yup, { notTooOld } from '../../../../lib/yup'
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaDate from '../../../../components/SeaDate/SeaDate';
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';

interface EditTrainingReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    vesselId?: string, // default vessel in form
    trainingTasks?: TrainingTasksData,
    trainingTaskReports: TrainingTaskReportsData,
    selectedTaskAndCrew?: {userId: string, taskId: string}
}

const limitByOptions = (values: string[], options?: {id: string, name: string}[]) => {
    const results = [] as string[];
    if (options) {
        values.forEach((value: string) => {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === value) {
                    results.push(value);
                    break;
                }
            }
        });
    }
    return results;
};

const EditTrainingReport: React.FC<EditTrainingReportProps> = ({showModal, setShowModal, level, vesselId, trainingTasks, trainingTaskReports, selectedTaskAndCrew}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const userId = sharedState.userId.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const users = sharedState.users.use(showModal);
    const [completedBy, setCompletedBy] = useState<string[]>([]);
    const [tasksCompleted, setTasksCompleted] = useState<string[]>([]);
    const [files, setFiles] = useState<SeaFile[]>([]);
    const [taskNotes, setTaskNotes] = useState<{
        [key: string]: string
    }>({});
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const initialValues = useMemo(() => {
        return {
            vesselId: vesselId ? vesselId : '',
            whenCompleted: formatSeaDate(),
            trainer: ''+renderFullName()
        };
    }, [vesselId]);

    const onOpened = () => {
        setHasSubmitted(false);
        resetForm();
        setValues(initialValues);
        setCompletedBy(selectedTaskAndCrew ? [selectedTaskAndCrew.userId] : []);
        setTasksCompleted(selectedTaskAndCrew ? [selectedTaskAndCrew.taskId] : []);
        setTaskNotes({});
        setFiles([]);
    };

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, resetForm, isValid, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            vesselId: Yup.string().max(200).required(),
            whenCompleted: Yup.date().required().min(...notTooOld),
            trainer: Yup.string().max(500).required(),
        }), onSubmit: (data) => {
            setHasSubmitted(true);

            if (!isStatesValid) {
                return;
            }

            const _completedBy = limitByOptions(completedBy, completedByOptions);
            
            // Process form
            const batch = splittableBatch(firestore);
            const batchTrace = makeBatchTrace(batch, 'trainingTaskReports', 'create');

            tasksCompleted.forEach((taskId: string) => {
                const task = trainingTasks?.byId[taskId];
                if (task === undefined) {
                    return;
                }
                const reportRef = doc(collection(firestore, 'trainingTaskReports'));
                batchTrace.exampleDocId = reportRef.id;
                batch.set(
                    reportRef,
                    {
                        taskId: taskId,
                        vesselId: data.vesselId,
                        licenseeId: licenseeId,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        completedBy: _completedBy,
                        whenCompleted: toMillis(data.whenCompleted),
                        whenDue: addInterval(data.whenCompleted, task.interval),
                        notes: taskNotes[taskId] ? taskNotes[taskId] : undefined,
                        trainer: data.trainer ? data.trainer : undefined,
                        state: 'active',
                        touched: serverTimestamp(),
                    }
                );

                // Update trainingTask.whenLatestCompleted values if necessary
                let whenLastCompleted = toMillis(data.whenCompleted) as number;

                trainingTaskReports?.byTaskId[taskId]?.forEach((report, index) => {
                    if (
                        index < 10 &&
                        //(itemToUpdate === undefined || report.id !== itemToUpdate.id) &&
                        report.whenCompleted > whenLastCompleted
                    ) {
                        whenLastCompleted = report.whenCompleted;
                    }
                });
                if (task.whenLastCompleted !== whenLastCompleted) {
                    batch.set(
                        doc(firestore, 'trainingTasks', taskId),
                        {
                            whenLastCompleted: whenLastCompleted,
                            whenDue: addInterval(whenLastCompleted, task.interval),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'trainingTasks');
                }

                logAction(
                    batch,
                    'Add',
                    'trainingTaskReports',
                    reportRef.id,
                    trainingTasks?.byId[taskId]?.task as string,
                    [data.vesselId],
                    _completedBy
                );
            });

            onCollectionUpdated(batch, 'trainingTaskReports');
            batchTrace.data = {
                data,
                tasksCompleted,
                taskNotes,
                _completedBy
            };
            batchTrace.save(`Create crew training reports`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error: any) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
        }
    });

    const onVesselChanged = (e: CustomEvent) => {
        handleChange(e);
    };

    const completedByOptions = useMemo(() => {
        if (values.vesselId && users?.byVesselId && users.byVesselId[values.vesselId]) {
            return users.byVesselId[values.vesselId].map((user: UserType) => {
                return {
                    id: user.id as string,
                    name: renderFullName(user)
                };
            });
        }
        return undefined;
    }, [values.vesselId, users]);

    const possibleTasks = useMemo(() => {
        if (values.vesselId && trainingTasks?.allByVesselId && trainingTasks.allByVesselId[values.vesselId]) {
            return trainingTasks.allByVesselId[values.vesselId].map((task) => {
                return {
                    id: task.id,
                    name: task.task
                }
            });
        }
        return undefined;
    }, [values.vesselId, trainingTasks]);

    const isStatesValid = useMemo(() => {
        const _tasksCompleted = limitByOptions(tasksCompleted, possibleTasks);
            const _completedBy = limitByOptions(completedBy, completedByOptions);
            if (_tasksCompleted === undefined || _tasksCompleted.length === 0) {
                return false;
            }
            if (_completedBy === undefined || _completedBy.length === 0) {
                return false;
            }
            return true
    }, [completedBy, completedByOptions, possibleTasks, tasksCompleted])

    const onTaskNotesChanged = (e: CustomEvent, taskId: string) => {
        const _taskNotes = {...taskNotes};
        _taskNotes[taskId] = e.detail.value;
        setTaskNotes(_taskNotes);
    };

    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, []) ||
            limitByOptions(completedBy, completedByOptions).length > 0 ||
            limitByOptions(tasksCompleted, possibleTasks).length > 0
        );
    }, [initialValues, values, files, completedBy, completedByOptions, tasksCompleted, possibleTasks]);

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={'Complete Training'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
            size="wide"
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="6">
                            <SeaSelect
                                label="Vessel or Facility"
                                name="vesselId"
                                value={values.vesselId}
                                onchange={(e) => onVesselChanged(e)}
                                onblur={handleBlur}
                                error={touched.vesselId ? errors.vesselId : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                {vessels?.all?.map((vessel) => {
                                    return (
                                        <IonSelectOption key={vessel.id} value={vessel.id}>{vessel.name}</IonSelectOption>
                                    );
                                })}
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="6">
                            <SeaDate
                                label="Date"
                                name="whenCompleted"
                                value={values.whenCompleted}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                error={touched.whenCompleted ? errors.whenCompleted : ''}
                            />
                        </IonCol>
                        <IonCol size="6">
                            <SeaInput
                                label="Trainer"
                                name="trainer"
                                value={values.trainer}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                type="text"
                                inputmode="text"
                                error={touched.trainer ? errors.trainer : ''}
                            />
                        </IonCol>
                        {completedByOptions &&
                            <IonCol size="12">
                                <SeaMultiSelect
                                    label="Crew that completed this training"
                                    values={completedBy}
                                    setValues={setCompletedBy}
                                    options={completedByOptions}
                                    useAllOption={false}
                                    required={true}
                                    requiredError="At least one crew member is required"
                                    isSubmitting={isSubmitting}
                                    emptyText="Not Set"
                                    mode="tags"
                                />
                            </IonCol>
                        }
                        {possibleTasks &&
                            <IonCol size="12">
                                <SeaMultiSelect
                                    label="Training tasks completed"
                                    values={tasksCompleted}
                                    setValues={setTasksCompleted}
                                    options={possibleTasks}
                                    useAllOption={false}
                                    required={true}
                                    requiredError="At least one training task is required"
                                    isSubmitting={isSubmitting}
                                    emptyText="Not Set"
                                />
                            </IonCol>
                        }
                        {tasksCompleted && tasksCompleted.map((taskId: string) => {
                            return (
                                <IonCol key={taskId} size="6">
                                    <SeaTextarea
                                        label={`Notes for ${trainingTasks?.byId[taskId]?.task}`}
                                        name={`task${taskId}`}
                                        value={taskNotes[taskId] ? taskNotes[taskId] : ''}
                                        onchange={(e) => onTaskNotesChanged(e, taskId)}
                                        //onblur={handleBlur}
                                        zone="white"
                                        height={100}
                                        inputmode="text"
                                        //error={touched.currentMedicalIssues ? errors.currentMedicalIssues : ''}
                                    />
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </IonGrid>
                <div className='grid-row-spacer'></div>
                <SeaFormHasErrors
                    hasSubmitted={hasSubmitted}
                    isValid={isValid && isStatesValid}
                />
                <div className="view-modal-buttons">
                    <SeaButton zone="white" type="submit">Submit Training Form</SeaButton>
                </div>
            </form>
        </SeaModal>
    );
};

export default EditTrainingReport;
