import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IonDatetime, IonInput, IonPopover, isPlatform } from '@ionic/react';
import { pulseElementById } from '../../lib/util';
import SeaIcon from '../SeaIcon/SeaIcon';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaDatetime.css';

// Function to pad the start of a string with zeros
const padStart = (value?: string, isYear?: boolean) => {
    if (!value || value === '') return '--';
    if (isYear) {
        // Limit year to 4 digits
        return value.slice(0, 4).padStart(4, '0');
    }
    value = value.padStart(2, '0');
    // Remove the leading zero if value is more than 2 characters in length
    if (value.length > 2) {
        value = value.slice(1);
    }
    return value;
}

interface SeaDatetimeProps {
    zone?: 'blue' | 'white' | 'grey',
    id?: string, // required for pulse
    name?: string,
    label?: string,
    value?: string,
    error?: string,
    disabled?: boolean,
    readonly?: boolean,
    required?: boolean,
    inputType?: 'datetime-local' | 'time',
    setToCurrentTime?: () => void,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onkeyup?: (e: CustomEvent) => void
}

const SeaDatetime = React.forwardRef<HTMLInputElement, SeaDatetimeProps>(({
    id,
    zone,
    name,
    label,
    value,
    error,
    disabled,
    readonly,
    required,
    inputType = 'datetime-local',
    setToCurrentTime,
    onchange,
    onblur,
    onfocus,
    onkeyup
}, forwardedRef:any) => {

    // Function to get the value of date, hours, or minutes from a string
    const getValue = useCallback((value: string | undefined, type: 'date' | 'hours' | 'minutes') => {
        if (!value) return '--';
        if (inputType === 'datetime-local') {
            const [date, time] = value.split('T');
            const [hours, minutes] = time?.split(':') || [];
            const valueMap = {
                'date': date,
                'hours': hours,
                'minutes': minutes
            };
            return valueMap[type] || '--';
        }
        if (inputType === 'time') {
            const [hours, minutes] = value.split(':');
            const valueMap = {
                'hours': hours,
                'minutes': minutes
            };
            return valueMap[type as 'hours' | 'minutes'] || '--';
        }
        return '--';
    }, [inputType]);

    // Define state variables for date, hours, and minutes
    const [date, setDate] = useState(getValue(value, 'date'));
    const [hours, setHours] = useState(getValue(value, 'hours'));
    const [minutes, setMinutes] = useState(getValue(value, 'minutes'));

    const [isOpen, setIsOpen] = useState(false);

    // Define refs for the IonInput and IonDatetime components
    const ref = useRef<HTMLIonInputElement>(null);
    const datetimeRef = useRef<HTMLIonDatetimeElement>(null);
    const timeRef = useRef<HTMLIonDatetimeElement>(null);
    const _time = useMemo(() => `${padStart(hours)}:${padStart(minutes)}`, [hours, minutes])
    const _timeValue = useMemo(() => _time.replace(/-/g, '0'), [_time]);

    // Define a memoized value for time and newValue
    const newValue = useMemo(() => {
        if (inputType === 'datetime-local') {
            // Ensure date and time are valid before combining
            const validDate = date && date !== '--' ? date : '1970-01-01';
            const validTime = _time && _time !== '--:--' ? _time : '00:00';
            return `${validDate}T${validTime}`;
        }
        return _time && _time !== '--:--' ? _time : '00:00';
    }, [date, inputType, _time]);

    // Define a state variable for timeError
    const [timeError, setTimeError] = useState<string | null>(null);
    const [dateError, setDateError] = useState<string | null>(null);

    const displayError = useMemo(() => dateError || timeError || error, [error, timeError, dateError]);

    // Function to open the IonDatetime picker
    const openDatetime = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
        // if (datetimeRef.current) {
        //     (datetimeRef.current as any)?.open();
        // }
    }


    // Function to validate the time format
    const validateTime = (timeString: string) => {
        const [hours, minutes] = timeString.split(':');
        if (hours === '--' && minutes === '--') {
            setTimeError(null);
            return;
        }
        const time = new Date(`1970-01-01T${padStart(hours)}:${padStart(minutes)}Z`);
        if (time.getTime() > 0) {
            setTimeError(null);
        } else {
            setTimeError('Invalid time format (HH:MM)');
        }
    };

    // Function to validate the date format
    const validateDate = (dateString: string | null) => {
        const [year, month, day] = (dateString || '').split('-');
        if (year === 'yyyy' && month === 'mm' && day === 'dd') {
            setDateError(null);
            return;
        }
        const paddedYear = padStart(year, true);
        const time = new Date(`${paddedYear}-${padStart(month)}-${padStart(day)}T00:00Z`);
        if (time.getTime() > 0) {
            setDateError(null);
        }
        else {
            setDateError('Invalid date format (dd/mm/yyyy)');
        }
    };

    // Function to handle changes in the IonInput and IonDatetime components
    const onIonChange = (e: CustomEvent, type: 'full-date' | 'date' | 'hours' | 'minutes' | 'time') => {
        if (e.detail) {
            let { value } = e.detail;
            if (type === 'full-date') {
                const [localdate, localtime] = value.split('T');
                const [localhours, localminutes] = localtime.split(':');
                validateDate(localdate);
                validateTime(localtime);
                setDate(localdate);
                setHours(localhours);
                setMinutes(localminutes);
            } else if (type === 'date') {
                validateDate(value);
                setDate(value);
            } else if (type === 'time') {
                validateTime(value);
                const [hours, minutes] = value.split(':');
                setHours(padStart(hours));
                setMinutes(padStart(minutes))
            } else if (type === 'hours') {
                padStart(value)
                validateTime(`${value}:${minutes}`);
                setHours(value);
            } else if (type === 'minutes') {
                padStart(value)
                validateTime(`${hours}:${value}`);
                setMinutes(value);
            }
            // Safely blur the input element if it exists
            const ionInputElement = e.target as HTMLIonInputElement;
            if (ionInputElement && typeof ionInputElement.blur === 'function') {
                ionInputElement.blur();
            }
        }
        if (ref.current) {
            ref.current.focus();
        }
    }

    const onFocus = async (e: CustomEvent) => {
        const ionInputElement = e.target as HTMLIonInputElement;
        const inputElement = await ionInputElement.getInputElement();
        inputElement.select();
        if (onfocus) {
            onfocus(e);
        }
    };

    const onBlur = async (e: CustomEvent, type: 'hours' | 'minutes') => {
        const ionInputElement = e.target as HTMLIonInputElement;
        const inputElement = await ionInputElement.getInputElement();
        if (!inputElement.value) inputElement.value = '--';
        if (type === 'hours') {
            validateTime(`${inputElement.value}:${minutes}`);
            setHours(inputElement.value);
        } else if (type === 'minutes') {
            validateTime(`${hours}:${inputElement.value}`);
            setMinutes(inputElement.value);
        }
        if (onblur) {
            onblur(e);
        }
    }

    const _setToCurrentTime = () => {
        setToCurrentTime && setToCurrentTime();
        const now = new Date();
        const year = now.getFullYear();
        const month = padStart((now.getMonth() + 1).toString());
        const day = padStart(now.getDate().toString());
        const hours = padStart(now.getHours().toString());
        const minutes = padStart(now.getMinutes().toString());
        setDate(`${year}-${month}-${day}`);
        setHours(hours);
        setMinutes(minutes);
        if (ref.current) {
            ref.current.value = `${year}-${month}-${day}T${hours}:${minutes}`;
        }
        if (onchange) {
            onchange(new CustomEvent('ionChange', { detail: { value: `${year}-${month}-${day}T${hours}:${minutes}` } }));
        }
    }

    // Update state variables when the value prop changes
    React.useEffect(() => {
        setDate(getValue(value, 'date'));
        setHours(getValue(value, 'hours'));
        setMinutes(getValue(value, 'minutes'));
    }, [getValue, value]);

    // Determine the color based on the zone prop
    const color = useMemo(() => {
        switch (zone) {
            case 'blue':
                return 'light'; // white
            case 'white':
            case 'grey':
                return 'primary'; // blue
        }
    }, [zone]);

    return (
        <>
            {/* Display label if provided */}
            {label && <SeaLabel>{label}</SeaLabel>}
            {/* Display the set to current time icon if setToCurrentTime prop is provided */}
            {setToCurrentTime && !disabled &&
                <div style={{ position: 'relative' }}>
                    <div className="input-action-icon pushy" aria-label="Set to current time" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        _setToCurrentTime();

                        if (id) {
                            pulseElementById(id);
                        }
                    }}>
                        <SeaIcon icon='clock' />
                    </div>
                </div>
            }
            <div className={`sea-input-container ${inputType === 'time' ? `sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}` : ''}`}>
                {/* Date input for datetime-local */}
                {inputType === 'datetime-local' && (
                    <>
                        <IonInput
                            id={id}
                            className={`sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}
                            color={color}
                            inputmode="text"
                            type="date"
                            name={`${name}-date`}
                            value={date !== '' ? date : null}
                            placeholder='dd/mm/yyyy'
                            disabled={disabled}
                            readonly={readonly}
                            required={required}
                            onIonChange={(e) => {
                                const value = e.detail.value;
                                if (value) {
                                    const [year, month, day] = value.split('-');
                                    const paddedYear = padStart(year, true);
                                    const newDate = `${paddedYear}-${month}-${day}`;
                                    onIonChange({ ...e, detail: { ...e.detail, value: newDate } }, 'date');
                                } else {
                                    onIonChange(e, 'date');
                                }
                            }}
                            onIonBlur={onblur}
                            onIonFocus={onfocus}
                            onIonInput={onkeyup}
                        ></IonInput>
                        {/* Overlay to open picker popup */}
                        {isPlatform('desktop') && !disabled && <div className="calendar-icon-overlay" onClick={openDatetime}></div>}
                        <IonPopover
                            isOpen={isOpen} 
                            onDidDismiss={() => setIsOpen(false)}
                            trigger={id}
                            side="top"
                            alignment="center"
                            arrow={true}
                            reference="trigger"
                            showBackdrop={false}
                            className="datetime-tooltip"
                        >
                            {isOpen && <IonDatetime
                                ref={datetimeRef}
                                presentation='date-time'
                                onIonCancel={() => setIsOpen(false)}
                                onIonChange={(e) => {
                                    onIonChange(e, 'full-date');
                                }}
                            />}
                        </IonPopover>
                    </>
                )}
                {/* Time input for desktop */}
                {/* {isPlatform('desktop') ?  */}
                    <div className={`time-container ${inputType === 'datetime-local' ? 'has-date' : ''}`}>
                        {/* Hours Input */}
                        <IonInput
                            id={`${id}-hours`}
                            className={`sea-input hours ${zone}-zone${disabled ? ' disabled' : ''}`}
                            color={color}
                            inputmode="numeric"
                            type="text"
                            pattern="\d*"
                            name={`${name}-hours`}
                            value={hours}
                            disabled={disabled}
                            readonly={readonly}
                            required={required}
                            onIonChange={(e) => onIonChange(e, 'hours')}
                            onIonBlur={(e) => onBlur(e, 'hours')}
                            onIonFocus={onFocus}
                            onIonInput={onkeyup}
                        ></IonInput>
                        <span className="separator">:</span>
                        {/* Minutes Input */}
                        <IonInput
                            id={`${id}-minutes`}
                            className={`sea-input minutes ${zone}-zone${disabled ? ' disabled' : ''}`}
                            color={color}
                            inputmode="numeric"
                            type="text"
                            pattern="\d*"
                            name={`${name}-minutes`}
                            value={minutes}
                            disabled={disabled}
                            readonly={readonly}
                            required={required}
                            onIonChange={(e) => onIonChange(e, 'minutes')}
                            onIonBlur={(e) => onBlur(e, 'minutes')}
                            onIonFocus={onFocus}
                            onIonInput={onkeyup}
                        ></IonInput>
                        {/* Hidden time picker used for its popup */}
                        <IonDatetime
                            ref={timeRef}
                            value={_timeValue}
                            presentation='time'
                            // displayFormat="HH:mm"
                            // pickerFormat="HH:mm"
                            onIonChange={(e) => onIonChange(e, 'time')}
                            style={{ display: 'none' }} // hide the input
                        ></IonDatetime>
                    </div> 
                    {/* :
                    <IonDatetime 
                        id={id}
                        className={`sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}
                        color={color}
                        inputMode="text"
                        displayFormat="HH:mm"
                        pickerFormat="HH:mm"
                        name={`${name}-time`}
                        value={time}
                        disabled={disabled}
                        readonly={readonly}
                        onIonChange={(e) => onIonChange(e, 'time')}
                        onIonBlur={onblur}
                        onIonFocus={onfocus}
                    ></IonDatetime>
                } */}
            </div>
            {/* Hidden input used for the form */}
             <IonInput
                ref={ref}
                id={id}
                style={{display: 'none'}} // hide this input, but keep it for the form
                className={`sea-input ${zone}-zone${displayError ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}
                color={color}
                inputmode="text"
                type={inputType}
                name={name}
                value={newValue}
                disabled={disabled}
                readonly={readonly}
                required={required}
                onIonChange={onchange}
                onIonBlur={onblur}
                onIonFocus={onfocus}
                onIonInput={onkeyup}
            ></IonInput>
            <SeaInputError>{displayError}</SeaInputError>
        </>
    );
});

export default SeaDatetime;
