import React, { useEffect, useState } from 'react';
import { calcFuelUsedFromVoyage, formatValue, renderCamelCase } from '../../../../lib/util';
import { formatDate } from '../../../../lib/datesAndTime';
import { logModalView } from '../../../../lib/firebase';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { Voyage } from '../../../../shared-state/VesselLogbook/voyages';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { sharedState } from '../../../../shared-state/shared-state';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';
import FuelUsedReportPdf from '../../../../exports/Reporting/Logbook/FuelUsedReportPdf';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../../lib/reporting';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import './ViewFuelUsedReport.css';

interface ViewFuelUsedReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: Voyage[], // Adjust type as necessary
    categories?: (string | undefined)[],
}

const ViewFuelUsedReport: React.FC<ViewFuelUsedReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
}) => {
    const {selectedVesselId} = useReportSettings();
    const vessels = sharedState.vessels.use();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('FuelUsed Report', true);
        }
    }, [showModal]);

    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')} // Add PDF generation handler
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={true}
            />
            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                colourPalette={reporting.colours.default}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
            />

            <div className="reporting-data">
                <SeaNoData
                    dataName="fuelUsed"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`fuel-used-report ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'}`}>
                    <div className="sea-row-history headings-history">
                        <div>Name</div>
                        <div>Departed</div>
                        <div>Fuel Started</div>
                        <div>Fuel Ended</div>
                        <div>Fuel Bunkered</div>
                        <div>Fuel Used</div>
                        <div>Trip Type</div>
                        {!selectedVesselId ? <div>Vessel</div> : null}
                    </div>
                    {filteredItems?.map((item) => {
                        const fuelUsed = calcFuelUsedFromVoyage(item);
                        return (
                            <div key={item.id} className="sea-card sea-row-history">
                                <div className="bold truncate-2">{formatValue(item.name)}</div>
                                <div>{formatDate(item.whenDeparted)}</div>
                                <div>{item.fuelStart ? `${item.fuelStart}L` : ''}</div>
                                <div>{item.fuelEnd ? `${item.fuelEnd}L` : ''}</div>
                                <div>{item.fuelBunkered ? `${item.fuelBunkered}L` : ''}</div>
                                <div>{fuelUsed ? `${fuelUsed}L` : ''}</div>
                                <div>{renderCamelCase(item.tripType)}</div>
                                {!selectedVesselId ? <div className="truncate-2">{vessels?.byId[item.vesselId]?.name}</div> : null}
                            </div>
                        )
                    })}
                </div>
            </div>

            {showModal && exportType === 'pdf' &&
                <FuelUsedReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
        </SeaModal>
    );
};

export default ViewFuelUsedReport;