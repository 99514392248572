import React from 'react';
import { formatValue } from '../../../../../lib/util';
import { formatDate } from '../../../../../lib/datesAndTime';
import { sharedState } from '../../../../../shared-state/shared-state';
import { UserType } from '../../../../../shared-state/Core/user';
import { useUserTrainingTaskReports } from '../../../../../shared-state/Crew/userTrainingTaskReports';
import { openContextualHelp } from '../../../../../managers/ContextualHelpManager/ContextualHelpManager';
import SeaFileImage from '../../../../../components/SeaFileImage/SeaFileImage';
import SeaNoData from '../../../../../components/SeaNoData/SeaNoData';
import './UserTrainingTab.css';

interface UserTrainingTabProps {
    showModal?: boolean,
    selectedUser?: UserType;
}

const UserTrainingTab: React.FC<UserTrainingTabProps> = ({showModal, selectedUser}) => {
    const trainingTasks = sharedState.trainingTasks.use(showModal);
    const userTrainingTaskReports = useUserTrainingTaskReports(showModal ? selectedUser : undefined);

    return (<>
        <SeaNoData
            dataName="training reports"
            isLoading={!userTrainingTaskReports}
            hasNoData={userTrainingTaskReports && (userTrainingTaskReports.length || 0) === 0}
            isUsingFilter={false}
        />
        <div className={`user-training has-thumbs ${userTrainingTaskReports && userTrainingTaskReports.length > 0 ? 'reveal' : 'conceal'}`}>
            <div className="sea-row-history headings-history">
                <div></div>
                <div>Task</div>
                <div>Date</div>
                <div>Trainer</div>
                <div>Notes</div>
            </div>
            {trainingTasks?.byId && userTrainingTaskReports && userTrainingTaskReports.map((item) => {
                const task = trainingTasks.byId[item.taskId];
                if (task) {
                    return (
                        <div
                            key={item.id}
                            className="sea-card sea-row-history"
                        >
                            <div><SeaFileImage files={task.files} size="tiny"/></div>
                            <div
                                className="truncate"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(task.task)})}
                            >
                                {formatValue(task.task)}
                            </div>
                            <div className="truncate">{formatDate(item.whenCompleted)}</div>
                            <div className="truncate">{formatValue(item.trainer)}</div>
                            <div
                                className="truncate"
                                onClick={(e) => openContextualHelp(e, {text: formatValue(item.notes)})}
                            >
                                {formatValue(item.notes)}
                            </div>
                        </div>
                    );
                }
                return undefined;
            })}
        </div>
    </>);
};

export default UserTrainingTab;
