import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { IonItem, IonList, IonModal, IonPopover } from '@ionic/react';
import { haveObjectsChanged } from '../../../../lib/util';
import { alertMessage } from '../../../../managers/AlertManager/AlertManager';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { VesselOptions } from '../../../../shared-state/Core/vessels';
import SeaScrollableArea from '../../../../components/SeaScrollableArea/SeaScrollableArea';
import CustomFormElement, { CustomFormElementType } from '../../../../components/CustomFormElement/CustomFormElement';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import BuildCustomFormElementPanel from '../BuildCustomFormElementPanel/BuildCustomFormElementPanel';
import './BuildCustomForm.css';

const elementDefaults = {
    vessels: {
        name: 'Vessels',
        label: 'Select Vessels / Facilities',
        required: true
    },
    input: {
        name: 'Text Field',
        label: 'TEXT FIELD',
        required: false
    },
    textarea: {
        name: 'Text Area',
        label: 'TEXT AREA',
        lines: 3, // height = 37px + (lines * 15px)
        required: false
    },
    checkbox: {
        name: 'Checkbox',
        label: 'Checked',
        required: false
    },
    dropdown: {
        name: 'Dropdown',
        label: 'DROPDOWN',
        options: ['Option A', 'Option B'],
        required: false
    },
    date: {
        name: 'Date',
        label: 'DATE',
        required: false
    },
    datetime: {
        name: 'Date with Time',
        label: 'DATE WITH TIME',
        required: false
    },
    yesno: {
        name: 'Yes | No',
        label: 'Question?'
    },
    checks: {
        name: 'Checklist',
        label: 'CHECKLIST',
        options: ['A check']
    },
    files: {
        name: 'Images / Documents',
        label: 'IMAGES / DOCUMENTS',
        required: false
    },
    signature: {
        name: 'Signature',
        label: 'SIGN OR INITIAL BELOW',
        required: true
    },
    heading: {
        name: 'Heading',
        heading: 'A Heading',
        size: 'medium'
    },
    text: {
        name: 'Text',
        text: 'Some text',
        size: 'medium'
    },
    line: {},
    spacer: {
        height: 20
    }
} as {
    [key: string]: Partial<CustomFormElementType>
};

interface BuildCustomFormProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    parentFormElements: CustomFormElementType[],
    setParentFormElements: (parentFormElements: CustomFormElementType[]) => void,
    setWhenNewVersion: (whenNewVersion: number) => void,
    historyElementN: number,
    setHistoryElementN: (n: number) => void,
    forVessels?: boolean,
    forVesselIds?: string[],
    setForVesselIds?: (vesselIds: string[]) => void,
    vesselOptions?: VesselOptions[],
    vesselsElement?: CustomFormElementType,
    setVesselsElement: (object: CustomFormElementType) => void,
    forCrew?: boolean,
    crewElement?: CustomFormElementType,
    setCrewElement: (object: CustomFormElementType) => void,
    confirmationText?: string,
}

const BuildCustomForm: React.FC<BuildCustomFormProps> = ({
    showModal,
    setShowModal,
    title,
    parentFormElements,
    setParentFormElements,
    setWhenNewVersion,
    historyElementN,
    setHistoryElementN,
    forVessels,
    forVesselIds,
    setForVesselIds,
    vesselOptions,
    vesselsElement,
    setVesselsElement,
    forCrew,
    crewElement,
    setCrewElement,
    confirmationText = "Save Changes"
}) => {
    const nRef = useRef(0);
    const [initialFormElements, setInitialFormElements] = useState<CustomFormElementType[]>([]);
    const [formElements, setFormElements] = useState<CustomFormElementType[]>([]);
    const [selectedElement, setSelectedElement] = useState<CustomFormElementType>();
    const [addElementPopoverState, setAddElementPopoverState] = useState<{showPopover: boolean; event?: any}>({ showPopover: false, event: undefined });
    const isDirtyRef = useRef(false);

    useEffect(() => {
        nRef.current = 0;
        if (showModal) {
            const _formElements = [] as CustomFormElementType[];
            if (forVessels) {
                _formElements.push({
                    isSpecial: true,
                    id: 'vessels',
                    n: -2,
                    o: -2,
                    label: (vesselsElement?.label) ? vesselsElement.label : 'SELECT VESSELS / FACILITIES',
                    width: (vesselsElement?.width) ? vesselsElement.width : 100,
                    allowMultiple: (vesselsElement && vesselsElement.allowMultiple !== undefined) ? vesselsElement.allowMultiple : true,
                    forVesselIds: forVesselIds ? [...forVesselIds] : [],
                    vesselOptions: vesselOptions
                    //value: forVesselIds,
                });
            }
            if (forCrew) {
                _formElements.push({
                    isSpecial: true,
                    id: 'crew',
                    n: -1,
                    o: -1,
                    label: (crewElement?.label) ? crewElement.label : 'SELECT PERSONNEL',
                    width: (crewElement?.width) ? crewElement.width : 100,
                    allowMultiple: (crewElement && crewElement.allowMultiple !== undefined) ? crewElement.allowMultiple : true
                });
            }
            // ...
            parentFormElements.forEach((element) => {
                if (element.id)
                _formElements.push(Object.assign(
                    {},
                    elementDefaults[element.id],
                    element
                ));
                if (element.n && element.n > nRef.current) {
                    nRef.current = element.n;
                }
            });
            const _initialFormElements = [] as CustomFormElementType[];
            _formElements.forEach((element) => {
                _initialFormElements.push({
                    ...element
                });
            });
            setFormElements(_formElements);
            setInitialFormElements(_initialFormElements);
        }
    }, [showModal]);

    const onDidPresent = useCallback(() => {
        // ...
    }, []);

    const onDidDismiss = useCallback(() => {
        setFormElements([]);
    }, []);

    const onSaveChanges = () => {
        const strippedElements = [] as CustomFormElementType[];
        let error: string | undefined = undefined;
        formElements.forEach((element) => {
            if (element.isSpecial) {
                if (element.id === 'vessels') {
                    if (element.forVesselIds && element.forVesselIds.length > 0 && setForVesselIds) {
                        setForVesselIds(element.forVesselIds);
                    } else if (element.forVesselIds && element.forVesselIds.length === 0) {
                        error = 'You must allow at least one vessel or facility';
                    }
                    setVesselsElement({
                        label: element.label,
                        width: element.width,
                        allowMultiple: element.allowMultiple
                    } as CustomFormElementType);
                } else if (element.id === 'crew') {
                    setCrewElement({
                        label: element.label,
                        width: element.width,
                        allowMultiple: element.allowMultiple
                    } as CustomFormElementType);
                }
            } else {
                strippedElements.push(element);
            }
        });
        if (error) {
            alertMessage(error);
            return;
        }
        if (haveObjectsChanged(strippedElements, parentFormElements)) {
            setParentFormElements(strippedElements);
            setWhenNewVersion(Date.now());
        }
        setShowModal(false);
    };

    const openPopover = (e: React.MouseEvent<Element, MouseEvent>) => {
        // e.persist(); // No longer needed in React 17+
        setAddElementPopoverState({ showPopover: true, event: e });
    };
    const closePopover = () => {
        setAddElementPopoverState({ showPopover: false, event: undefined });
    };

    const addElement = (id: string) => {
        nRef.current++;
        let properties = {
            n: nRef.current,
            id: id,
            width: 100
        };
        for (let i = formElements.length - 1; i >= 0; i--) {
            if (formElements[i].id === id && formElements[i].width !== undefined) {
                properties.width = formElements[i].width as number;
                break;
            }
        }
        setFormElements([...formElements, Object.assign(
            properties,
            elementDefaults[id]
        )]);
        closePopover();
    };

    const updateElement = (element: CustomFormElementType) => {
        const _elements = [...formElements];
        for (let i = 0; i < _elements.length; i++) {
            if (_elements[i].n === element.n) {
                _elements[i] = element;
                setFormElements(_elements);
                setSelectedElement(element);
                return;
            }
        }
    };

    const removeElement = (element: CustomFormElementType) => {
        const _elements = [...formElements];
        for (let i = 0; i < _elements.length; i++) {
            if (_elements[i].n === element.n) {
                _elements[i] = element;
                _elements.splice(i, 1);
                setFormElements(_elements);
                return;
            }
        }
    };

    const canMoveElement = (element: CustomFormElementType, movement: number) => {
        for (let i = 0; i < formElements.length; i++) {
            if (formElements[i].n === element.n) {
                const newIndex = i + movement;
                return !(
                    newIndex < 0 ||
                    newIndex >= formElements.length ||
                    formElements[newIndex].isSpecial
                );
            }
        }
        return false;
    }

    const moveElement = (element: CustomFormElementType, movement: number) => {
        if (!canMoveElement(element, movement)) {
            return;
        }
        const _elements = [...formElements];
        for (let i = 0; i < _elements.length; i++) {
            if (_elements[i].n === element.n) {
                const newIndex = i + movement;
                _elements[i] = _elements[newIndex];
                _elements[newIndex] = element;
                setFormElements(_elements);
                return;
            }
        }
    };

    const isDirty = useMemo(() => {
        isDirtyRef.current = haveObjectsChanged(formElements, initialFormElements);
        return isDirtyRef.current;
    }, [formElements, initialFormElements]);

    const attemptDismiss = useCallback(() => {
        if (isDirtyRef.current) {
            confirmAction(
                'Are you sure you want to close the builder?',
                'Yes, close',
                'Cancel',
                undefined,
                'Closing will mean losing any changes you have made.'
            ).then(() => {
                setShowModal(false);
            }).catch(() => {});
        } else {
            setShowModal(false);
        }
    }, [setShowModal]);

    return (
        <IonModal
            isOpen={showModal}
            mode="ios"
            showBackdrop={false}
            backdropDismiss={false}
            className={`sea-modal builder`}
            onDidPresent={onDidPresent}
            onDidDismiss={onDidDismiss}
        >
            <div className="columns top-bar">
                <div className="title">
                    <div className="columns">
                        <div className="builder-box">
                            <div className="builder-icon">
                                <SeaIcon icon="build"/>
                            </div>
                            <div className="builder-name">
                                FORM<br/>BUILDER
                            </div>
                        </div>
                        <div>
                            <h2>{title}</h2>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="sea-modal-x" onClick={(e) => attemptDismiss()}>
                        <SeaIcon icon="close"/>
                    </div>
                </div>
            </div>
            <SeaScrollableArea
                className="main-panel"
                onClick={(e) => {
                    setSelectedElement(undefined)}
                }
            >
                <div>
                    <div style={{ padding: '20px 16px 50px' }}>
                        <div className="custom-form-container">
                            {formElements.map((element) => {
                                return (
                                    <div key={element.n} style={{ width: `${element.width}%`}}>
                                        <CustomFormElement
                                            element={element}
                                            mode="edit"
                                            selectedElement={selectedElement}
                                            onSelectElement={(element) => {
                                                setSelectedElement(element);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="sea-add-new-button no-select" style={{marginTop: '16px'}}>
                            <SeaButton zone="white" shape="circle" onClick={(e) => openPopover(e)}>
                                <SeaIcon slot="icon-only" icon="add" />
                            </SeaButton>
                            <div className="text" onClick={(e) => openPopover(e)}>
                                Add New Element
                            </div>
                        </div>
                        <div className={isDirty ? 'reveal' : 'conceal'} style={{ marginTop: '50px' }}>
                            <SeaButton onClick={(e) => onSaveChanges()}>
                                {confirmationText}
                            </SeaButton>
                        </div>
                    </div>
                    <IonPopover
                        event={addElementPopoverState.event}
                        isOpen={addElementPopoverState.showPopover}
                        onDidDismiss={() => closePopover()}
                    >
                        <IonList className="account-actions" lines="none">
                            <IonItem button color="primary" onClick={(e) => addElement('input')}>Text Field</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('textarea')}>Text Area</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('checkbox')}>Checkbox</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('dropdown')}>Dropdown</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('date')}>Date</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('datetime')}>Date with time</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('yesno')}>Yes | No</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('checks')}>Checklist (Pass|Fail|NA)</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('files')}>Images / Documents</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('signature')}>Signature</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('heading')}>Heading</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('text')}>Text</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('line')}>Line</IonItem>
                            <IonItem button color="primary" onClick={(e) => addElement('spacer')}>Spacer</IonItem>
                        </IonList>
                    </IonPopover>
                </div>
            </SeaScrollableArea>
            <div className="editor-panel">
                <div style={{ maxWidth: '500px', padding: '16px' }}>
                    <BuildCustomFormElementPanel
                        element={selectedElement}
                        updateElement={updateElement}
                        removeElement={removeElement}
                        moveElement={moveElement}
                        canMoveElement={canMoveElement}
                        historyElementN={historyElementN}
                        setHistoryElementN={setHistoryElementN}
                    />
                </div>
            </div>
        </IonModal>
    );

};

export default BuildCustomForm;
