import { formatTimeWithMillis } from "../../lib/datesAndTime";
import { sharedState, SharedStateConfig } from "../shared-state";

//
// Debugging differs from console logging in the following ways:
//
// 1. It is for displaying within the app (usually within the debug panel) so it is accessible when using a device (i.e. don't need development console).
// 2. It is categorised so different types of logs can be grouped together
// 3. It is timestamped (by default) to help see when things happened
// 4. To stop crashing the device the amount of output stored is limited. When the limit is reached, the middle logs will be dropped.
//

const halfMaxLoggingCharacters = 50000;

export type Debugging = {
    [category: string]: [string, string] // 1st string = oldest logging, 2nd = newest logging
};

export const debuggingConfig: SharedStateConfig<Debugging> = {
    isAlwaysActive: true,
    default: {},
    notes: 'Used to store debugging that can be viewed within the debug panel'
};

export const debugApp = (category: string, line: string, object?: object, includeTime = true) => {
    console.log(`[${category}] ${line}`, object);
    sharedState.debugging.set((current) => {
        const o = {...current as Debugging};
        if (o[category] === undefined) {
            o[category] = ['',''];
        }

        let newLogging = `${includeTime ? `[${formatTimeWithMillis()}]` : ''} ${line}\n`;
        if (object) {
            newLogging += `${JSON.stringify(object, undefined, '  ')}\n`;
        }

        if (o[category][0].length >= halfMaxLoggingCharacters) {
            // Oldest logging has been filled up
            o[category][1] += newLogging;
            while (o[category][1].length > halfMaxLoggingCharacters) {
                // Newest logging has got too big
                o[category][1] = o[category][1].substring(o[category][1].length - halfMaxLoggingCharacters);
                // try {
                //     o[category][1] = o[category][1].substring(
                //         o[category][1].indexOf('\n')
                //     );
                // } catch(e) {
                //     o[category][1] = '';
                // }
            }
        } else {
            // Still room to fill in top half
            o[category][0] += newLogging;
        }
        return o;
    });
};

export const renderDebugging = (category: string) => {
    if (sharedState.debugging.current?.[category]) {
        const newest = sharedState.debugging.current[category][1];
        if (newest.length >= halfMaxLoggingCharacters) {
            return `${sharedState.debugging.current[category][0]}\n...\n\n${newest}`;
        }
        return `${sharedState.debugging.current[category][0]}${newest}`;
    }
    return '';
};
