import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import { IonSelectOption } from '@ionic/react';
import { CategoriesData, extractHeadingFromCategoryName, renderCategoryName } from "../../lib/categories";
import SeaInputError from '../SeaInputError/SeaInputError';
import SeaSelect from '../SeaSelect/SeaSelect';
import SeaInput from '../SeaInput/SeaInput';
import './SeaSelectCategory.css';

interface SeaSelectCategoryProps {
    categories: CategoriesData | undefined,
    initialCategoryId?: string, // either a categoryId or a new category name
    categoryId?: string, // either a categoryId or a new category name
    zone?: 'blue' | 'white' | 'grey',
    name?: string,
    label?: string,
    placeholder?: string,
    otherPlaceholder?: string,
    error?: string,
    disabled?: boolean,
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onAddNew?: (visible?: boolean) => void
}

const SeaSelectCategory: React.FC<SeaSelectCategoryProps> = ({
    categories,
    zone,
    name,
    label,
    categoryId,
    initialCategoryId,
    placeholder,
    otherPlaceholder,
    error,
    disabled,
    onchange,
    onblur,
    onfocus,
    onAddNew
}) => {
    const selectValueRef = useRef(categoryId);
    const isNewCategoryRef = useRef(false);
    const [orphanCategoryId, setOrphanCategoryId] = useState<string>();
    const customRef = useRef<HTMLInputElement>(null);
    const categoryIdRef = useRef(initialCategoryId);
    const [, forceUpdate] = useState({});

    // Create an orphan category if needed
    useEffect(() => {
        if (initialCategoryId === '') {
            selectValueRef.current = initialCategoryId;
        } else if (initialCategoryId && categories) {
            if (categories?.byId[initialCategoryId]) {
                if (categories.byId[initialCategoryId].state !== 'active') {
                    // Not in options, so let's create a special option just for this value
                    setOrphanCategoryId(initialCategoryId);
                }
                selectValueRef.current = initialCategoryId;
            } else {
                selectValueRef.current = '_new';
                isNewCategoryRef.current = true;
                const input = (customRef.current as any).inputRef.current;
                input.value = initialCategoryId;
            }
        }
        forceUpdate({});
    }, [initialCategoryId, categories]);

    useEffect(() => {
        if (
            categoryId &&
            categories &&
            categories?.byId[categoryId]
        ) {
            selectValueRef.current = categoryId;
        } else if (categoryId === '') {
            selectValueRef.current = '';
        }
    }, [categoryId, categories]);

    const onSelectChange = useCallback((e: CustomEvent) => {
        // Prevent re-triggering if the value hasn't actually changed
        if (e.detail.value === '' && isNewCategoryRef.current) {
            return;
        }

        const newValue = e.detail.value === '_new' ? '' : e.detail.value;
        selectValueRef.current = e.detail.value;
        categoryIdRef.current = e.detail.value;

        if (e.detail.value === '_new') {
            isNewCategoryRef.current = true;
            onAddNew?.(true);
            if (customRef.current) {
                (customRef.current as any).focus();
            }
        } else {
            isNewCategoryRef.current = false;
            onAddNew?.(false);
        }
        
        // Create single synthetic event
        const syntheticEvent = new CustomEvent('change', {
            detail: {
                value: newValue,
                name: name
            },
            bubbles: true
        });
        
        Object.defineProperty(syntheticEvent, 'target', {
            writable: false,
            value: {
                name: name,
                value: newValue,
                type: 'change'
            }
        });

        // Wrap in setTimeout to break the potential circular update chain
        setTimeout(() => {
            onchange?.(syntheticEvent);
            forceUpdate({});
        }, 0);
        
    }, [onchange, name, onAddNew]);

    let currentHeading = '';
    return (
        <>
            <SeaSelect
                zone={zone}
                name={`${name}_select`}
                label={label}
                disabled={disabled}
                value={selectValueRef.current}
                placeholder={placeholder}
                onchange={onSelectChange}
                warn={error ? true : false}
            >
                <IonSelectOption value="">Not Set</IonSelectOption>
                {orphanCategoryId &&
                    <IonSelectOption value={orphanCategoryId}>{renderCategoryName(orphanCategoryId, categories)}</IonSelectOption>
                }
                {categories?.ids?.map((id: string) => {
                    if (categories.byId[id].state === 'active') {
                        const heading = extractHeadingFromCategoryName(categories.byId[id].name);
                        if (heading !== currentHeading) {
                            currentHeading = heading;
                            return (
                                <React.Fragment key={id}>
                                    <IonSelectOption className="sea-select-category-heading" disabled key={`${id}_heading`} >{heading}</IonSelectOption>
                                    <IonSelectOption key={id} value={id}>{renderCategoryName(id, categories)}</IonSelectOption>
                                </React.Fragment>
                            );
                        }
                        return (
                            <IonSelectOption key={id} value={id}>{renderCategoryName(id, categories)}</IonSelectOption>
                        );
                    }
                    return undefined;
                })}
                <IonSelectOption value="_new">Add New...</IonSelectOption>
            </SeaSelect>
            <div className={`sea-select-input ${isNewCategoryRef.current ? 'show' : ''}`}>
                <div style={{ height: '4px' }}></div>
                <SeaInput
                    ref={customRef}
                    name={name}
                    placeholder={otherPlaceholder}
                    value={categoryId}
                    onchange={(e) => {
                        if (isNewCategoryRef.current) {
                            onchange?.(e);
                        }
                    }}
                    onblur={onblur}
                    onfocus={onfocus}
                    zone={zone}
                    type="text"
                    inputmode="text"
                    warn={error ? true : false}
                />
            </div>
            <SeaInputError>{error}</SeaInputError>
        </>
    );
};

export default SeaSelectCategory;
