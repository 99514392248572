import React from 'react';
import { appActivityFields } from '../../shared-state/General/appActivity';
import { sharedState } from '../../shared-state/shared-state';
import { formatDatetime } from '../../lib/datesAndTime';
import './AppActivityViewer.css';

interface AppActivityViewerProps {
    appActivityJson?: string,
    overrideLatestTime?: number
}

const activityTypes = {
    isActive: {
        bgColor: '#99e48d',
        format: (value: any) => (value === 'true' || value === true) ? 'App to FOREGROUND' : 'App to BACKGROUND'
    },
    isOnline: {
        bgColor: '#8db2e4',
        format: (value: any) => (value === 'true' || value === true) ? 'Went ONLINE' : 'Went OFFLINE'
    },
    fileCached: {
        bgColor: '#ccc',
        format: (value: any) => `Cached file: ${value.toLocaleString('en-US')} bytes`
    },
    fileUploaded: {
        bgColor: '#e48dc2',
        format: (value: any) => `Uploaded file: ${value}`
    },
    fullSizeFileLoaded: {
        bgColor: '#8de4d7',
        format: (value: any) => `Loaded full size file: ${value}`
    },
    page: {
        bgColor: '#c28de4',
        format: (value: any) => `Visited page: ${value}`
    },
    modal: {
        bgColor: '#e48d8d',
        format: (value: any) => `Opened modal: ${value}`
    },
    action: {
        bgColor: '#e4d6ad',
        format: (value: any) => value
    }
} as {
    [key: string]: {
        bgColor: string,
        format: (value: any) => string
    }
};

const formatTimeAgo = (millis: number) => {
    const hours = Math.floor(millis / (60 * 60 * 1000));
    millis -= hours * (60 * 60 * 1000);
    const minutes = Math.floor(millis / (60 * 1000));
    millis -= minutes * (60 * 1000);
    const seconds = Math.floor(millis / 1000);
    millis -= seconds * 1000;
    return `-${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}.${millis < 100 ? '0' : ''}${millis < 10 ? '0' : ''}${millis}`;
};

const AppActivityViewer: React.FC<AppActivityViewerProps> = ({ appActivityJson, overrideLatestTime }) => {

    const appActivity = appActivityJson ? JSON.parse(appActivityJson) : sharedState.appActivity.use();

    const items: { when: number, type: string, value: any }[] = [];

    let latestTime = 0;
    appActivityFields.forEach((field) => {
        if (activityTypes[field]) {
            const timeData = appActivity[field];
            if (timeData) {
                Object.keys(timeData).forEach((key: any) => {
                    if (key > latestTime) {
                        latestTime = key;
                    }
                    items.push({
                        when: Number(key),
                        type: field,
                        value: timeData[key]
                    });
                });
            }
        }
    });

    items.sort((a, b) => {
        return b.when - a.when;
    });

    if (overrideLatestTime && overrideLatestTime > latestTime) {
        latestTime = overrideLatestTime;
    }

    if (!appActivityJson || latestTime === 0) {
        latestTime = Date.now();
    }

    return (
        <>
            <div className="app-activity">
                <div style={{ fontWeight: 'bold', padding: '0px 4px 8px 8px' }}>
                    {formatDatetime(latestTime, ' ')}
                </div>
                {items.map((item) => {
                    const type = activityTypes[item.type];
                    return <div key={`${item.when}${item.value}`} className="item-row">
                        <div className="item-time">
                            {formatTimeAgo(latestTime - item.when)}
                        </div>
                        <div className="item-value" style={{ backgroundColor: type.bgColor, color: '#000' }}>
                            {type.format(''+item.value)}
                        </div>
                    </div>
                })}
            </div>
        </>
    );
};

export default AppActivityViewer;
