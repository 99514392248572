import React, { useCallback } from 'react';
import { DeleteableDocument, SharedStateConfig, sharedState } from '../../shared-state/shared-state';
import { IonAlert } from '@ionic/react';
import { FieldValue, WriteBatch, doc, serverTimestamp } from 'firebase/firestore';
import { SplittableBatch, firestore, splittableBatch } from '../../lib/firebase';
import { LicenseeDataSyncCollection, VesselDataSyncCollection, licenseeCollectionsToDataSync, vesselCollectionsToDataSync } from '../../shared-state/DataSyncSystem/dataSyncTasks';
import { logAction } from '../../shared-state/General/actionLog';
import { showToast } from '../ToastManager/ToastManager';
import { makeBatchTrace } from '../ErrorsManager/ErrorsManager';
import './ConfirmDialogManager.css';

//
// Handles asking the user to confirm a particular action
//

interface ExtendedDeleteableDocument extends DeleteableDocument {
    state: 'deleted',
    touched: FieldValue
}

export type ConfirmDialog = {
    show: boolean,
    header?: string,
    confirmText: string,
    cancelText: string,
    subHeader?: string,
    message?: string,
    onConfirmed: () => void,
    onCancelled: () => void
};

export const confirmDialogConfig: SharedStateConfig<ConfirmDialog> = {
    isAlwaysActive: true,
    default: {
        show: false,
        confirmText: 'Yes',
        cancelText: 'Cancel',
        onConfirmed: () => undefined,
        onCancelled: () => undefined
    },
    notes: 'Allows components to request confirmation from the user for a particular action.'
};

export const confirmAction = (
    header = 'Are you sure?',
    confirmText?: string,
    cancelText?: string,
    subHeader?: string,
    message?: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        sharedState.confirmDialog.set({
            show: true,
            header,
            confirmText: confirmText ?? 'Yes',
            cancelText: cancelText ?? 'Cancel',
            subHeader,
            message,
            onConfirmed: () => {
                console.log('confirmed!');
                resolve('confirmed');
            },
            onCancelled: () => {
                console.log('cancelled!');
                reject();
            }
        });
    });
};

export const deleteIfConfirmed = (
    collectionName: VesselDataSyncCollection | LicenseeDataSyncCollection,
    itemId: string,
    onDeleted: (batch: WriteBatch | SplittableBatch) => void,
    itemType = 'item',
    itemDetail = '',
    vesselIds: string[] | undefined = undefined,
    personnelIds: string[] | undefined = undefined,
    confirmHeader = undefined,
    confirmText = undefined,
    confirmCancel = 'Cancel'
): Promise<any> => {
    return confirmAction(
        confirmHeader ? confirmHeader : `Are you sure you want to delete this ${itemType}?`,
        confirmText ? confirmText : `Yes, delete`,
        confirmCancel,
        undefined,
        undefined
    ).then(() => {
        const batch = splittableBatch(firestore, 20 - 0);
        const batchTrace = makeBatchTrace(batch, collectionName, 'delete', itemId);
        const data = {
            state: 'deleted',
            whenDeleted: Date.now(),
            deletedBy: sharedState.userId.current,
        } as ExtendedDeleteableDocument ;
        if (
            vesselCollectionsToDataSync.includes(collectionName as VesselDataSyncCollection) ||
            licenseeCollectionsToDataSync.includes(collectionName as LicenseeDataSyncCollection)
        ) {
            data.touched = serverTimestamp();
        }
        batch.set(
            doc(firestore, collectionName, itemId),
            data,
            { merge: true }
        );

        logAction(
            batch,
            'Delete',
            collectionName,
            itemId,
            itemDetail,
            vesselIds,
            personnelIds
        );

        if (onDeleted) {
            onDeleted(batch);
        }

        batchTrace.data = {
            itemDetail
        };
        batchTrace.save(`Delete confirmed ${collectionName}.${itemId}`);
        batch.commit().then(() => {
            batchTrace.reportSuccess();
        }).catch((error) => {
            batchTrace.reportError(error.message, error);
        });

        showToast(`${itemType[0].toUpperCase()}${itemType.substring(1)} has been deleted`);
    }).catch((error) => {
        console.log(`Delete cancelled for ${collectionName}.${itemId}`);
        // Delete was cancelled, therefore do nothing
    });
};

const ConfirmDialogManager: React.FC = () => {
    const confirmDialog = sharedState.confirmDialog.use()!;

    const onCloseConfirm = useCallback((e: any) => {
        sharedState.confirmDialog.set((current) => {
            return {
                ...current,
                show: false
            } as ConfirmDialog;
        });
        if (e.detail.role !== 'cancel') {
            confirmDialog.onConfirmed();
        } else {
            confirmDialog.onCancelled();
        }
    }, [confirmDialog]);

    return <IonAlert
        isOpen={confirmDialog.show}
        header={confirmDialog.header}
        buttons={[confirmDialog.cancelText, confirmDialog.confirmText]}
        backdropDismiss={false}
        onDidDismiss={onCloseConfirm}
    />
};

export default ConfirmDialogManager;
