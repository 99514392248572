import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { Engine } from './engines';
import { firestore } from '../../lib/firebase';

export type EnginesByVesselData = {
    all: Engine[], // All active sorted by name
    byId: {
        [id: string]: Engine
    },
    byVesselId: {
        [id: string]: Engine[]
    },
};

export const useEnginesFromVessels = (vesselIds: string[] | undefined) => {
    const [enginesData, setEnginesData] = useState<EnginesByVesselData>();

    useEffect(() => {
        setEnginesData(undefined);
        if (!vesselIds || vesselIds.length === 0) {
            return;
        }

        return onSnapshot(
            query(
                collection(firestore, 'engines'),
                where('vesselId', 'in', vesselIds),
                where('state', '==', 'active')
            ),
            (snapshot) => {
                const byId: { [id: string]: Engine } = {};
                const byVesselId: { [id: string]: Engine[] } = {};
                const all: Engine[] = [];
                snapshot.forEach((doc) => {
                    const engine = {
                        id: doc.id,
                        ...doc.data(),
                    } as Engine;

                    byId[engine.id] = engine;
                    if (byVesselId[engine.vesselId] === undefined) {
                        byVesselId[engine.vesselId] = [];
                    }
                    byVesselId[engine.vesselId].push(engine);
                    all.push(engine);
                });
                setEnginesData({
                    all,
                    byId,
                    byVesselId,
                });
            },
            (error) => {
                console.error('Error fetching engines:', error);
            }
        );
    }, [vesselIds]);

    return enginesData;
};