import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { IonInput } from '@ionic/react';
import { SeaHelp } from '../SeaContextualHelp/SeaContextualHelp';
import SeaLabel from '../SeaLabel/SeaLabel';
import SeaInputError from '../SeaInputError/SeaInputError';
import './SeaInput.css';

interface SeaInputProps {
    zone?: 'blue' | 'white' | 'grey',
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search',
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
    name?: string,
    label?: string,
    help?: SeaHelp,
    value?: string | number,
    placeholder?: string,
    error?: JSX.Element | string,
    warn?: boolean,
    style?: any,
    disabled?: boolean,
    minLength?: number,
    maxLength?: number,
    max?: string,
    readonly?: boolean,
    required?: boolean,
    debounce?: number,
    prefix?: string,
    suffix?: React.ReactNode,
    enterkeyhint?: 'done' | 'enter' | 'go' | 'next' | 'previous' | 'search' | 'send',
    autocapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters',
    autocomplete?: 'on' | 'off' | 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'email' | 'username' | 'new-password' | 'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-extension' | 'impp' | 'url' | 'photo',
    id?: 'email' | 'pwd' | string;
    onchange?: (e: CustomEvent) => void,
    onblur?: (e: CustomEvent) => void,
    onfocus?: (e: CustomEvent) => void,
    onkeyup?: (e: CustomEvent) => void,
    setFocus?: () => Promise<void> // Call to focus the input
}

const SeaInput = React.forwardRef<HTMLInputElement, SeaInputProps>(({
    zone,
    inputmode,
    type,
    name,
    label,
    help,
    value,
    placeholder,
    error,
    warn,
    style,
    disabled,
    minLength,
    maxLength,
    max,
    readonly,
    required,
    debounce,
    prefix,
    suffix,
    enterkeyhint,
    autocapitalize = 'sentences',
    autocomplete,
    id,
    onchange,
    onblur,
    onfocus,
    onkeyup
}, forwardedRef:any) => {
    const isMounted = useRef(false);
    const inputRef = useRef<HTMLIonInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    const focus = useCallback(() => {
        setTimeout(() => {
            if (!isMounted.current) return;
            if (inputRef?.current) {
                inputRef.current.setFocus();
                setIsFocused(true);
            }
        }, 600);
    }, []);

    // Provide callable method focus() to parent
    useImperativeHandle(forwardedRef, () => ({
        focus,
        inputRef: inputRef
    }));

    const handleChange = useCallback((e: CustomEvent) => {
        if (onchange) {
            onchange(e);
        }
        // Manually trigger a change event on the native input element
        // if (inputRef.current) {
        //     const nativeInput = inputRef.current.getInputElement();
        //     if (nativeInput) {
        //         nativeInput.then((element) => {
        //             const nativeEvent = new Event('input', { bubbles: true });
        //             Object.defineProperty(nativeEvent, 'target', { value: element, enumerable: true });
        //             element.dispatchEvent(nativeEvent);
        //         });
        //     }
        // }
    }, [onchange]);

    const handleKeyUp = (e: CustomEvent) => {
        if (onkeyup) {
            onkeyup(e);
        }
    }
    const onBlur = useCallback((e: CustomEvent) => {
        // This is a hack to get autofill to work
        // Makes sure the input's value is correct upon the onblur event
        if (e?.detail?.target?.value) {
            setTimeout(() => {
                if (!isMounted.current) return;
                if (inputRef.current) {
                    inputRef.current.value = e.detail.target.value;
                    const event = new Event("input", { bubbles: true });
                    inputRef.current.dispatchEvent(event);
                }
            }, 50);
        }
        if (onblur) {
            onblur(e);
        }
        setIsFocused(false);
    }, [onblur]);

    const onFocus = (e: CustomEvent) => {
        if (onfocus) {
            onfocus(e);
        }
        setIsFocused(true);
    };

    let color = 'primary';
    switch (zone) {
        case 'blue':
            color = 'light'; // white
            break;
        case 'white':
        case 'grey':
            color = 'primary'; // blue
            break;
    }

    if (readonly) {
        return <div className="element view-only">
            {label && <SeaLabel>{label}</SeaLabel>}
            <div className="sea-input view-only-input light">
                {value}
            </div>
        </div>;
    }

    return (
        <>
            {label && <SeaLabel help={help}>{label}</SeaLabel>}
            {suffix ? (
                <div className="sea-input-wrapper">
                    <IonInput
                        id={id}
                        className={`sea-input ${zone}-zone${(error || warn) ? ' has-error' : ''}${disabled ? ' disabled' : ''}${prefix ? ' has-prefix' : ''}${suffix ? ' has-suffix' : ''}`}
                        //ref={forwardedRef}
                        ref={inputRef}
                        color={color}
                        inputmode={inputmode}
                        type={type}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        minlength={minLength}
                        maxlength={maxLength}
                        max={max}
                        readonly={readonly}
                        required={required}
                        debounce={debounce}
                        enterkeyhint={enterkeyhint}
                        autocapitalize={autocapitalize}
                        autocomplete={autocomplete}
                        onIonChange={onchange}
                        onIonBlur={onBlur}
                        onIonFocus={onFocus}
                        onIonInput={handleKeyUp}
                        style={style}
                        clearInput
                    >
                        {prefix &&
                            <span className="prefix">
                                {prefix}
                            </span>
                        }
                    </IonInput>
                    {suffix &&
                        <div className={`suffix${isFocused ? ' has-focus' : ''}${(error || warn) ? ' has-error' : ''}${disabled ? ' disabled' : ''}`}>
                            {suffix}
                        </div>
                    }
                </div>
            ) : (
                <IonInput
                    className={`sea-input ${zone}-zone${(error || warn) ? ' has-error' : ''}${disabled ? ' disabled' : ''}${prefix ? ' has-prefix' : ''}${suffix ? ' has-suffix' : ''}`}
                    //ref={forwardedRef}
                    ref={inputRef}
                    color={color}
                    inputmode={inputmode}
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    minlength={minLength}
                    maxlength={maxLength}
                    max={max}
                    readonly={readonly}
                    required={required}
                    debounce={debounce}
                    enterkeyhint={enterkeyhint}
                    autocapitalize={autocapitalize}
                    autocomplete={autocomplete}
                    onIonChange={handleChange}
                    onIonBlur={onBlur}
                    onIonFocus={onFocus}
                    onIonInput={handleKeyUp}
                    style={style}
                >
                    {prefix &&
                        <span className="prefix">
                            {prefix}
                        </span>
                    }
                </IonInput>
            )}
            <SeaInputError>{error}</SeaInputError>
        </>
    );
});

export default SeaInput;
