import React, { useState, useEffect, Profiler, useMemo } from 'react';
import { logPageView } from '../../../lib/firebase';
import { extractSearchTerms, formatValue } from '../../../lib/util';
import { formatDate } from '../../../lib/datesAndTime';
import { usePageLimiter } from '../../../hooks/usePageLimiter';
import { permissionLevels } from '../../../shared-state/Core/userPermissions';
import { onProfilerRender } from '../../../lib/performance';
import { sharedState } from '../../../shared-state/shared-state';
import { renderFullName } from '../../../shared-state/Core/users';
import { UserType } from '../../../shared-state/Core/user';
import SeaExporter, { ExportType } from '../../../components/SeaExporter/SeaExporter';
import SeaButton from '../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../components/SeaIcon/SeaIcon';
import ArchiveUserModal from '../../../modals/Crew/CrewParticulars/ArchiveUserModal/ArchiveUserModal'
import CrewSettingsModal from '../../../modals/Crew/CrewParticulars/CrewSettingsModal/CrewSettingsModal'
import SeaNoData from '../../../components/SeaNoData/SeaNoData';
import RequirePermissions from '../../../components/RequirePermissions/RequirePermissions';
import EditUserModal from '../../../modals/Crew/CrewParticulars/EditUserModal/EditUserModal';
import ViewUserModal from '../../../modals/Crew/CrewParticulars/ViewUserModal/ViewUserModal';
import SeaSearchbar from '../../../components/SeaSearchbar/SeaSearchbar';
import CrewParticularsListPdf from '../../../exports/Crew/CrewParticulars/CrewParticularsListPdf';
import CrewParticularsCsv from '../../../exports/Crew/CrewParticulars/CrewParticularsCsv';
import './CrewParticulars.css';

interface CrewParticularsProps {
    visible: boolean
}

const CrewParticulars: React.FC<CrewParticularsProps> = ({ visible }) => {
    const users = sharedState.users.use(visible ? 1 : 101);
    const userDetails = sharedState.userDetails.use(visible ? 1 : 101);
    sharedState.customForms.use(visible ? 102 : 0); // Prepare for ViewUser modal > forms and documents tab
    sharedState.trainingTasks.use(visible ? 103 : 0); // Prepare for ViewUser modal > training tab
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit, isLimitReached } = usePageLimiter();
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [searchText, setSearchText] = useState("");
    const [showArchiveUserModal, setShowArchiveUserModal] = useState(false);
    const [showCrewSettingsModal, setShowCrewSettingsModal] = useState(false);
    const [showViewUserModal, setShowViewUserModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [exportType, setExportType] = useState<ExportType>();


    useEffect(() => {
        if (visible) {
            logPageView('Crew/CrewParticulars');
        }
        resetPageLimit();
    }, [visible, resetPageLimit]);

    // Keep selectedUser fresh
    useEffect(() => {
        if (selectedUser?.id && users?.byId[selectedUser.id]) {
            setSelectedUser(users.byId[selectedUser.id])
        };
    }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

    const onAddNewUser = () => {
        setSelectedUser(undefined);
        setShowEditUserModal(true);
    };
    const onArchiveUsers = () => {
        setShowArchiveUserModal(true)
    };
    const onSettingsClick = () => {
        setShowCrewSettingsModal(true);
    }
    const onViewUser = (user: UserType) => {
        setSelectedUser(user);
        setShowViewUserModal(true);
    };

    const isUsingFilter = useMemo(() => {
        return searchText ? true : false;
    }, [searchText]);

    const filteredUsers = useMemo(() => {
        let _staff = users?.staff;
        let _nonStaff = users?.nonStaff;
        let _filteredStaff = [] as UserType[];
        let _filteredNonStaff = [] as UserType[];
        const terms = extractSearchTerms(searchText, true);
        if (_staff) {
            for (let i = 0; i < _staff.length; i++) {
                const _user = _staff[i];
                if (_user.id) {
                    const _userDetails = userDetails?.byId[_user.id];
                    if (terms.length > 0) {
                        let isMatch = true;
                        for (let j = 0; j < terms.length; j++) {
                            if (_userDetails?.searchText?.indexOf(terms[j]) === -1) {
                                isMatch = false;
                                break;
                            }
                        }
                        if (!isMatch) {
                            continue;
                        }
                    }
                    _filteredStaff.push(_user);
                }
            }
        }
        if (_nonStaff) {
            for (let i = 0; i < _nonStaff.length; i++) {
                const _user = _nonStaff[i];
                if (_user.id) {
                    const _userDetails = userDetails?.byId[_user.id];
                    if (terms.length > 0) {
                        let isMatch = true;
                        for (let j = 0; j < terms.length; j++) {
                            if (_userDetails?.searchText?.indexOf(terms[j]) === -1) {
                                isMatch = false;
                                break;
                            }
                        }
                        if (!isMatch) {
                            continue;
                        }
                    }
                    _filteredNonStaff.push(_user);
                }
            }
        }
        return {
            staff: _filteredStaff,
            nonStaff: _filteredNonStaff
        };
    }, [users?.staff, users?.nonStaff, searchText, userDetails?.byId]);


    return (
        <RequirePermissions
            role="crewParticulars"
            level={permissionLevels.VIEW}
            showDenial={true}
        >
            <div className="crew-particulars page-head">
                <div><h2>Crew</h2></div>
                <div className="actions">
                    <RequirePermissions
                        role="crewParticulars"
                        level={permissionLevels.CREATE}
                    >
                        <div className="spacer"></div>
                        <SeaButton onClick={(e) => onAddNewUser()} zone="grey">
                            <SeaIcon slot="start" icon="add" />
                            Add New User
                        </SeaButton>

                        <RequirePermissions
                            role="crewParticulars"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer"></div>
                            <SeaButton onClick={(e) => onArchiveUsers()} zone="grey">
                                Archived Users
                            </SeaButton>
                        </RequirePermissions>
                        <div className="spacer"></div>
                        <SeaExporter 
                            setExportType={setExportType} 
                            pdf={exportType === 'pdf' && (
                                <CrewParticularsListPdf
                                    onCompleted={() => setExportType(undefined)}
                                    filteredUsers={filteredUsers}
                                />
                            )}
                            csv={exportType === "csv" && <CrewParticularsCsv onCompleted={() => setExportType(undefined)} />} 
                        />
                        <div className="spacer"></div>
                        <RequirePermissions
                            role="crewParticulars"
                            level={permissionLevels.FULL}
                        >
                            <SeaButton onClick={onSettingsClick} zone="grey" shape="circle">
                                <SeaIcon slot="icon-only" icon="settings" />
                            </SeaButton>
                        </RequirePermissions>
                    </RequirePermissions>

                </div>
            </div>

            <div className={`columns wrap filters`}>
                <div>
                    <SeaSearchbar value={searchText} setValue={setSearchText} />
                </div>
            </div>

            <SeaNoData
                dataName="crew"
                isLoading={!users}
                hasNoData={filteredUsers.staff && filteredUsers.staff.length === 0 && filteredUsers.nonStaff && filteredUsers.nonStaff.length === 0}
                isUsingFilter={isUsingFilter}
            />

            <div className={`crew-particulars ${filteredUsers.staff.length || filteredUsers.nonStaff.length ? 'reveal' : 'conceal'}`}>
                <div className="sea-row headings">
                    <div>Name</div>
                    <div>Email</div>
                    <div>Job Title / Position</div>
                    <div>Inducted Date</div>
                    <div>Login Access</div>
                </div>
                <Profiler id="crew.particulars" onRender={onProfilerRender}>
                    {/* Crew/Staff Category */}
                    <div className="category-heading">Crew/Staff</div>
                    {mapArrayWithLimit(filteredUsers?.staff, (user: UserType) => {
                        return (
                            <div key={user.id} className="sea-card sea-row" onClick={(e) => onViewUser(user)}>
                                <div className="bold truncate">
                                    {renderFullName(user)}
                                    {user.isLicensee && <><br /><span style={{ color: 'var(--ion-color-danger)', textTransform: 'uppercase' }}> Licensee</span></>}
                                </div>
                                <div className="truncate">{user.id ? userDetails?.byId[user.id]?.email : ''}</div>
                                <div className="truncate">{formatValue(user.position)}</div>
                                <div>{formatValue(user.whenInducted ? formatDate(user.whenInducted) : '')}</div>
                                <div>{!user.isLoginDisabled ? 'Y' : 'N'}</div>
                            </div>
                        )
                    })}
                    {/* Non-staff Category */}
                    {!isLimitReached() && filteredUsers?.nonStaff && filteredUsers.nonStaff.length > 0 && <>
                        <div className="category-heading">Non-staff</div>
                        {mapArrayWithLimit(filteredUsers.nonStaff, (user: UserType) => {
                            return (
                                <div key={user.id} className="sea-card sea-row" onClick={(e) => onViewUser(user)}>
                                    <div className="bold truncate">{renderFullName(user)}</div>
                                    <div className="truncate">{user.id ? userDetails?.byId[user.id]?.email : ''}</div>
                                    <div className="truncate">{formatValue(user.position)}</div>
                                    <div></div>
                                    <div>{!user.isLoginDisabled ? 'Y' : 'N'}</div>
                                </div>
                            )
                        })}
                    </>}
                    {visible && limitTriggerElement}
                </Profiler>
            </div>

            {visible &&
                <>
                    <ArchiveUserModal
                        showModal={showArchiveUserModal}
                        setShowModal={setShowArchiveUserModal}
                    />
                    {showCrewSettingsModal && <CrewSettingsModal
                        showModal={showCrewSettingsModal}
                        setShowModal={setShowCrewSettingsModal}
                    />}
                    <ViewUserModal
                        showModal={showViewUserModal}
                        setShowModal={setShowViewUserModal}
                        selectedUser={selectedUser}
                    />
                    <EditUserModal
                        showModal={showEditUserModal}
                        setShowModal={setShowEditUserModal}
                        userToEdit={selectedUser}
                        level={2}
                    />
                   
                </>
            }
        </RequirePermissions>
    );
};

export default CrewParticulars;
