import { DateTime } from 'luxon';
import { renderFullNameForUserId } from '../shared-state/Core/users';
import { getFileNameFromString } from "./files";
import { DateTimeAcceptable, formatDatetimeForCsv, formatSeaDate } from './datesAndTime';
import { SparePart } from '../shared-state/VesselMaintenance/spareParts';
import { sharedState } from '../shared-state/shared-state';
import { Control } from '../shared-state/HealthSafety/risks';
import { ControlType } from '../modals/HealthSafety/RiskRegister/EditRiskAssessment/EditRiskAssessment';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CsvColumnBase<T> = {
    name: string;
};
  
export type CsvSingleColumn<T> = CsvColumnBase<T> & {
    value: (item: T) => string;
};
  
export type CsvMultiColumn<T, U> = CsvColumnBase<T> & {
    items: (item: T) => U[];
    columns: (item?: T) => CsvColumn<U, T>[];
};
  
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CsvColumn<T, U = undefined> = CsvSingleColumn<T> | CsvMultiColumn<T, any>;
  
export type CsvConfig<T> = CsvColumn<T>[];

export const formatCsvString = (value?: any): string => {
    if (!value) return '';
    if (typeof value !== 'string') value = value.toString();

    // Replace commas with fullwidth commas, escape double quotes, and replace line breaks with CRLF.
    return value
        .replace(/"/g, '""')       // Escape double quotes
        .replace(/\r\n|\n|\r/g, '\r\n'); // Replace line breaks
};

export const formatCsvDate = (date?: number) => {
    if (!date) return '';
    return formatDatetimeForCsv(date);
};

export const formatCsvFiles = (files?: string[]) => {
    if (!files?.length) return '';
    return files.map((file) => {
        return getFileNameFromString(file);
    }).join(', ');
};

export const formatCsvBoolean = (value: any) => {
    return value ? 'Y' : 'N';
};

export const formatCsvSignature = (value: any) => {
    return value ? 'Signed' : '';
};

export const renderCsvIntervalType = (value?: string) => {
    if (!value) return '';
    
    switch (value) {
        case 'weekMonth':
            return 'Week / Month';
        case 'engineHours':
            return 'Hours';
        case 'weekMonthAndHours':
            return 'Week / Month & Hours';
        default:
            return value;
    }
}

export const formatCsvStatus = (whenDue: DateTimeAcceptable | undefined, warnDays: number, hasFault?: boolean) => {
    if (!whenDue) return ''; // Return early if no due date

    const due = DateTime.fromISO(formatSeaDate(whenDue)); // Crunch day to match local timezone
    const today = DateTime.fromISO(formatSeaDate());
    const days = due.diff(today, ['days']).days;

    if (hasFault) return 'FAULT';
    if (days === 0) return 'Due Today';
    
    return `${days} day${(days > 1) ? 's' : ''}`;
}

export const formatCsvSpareParts = (sparePartsUsed: {[sparePartId: string]: { used?: number | undefined; added?: number | undefined; }} | undefined, spareParts: Record<string, SparePart> | undefined, format: 'used' | 'added' | 'both') => {
    const _format = format || 'both';
    if (!sparePartsUsed || !spareParts) return '';
    return Object.entries(sparePartsUsed)
        .map(([key, value]) => {
            const sparePart = spareParts[key];
            if (!sparePart) return '';
            const used = value.used || 0;
            const added = value.added || 0;
            if (_format === 'used' && used) return `${sparePart.item}: x${used}`;
            if (_format === 'added' && added) return `${sparePart.item}: x${added}`;
            return `${sparePart.item}: x${used}/x${added}`;
        }).join(', ');
}

export const formatCsvRisk = (riskRating: number | string, type: 'likelihoods' | 'consequences' = 'consequences') => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister) return '';
    return `${(licenseeSettings.riskRegister[type][`C${riskRating}`] as ControlType)?.name}`;
}

export const formatCsvRiskRating = (likelihoodId: number | string, consequenceId?: number | string) => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister?.matrix) return '';
    const raw = licenseeSettings.riskRegister.matrix[`${likelihoodId}-${consequenceId || 0}`] || '';
    return raw.substring(7);
}

export const formatCsvRiskDescription = (controls: Control) => {
    const licenseeSettings = sharedState.licenseeSettings.current;
    if (!licenseeSettings?.riskRegister?.consequences) return '';
    console.log('[][]', licenseeSettings.riskRegister.consequences[`C${Number(controls.likelihood) - Number(controls.consequence)}`]);
    return (licenseeSettings.riskRegister.consequences[`C${Number(controls.likelihood) - Number(controls.consequence)}`] as { name: string, description: string })?.description || '';
};


export const formatCsvNamesFromIds = (ids: string[] | undefined, collection?: Record<string, any>, key = 'name') => {
    if (!ids?.length || !collection) return '';
    return ids.map(
        (id) => {
            if (!collection[id]?.[key]) return '';
            return collection[id][key]
            // .replace(/[.,;:]$/, '')
        }
    ).join(', '); // trims any trailing periods
};

export const formatCsvUserNamesFromIds = (ids: string[] | undefined) => {
    if (!ids?.length) return '';
    return ids.map((id) => {
        const fullName = renderFullNameForUserId(id);
        return formatCsvString(fullName);
    }).join(',');
}

// Generate CSV object for exporting from data and columns
export const generateCsvObject = <T>(data: T[], columns: CsvColumn<T>[]) => {    
    const headers: { label: string; key: string }[] = [];
    const rows: { [key: string]: any; }[] = [];
    
    // Generate headers including multi column headers
    for (const column of columns) {
        if ('value' in column) {
            headers.push({ label: column.name, key: column.name });
        } else {
            // Multi column
            for (const subheader of column.columns()) {
                if ('name' in subheader) {
                    headers.push({ label: `${column.name}/${subheader.name}`, key: `${column.name}/${subheader.name}` });
                }
            }
        }
    }
    
    // Generate data rows
    data.forEach((item) => {
        const rowObject: { [key: string]: any } = {}; 
        const subRows: { [key: string]: any }[] = [];
        columns.forEach((column) => {
            if ('value' in column) {
                // Single column
                rowObject[column.name] = formatCsvString(column.value(item));
            } else {
                // Multi column
                const subItems = column.items(item);
                // Check if subitems is defined and is an array
                if (Array.isArray(subItems) && subItems.length > 0) {
                    const firstSubItem = subItems[0];
                    // Add first subItem values to rowObject
                    column.columns(item).forEach((subColumn) => {
                        if ('value' in subColumn) {
                            rowObject[column.name + '/' + subColumn.name] = formatCsvString(subColumn.value(firstSubItem));
                        }
                    });
                    // Add subsequent subItems to subRows
                    subItems.slice(1).forEach((subItem) => {
                        const subRow: { [key: string]: string } = {};
                        column.columns(item).forEach((subColumn) => {
                            if ('value' in subColumn) {
                                subRow[column.name + '/' + subColumn.name] = formatCsvString(subColumn.value(subItem));
                            }
                        });
                        subRows.push(subRow); // Push subRow to subRows
                    });
                } else {
                    rowObject[column.name] = {}; // Default to an empty object if subitems is not valid
                }
            }
        });
        // Add rowObject to rows
        rows.push(rowObject);
        // Add subRows to rows
        if (subRows.length > 0) {
            rows.push(...subRows);
        }
    });
    
    // Combine headers and data rows
    return {headers, rows};
}
