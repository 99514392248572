import { useMemo, useState } from "react";
import { CsvConfig, formatCsvBoolean, formatCsvDate, formatCsvString, renderCsvIntervalType } from "../../../lib/csv";
import { sharedState } from "../../../shared-state/shared-state";
import { ScheduledMaintenanceTask } from "../../../shared-state/VesselMaintenance/maintenanceSchedule";
import { renderCamelCase } from '../../../lib/util';
import { renderFullNameForUserId } from '../../../shared-state/Core/users';
import { DateRange } from '../../../components/SeaDateRange/SeaDateRange';
import { formatInterval } from "../../../lib/datesAndTime";
import { useVesselMaintenanceTasksCompleted } from '../../../shared-state/VesselMaintenance/useVesselMaintenanceTasksCompleted';
import { renderCategoryName } from '../../../lib/categories';
import SeaExporterDateRange from '../../../components/SeaExporter/SeaExporterDateRange';
import SeaCSVGenerator from '../../../components/SeaCSV/SeaCSVGenerator';

type MaintenanceScheduleCsvProps = {
    onCompleted: () => void
}

export const MaintenanceScheduleCsv = ({onCompleted}: MaintenanceScheduleCsvProps) => {
    const [dateRange, setDateRange] = useState<DateRange>();
    const scheduledMaintenanceTasks = sharedState.scheduledMaintenanceTasks.use(dateRange ? 1 : 0);
    const vesselSystems = sharedState.vesselSystems.use(dateRange ? 1 : 0);
    const vesselLocations = sharedState.vesselLocations.use(dateRange ? 1 : 0);
    const vesselMaintenanceTasksCompleted = useVesselMaintenanceTasksCompleted(dateRange);

    const csvConfig: CsvConfig<ScheduledMaintenanceTask> | undefined = useMemo(() => {
        if (!dateRange ||!scheduledMaintenanceTasks || !vesselMaintenanceTasksCompleted) {
            return undefined;
        }
        return [
            {
                name: 'System',
                value: (task) => renderCategoryName(task.equipment?.systemId, vesselSystems)
            },
            {
                name: 'Equipment',
                value: (task) => task.equipment?.equipment || ''
            },
            {
                name: 'Location',
                value: (task) => renderCategoryName(task.equipment?.locationId, vesselLocations)
            },
            {
                name: 'Task Title',
                value: (task) => task.task || ''
            },
            {
                name: 'Task Description',
                value: (task) => task.description || ''
            },
            {
                name: 'Interval Type',
                value: (task) => renderCsvIntervalType(task.intervalType)
            },
            {
                name: 'Interval Week/Hours',
                value: (task) => task.intervalType !== 'engineHours' && task.intervalWeekMonth ? formatInterval(task.intervalWeekMonth) : ''
            },
            {
                name: 'Maintenance Tags',
                value: (task) => (task.maintenanceTags || []).join(', ')
            },
            {
                name: 'Critical',
                value: (task) => formatCsvBoolean(task.isCritical)
            },
            {
                name: 'Next Due',
                value: (task) => formatCsvDate(task.whenDue)
            },
            {
                name: 'Status',
                value: (task) => renderCamelCase(task.state)
            },
            {
                name: 'Make',
                value: (task) => task.equipment?.make || ''
            },
            {
                name: 'Model',
                value: (task) => task.equipment?.model || ''
            },
            {
                name: 'Serial No.',
                value: (task) => task.equipment?.serial || ''
            },
            {
                name: 'Completed',
                items: (task) => vesselMaintenanceTasksCompleted.byMaintenanceId[task.id],
                columns: () => [
                    {
                        name: 'Date Completed',
                        value: (item: any) => formatCsvDate(item.whenCompleted)
                    },
                    {
                        name: 'Engine Hours',
                        value: (item: any) => item.engineHours ? `${item.engineHours} Hours` : ''
                    },
                    {
                        name: 'Completed By',
                        value: (item: any) => renderFullNameForUserId(item.completedBy)
                    },
                    {
                        name: 'Notes',
                        value: (item: any) => formatCsvString(item.notes)
                    }
                ]
            }

        ];
    }, [dateRange, scheduledMaintenanceTasks, vesselLocations, vesselMaintenanceTasksCompleted, vesselSystems]);

    if (!dateRange) {
        return (
            <SeaExporterDateRange onClose={onCompleted} onExport={setDateRange} />
        )
    }

    return <SeaCSVGenerator name={'Maintenance-Schedule'} onCompleted={onCompleted} config={csvConfig} data={scheduledMaintenanceTasks?.prioritised} />
};
