import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../../lib/util';
import { formatDate, warnDays } from '../../../../lib/datesAndTime';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { logModalView } from '../../../../lib/firebase';
import { useReportSettings } from '../../../../pages/Reporting/Reporting';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { GraphData } from '../../../../components/reporting/SeaHorizontalBarGraph/SeaHorizontalBarGraph';
import { VesselCertificate } from '../../../../shared-state/VesselDocuments/vesselCertificates';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaReportSettings from '../../../../components/SeaReportSettings/SeaReportSettings';
import SeaHorizontalStackedBarGraph from '../../../../components/reporting/SeaHorizontalStackedBarGraph/SeaHorizontalStackedBarGraph';
import reporting from '../../../../lib/reporting';
import SeaFileImage from '../../../../components/SeaFileImage/SeaFileImage';
import SeaStatusDueDate from '../../../../components/SeaStatusDueDate/SeaStatusDueDate';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import VesselCertificatesReportPdf from '../../../../exports/Reporting/Documents/VesselCertificatesReportPdf';
import './ViewVesselCertificatesReport.css';
import { ExportType } from '../../../../components/SeaExporter/SeaExporter';

const statusOptions = [
    {
        id: 'upcoming',
        name: 'Upcoming'
    },
    {
        id: 'overdue',
        name: 'Overdue'
    },
];

interface ViewVesselCertificatesReportProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    title: string,
    subTitle: string,
    graphData?: GraphData[],
    filteredItems?: VesselCertificate[],
    categories?: (string | undefined)[],
    selectedStatuses: string[],
    setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>,
}

const ViewVesselCertificatesReport: React.FC<ViewVesselCertificatesReportProps> = ({
    showModal,
    setShowModal,
    title,
    subTitle,
    graphData,
    filteredItems,
    categories,
    selectedStatuses,
    setSelectedStatuses,
}) => {
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const { selectedVesselId } = useReportSettings();
    const [exportType, setExportType] = useState<ExportType>();
    const [labelWidthsForPdf, setLabelWidthsForPdf] = useState<number[]>([]);

    useEffect(() => {
        if (showModal) {
            logModalView('Vessel Certificates Report', true);
        }
        resetPageLimit();
    }, [showModal, resetPageLimit]);


    return (
        <SeaModal
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
            size="wide"
            noContentPadding={true}
            onPdf={() => setExportType('pdf')}
        >
            <SeaReportSettings
                mode="modal"
                showDateRange={false}
                extraComponents={[
                    <SeaMultiSelect
                        label="Statuses"
                        values={selectedStatuses}
                        setValues={setSelectedStatuses}
                        options={statusOptions}
                        useAllOption={true}
                    />
                ]}
            />

            <SeaHorizontalStackedBarGraph
                n={0}
                mode="modal"
                visible={showModal}
                data={graphData}
                categories={categories}
                setLabelWidthsForPdf={setLabelWidthsForPdf}
                colourPalette={reporting.colours.taskStatuses}
            />

            <div style={{ height: '20px' }}></div>

            <div className="reporting-data">
                <SeaNoData
                    dataName="vessel certificates by vessel"
                    isLoading={!filteredItems}
                    hasNoData={filteredItems?.length === 0}
                    isUsingFilter={true}
                />
                <div className={`vessel-certificate-report has-thumbs has-status ${(filteredItems && filteredItems.length > 0) ? 'reveal' : 'conceal'} ${selectedVesselId ? 'hide-vessel' : ''}`}>
                    <div className="sea-row-history headings-history">
                        <div></div>
                        <div>Title</div>
                        <div>Expiry</div>
                        <div>Vessel</div>
                        <div>Status</div>
                    </div>
                    {mapArrayWithLimit(filteredItems, (item) => {
                        return (
                            <div
                                key={item.id}
                                className="sea-card sea-row-history"
                            >
                                <div>
                                    <SeaFileImage files={item.files} size="tiny"/>
                                </div>
                                <div className="bold truncate-2">
                                    {formatValue(item.title)}
                                </div>
                                <div>{formatDate(item.whenExpires)}</div>
                                <div className="truncate-2">
                                    {renderVesselName(item.vesselId)}
                                </div>
                                <div>
                                    <SeaStatusDueDate whenDue={item.whenExpires} warnDays={warnDays.vesselCertificates[0]}/>
                                </div>
                                
                            </div>
                        );
                    })}
                    {limitTriggerElement}
                </div>
            </div>

            {showModal && exportType === 'pdf' &&
                <VesselCertificatesReportPdf
                    onCompleted={() => setExportType(undefined)}
                    title={title}
                    subTitle={subTitle}
                    graphData={graphData}
                    filteredItems={filteredItems}
                    categories={categories}
                    labelWidthsForPdf={labelWidthsForPdf}
                />
            }
        </SeaModal>
    );
};

export default ViewVesselCertificatesReport;
