import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { $getRoot, $createParagraphNode, LexicalEditor, LexicalNode, createEditor } from 'lexical';
import { sharedState } from '../../../shared-state/shared-state';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
// import { AutoLinkPlugin} from '@lexical/react/LexicalAutoLinkPlugin';
// import { TreeView} from '@lexical/react/LexicalTreeView';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ImageNode } from '../nodes/ImageNode/ImageNode';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { TableContext} from '../plugins/LexTable/LexTable';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { SeaSectionNode } from '../nodes/SeaSectionNode/SeaSectionNode';
import { RichTextState } from '../../../lib/richText';
import { getSafeInsetTop } from '../../../lib/util';
import { SeaScrollable } from '../../SeaScrollableArea/SeaScrollableArea';
import { scrollPageToTop, scrollToTarget } from '../../../layouts/AppLayout/AppLayout';
import SeaIcon from '../../SeaIcon/SeaIcon';
import LexTableCellResizer from '../plugins/LexTableCellResizer/LexTableCellResizer';
import LexImages from '../plugins/LexImages/LexImages';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import SeaSelectRichTextHeading from '../SeaSelectRichTextHeading/SeaSelectRichTextHeading';
import './SeaRichText.css';

export const lexContentWidth = 760; // pixels that cover 21cm of A4 paper
export const lexMarginX = 28;
export const lexMarginTop = 20;
export const lexMarginBottom = 40;

// 19cm = 7.48031 inches. for 300DPI, need 2244.093
// therefore, 760 pixels on screen needs 2244.093 pixels of resolution for print
// therefore 1 screen pixel needs 2.952753947368421 pixels. Just use 3x ratio?
// so, approximately 3X pixels per screen pixel required.
// [] Do not convert SVG

//export const lexNodes: Array<Klass<LexicalNode>> = [
export const lexNodes: Array<any> = [
    //HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    // CodeNode,
    // NewTableNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    // HashtagNode,
    // CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    // OverflowNode,
    // PollNode,
    // StickyNode,
    ImageNode,
    // MentionNode,
    // EmojiNode,
    // ExcalidrawNode,
    // EquationNode,
    // AutocompleteNode,
    // KeywordNode,
    HorizontalRuleNode,
    // TweetNode,
    // YouTubeNode,
    // FigmaNode,
    // MarkNode,
    // CollapsibleContainerNode,
    // CollapsibleContentNode,
    // CollapsibleTitleNode,
    SeaSectionNode,
    {
        replace: HeadingNode,
        with: (node: HeadingNode): SeaSectionNode => {
            if (node.__tag === 'h1' || node.__tag === 'h2') {
                return new SeaSectionNode(node.__tag);
            } else {
                return node;
            }
        }
    }
];

export const lexicalTheme = {
    ltr: 'lex-ltr',
    rtl: 'lex-rtl',
    placeholder: 'lex-placeholder',
    paragraph: 'lex-paragraph',
    quote: 'lex-quote',
    heading: {
        h1: 'lex-heading-h1',
        h2: 'lex-heading-h2',
        h3: 'lex-heading-h3',
        h4: 'lex-heading-h4',
        h5: 'lex-heading-h5',
        h6: 'lex-heading-h6',
    },
    list: {
        nested: {
            listitem: 'lex-nested-listitem',
        },
        ol: 'lex-list-ol',
        ul: 'lex-list-ul',
        listitem: 'lex-listItem',
        listitemChecked: 'lex-listItemChecked',
        listitemUnchecked: 'lex-listItemUnchecked',
    },
    hashtag: 'lex-hashtag',
    image: 'lex-image',
    link: 'lex-link',
    text: {
        bold: 'lex-textBold',
        code: 'lex-textCode',
        italic: 'lex-textItalic',
        strikethrough: 'lex-textStrikethrough',
        subscript: 'lex-textSubscript',
        superscript: 'lex-textSuperscript',
        underline: 'lex-textUnderline',
        underlineStrikethrough: 'lex-textUnderlineStrikethrough',
    },
    code: 'lex-code',
    codeHighlight: {
        atrule: 'lex-tokenAttr',
        attr: 'lex-tokenAttr',
        boolean: 'lex-tokenProperty',
        builtin: 'lex-tokenSelector',
        cdata: 'lex-tokenComment',
        char: 'lex-tokenSelector',
        class: 'lex-tokenFunction',
        'class-name': 'lex-tokenFunction',
        comment: 'lex-tokenComment',
        constant: 'lex-tokenProperty',
        deleted: 'lex-tokenProperty',
        doctype: 'lex-tokenComment',
        entity: 'lex-tokenOperator',
        function: 'lex-tokenFunction',
        important: 'lex-tokenVariable',
        inserted: 'lex-tokenSelector',
        keyword: 'lex-tokenAttr',
        namespace: 'lex-tokenVariable',
        number: 'lex-tokenProperty',
        operator: 'lex-tokenOperator',
        prolog: 'lex-tokenComment',
        property: 'lex-tokenProperty',
        punctuation: 'lex-tokenPunctuation',
        regex: 'lex-tokenVariable',
        selector: 'lex-tokenSelector',
        string: 'lex-tokenSelector',
        symbol: 'lex-tokenProperty',
        tag: 'lex-tokenProperty',
        url: 'lex-tokenOperator',
        variable: 'lex-tokenVariable',
    }
};

export const scrollToSection = (tag: string, key: string, smooth = true) => {
    let target = document.getElementById(`${tag}_${key}`);
    //console.log(`scrollToSection tag=${tag} key=${key} smooth=${smooth} target`, target);
    if (
        tag === 'h2' &&
        target?.previousSibling &&
        (target.previousSibling as any).offsetHeight &&
        (target.previousSibling as any).offsetHeight < 50
    ) {
        target = (target as any).previousSibling;
        target?.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto',
            block: 'start'
        });
    } else {
        target?.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto',
            block: 'start'
        });
    }
};

export const extractHeadingText = (node: LexicalNode | null | undefined) => {
    if (node) {
        let s = '';
        if (
            node.__text &&
            !(node.__format & (1 << 5)) && // is not subscript format
            !(node.__format & (1 << 6)) // is not superscript format
        ) {
            s += node.__text;
        }
        if (node.getFirstChild && node.getFirstChild()) {
            s += extractHeadingText(node.getFirstChild());
        }
        if (node.getNextSibling()) {
            s += extractHeadingText(node.getNextSibling());
        }
        return s ? s : '...';
    }
    return '...';
};

export const debugNodeTree = (node: LexicalNode | null | undefined, level = 0) => {
    while (node) {
        let s = '';
        for (let i = 0; i < level; i++) {
            s = '    '+s;
        }
        console.log(s+node.getType(), node);
        if (node.getFirstChild && node.getFirstChild()) {
            debugNodeTree(node.getFirstChild(), level + 1);
        }
        node = node.getNextSibling();
    }
};

interface SeaRichTextProps {
    forModal: boolean,
    visible: boolean,
    setOnScroll: React.Dispatch<React.SetStateAction<((event: any) => void) | undefined>>,
    richTextState: RichTextState,
    modalContentRef?: React.RefObject<SeaScrollable>
    editButtons?: JSX.Element
}

const SeaRichText: React.FC<SeaRichTextProps> = ({
    forModal,
    visible,
    setOnScroll,
    richTextState,
    modalContentRef,
    editButtons
}) => {
    const isMounted = useRef(false);
    const onlineStatus = sharedState.onlineStatus.use();
    const [sections, setSections] = useState<any[]>();
    const [activeSection, setActiveSection] = useState<string>();
    const sectionsRef = useRef<any[]>();
    const activeSectionRef = useRef<string>();
    const editorRef = useRef<LexicalEditor>();
    const containerRef = useRef<HTMLDivElement>(null);
    const onScrollJobRef = useRef(0);
    const scrollOnChange = useRef(false); // If currently true, a change in activeSection will scroll to that section

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    function EditorRefPlugin() { // Simply for getting a reference to our LexicalEditor
        const [editor] = useLexicalComposerContext();
        editorRef.current = editor;
        return null;
    }

    useEffect(() => {
        activeSectionRef.current = activeSection;
    }, [activeSection]);

    const onScroll = useCallback(() => {
        const y = containerRef.current?.getBoundingClientRect().top;

        let headerHeight;
        if (forModal) {
            headerHeight = 80;
        } else {
            headerHeight = 44 + getSafeInsetTop();
        }
        if (y !== undefined && y < headerHeight) { // if element scrolled above header bar (44px)
            // Fixed mode
            containerRef.current?.classList.add('fixed');
        } else {
            // Normal mode
            containerRef.current?.classList.remove('fixed');
        }

        const jobId = ++onScrollJobRef.current;
        setTimeout(() => {
            if (!isMounted.current) return;
            if (
                sectionsRef.current &&
                sectionsRef.current.length > 0 &&
                jobId === onScrollJobRef.current
            ) {
                // Scrolling finished. Let's see if the current section needs updating.
                let index = 0;
                while (index < sectionsRef.current.length - 1) {
                    
                    const target = document.getElementById(`${sectionsRef.current[index + 1].tag}_${sectionsRef.current[index + 1].key}`);

                    if (target && target.getBoundingClientRect().top > 150) {
                        break;
                    }
                    index++;
                }
                if (sectionsRef.current[index].key !== activeSectionRef.current) {
                    scrollOnChange.current = false;
                    setActiveSection(sectionsRef.current[index].key);
                }
            }
        }, 50);
    }, [forModal]);

    useEffect(() => {
        if (visible && forModal) {
            scrollOnChange.current = false;
            // Make sure 'fixed' class is added after DOM elements have been drawn
            setTimeout(() => {
                if (!isMounted.current) return;
                onScroll();
            }, 10);
            setTimeout(() => {
                if (!isMounted.current) return;
                onScroll();
            }, 100);
            setTimeout(() => {
                if (!isMounted.current) return;
                onScroll();
            }, 250);
            setTimeout(() => {
                if (!isMounted.current) return;
                onScroll();
            }, 500);
            setTimeout(() => {
                if (!isMounted.current) return;
                onScroll();
            }, 1000);
        }
    }, [visible, forModal, onScroll]);

    const scrollToTop = useCallback(() => {
        scrollOnChange.current = false;
        if (sections && sections.length > 0) {
            setActiveSection(sections[0].key);
        }
        if (forModal) {
            if (modalContentRef?.current) {
                modalContentRef.current.scrollToTop(300);
            }
        } else {
            scrollPageToTop(300);
        }
    }, [sections, forModal, modalContentRef]);

    useEffect(() => {
        if (visible) {
            setOnScroll(() => { // We must pass onScroll via a function because setOnScroll will call the function to get the value
                return onScroll;
            });
            onScroll();
        } else {
            setOnScroll(undefined);
        }
    }, [visible, setOnScroll, onScroll]);

    useEffect(() => {
        //let isActive = true;
        if (richTextState?.loadedJson) {
            setTimeout(() => {
                if (editorRef.current) {
                    try {
                        const editorState = editorRef.current.parseEditorState(richTextState.loadedJson);
                        editorRef.current.setEditorState(editorState);
                        // debug tree
                        editorState.read(() => {
                            const root = $getRoot();
                            const _sections = [] as any[];
                            const scanNode = (node: LexicalNode | null | undefined) => {
                                while (node) {
                                    if (node.__tag === 'h1') {
                                        _sections.push({
                                            title: extractHeadingText(node.getFirstChild()),
                                            key: node.__key,
                                            tag: 'h1'
                                        });
                                    } else if (node.__tag === 'h2') {
                                        _sections.push({
                                            title: extractHeadingText(node.getFirstChild()),
                                            key: node.__key,
                                            tag: 'h2'
                                        });
                                    }
                                    if (node.getFirstChild && node.getFirstChild()) {
                                        scanNode(node.getFirstChild());
                                    }
                                    node = node.getNextSibling();
                                }
                            };

                            //debugNodeTree(root.getFirstChild());
                            scanNode(root.getFirstChild());
                            setSections(_sections);
                            sectionsRef.current = _sections;
                            //scrollOnChange.current = false;
                            if (_sections.length > 0) {
                                setActiveSection(_sections[0].key);
                            } else {
                                setActiveSection(undefined);
                            }
                        });
                    } catch (error) {
                        console.error('Error parsing editor state:', error);
                        // Create a fallback empty editor state
                        const fallbackState = createEditor().parseEditorState(JSON.stringify({
                            root: {
                                children: [
                                    {
                                        children: [],
                                        direction: null,
                                        format: "",
                                        indent: 0,
                                        type: "paragraph",
                                        version: 1
                                    }
                                ],
                                direction: null,
                                format: "",
                                indent: 0,
                                type: "root",
                                version: 1
                            }
                        }));
                        editorRef.current.setEditorState(fallbackState);
                        setSections([]);
                        setActiveSection(undefined);
                    }
                } else {
                    console.error('editorRef.current missing!');
                }
            }, 0);
        }
    }, [richTextState?.loadedJson]);

    const onLexicalError = (error: Error, editor: LexicalEditor) => {
        console.error(error);
        console.log('Lexical editor', editor);
    };

    const changeSection = (increment: number) => {
        if (activeSection && sections && sections.length > 0) {
            let index = 0;
            for (let i = 0; i < sections.length; i++) {
                if (activeSection === sections[i].key) {
                    index = i;
                    break;
                }
            }
            index = index + increment;
            if (index === -1) {
                scrollToTop();
                return;
            }
            index = Math.min(index, sections.length - 1);
            index = Math.max(index, 0);
            scrollOnChange.current = true;
            setActiveSection(sections[index].key);
        }
    };

    return (
        <>
            {(richTextState.loading && richTextState.downloading && !(onlineStatus?.isOnline)) ? (
                <div className="sea-no-data columns">
                    <div><SeaIcon icon="info"/></div>
                    <div>
                        The latest version of this document is currently unavailable while offline.
                    </div>
                </div>
            ) : (
                richTextState.message && (
                    <div className="sea-no-data columns">
                        <div><SeaIcon icon="info"/></div>
                        <div>
                            {richTextState.message}
                        </div>
                    </div>
                )                    
            )}
            {richTextState.loading &&
                <IonSpinner name="crescent" className="sea-spinner"/>
            }
            {!richTextState.message && richTextState.loadedJson && <>
                <div
                    ref={containerRef}
                    className="sfdoc-view columns"
                    style={{ opacity: richTextState.loading ? 0 : 1.0 }}
                >
                    {sections &&
                        <div className="sfdoc-sections">
                            <div
                                className={`lex-section-button pushy no-select only-if-fixed`}
                                onClick={(e) => scrollToTop()}
                                style={{ textAlign: 'center' }}
                            >
                                <SeaIcon
                                    //slot="icon-only"
                                    icon="moveUp"
                                    marginTop={0}
                                    forceFontSize="20px"
                                />
                            </div>
                            {sections.map((section) => {
                                return (
                                    <div
                                        key={section.key}
                                        className={`lex-section-button ${section.tag} pushy no-select${(section.key === activeSection) ? ' active' : ''}`}
                                        onClick={(e) => {
                                            scrollOnChange.current = false;
                                            setActiveSection(section.key);
                                            scrollToSection(section.tag, section.key, true);
                                        }}
                                    >
                                        {section.title}
                                    </div>
                                );
                            })}
                        </div>
                    }
                    <div style={{ flex: '1 1 0', maxWidth: ''+(lexContentWidth + 2 * lexMarginX) }}>
                        {sections && sections.length > 0 &&
                            <div className="sfdoc-bar columns">
                                <div className="button pushy no-select lex-next-prev" onClick={(e) => changeSection(-1)}>
                                    <SeaIcon icon="moveLeft" forceFontSize="50px"/>
                                </div>
                                <div>
                                    <SeaSelectRichTextHeading
                                        value={activeSection}
                                        setValue={(key) => {
                                            setActiveSection(key);
                                            let section;
                                            for (let i = 0; i < sections.length; i++) {
                                                if (sections[i].key === key) {
                                                    section = sections[i];
                                                    break;
                                                }
                                            }
                                            if (section) {
                                                if (scrollOnChange.current !== undefined) {
                                                    const target = document.getElementById(`${section.tag}_${section.key}`);
                                                    const isFirst = (section === sections[0]);
                                                    let offset = isFirst ? -66 : -16;
                                                    if (
                                                        (forModal && window.matchMedia('(max-width: 1180px)').matches) ||
                                                        (!forModal && window.matchMedia('(max-width: 1142px)').matches)
                                                    ) {
                                                        offset = isFirst ? -120 : -60;
                                                        offset -= 20;
                                                    }
                                                    if (section.tag === 'h2') {
                                                        offset -= 40;
                                                    }


                                                    // if (forModal) {
                                                    //     offset -= (section.tag === 'h2') ? 25 : 40;
                                                    //     if (modalContentRef?.current && target?.getBoundingClientRect) {
                                                    //         modalContentRef.current.scrollByPoint(0, target.getBoundingClientRect().top + offset, 300);
                                                    //     }
                                                    // } else {
                                                    //     scrollToTarget(target, offset, 300);
                                                    // }

                                                    if (forModal) {
                                                        offset -= (section.tag === 'h2') ? 25 : 40;
                                                        if (modalContentRef?.current && target?.getBoundingClientRect) {
                                                            modalContentRef.current.scrollByPoint(0, target.getBoundingClientRect().top + offset, 300);
                                                        }
                                                    } else {
                                                        scrollToTarget(target, offset, 300);
                                                    }
                                                }
                                                scrollOnChange.current = true;
                                            }
                                        }}
                                        sections={sections}
                                        scrollToTop={scrollToTop}
                                    />
                                </div>
                                <div className="button pushy no-select lex-next-prev" onClick={(e) => changeSection(1)}>
                                    <SeaIcon icon="moveRight" forceFontSize="50px"/>
                                </div>
                                {editButtons &&
                                    <div className="edit-buttons only-if-fixed only-if-atleast-720">
                                        {editButtons}
                                    </div>
                                }
                            </div>
                        }
                        <div className="document">
                            <div className="sfdoc white-zone">
                                <LexicalComposer initialConfig={{
                                    editable: false,
                                    namespace: 'LexEditor',
                                    theme: lexicalTheme,
                                    nodes: lexNodes,
                                    onError: onLexicalError
                                    //editorState: content
                                }}>
                                    <TableContext>
                                        <div className="lex-container">
                                            {/* <ToolBarPlugin level={level} /> */}
                                            {/* <LexDragDropPaste /> */}
                                            {/* <AutoFocusPlugin /> */}
                                            <RichTextPlugin
                                                contentEditable={
                                                    <div className="lex-scroller">
                                                        {/* <div className="lex-editor" ref={onRef}> */}
                                                        <div className="lex-editor">
                                                            <ContentEditable className="lex-content-editable-root"/>
                                                        </div>
                                                    </div>
                                                }
                                                placeholder={null}
                                                ErrorBoundary={LexicalErrorBoundary}
                                            />
                                            <ListPlugin />
                                            <TablePlugin />
                                            <LexTableCellResizer />
                                            <LexImages />
                                            <HorizontalRulePlugin />
                                            <TabIndentationPlugin />
                                            <EditorRefPlugin />
                                            {/* <OnChangePlugin onChange={onLexicalChange} /> */}
                                            {/* <HistoryPlugin /> */}
                                            {/* <TreeViewPlugin /> */}
                                        </div>
                                    </TableContext>
                                </LexicalComposer>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
};

export default SeaRichText;
