import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonCol, IonGrid, IonRow, IonSelectOption } from '@ionic/react';
import { sharedState } from '../../shared-state/shared-state';
import { Equipment } from '../../shared-state/VesselMaintenance/equipment';
import SeaInput from '../SeaInput/SeaInput';
import SeaSelect from '../SeaSelect/SeaSelect';
import EditEquipment from '../../modals/VesselMaintenance/EquipmentList/EditEquipment/EditEquipment';
import SeaLinkButton from '../SeaLinkButton/SeaLinkButton';
import SeaSelectCategory from '../SeaSelectCategory/SeaSelectCategory';
import SeaCheckbox from '../SeaCheckbox/SeaCheckbox';
import './SeaEquipment.css';
import { isFirestoreId } from '../../lib/firebase';

interface SeaEquipmentProps {
    equipmentId: string | undefined,
    setEquipmentId: (equipmentId: string) => void,
    initialLocationId?: string | undefined,
    locationId?: string | undefined,
    setLocationId?: (locationId: string) => void,
    hasSubmitted?: boolean,
    includeLocation?: boolean,
    includeMakeModelSerial?: boolean,
    includeContactsAndManuals?: boolean,
    includeCritical?: boolean,
    required?: boolean,
    columnSize?: string
}

const SeaEquipment: React.FC<SeaEquipmentProps> = ({
    equipmentId,
    setEquipmentId,
    initialLocationId,
    locationId,
    setLocationId,
    hasSubmitted,
    includeLocation = true,
    includeMakeModelSerial = false,
    includeContactsAndManuals = false,
    includeCritical = false,
    required = true,
    columnSize = '6'
}) => {
    const isMounted = useRef(false);
    const contacts = sharedState.contacts.use();
    const equipment = sharedState.equipment.use();
    const equipmentManualDocuments = sharedState.equipmentManualDocuments.use();
    const vesselLocations = sharedState.vesselLocations.use();
    const vesselSystems = sharedState.vesselSystems.use();
    const [initialSystemId, setInitialSystemId] = useState<string>('');
    const [systemId, setSystemId] = useState<string>('');
    const [showEditEquipmentModal, setShowEditEquipmentModal] = useState(false);
    const [equipmentItem, setEquipmentItem] = useState<Partial<Equipment>>();
    const [pulseSystem, setPulseSystem] = useState(false);
    const [pulseEquipment, setPulseEquipment] = useState(false);
    const [pulseDetails, setPulseDetails] = useState(false);
    const [addNewVisible, setAddNewVisible] = useState(false);
    const [, forceUpdate] = useState({});

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);

    // equipmentId --> equipmentItem
    useEffect(() => {
        if (!isMounted.current) {
            return;
        }

        if (equipmentId && equipmentId !== '' && equipment?.byId[equipmentId]) {
            const item = equipment.byId[equipmentId];
            setEquipmentItem(item);
            
            const systemIdToSet = item.systemId || '';
            setInitialSystemId(systemIdToSet);
            setSystemId(systemIdToSet);
            
            if (setLocationId) {
                setLocationId(item.locationId ? item.locationId : '');
            }
        } else {
            setEquipmentItem(undefined);
            // setInitialSystemId('');
            // setSystemId('');
            if (setLocationId) {
                setLocationId('');
            }
        }
    }, [equipmentId, equipment, setLocationId]);

    const equipmentList = useMemo(() => {
        if (!equipment?.all) {
            return undefined;
        }
    
        // Return empty array if systemId is invalid
        if (addNewVisible || (systemId && !equipment.bySystemId[systemId])) {
            return [];
        }
    
        let list: Equipment[] = systemId ? equipment.bySystemId[systemId] || [] : equipment.all;
    
        // Only add the deleted equipment if it exists and matches current systemId
        if (equipmentId && !list.some(item => item.id === equipmentId)) {
            const deletedItem = equipment.byId[equipmentId];
            if (deletedItem && (!systemId || deletedItem.systemId === systemId)) {
                list = [deletedItem, ...list];
            }
        }
    
        return list;
    }, [equipmentId, equipment, systemId, addNewVisible]);

    const setNewEquipment = (newEquipment: Partial<Equipment>) => {
        setEquipmentItem(newEquipment);
    };

    const onSystemChanged = useCallback((e: CustomEvent<any>) => {
        const newSystemId: string = e.detail.value;
        
        // Clear equipment selection if system changes and equipment doesn't belong to new system
        if (equipmentId && equipment) {
            const equipmentBelongsToNewSystem = equipment.byId[equipmentId]?.systemId === newSystemId;
            if (!equipmentBelongsToNewSystem) {
                setEquipmentId('');
                setEquipmentItem(undefined);
            }
        }
        
        setSystemId(newSystemId);
        forceUpdate({});
        if (isFirestoreId(newSystemId)) {
            setPulseEquipment(true);
            
            const timer = setTimeout(() => {
                if (isMounted.current) {
                    setPulseEquipment(false);
                }
            }, 1);
            return () => clearTimeout(timer);
        }
    
    }, [equipment, equipmentId, setEquipmentId]);

    const onEquipmentChanged = useCallback((e: CustomEvent<any>) => {
        const id = e.detail.value;
        if (id === '') {
            setEquipmentId('');
            setEquipmentItem(undefined);
        } else if (id === '__addNew') {
            setEquipmentId('');
            setEquipmentItem(undefined);
            // Open add new modal here
            setShowEditEquipmentModal(true);
        }  else {
            setEquipmentId(id);
            const item = equipment?.byId[id];
            if (item) {
                if (item.systemId && item.systemId !== systemId) {
                    setSystemId(item.systemId);
                    setPulseSystem(true);
                    setTimeout(() => {
                        if (!isMounted.current) return;
                        setPulseSystem(false);
                    }, 1);
                }
                if (item.locationId && setLocationId) {
                    setLocationId(item.locationId);
                }
                setPulseDetails(true);
                setTimeout(() => {
                    if (!isMounted.current) return;
                    setPulseDetails(false);
                }, 1);
            }
        }
    }, [equipment?.byId, setEquipmentId, setLocationId, systemId]);

    const handleLocationChange = useCallback((e: CustomEvent) => {
        if (setLocationId && e.detail.value !== locationId) {
            setLocationId(e.detail.value);
        }
    }, [setLocationId, locationId]);
    
    return (
        <>
            <IonCol size={columnSize} className={`${pulseSystem ? 'pulse-left' : 'pulse'}`}>
                <SeaSelectCategory
                    categories={vesselSystems}
                    label="System"
                    name="systemId"
                    initialCategoryId={initialSystemId}
                    categoryId={systemId}
                    otherPlaceholder="System"
                    onchange={onSystemChanged}
                    //onblur={handleBlur}
                    //error={touched.system ? errors.system : ''}
                    error={
                        (hasSubmitted && (systemId === undefined || systemId === ''))
                        ? (required ? 'This field is required' : (equipmentId !== undefined && equipmentId !== '' ? 'Required if you select equipment' : '')) : ''
                    }
                    onAddNew={(visible?: boolean) => setAddNewVisible(Boolean(visible))}
                />
            </IonCol>
            <IonCol size={columnSize} className={`${pulseEquipment ? 'pulse-right' : 'pulse'}`}>
                <SeaSelect
                    label="Equipment"
                    value={equipmentId}
                    onchange={onEquipmentChanged}
                    error={
                        (hasSubmitted && (equipmentId === undefined || equipmentId === ''))
                        ? (required ? 'This field is required' : (systemId !== undefined && systemId !== '' ? 'Required if you select a system' : '')) : ''
                    }
                >
                    <IonSelectOption value="">Not Set</IonSelectOption>
                    {equipmentList?.map((item) => {
                        return (
                            <IonSelectOption key={item.id} value={item.id}>
                                {item.equipment}{item.state === 'deleted' && ' (deleted)'}
                            </IonSelectOption>
                        );
                    })}
                    <IonSelectOption value="__addNew">Add New...</IonSelectOption>
                </SeaSelect>
            </IonCol>
            {includeLocation &&
                <IonCol size={columnSize} className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <SeaSelectCategory
                        categories={vesselLocations}
                        label="Location"
                        name="locationId"
                        initialCategoryId={initialLocationId}
                        categoryId={locationId}
                        otherPlaceholder="Add New Location"
                        onchange={handleLocationChange}
                        //onchange={handleChange}
                        //onblur={handleBlur}
                        //error={touched.locationId ? errors.locationId : ''}
                    />
                </IonCol>
            }
            {equipmentItem && includeCritical &&
                <IonCol size={columnSize} className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <SeaCheckbox
                        mode="beside-input"
                        label="Critical Equipment"
                        name="isCritical"
                        checked={equipmentItem.isCritical ? true : false}
                        disabled
                        help={{text: 'Equipment are marked as critical if its failure or loss of function could pose a risk to the vessel / crew. You can change whether an equipment item is critical or not through the Equipment List page.'}}
                    >
                        This equipment is critical
                    </SeaCheckbox>
                </IonCol>
            }
            {equipmentItem && includeMakeModelSerial && (equipmentItem.make || equipmentItem.model || equipmentItem.serial) &&
                <IonCol size="12" className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size="4">
                                <SeaInput
                                    label="Make"
                                    value={equipmentItem?.make}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Model"
                                    value={equipmentItem?.model}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                            <IonCol size="4">
                                <SeaInput
                                    label="Serial"
                                    value={equipmentItem?.serial}
                                    zone="white"
                                    type="text"
                                    disabled={true}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            }
            {(
                equipmentItem &&
                includeContactsAndManuals && (
                    (equipmentItem.equipmentDocumentIds && equipmentItem.equipmentDocumentIds.length > 0) ||
                    (equipmentItem.contactIds && equipmentItem.contactIds.length > 0)
                )
            ) &&
                <IonCol size="12" className={`${pulseDetails ? 'pulse-in' : 'pulse'}`}>
                    <IonGrid className="form-grid">
                        <IonRow>
                            <IonCol size={columnSize}>
                                <SeaSelect
                                    disabled={true}
                                    label="Contacts / Suppliers"
                                    name="type"
                                    value=""
                                >
                                    <IonSelectOption value="">
                                        {equipmentItem?.contactIds?.map((id: string, index: number) => {
                                            return contacts?.byId[id].name;
                                        }).join(', ')}
                                    </IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                            <IonCol size={columnSize}>
                                <SeaSelect
                                    disabled={true}
                                    label="Equipment Manuals"
                                    name="type"
                                    value=""
                                >
                                    <IonSelectOption value="">
                                        {equipmentItem?.equipmentDocumentIds?.map((id: string, index: number) => {
                                            if (!equipmentManualDocuments?.byId[id]) {
                                                return undefined;
                                            }
                                            return equipmentManualDocuments?.byId[id]?.title;
                                        }).filter((v) => v).join(', ')}
                                    </IonSelectOption>
                                </SeaSelect>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            }
            {equipmentItem &&
                <IonCol size="12">
                    <div style={{
                        textAlign: 'right',
                        margin: '-12px 0px 12px 0px'
                    }}>
                    <SeaLinkButton
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '400px'
                        }}
                        mode="simple-link"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowEditEquipmentModal(true);
                        }}
                    >
                        Edit {equipmentItem.equipment}
                    </SeaLinkButton>
                    </div>
                </IonCol>
            }
            <EditEquipment
                showModal={showEditEquipmentModal}
                setShowModal={setShowEditEquipmentModal}
                itemToUpdate={equipmentItem as Equipment}
                setNewEquipment={setNewEquipment}
                defaultSystemId={initialSystemId} // ???
                disableDelete={true}
                level={3}
            />
        </>
    );
};

export default SeaEquipment;
